import React from "react";
import { colors, media, fontSizing, THEME_SCALE } from "../theme";
import { RouteName, RouteParams } from "../../lib/routes";
import styled, { css } from "styled-components";
import Link from "../utils/Link";
import A from "../common/A";
import Flex from "./Flex";
import StyledSpan from "./StyledSpan";

const StyledInformationCard = styled(Flex.withComponent("a"))<{
  noBorder?: boolean;
  noTransform?: boolean;
}>`
  border-radius: 4px;
  width: 100%;
  min-height: calc((4px * 45) * ${THEME_SCALE});
  padding: calc(4px * 6);
  background: ${colors.pigeonSuperLight};
  box-shadow: 0 2px 4px 0 rgba(159, 173, 180, 0.5);
  transition: transform ease-out 220ms;
  text-decoration: none;

  @media screen and ${media.tablet} {
    height: calc(4px * 44);
  }
  @media screen and ${media.mobile} {
    height: auto;
  }
  ${p =>
    !p.noBorder &&
    css`
      border: 1px solid ${colors.pigeonGreyLight};
    `}
  ${p =>
    !p.noTransform &&
    css`
      &:hover {
        transform: translateY(-5px);
      }
    `}
`;

const Label = styled(StyledSpan)`
  color: ${colors.blue};
`;

const Text = styled(A)<{ centerAndScale?: boolean }>`
  ${fontSizing("label", 24)}
  ${p =>
    p.centerAndScale
      ? css`
          color: ${colors.pigeonLight};
          margin: auto 0;
          align-self: center;
          ${fontSizing("label", 32)}
        `
      : undefined};
`;

interface Props {
  route?: RouteName;
  params?: RouteParams;
  href?: string;
  className?: string;
  noBorder?: boolean;
  noTransform?: boolean;
  label?: string;
  text: string;
  ariaLabel?: string;
}

const SimpleInformationCard = ({
  label,
  text,
  route,
  params,
  href,
  className,
  noBorder,
  noTransform,
  ariaLabel
}: Props) => {
  return (
    <Link route={route} params={params} href={href}>
      {ariaLabel ? (
        <StyledInformationCard
          className={className}
          noBorder={noBorder}
          noTransform={noTransform}
          flexDirection="column"
          justifyContent="flex-start"
          aria-label={ariaLabel || `${label} - ${text}`}
          tabIndex={0}
        >
          {label && (
            <Label blue tiny>
              {label}
            </Label>
          )}
          <Text as="div" centerAndScale={!label}>
            {text}
          </Text>
        </StyledInformationCard>
      ) : (
        <StyledInformationCard
          className={className}
          noBorder={noBorder}
          noTransform={noTransform}
          flexDirection="column"
          justifyContent="flex-start"
          tabIndex={0}
        >
          {label && (
            <Label blue tiny>
              {label}
            </Label>
          )}
          <Text as="div" centerAndScale={!label}>
            {text}
          </Text>
        </StyledInformationCard>
      )}
    </Link>
  );
};

export default SimpleInformationCard;
