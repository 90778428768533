import React, { Fragment, ReactNode } from "react";
import slugify from "slugify";
import Nbsp from "../components/common/Nbsp";
import { format } from "date-fns";

export function slug(s: string) {
  return slugify(s, {
    replacement: "-",
    lower: true
  });
}

export const sizeFormat = (bytes: number, decimals?: number): ReactNode => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals || 2;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return (
    <Fragment>
      {parseFloat((bytes / Math.pow(k, i)).toFixed(i > 2 ? dm : 0))}
      <Nbsp />
      {sizes[i]}
    </Fragment>
  );
};

const types = {
  "text/plain": "TXT",
  "image/gif": "GIF",
  "image/svg+xml": "SVG",
  "image/png": "PNG",
  "image/jpeg": "JPG",
  "application/pdf": "PDF",
  "application/zip": "ZIP"
};

export const mimeFormat = (mime?: string): string | null => {
  if (!mime || !types[mime]) return null;
  return types[mime];
};
export const date = (d: string) => format(new Date(d), "DD.MM.YYYY");

export const AriaDate = ({ date: dateString }: { date: string }) => (
  // ATTENTION: changed from de-locale to format for easy ISO date formatting due to validation issue
  //            e.g.: Bad value June 1, 2023 for attribute datetime on element time
  //            see: https://html.spec.whatwg.org/multipage/text-level-semantics.html#attr-time-datetime
  <time dateTime={format(new Date(dateString), "YYYY-MM-DD")}>
    {date(dateString)}
  </time>
);
export const time = (d: string) => format(new Date(d), "HH:mm");
