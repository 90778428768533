import { css } from "styled-components";

export const THEME_SCALE = 0.75;

export const colors = {
  blue: "#00456A",
  blue20: "rgba(0, 69, 106, .2)",
  lightBlue: "#CEDBE7",
  blueDark: "#004669",
  bluegrey: "#DFE7EC",
  bluegreyTwo: "#7f99b4",
  bluegreyDark: "#819CB9",
  bluegreyDarker: "#59799B",
  shadowDarkBlue: "rgba(39, 53, 76, .8)",
  red: "#D5232E",
  errorRed: "#f44336",
  pigeon: "#818E95",
  // pigeon: "#00456A",
  anotherblue: "#E7EDF1",
  pigeonGreyLight: "#DEE5E9",
  pigeonGrey: "#B6C6CE",
  pigeonLight: "#748394",
  pigeonExtraLight: "#9FADB4",
  pigeonSuperLight: "#F1F4F6",
  darkgrey: "#4C4C4C",
  greyer: "#767676",
  grey: "#A5A5A5",
  lightGrey: "#F6F6F6",
  cloudyBlue: "#b7cbdd",
  cloudyBlueTwo: "#ccd7dd",
  paleGrey: "#f9fafb",
  paleGreyTwo: "#f0f4f5",
  white: "#ffffff",
  whiteThree: "#ddd",
  focus: "#E10019",

  // color names taken from https://chir.ag/projects/name-that-color
  calypso: "#3C6C92",
  monza: "#E10019",
  alto: "#DDDDDD"
};

export const gradientBackground = `background-image: linear-gradient(
  to bottom,
  #385370,
  #59799B
)`;

export const breakpoints = {
  superTinyZoom125: 260,
  superTiny: 380,
  tiny: 450,
  tablet: 768,
  desktop: 1024,
  wide: 1408
};

export const maxWidth = {
  text: 720,
  page: 1440
};

export const media = {
  superTinyZoom125: `(max-width: ${breakpoints.superTinyZoom125 - 1}px)`,
  superTiny: `(max-width: ${breakpoints.superTiny - 1}px)`,
  tiny: `(max-width: ${breakpoints.tiny - 1}px)`,
  mobile: `(max-width: ${breakpoints.tablet - 1}px)`,
  tablet: `(max-width: ${breakpoints.desktop - 1}px)`,
  desktop: `(min-width: ${breakpoints.desktop}px)`,
  wide: `(min-width: ${breakpoints.wide}px)`
};

export const border = {
  radius: "4px"
};

export const content = {
  width: "1408px"
};

export const font = {
  main: `"Brandon Text", sans-serif`,
  secondary: `"Minion Pro", serif`,
  sizeXXXXXL: "64px",
  sizeXXXXL: "56px",
  sizeXXXL: "48px",
  sizeXXL: "40px",
  sizeXL: "36px",
  sizeL: "32px",
  sizeM: "28px",
  sizeS: "24px",
  sizeXS: "20px",
  sizeXXS: "18px",
  sizeXXXS: "16px",
  sizeXXXXS: "14px",

  weightMedium: "500",
  weightRegular: "400",
  weightBold: "600",
  weightBlack: "900",
  weightLight: "300"
};

//[desktop, tablet+mobile, tiny]
const spacings = {
  160: [120, 80],
  120: [80, 64],
  80: [64, 40],
  64: [48, 32],
  48: [32, 24],
  40: [24, 16],
  32: [24, 16],
  24: [16, 12],
  16: [12, 8],
  12: [8],
  8: [8],
  0: [0]
};
export const spacing = (
  type: string,
  size: keyof typeof spacings,
  negative?: boolean
) => css`
  ${type}: ${negative && "-"}${spacings[size][0]}px;
  @media screen and ${media.tablet} {
    ${type}: ${negative && "-"}${spacings[size][1] || spacings[size][0]}px;
  }
  @media screen and ${media.tiny} {
    ${type}: ${negative && "-"}${spacings[size][2] ||
  spacings[size][1] ||
  spacings[size][0]}px;
  }
`;

export const bottomMargins = {
  biggest: spacing("margin-bottom", 160),
  medium: spacing("margin-bottom", 80),
  smallPlusPlus: spacing("margin-bottom", 64),
  smallPlus: spacing("margin-bottom", 48),
  small: spacing("margin-bottom", 40),
  tinyPlusPlusPlus: spacing("margin-bottom", 32),
  tinyPlusPlus: spacing("margin-bottom", 24),
  tinyPlus: spacing("margin-bottom", 16),
  tiny: spacing("margin-bottom", 12)
};

//[desktop, tablet, mobile, tiny]
const fontSizes = {
  footer: {
    24: [22],
    16: [14]
  },
  header: {
    22: [16],
    18: [14]
  },
  label: {
    32: [24, 24, 18],
    28: [20, 20, 18],
    24: [20, 18],
    20: [18, 16],
    18: [16, 14]
  },
  headline: {
    80: [80, 64, 64, 48],
    64: [48, 48, 40],
    60: [45, 45, 40],
    56: [36, 36, 28],
    48: [36, 36, 28],
    32: [24],
    24: [18],
    10: [10]
  },
  diashow: {
    48: [48, 28, 56, 32]
  },
  text: {
    48: [36, 24],
    28: [20],
    24: [18],
    16: [16]
  }
};
export const fontSizing = (type: keyof typeof fontSizes, size: number) => {
  if (!fontSizes[type][size]) {
    throw new Error("Size not exist");
  }
  return css`
  font-size: ${fontSizes[type][size][0]}px;
  ${fontSizes[type][size][1] &&
    fontSizes[type][size][1] !== fontSizes[type][size][0] &&
    css`
      @media screen and ${media.tablet} {
        font-size: ${fontSizes[type][size][1]}px;
      }
    `}
  ${fontSizes[type][size][2] &&
    fontSizes[type][size][2] !== fontSizes[type][size][1] &&
    css`
      @media screen and ${media.mobile} {
        font-size: ${fontSizes[type][size][2]}px;
      }
    `}
  ${fontSizes[type][size][3] &&
    fontSizes[type][size][3] !== fontSizes[type][size][2] &&
    css`
      @media screen and ${media.tiny} {
        font-size: ${fontSizes[type][size][3]}px;
      }
    `}
`;
};

export const breakpointsSrcsetWide = {
  0: breakpoints.tiny,
  [breakpoints.tiny]: breakpoints.tablet,
  [breakpoints.tablet]: breakpoints.desktop,
  [breakpoints.desktop]: breakpoints.wide
};

export const maxWidthImage = 1600;
