import styled, { css } from "styled-components";
import { breakpoints, maxWidth, media, spacing } from "../theme";

export const DEFAULT_PADDING = "8vw";
export const DEFAULT_PADDING_MOBILE_ZOOM = "4vw";
interface Props {
  padded?: boolean;
  leftIndented?: boolean;
  bottomMargin?: boolean;
  topMargin?: boolean;
  smallBottomMargin?: boolean;
  smallTopMargin?: boolean;
  relative?: boolean;
  center?: boolean;
  row?: boolean;
  inline?: boolean;
  block?: boolean;
}

export const defaultMaxWithStyle = css`
  max-width: ${breakpoints.wide}px;
  margin: auto;
  @media (max-width: ${maxWidth.page + 80}px) {
    padding: 0 ${DEFAULT_PADDING} 0 ${DEFAULT_PADDING};
  }
`;

const Content = styled.div<Props>`
  width: 100%;
${p =>
  p.padded &&
  css`
    max-width: ${breakpoints.wide}px;
  `}
  margin: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

${p =>
  p.relative &&
  css`
    position: relative;
  `}
${p =>
  p.inline &&
  css`
    display: inline;
  `}
${p =>
  p.block &&
  css`
    display: block;
  `}
${p =>
  p.row &&
  css`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `}
  ${p =>
    p.center &&
    css`
      justify-content: center;
      align-items: center;
    `} 
  ${p => p.bottomMargin && spacing("margin-bottom", 160)} 
  ${p => p.smallBottomMargin && spacing("margin-bottom", 80)} 
  ${p => p.topMargin && spacing("margin-top", 160)} 
  ${p => p.smallTopMargin && spacing("margin-top", 80)} 

  @media (max-width: ${maxWidth.page + 80}px) {
    padding: 0;
    ${p =>
      p.padded && p.leftIndented
        ? css`
            padding-right: ${DEFAULT_PADDING};
            padding-left: calc(${DEFAULT_PADDING} + calc(4px * 50));
          `
        : p.padded
        ? css`
            padding: 0 ${DEFAULT_PADDING};
          `
        : p.leftIndented
        ? css`
            padding: 0;
            padding-left: calc(4px * 50);
          `
        : css`
            padding: 0;
          `}
  }
  
  /* @media (max-width: ${maxWidth.page + 80}px) and ${media.wide} {
    padding: ${(p: Props) =>
      p.padded
        ? `0 ${DEFAULT_PADDING} 0 ${
            p.leftIndented ? "calc(4px * 98)" : `${DEFAULT_PADDING}`
          }`
        : 0};
  } */
  
  @media (min-width: ${maxWidth.page + 81}px) {
    padding-left: ${({ leftIndented }: Props) =>
      leftIndented && "calc(4px * 70)"};
  }
    
  @media ${media.mobile} {
    padding: ${(p: Props) =>
      p.padded ? `0 ${DEFAULT_PADDING} 0 ${DEFAULT_PADDING}` : 0};
  }

  @media ${media.superTinyZoom125} {
    padding: ${(p: Props) =>
      p.padded ? `0 ${DEFAULT_PADDING_MOBILE_ZOOM}` : 0};
  }
    word-break: break-word;
    hyphens: auto;
  }
  
  ${p =>
    p.leftIndented &&
    css`
      @media print {
        padding-left: 0 !important;
      }
    `}

`;

export default Content;
