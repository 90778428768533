import React, { Component, ReactNode } from "react";
import hoistNonReactStatic from "hoist-non-react-statics";
import { Api, ApiResult, Segment } from "../../lib/CmsApi";
import { RouteName, RouteParams } from "../../lib/routes";
import { breakpoints } from "../theme";
import { sortByPositionField } from "../../lib/sort";
import PageNotFoundError from "../../lib/pageNotFoundError";

interface NavItem {
  name: string;
  description?: string;
  route: RouteName;
  params: RouteParams;
}

const ifbDefaultItems: NavItem[] = [
  {
    name: "Über uns",
    route: "aboutUs",
    params: {}
  },
  {
    name: "Karriere",
    route: "career",
    params: {}
  },
  {
    name: "Presse",
    route: "press",
    params: {}
  },
  {
    name: "Aktiv-/Passivmanagement",
    route: "apManagement",
    params: {}
  }
];

export interface GlobalContextType {
  global: ApiResult<"loadGlobal">;
  segments: ApiResult<"listSegments">;
  ifbNav: NavItem[];
  theme: {
    isMobile: boolean;
  };
}

interface Props {
  global: GlobalContextType;
  children: ReactNode | ReactNode[];
}
interface State {
  isMobileSize: boolean;
}
export class GlobalProvider extends Component<Props, State> {
  static getInitialProps = async (api: Api): Promise<GlobalContextType> => {
    const [global, segments, infoPages] = await Promise.all([
      api.loadGlobal().catch(() => {
        console.error("MISSING GLOBAL");
        throw new PageNotFoundError();
      }),
      api
        .listSegments({
          orderBy: "posit"
        })
        .catch(() => ({
          total: 0,
          items: [] as ApiResult<"listSegments">["items"]
        })),
      api.listIfbInfopages().catch(() => ({
        total: 0,
        items: [] as ApiResult<"listIfbInfopages">["items"]
      }))
    ]);
    segments.items = segments.items.map(item => ({
      ...item,
      categoryClusters: item.categoryClusters
        ? sortByPositionField(item.categoryClusters)
        : []
    }));

    const ifbNav = ifbDefaultItems.concat(
      infoPages.items.map(p => ({
        name: p.name,
        route: "infoArticle",
        params: { slug: p.slug }
      }))
    );

    return {
      global,
      segments,
      ifbNav,
      theme: { isMobile: false }
    };
  };

  state: State = {
    isMobileSize: false
  };

  handleResize = () => {
    this.setState({ isMobileSize: window.innerWidth < breakpoints.tablet });
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    const { children, global } = this.props;

    return (
      <GlobalContext.Provider
        value={{
          ...global,
          theme: {
            isMobile: this.state.isMobileSize
          }
        }}
      >
        {children}
      </GlobalContext.Provider>
    );
  }
}

const GlobalContext = React.createContext<GlobalContextType | null>(null);
export default GlobalContext;

export interface WithGlobal {
  global: GlobalContextType | null;
}

export function withGlobalContext<P extends WithGlobal>(
  Component: React.ComponentType<P>
) {
  function ComponentWithGlobal(
    props: Pick<P, Exclude<keyof P, keyof WithGlobal>>
  ) {
    return (
      <GlobalContext.Consumer>
        {g => <Component {...(props as any)} global={g} />}
      </GlobalContext.Consumer>
    );
  }
  return hoistNonReactStatic(ComponentWithGlobal, Component);
}
