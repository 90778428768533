import React, { Children, ReactNode, useRef, useEffect } from "react";
import styled, { css } from "../../lib/styled";
import DotControls from "./DotControls";
import PlainSlider from "./AbstractSlider";
import { media } from "../theme";
import ArrowControls, { PADDING_BOTTOM } from "./ArrowControls";

const EASE_OUT_CUBIC = "cubic-bezier(0.215, 0.61, 0.355, 1)";

const Root = styled.div<{
  hideArrowsOnMobile?: boolean;
}>`
  display: flex;
  position: relative;
  @media print {
    page-break-inside: avoid;
  }
  ${p =>
    p.hideArrowsOnMobile &&
    css`
      @media screen and ${media.mobile} {
        & > button {
          display: none;
        }
      }
    `}
`;

interface RootTypes {
  autoHeight: boolean;
}
const ContentContainer = styled.div<RootTypes>`
  width: 100%;
  ${(p: RootTypes) =>
    p.autoHeight &&
    css`
      height: 100%;
    `}
`;

const OverflowContainer = styled.div<{
  padded?: boolean;
  extraBottomPadding?: boolean;
  autoHeight?: boolean;
}>`
  overflow: hidden;

  ${p =>
    // if container gets padding on the side, it messes up the
    // measure function in the abstract slider, since the parent container of this container
    // is used as base for the width of the slider
    p.padded &&
    css`
      padding: 4px 0;
    `}
  ${p =>
    p.extraBottomPadding &&
    css`
      padding-bottom: ${PADDING_BOTTOM}px;
    `}
  ${p =>
    p.autoHeight &&
    css`
      height: 100%;
    `}

`;

const SlidesStrip = styled.div<{ autoHeight?: boolean }>`
  position: relative;
  transform: translateX(0);
  transition: all 0.6s;
  ${p =>
    p.autoHeight &&
    css`
      height: 100%;
    `}
`;

const Slides = styled.ul<{ autoHeight?: boolean }>`
  list-style: none;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  padding: 0;
  padding-bottom: 10px;
  margin: 0 calc(-1 * calc(0.5 * (1vw + 8.5px)));
  ${p =>
    p.autoHeight &&
    css`
      height: 100%;
    `}
`;
const DivBox = styled.div<{ autoHeight?: boolean }>`
  ${p =>
    p.autoHeight &&
    css`
      height: 100%;
    `}
`;

const Slide = styled.li<{
  autoHeight?: boolean;
  stzCount?: number;
  sCount?: number;
  mCount?: number;
  lCount?: number;
  xlCount?: number;
  disabled?: boolean;
}>`
  padding: 0 calc(0.5 * (1vw + 8.5px));
  display: flex;
  list-style: none;
  ${p =>
    p.disabled &&
    css`
      *[tabindex] {
        pointer-events: none;
        visibility: hidden;
      }
    `}
${p =>
  p.autoHeight &&
  css`
    height: 100%;
  `}
  @media ${media.tablet} {
    ${p => {
      const width = 100 / p.mCount;
      return css`
        flex: 0 0 ${width}%;
        width: ${width}%;
        max-width: ${width}%;
      `;
    }}
  }
  @media ${media.mobile} {
    ${p => {
      const width = 100 / p.sCount;
      return css`
        flex: 0 0 ${width}%;
        width: ${width}%;
        max-width: ${width}%;
      `;
    }}
  }
  @media ${media.desktop} {
    ${p => {
      const width = 100 / p.lCount;
      return css`
        flex: 0 0 ${width}%;
        width: ${width}%;
        max-width: ${width}%;
      `;
    }}
  }
  @media ${media.wide} {
    ${p => {
      const width = 100 / p.xlCount;
      return css`
        flex: 0 0 ${width}%;
        width: ${width}%;
        max-width: ${width}%;
      `;
    }}
  }
  @media ${media.superTinyZoom125} {
    ${p => {
      const width = 100 / p.stzCount;
      return css`
        flex: 0 0 ${width}%;
        width: ${width}%;
        max-width: ${width}%;
      `;
    }}
  }
`;

interface Props {
  showArrowControls?: boolean;
  itemCountSuperTinyZoom?: number;
  itemCountSmall?: number;
  itemCountMedium?: number;
  itemCountLarge?: number;
  itemCountXLarge?: number;
  children: ReactNode | ReactNode[];
  innerButtons?: boolean;
  autoHeight?: boolean;
  className?: string;
  padded?: boolean;
  ariaLabel?: string;
  ariaHidden?: boolean;
  alwaysMobileControls?: boolean;
  setFocusOnContentChange?: boolean;
  borderedOverlayButtons?: boolean;
  hideArrowsOnMobile?: boolean;
  heroSlider?: boolean;
}
export default function Slider(props: Props) {
  const {
    showArrowControls = true,
    children,
    itemCountSuperTinyZoom = 1,
    itemCountSmall = 1,
    itemCountMedium = 2,
    itemCountLarge = 3,
    itemCountXLarge = 4,
    className = "",
    innerButtons = false,
    autoHeight,
    padded = false,
    ariaLabel,
    ariaHidden = true,
    alwaysMobileControls,
    setFocusOnContentChange = false,
    borderedOverlayButtons = false,
    hideArrowsOnMobile,
    heroSlider
  } = props;
  const slides = Children.toArray(children);
  const slidesContainerRef = useRef(null);
  useEffect(() => {
    if (setFocusOnContentChange && slidesContainerRef.current) {
      // focus needed for a11y
      slidesContainerRef.current.focus({ preventScroll: true });
      setTimeout(() => window.scrollTo(0, 0), 0);
    }
    // TODO: check if it is intentional, that `setFocusOnContentChange` is not in the deps array??
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);
  return (
    <PlainSlider
      singleSlide={
        itemCountLarge === 1 &&
        itemCountMedium === 1 &&
        itemCountSmall === 1 &&
        itemCountSuperTinyZoom === 1
      }
      numSlides={slides.length}
      slidesContainerRef={slidesContainerRef}
      render={({
        rootProps,
        strip,
        slidesContainerRef,
        slideProps,
        elementsPerPage,
        ...controlProps
      }) => (
        <Root
          hideArrowsOnMobile={hideArrowsOnMobile}
          role="region"
          aria-roledescription="Karussell"
        >
          {showArrowControls && controlProps.pages > 1 && (
            <ArrowControls
              left
              {...controlProps}
              alwaysMobileControls={alwaysMobileControls}
              borderedOverlayButtons={borderedOverlayButtons}
              bidirectionalScroll={heroSlider}
            />
          )}
          {showArrowControls && controlProps.pages > 1 && (
            <ArrowControls
              right
              {...controlProps}
              alwaysMobileControls={alwaysMobileControls}
              borderedOverlayButtons={borderedOverlayButtons}
              bidirectionalScroll={heroSlider}
            />
          )}
          <ContentContainer
            {...rootProps}
            autoHeight={autoHeight}
            className={className}
          >
            <OverflowContainer
              extraBottomPadding={!innerButtons && controlProps.pages > 1}
              autoHeight={autoHeight}
              padded={padded}
            >
              <SlidesStrip
                ref={strip.ref}
                autoHeight={autoHeight}
                style={{
                  transitionTimingFunction: EASE_OUT_CUBIC,
                  transform: strip.transform
                }}
              >
                <DivBox autoHeight={autoHeight}>
                  <Slides
                    tabIndex={-1}
                    ref={slidesContainerRef}
                    autoHeight={autoHeight}
                  >
                    {slides.map((c, i) => {
                      return (
                        <Slide
                          autoHeight={autoHeight}
                          stzCount={itemCountSuperTinyZoom}
                          sCount={itemCountSmall}
                          mCount={itemCountMedium}
                          lCount={itemCountLarge}
                          xlCount={itemCountXLarge}
                          {...slideProps}
                          key={i}
                          aria-hidden={
                            !(
                              i >= controlProps.index * elementsPerPage &&
                              i < (controlProps.index + 1) * elementsPerPage
                            )
                          }
                          disabled={
                            !(
                              i >= controlProps.index * elementsPerPage &&
                              i < (controlProps.index + 1) * elementsPerPage
                            )
                          }
                        >
                          {c}
                        </Slide>
                      );
                    })}
                  </Slides>
                </DivBox>
              </SlidesStrip>
              {controlProps.pages > 1 && (
                <DotControls
                  {...controlProps}
                  innerButtons={innerButtons}
                  ariaLabel={ariaLabel}
                  alwaysMobileControls={alwaysMobileControls}
                  blueControls={heroSlider}
                />
              )}
            </OverflowContainer>
          </ContentContainer>
        </Root>
      )}
    />
  );
}
