import React, { useContext } from "react";
import Button, { ButtonProps } from "../common/Button";
import BookmarksContext, { Bookmark } from "./BookmarksProvider";

type Props = {
  bookmark: Bookmark;
  activeText?: string;
  isDownload?: boolean;
  inverse?: boolean;
} & Omit<Omit<Omit<ButtonProps, "onClick">, "ghost">, "aria-label">;

const BookmarkButton = ({
  bookmark,
  children,
  activeText,
  isDownload,
  inverse,
  ...p
}: Props) => {
  const bookmarks = useContext(BookmarksContext);
  const activeBookmark = isDownload
    ? bookmarks.downloadIsBookmarked(bookmark.id)
    : bookmarks.articleIsBookmarked(bookmark.id);
  const onClick = activeBookmark
    ? () => {
        isDownload
          ? bookmarks.removeDownload(bookmark.id)
          : bookmarks.removeArticle(bookmark.id);
      }
    : () => {
        isDownload
          ? bookmarks.addDownload(bookmark)
          : bookmarks.addArticle(bookmark);
      };
  return (
    <Button
      {...p}
      aria-label={
        activeBookmark ? "Gemerkt - Artikel von Merkliste entfernen" : undefined
      }
      ghost={inverse ? activeBookmark : !activeBookmark}
      onclick={onClick}
    >
      {activeText && activeBookmark ? activeText : children}
    </Button>
  );
};

export default BookmarkButton;
