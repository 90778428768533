import { createGlobalStyle, css } from "styled-components";
import { colors, font, fontSizing } from "./theme";

export const FOCUS_CLASS_NAME = "with-focus";
export default createGlobalStyle`
  @font-face {
      font-family: 'Minion Pro';
      font-display: swap;
      src: url('/static/fonts/MinionPro-It.woff2') format('woff2'),
          url('/static/fonts/MinionPro-It.woff') format('woff'),
          url('/static/fonts/MinionPro-It.svg#MinionPro-It') format('svg');
      font-weight: 400;
      font-style: italic;
  }
  
  @font-face {
      font-family: 'Minion Pro';
      font-display: swap;
      src: url('/static/fonts/MinionPro-BoldIt.woff2') format('woff2'),
          url('/static/fonts/MinionPro-BoldIt.woff') format('woff'),
          url('/static/fonts/MinionPro-BoldIt.svg#MinionPro-BoldIt') format('svg');
      font-weight: 600;
      font-style: italic;
  }
  
  @font-face {
      font-family: 'Brandon Text';
      font-display: swap;
      src: url('/static/fonts/BrandonText-BoldItalic.woff2') format('woff2'),
          url('/static/fonts/BrandonText-BoldItalic.woff') format('woff'),
          url('/static/fonts/BrandonText-BoldItalic.svg#BrandonText-BoldItalic') format('svg');
      font-weight: 600;
      font-style: italic;
  }
  
  @font-face {
      font-family: 'Brandon Text';
      font-display: swap;
      src: url('/static/fonts/BrandonText-Black.woff2') format('woff2'),
          url('/static/fonts/BrandonText-Black.woff') format('woff'),
          url('/static/fonts/BrandonText-Black.svg#BrandonText-Black') format('svg');
      font-weight: 900;
      font-style: normal;
  }
  
  @font-face {
      font-family: 'Brandon Text';
      font-display: swap;
      src: url('/static/fonts/BrandonText-BlackItalic.woff2') format('woff2'),
          url('/static/fonts/BrandonText-BlackItalic.woff') format('woff'),
          url('/static/fonts/BrandonText-BlackItalic.svg#BrandonText-BlackItalic') format('svg');
      font-weight: 900;
      font-style: italic;
  }
  
  @font-face {
      font-family: 'Minion Pro';
      font-display: swap;
      src: url('/static/fonts/MinionPro-Regular.woff2') format('woff2'),
          url('/static/fonts/MinionPro-Regular.woff') format('woff'),
          url('/static/fonts/MinionPro-Regular.svg#MinionPro-Regular') format('svg');
      font-weight: 400;
      font-style: normal;
  }
  
  @font-face {
      font-family: 'Brandon Text';
      src: url('/static/fonts/BrandonText-Medium.woff2') format('woff2'),
          url('/static/fonts/BrandonText-Medium.woff') format('woff'),
          url('/static/fonts/BrandonText-Medium.svg#BrandonText-Medium') format('svg');
      font-weight: 500;
      font-style: normal;
  }
  
  
  
  @font-face {
      font-family: 'Brandon Text';
      font-display: swap;
      src: url('/static/fonts/BrandonText-RegularItalic.woff2') format('woff2'),
          url('/static/fonts/BrandonText-RegularItalic.woff') format('woff'),
          url('/static/fonts/BrandonText-RegularItalic.svg#BrandonText-RegularItalic') format('svg');
      font-weight: 400;
      font-style: italic;
  }
  
  
  
  @font-face {
      font-family: 'Brandon Text';
      font-display: swap;
      src: url('/static/fonts/BrandonText-MediumItalic.woff2') format('woff2'),
          url('/static/fonts/BrandonText-MediumItalic.woff') format('woff'),
          url('/static/fonts/BrandonText-MediumItalic.svg#BrandonText-MediumItalic') format('svg');
      font-weight: 500;
      font-style: italic;
  }
  
  @font-face {
      font-family: 'Brandon Text';
      font-display: swap;
      src: url('/static/fonts/BrandonText-Bold.woff2') format('woff2'),
          url('/static/fonts/BrandonText-Bold.woff') format('woff'),
          url('/static/fonts/BrandonText-Bold.svg#BrandonText-Bold') format('svg');
      font-weight: 600;
      font-style: normal;
  }
  
  @font-face {
      font-family: 'Brandon Text';
      font-display: swap;
      src: url('/static/fonts/BrandonText-Regular.woff2') format('woff2'),
          url('/static/fonts/BrandonText-Regular.woff') format('woff'),
          url('/static/fonts/BrandonText-Regular.svg#BrandonText-Regular') format('svg');
      font-weight: 400;
      font-style: normal;
  }
  
  @font-face {
      font-family: 'Minion Pro';
      font-display: swap;
      src: url('/static/fonts/MinionPro-Bold.woff2') format('woff2'),
          url('/static/fonts/MinionPro-Bold.woff') format('woff'),
          url('/static/fonts/MinionPro-Bold.svg#MinionPro-Bold') format('svg');
      font-weight: 600;
      font-style: normal;
  }
  
  @font-face {
      font-family: 'Brandon Text';
      font-display: swap;
      src: url('/static/fonts/BrandonText-LightItalic.woff2') format('woff2'),
          url('/static/fonts/BrandonText-LightItalic.woff') format('woff'),
          url('/static/fonts/BrandonText-LightItalic.svg#BrandonText-LightItalic') format('svg');
      font-weight: 300;
      font-style: italic;
  }
  
  @font-face {
      font-family: 'Brandon Text';
      font-display: swap;
      src: url('/static/fonts/BrandonText-Light.woff2') format('woff2'),
          url('/static/fonts/BrandonText-Light.woff') format('woff'),
          url('/static/fonts/BrandonText-Light.svg#BrandonText-Light') format('svg');
      font-weight: 300;
      font-style: normal;
  }
  html,
  body {
    background-color: ${colors.white};
    color: ${colors.darkgrey};
    margin: 0;
    padding: 0;
    ${fontSizing("text", 24)}
    font-family: ${font.main};
    width: 100%;
    overflow-x: visible;
    
    @media print {
      @page {
        size: 420mm 600mm;
      }
      * {
        box-shadow: none !important;
      }
    }
  }
  h1,h2,h3,h4,h5,h6 {
    font-weight: ${font.weightLight};
  }
  b{
    font-weight: ${font.weightBold};
  }

  *,*:after, *:before{
    box-sizing: border-box;
  }
  *:focus {
    outline: none;
  }
  .${FOCUS_CLASS_NAME} *:focus {
      outline: none;
      box-shadow: 0 0 0 2px ${colors.focus};
    }
  
    .react-responsive-modal-root {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1000;
      }
      
      .react-responsive-modal-overlay {
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
      }
      
      .react-responsive-modal-container {
        height: 100%;
        outline: 0;
        overflow-x: hidden;
        overflow-y: auto;
        text-align: center;
      }
      
      /* Used to trick the browser to center the modal content properly  */
      .react-responsive-modal-containerCenter:after {
        width: 0;
        height: 100%;
        content: '';
        display: inline-block;
        vertical-align: middle;
      }
      
      .react-responsive-modal-modal {
        max-width: 800px;
        height: 80vh;
        display: inline-block;
        text-align: left;
        vertical-align: middle;
        background: #ffffff;
        box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
        padding: 1.2rem;
        position: relative;
        overflow-y: auto;
        width: calc(100% - 16px);
      }
      
      .react-responsive-modal-closeButton {
        position: absolute;
        top: 14px;
        right: 14px;
        border: none;
        padding: 0;
        cursor: pointer;
        background-color: transparent;
        display: flex;
      }
      
      @keyframes react-responsive-modal-overlay-in {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
      
      @keyframes react-responsive-modal-overlay-out {
        0% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }
      
      @keyframes react-responsive-modal-modal-in {
        0% {
          transform: scale(0.96);
          opacity: 0;
        }
        100% {
          transform: scale(1);
          opacity: 1;
        }
      }
      
      @keyframes react-responsive-modal-modal-out {
        0% {
          transform: scale(1);
          opacity: 1;
        }
        100% {
          transform: scale(0.96);
          opacity: 0;
        }
      }
      
`;

export const visuallyHidden = css`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;
