import { ReactNode } from "react";
import styled, { css } from "styled-components";
import { colors, font, fontSizing, spacing } from "../theme";

interface Props {
  big?: boolean;
  children: ReactNode | ReactNode[];
  small?: boolean;
  smallMargin?: boolean;
  tiny?: boolean;
  white?: boolean;
  blueGrey?: boolean;
  blue?: boolean;
  regular?: boolean;
  className?: string;
  noMargin?: boolean;
}

const StyledSpan = styled.span<Props>`
  display: inline-block;
  margin:0;
  color: ${p =>
    p.blueGrey
      ? colors.bluegrey
      : p.white
      ? colors.white
      : p.blue
      ? colors.blue
      : colors.darkgrey};
  font-weight: ${p => (p.regular ? font.weightRegular : font.weightMedium)};
  word-wrap: break-word;
  line-height: 1.6;
  ${fontSizing("label", 28)}
  ${p => !p.noMargin && spacing("margin-bottom", 16)}
  ${(p: Props) => p.big && fontSizing("label", 32)}
  ${({ small, noMargin }) =>
    small &&
    css`
      ${fontSizing("label", 24)}}
      ${!noMargin && spacing("margin-bottom", 12)}
    `}
  ${(p: Props) =>
    p.tiny &&
    css`
      ${fontSizing("label", 20)}}
    `}

`;

export default StyledSpan;
