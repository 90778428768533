import React from "react";
import { colors, font } from "../theme";
import styled, { css } from "styled-components";

export const defaultLinkStyling = css`
  color: ${colors.blue};
  text-decoration: none;
  font-weight: ${font.weightMedium};
  transition: color 120ms ease-out;
  :active {
    color: ${colors.bluegreyDark};
  }
  :hover,
  :focus {
    color: ${colors.blue};
  }
`;
interface Props {
  noState?: boolean;
  tabIndex?: number;
  as?: string;
}
const A = styled.a<Props>`
  ${defaultLinkStyling}
  ${(p: Props) =>
    p.noState &&
    css`
      :active,
      :visited,
      :hover,
      :focus {
        color: inherit;
      }
    `};
`;

export default A;
