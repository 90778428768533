import range from "lodash/range";
import Dot from "./Dot";

interface Props {
  pages: number;
  index: number;
  goto: (number) => void;
  blueControls?: boolean;
}

export default function Dots({ pages, index, goto, blueControls }: Props) {
  if (pages <= 1) return null;
  return (
    <>
      {range(pages).map(i => (
        <Dot
          key={i}
          active={index === i}
          onClick={() => goto(i)}
          aria-current={index === i ? "true" : undefined}
          aria-label={
            index === i ? `Aktuelle Seite ${i + 1}` : `Gehe zur Seite ${i + 1}`
          }
          blueControls={blueControls}
        />
      ))}
    </>
  );
}
