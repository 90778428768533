import styled, { css } from "styled-components";
import {
  media,
  fontSizing,
  colors,
  spacing,
  font,
  breakpoints
} from "../theme";
import { ContentRef } from "../../lib/CmsApi";
import { RouteName, RouteParams } from "../../lib/routes";
import DefaultRichText from "./RichText";
import Label from "./Label";
import Headline from "./Headline";
import Button from "./Button";
import { ReactNode } from "react";
import { generateAutoPlacementForIE } from "./Grid";
import { responsiveBackgroundImage } from "../../lib/responsiveImage";
import getImageFocus from "../../lib/getImageFocus";
import { Image } from "../../lib/image";

const StyledButton = styled(Button)``;

const TeaserItemContent = styled.div`
  grid-area: text;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  > * {
    width: 100%;
  }
  > ${StyledButton} {
    width: auto;
  }
`;
const TeaserGrid = styled.div<{
  bottomMargin?: boolean;
  split?: boolean;
  swap?: boolean;
  stack?: boolean;
  indent?: boolean;
}>`
display: -ms-grid;
display: grid;
grid-template-columns: 1fr;
grid-template-areas: "text";
${p => generateAutoPlacementForIE(p.children, "1fr", 4 * 16)}
column-gap: calc(4px * 16);
${p =>
  p.bottomMargin &&
  css`
    &:not(:last-child) {
      ${spacing("margin-bottom", 80)}
    }
  `}
${p =>
  p.split &&
  css`
    ${TeaserItemContent} {
      padding: 1.5em 0;
      @media screen and ${media.tablet} {
        padding: 1.5em 0 0 0;
      }
    }
  `}
${({ split, swap, stack, children }) =>
  split
    ? stack
      ? css`
          grid-template-areas: "image" "text";
        `
      : css`
          grid-template-columns: 1fr 1fr;
          grid-template-areas: ${swap ? '"text image"' : '"image text"'};
          ${generateAutoPlacementForIE(children, "1fr 1fr", 4 * 16, swap)}
        `
    : ""}
@media screen and ${media.tablet} {
  grid-template-columns: 1fr;
  ${p => generateAutoPlacementForIE(p.children, "1fr", 4 * 16)}
  ${p =>
    p.split &&
    css`
      grid-template-areas: "image" "text";
    `}
}
@media screen and ${media.desktop}{
  ${p =>
    p.indent &&
    css`
      ${spacing(p.swap ? "padding-right" : "padding-left", 120)}
    `}
  }
`;

const ImageBox = styled.div`
  grid-area: image;
  display: flex;
  background-size: cover;
  background-position: center;
`;

const TeaserItemImage = styled.div<{ image: Image }>`
  width: 100%;
  padding-bottom: 50%;
  background-size: cover;
  background-position: center;
  ${p =>
    responsiveBackgroundImage({
      image: p.image,
      sizes: {
        0: breakpoints.tiny,
        [breakpoints.tiny]: breakpoints.tablet,
        [breakpoints.tablet]: breakpoints.desktop,
        [breakpoints.desktop]: 620
      }
    })};
  background-position: ${({ image }) => getImageFocus(image)};
`;

const StyledRichText = styled(DefaultRichText)<{
  blue?: boolean;
}>`
  font-family: ${font.secondary};
  ${spacing("padding-bottom", 48)};
  p:last-child {
    margin: 0;
  }
  li,
  p,
  ul,
  span {
    ${p =>
      p.blue &&
      css`
        color: ${colors.blue};
      `}
    ${fontSizing("label", 20)};
    font-weight: 400;
  }
  li:before {
    ${p =>
      p.blue &&
      css`
        color: ${colors.blue};
      `}
  }
`;
const StyledOverline = styled(Label)`
  white-space: pre-wrap;
`;

export interface ImageTextTeaserProps {
  headline: string;
  overline?: string | ReactNode;
  image: Image;
  text: string;
  ctaLabel?: string;
  ctaRef?: ContentRef | { route: RouteName; params: RouteParams };
  small?: boolean;
  html?: boolean;
  blue?: boolean;
  swap?: boolean; // swap horizontal positioning of image and content
  indent?: boolean; // intend Section
  bottomMargin?: boolean;
  deeperHeading?: boolean;
  sublineElement?: ReactNode;
  className?: string;
}

const ImageTextTeaserComponent = (p: ImageTextTeaserProps) => {
  const link =
    p.ctaRef && "_url" in p.ctaRef ? { href: p.ctaRef._url } : p.ctaRef;
  const className =
    (!p.image ? "no-image " : "") +
    (p.swap ? "swapped" : "") +
    (p.className ? p.className : "");
  return (
    <TeaserGrid
      split={!!p.image}
      swap={p.swap}
      bottomMargin={p.bottomMargin}
      indent={p.indent}
      className={className}
    >
      {p.image && (
        <ImageBox>
          {p.image.alt ? (
            <TeaserItemImage
              image={p.image}
              role="img"
              aria-label={p.image.alt}
            />
          ) : (
            <TeaserItemImage image={p.image} />
          )}
        </ImageBox>
      )}
      <TeaserItemContent>
        {p.overline && (
          <StyledOverline small={p.small}>{p.overline}</StyledOverline>
        )}
        <Headline size={p.small ? 4 : 3} level={p.deeperHeading ? 3 : 2}>
          {p.headline}
        </Headline>
        {p.sublineElement && p.sublineElement}
        {p.text && <StyledRichText blue={p.blue} html={`<p>${p.text}</p>`} />}
        {p.ctaRef && p.ctaLabel && (
          <StyledButton ghost {...link}>
            {p.ctaLabel}
          </StyledButton>
        )}
      </TeaserItemContent>
    </TeaserGrid>
  );
};

ImageTextTeaserComponent.getImages = (props: ImageTextTeaserProps) => {
  if (!props.image) return [];
  return [props.image];
};

export default ImageTextTeaserComponent;
