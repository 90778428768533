import styled, { css } from "styled-components";
import { media, colors, breakpoints } from "../theme";
import {
  ArrowLeft,
  ArrowLeftBordered,
  ArrowRight,
  ArrowRightBordered
} from "../common/icons";

export const PADDING_BOTTOM = 60;

interface ArrowProps {
  active?: boolean;
  left?: boolean;
  alwaysMobileControls?: boolean;
  borderedOverlayButtons?: boolean;
}
const Arrow = styled.button<ArrowProps>`
  padding: 0;
  border: none;
  font: inherit;
  background-color: transparent;

  display: flex;
  align-items: center;
  font-size: 32px;
  color: ${({ active }) => (active ? colors.blue : colors.lightBlue)};
  fill: currentColor;
  cursor: ${({ active }) => (active ? "pointer" : "default")};
  position: absolute;
  top: calc(50% - ${PADDING_BOTTOM / 2}px);
  left: ${(p: ArrowProps) =>
    p.left ? (p.borderedOverlayButtons ? "1vw" : "-6vw") : "unset"};
  right: ${(p: ArrowProps) =>
    p.left ? "unset" : p.borderedOverlayButtons ? "1vw" : "-6vw"};
  z-index: 1;
  transform: translateX(${(p: ArrowProps) => (p.left ? "50%" : "-50%")})
    translateY(-50%);

  ${p =>
    p.alwaysMobileControls &&
    css`
      font-size: 24px;
      top: unset;
      /* bottom: 0; */
      top: 100%;
      left: ${(p: ArrowProps) => (p.left ? "50%" : "unset")};
      right: ${(p: ArrowProps) => (p.left ? "unset" : "50%")};
      transform: translate(
        calc(${(p: ArrowProps) => (p.left ? "-240%" : "240%")}),
        -100%
      );
      @media all and (-ms-high-contrast: none) {
        left: ${(p: ArrowProps) => (p.left ? "35%" : "unset")};
        right: ${(p: ArrowProps) => (p.left ? "unset" : "35%")};
      }
    `}
  @media ${media.tablet} {
    & svg {
      ${p =>
        css`
          width: ${p.borderedOverlayButtons ? "32px" : "16px"};
        `}
    }
  }
  @media ${media.mobile} {
    font-size: 24px;
    top: unset;
    /* bottom: 0; */
    top: 100%;
    left: ${(p: ArrowProps) => (p.left ? "50%" : "unset")};
    right: ${(p: ArrowProps) => (p.left ? "unset" : "50%")};
    transform: translate(
      calc(${(p: ArrowProps) => (p.left ? "-240%" : "240%")}),
      -100%
    );
  }
  /* Target IE11 specifically  */
  @media all and (-ms-high-contrast: none) and ${media.mobile} {
    left: ${(p: ArrowProps) => (p.left ? "35%" : "unset")};
    right: ${(p: ArrowProps) => (p.left ? "unset" : "35%")};
  }
  @media (min-width: ${breakpoints.wide + 112}px) {
    ${p =>
      !p.alwaysMobileControls &&
      !p.borderedOverlayButtons &&
      css`
        left: ${(p: ArrowProps) => (p.left ? "-4vw" : "unset")};
        right: ${(p: ArrowProps) => (p.left ? "unset" : "-4vw")};
        transform: translateX(${(p: ArrowProps) => (p.left ? "50%" : "-50%")})
          translateY(-50%);
      `}
    
  }
  @media (min-width: ${breakpoints.wide + 200}px) {
    ${p =>
      !p.alwaysMobileControls &&
      !p.borderedOverlayButtons &&
      css`
        left: ${(p: ArrowProps) => (p.left ? "-6vw" : "unset")};
        right: ${(p: ArrowProps) => (p.left ? "unset" : "-6vw")};
        transform: translateX(${(p: ArrowProps) => (p.left ? "50%" : "-50%")})
          translateY(-50%);
      `}
  }
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;

interface Props {
  prev: () => void;
  next: () => void;
  pages: number;
  index: number;
  right?: boolean;
  left?: boolean;
  alwaysMobileControls?: boolean;
  borderedOverlayButtons?: boolean;
  bidirectionalScroll?: boolean;
}

export default function ArrowControls(props: Props) {
  const {
    prev,
    next,
    pages,
    index,
    left,
    alwaysMobileControls,
    borderedOverlayButtons,
    bidirectionalScroll
  } = props;
  const shouldAllowPrev = index !== 0;
  const shouldAllowNext = index !== pages - 1;
  const active = left ? shouldAllowPrev : shouldAllowNext;
  return (
    <Arrow
      left={left}
      active={active}
      tabIndex={active ? 0 : -1}
      alwaysMobileControls={alwaysMobileControls}
      onClick={
        left
          ? shouldAllowPrev
            ? prev
            : bidirectionalScroll
            ? prev
            : undefined
          : shouldAllowNext
          ? next
          : bidirectionalScroll
          ? next
          : undefined
      }
      aria-disabled={!active}
      aria-label={left ? "Zum vorherigen Slide" : "Zum nächsten Slide"}
      borderedOverlayButtons={borderedOverlayButtons}
    >
      {left ? (
        !borderedOverlayButtons ? (
          <ArrowLeft />
        ) : (
          <ArrowLeftBordered disabled={index === 0 && !bidirectionalScroll} />
        )
      ) : !borderedOverlayButtons ? (
        <ArrowRight />
      ) : (
        <ArrowRightBordered
          disabled={index === pages - 1 && !bidirectionalScroll}
        />
      )}
    </Arrow>
  );
}
