import React, { HTMLAttributes, ReactNode } from "react";
import styled, { css } from "../../lib/styled";
import { colors, font, fontSizing } from "../theme";

const HeadlineStyle = styled.h1<{
  white?: boolean;
  grey?: boolean;
  bold?: boolean;
  noWordBreak?: boolean;
  noMargin?: boolean;
  size?: HeadingLevel;
}>`
  width: 100%;
  color: ${p =>
    p.white ? colors.white : p.grey ? colors.darkgrey : colors.blue};
  line-height: 1.25;
  font-weight: ${p => (p.size === 1 ? font.weightLight : font.weightRegular)};
  ${p =>
    p.bold &&
    css`
      font-weight: ${font.weightMedium};
    `}
  ${p =>
    !p.noWordBreak &&
    css`
      word-wrap: break-word;
      hyphens: auto;
    `};
  ${p =>
    p.noMargin
      ? css`
          margin: 0;
        `
      : css`
          margin: 0 0 0.6em 0;
        `}

  ${p => p.size === 1 && fontSizing("headline", 64)}
  ${p => p.size === 2 && fontSizing("headline", 56)}
  ${p => p.size === 3 && fontSizing("headline", 48)}
  ${p => p.size === 4 && fontSizing("headline", 32)}
  ${p => p.size === 5 && fontSizing("headline", 24)}
  ${p => p.size === 6 && fontSizing("headline", 10)}
  
  [href]{
    color: inherit;
    text-decoration: none;
    &:hover{
      text-decoration: underline;
    }
  }
`;

export type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;
type Props = {
  size?: HeadingLevel;
  level: HeadingLevel;
  className?: string;
  noMargin?: boolean;
  bold?: boolean;
  grey?: boolean;
  white?: boolean;
  noWordBreak?: boolean;
  children: ReactNode | ReactNode[];
  as?: keyof JSX.IntrinsicElements | React.ComponentType;
  forwardedAs?: keyof JSX.IntrinsicElements | React.ComponentType;
} & HTMLAttributes<HTMLHeadingElement>;

const Headline = (p: Props) => {
  const {
    children,
    size = 2,
    noMargin,
    noWordBreak,
    bold,
    grey,
    white,
    level,
    as,
    ...moreP
  } = p;

  const Tag =
    as || (`h${level}` as keyof JSX.IntrinsicElements | React.ComponentType);
  return (
    <HeadlineStyle
      size={size}
      className={p.className}
      noWordBreak={noWordBreak}
      as={Tag}
      noMargin={noMargin}
      bold={bold}
      grey={grey}
      white={white}
      {...moreP}
    >
      {children}
    </HeadlineStyle>
  );
};

export default Headline;
