import React from "react";
import styled from "styled-components";
import {
  breakpoints,
  colors,
  fontSizing,
  media,
  spacing,
  THEME_SCALE
} from "../../theme";
import Label from "../../common/Label";
import Button from "../../common/Button";
import Headline from "../../common/Headline";
import { Category, ContentRef } from "../../../lib/CmsApi";
import SimpleInformationCard from "../../common/SimpleInformationCard";
import Grid, { generateAutoPlacementForIE } from "../../common/Grid";
import { responsiveBackgroundImage } from "../../../lib/responsiveImage";
import { Image } from "../../../lib/image";
import getImageFocus from "../../../lib/getImageFocus";

// TODO: THIS NEEDS REFACTORING VERY VERY BADLY!!!! GET RID OF GRID
// see IFB-1231 height: ${4 * 160 * THEME_SCALE}px;
const Container = styled.div<{
  noBottomMargin?: boolean;
  small?: boolean;
}>`
  display: -ms-grid;
  display: grid;
  grid-template-columns: 1.2fr 2fr;
  ${p => generateAutoPlacementForIE(p.children, "1.2fr 2fr", 0)}
  -ms-grid-rows: 1fr;
  grid-template-rows: 1fr;
  width: 100%;
  background-color: ${colors.paleGreyTwo};
  color: white;
  @media screen and ${p =>
      p.small ? `(max-width: ${breakpoints.wide - 1}px)` : media.tablet} {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    ${p => generateAutoPlacementForIE(p.children, "1fr", 0)};
  }

  ${p => !p.noBottomMargin && spacing("margin-bottom", 80)};

  @media ${media.desktop} {
    :nth-child(even) {
      -ms-grid-columns: 2fr 0px 1.2fr;
      grid-template-columns: 2fr 1.2fr;
      & > :first-child {
        -ms-grid-column: 3;
      }
      & > :last-child {
        order: -1;
        -ms-grid-column: 1;
      }
    }
  }
  @media screen and ${p => (p.small ? media.tablet : media.mobile)} {
    height: auto;
    min-height: ${4 * 160 * THEME_SCALE}px;
    display: flex;
    flex-direction: column-reverse;
  }
  /* Target IE11 specifically  */
  @media all and (-ms-high-contrast: none) {
    height: ${4 * 160 * THEME_SCALE}px;
  }
`;

const FundingHeadline = styled(Headline)`
  color: ${colors.blue};
  ${spacing("margin-bottom", 48)}
`;

const FundingLabel = styled(Label)`
  color: ${colors.blue};
  max-width: 100%;
  width: 100%;
  word-break: keep-all;
  hyphens: none;
`;

const FundingButton = styled(Button)`
  border-color: ${colors.blue};
`;

interface TextContainerProps {
  small?: boolean;
}
const TextContainer = styled.div<TextContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: calc(4px * 16) calc(4px * 12);
  @media screen and ${media.wide} {
    padding: calc(4px * 16) calc(4px * 20);
  }
  @media screen and ${p =>
      p.small ? `(max-width: ${breakpoints.wide - 1}px)` : media.tablet} {
    -ms-grid-row: 2;
    grid-row: 2;
    padding: calc(4px * 12) calc(4px * 8);
  }

  @media screen and ${media.superTinyZoom125} {
    padding: calc(4px * 12 / 4) calc(4px * 12 / 4);
  }
`;

const ImageContainer = styled.div<{
  small?: boolean;
  image: Image;
}>`
${p =>
  p.image &&
  responsiveBackgroundImage({
    image: p.image,
    sizes: {
      0: breakpoints.tiny,
      [breakpoints.tiny]: breakpoints.tablet,
      [breakpoints.tablet]: 730,
      [breakpoints.wide]: 880
    }
  })};
  background-position:${({ image }) =>
    image ? getImageFocus(image) : "center"};
  width: 100%;
  height: 100%;
  flex: 1;
  /* padding: calc(4px * 16) calc(4px * 12); */
  ${spacing("padding-left", 64)}
  ${spacing("padding-right", 64)}
  ${spacing("padding-top", 80)}
  ${spacing("padding-bottom", 80)}
  background-size: cover;
  display: flex;
  align-items: flex-end;
  @media screen and ${media.wide} {
    /* padding: calc(4px * 16) calc(4px * 20); */
  }
`;

const StyledGrid = styled(Grid)<{ small?: boolean }>`
  width: 100%;
  @media screen and ${media.wide} {
  }
  @media screen and ${p =>
      p.small ? `(max-width: ${breakpoints.wide - 1}px)` : media.tablet} {
    -ms-grid-row: 1;
    grid-row: 1;
    justify-content: start;
  }
`;
const StyledInformationCard = styled(SimpleInformationCard)<{
  small?: boolean;
}>`
  > div {
    overflow-wrap: break-word;
    hyphens: auto;
    ${fontSizing("text", 24)};
  }
  @media screen and ${p => (p.small ? media.tablet : media.mobile)} {
    display: none;
  }
`;

export interface Props {
  headerImage?: Image;
  slug: string;
  title: string;
  programs: {
    slug: string;
    type: {
      name: string;
    };
    name: string;
  }[];
  noBottomMargin?: boolean;
  small?: boolean;
  categories?: (ContentRef & Category)[];
}
const FundingStory = function FundingStory(props: Props) {
  return (
    <Container noBottomMargin={props.noBottomMargin} small={props.small}>
      <TextContainer small={props.small}>
        <FundingHeadline size={3} level={3}>
          {props.categories && props.categories.length >= 1 && (
            <FundingLabel>{props.categories[0].name}</FundingLabel>
          )}
          {props.title}
        </FundingHeadline>
        <FundingButton
          ghost
          route="portraitArticle"
          params={{ slug: props.slug }}
        >
          Förderportrait lesen
        </FundingButton>
      </TextContainer>
      <ImageContainer
        image={props.headerImage}
        role={props.headerImage && props.headerImage.alt ? "img" : undefined}
        aria-label={
          props.headerImage && props.headerImage.alt
            ? props.headerImage.alt
            : undefined
        }
      >
        <StyledGrid small={props.small} columns={2}>
          {props.programs &&
            props.programs
              .filter(prog => !!prog.type)
              .slice(0, 2)
              .map(prog => {
                return (
                  <StyledInformationCard
                    small={props.small}
                    key={prog.slug}
                    route="shortProgram"
                    params={{
                      slug: prog.slug
                    }}
                    label={prog.type.name}
                    text={prog.name}
                  />
                );
              })}
        </StyledGrid>
      </ImageContainer>
    </Container>
  );
};
FundingStory.getImages = ({
  headerImage
}: {
  headerImage?: Props["headerImage"];
}) => {
  if (!headerImage) return [];
  return [headerImage];
};
export default FundingStory;
