import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { colors, media } from "../theme";
import { Caret } from "./icons";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState
} from "react-accessible-accordion";

// APPEARS IN: ContactPage, DownloadPage
const DURATION = 400;

const PanelContent = styled.div`
  box-sizing: border-box;
`;

const TitleBar = styled.div<{ leftMargin?: boolean }>`
  display: flex;
  align-items: center;
  padding: calc(4px * 6) 0;
  color: ${colors.pigeonLight};
  cursor: pointer;
  ${p =>
    p.leftMargin
      ? css`
          padding: 0.75em;
        `
      : ""}
`;

const Label = styled.div`
  flex: 1 1 auto;

  @media screen and ${media.superTinyZoom125} {
    white-space: initial;
    word-break: break-word;
  }
`;

interface P {
  expanded: boolean | number;
}
const Indicator = styled.div<P>`
  padding: 0 calc(2 * 4px);
  transition: transform ${DURATION}ms ease-in-out;
  transform: rotate(${p => (p.expanded ? -90 : 90)}deg);
  svg {
    width: 24px;
    height: 24px;
  }
`;

interface Props {
  children: React.ReactNode | React.ReactNode[];
  label: React.ReactNode;
  ariaLevel?: number;
  className?: string;
  delayPanelRender?: boolean;
  uuid: string;
}

export default function Collapsible(props: Props) {
  const [shouldRenderPanel, setShouldRenderPanel] = useState(
    props.delayPanelRender ? false : true
  );
  // render content of panel after a delay to optimize performance
  // when rendering nested collapsibles
  useEffect(() => {
    if (!props.delayPanelRender) return;
    const timer = setTimeout(() => setShouldRenderPanel(true), 100);

    return () => timer && clearTimeout(timer);
  }, [props.delayPanelRender]);

  return (
    <Accordion
      allowZeroExpanded
      allowMultipleExpanded
      className={props.className}
    >
      <AccordionItem uuid={props.uuid}>
        <AccordionItemHeading aria-level={props.ariaLevel}>
          <AccordionItemButton>
            <TitleBar>
              <Label>{props.label}</Label>
              <AccordionItemState>
                {({ expanded }) => (
                  <Indicator expanded={expanded ? 1 : 0}>
                    <Caret caretSize="small" />
                  </Indicator>
                )}
              </AccordionItemState>
            </TitleBar>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          {shouldRenderPanel ? (
            <PanelContent>{props.children}</PanelContent>
          ) : null}
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
}
