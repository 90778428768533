import styled, { css } from "../../lib/styled";
import { spacing } from "../theme";
import Content from "../common/Content";
import { HEIGHT } from "../Header/constants";
import hoistNonReactStatic from "hoist-non-react-statics";

export interface SectionProps {
  inline?: boolean; // if Section has elements to its sides
  reducedSectionPadding?: boolean;
  inSectionList?: boolean;
  noTopPadding?: boolean; // white sections in particular
  noBottomPadding?: boolean;
  contentBlock?: boolean; //Content gets display:block
  color?: string;
  id?: string;
  clearBoth?: boolean;
  noBackground?: boolean;
  leftIndented?: boolean;
  fullWidth?: boolean; // for sections within padded Sections/Contents
  isNachhaltigkeit?: boolean;
}

export const Section = styled.section.attrs((props: SectionProps) => ({
  className:
    props.color && !props.noBackground && !props.isNachhaltigkeit
      ? "colouredSection"
      : ""
}))<SectionProps>`
  width: 100%;
  ${p =>
    !p.noTopPadding &&
    spacing("padding-top", p.reducedSectionPadding ? 64 : 160)}
  ${p =>
    !p.noBottomPadding
      ? spacing("padding-bottom", p.reducedSectionPadding ? 64 : 160)
      : "padding-bottom: 0"}
  ${p =>
    p.clearBoth &&
    css`
      clear: both;
    `}
  ${p =>
    p.color
      ? css`
          background: ${p.noBackground ? "unset" : p.color};
        `
      : css`
          background: unset;
        `}
  ${p =>
    p.inSectionList &&
    !p.color &&
    css`
      :not(:first-child) {
        padding-top: 0;
      }
      .colouredSection + & {
        ${spacing("padding-top", p.reducedSectionPadding ? 64 : 160)}
      }
      :not(:last-child) {
        ${spacing(
          "padding-bottom",
          p.noBottomPadding ? 0 : p.reducedSectionPadding ? 32 : 120
        )}
      }
      & + .colouredSection {
        ${spacing("margin-top", p.reducedSectionPadding ? 32 : 48)}
      }
    `}
  @media print {
    page-break-inside: avoid;
    background: #fff;
  }
`;
export const SectionAnchor = styled.a<{ name: string }>`
  display: block;
  position: relative;
  top: ${(HEIGHT + 40) * -1}px;
`;
export default Section;

export function withSection<T>(
  Component: React.ElementType,
  defaultSectionProps?: (
    props: T & { sectionProps?: SectionProps }
  ) => SectionProps,
  renderNullOn?: (props: T & { sectionProps?: SectionProps }) => boolean
) {
  const ComponentWithSection = (props: T & { sectionProps?: SectionProps }) => {
    const { sectionProps = {}, ...componentProps } = props;
    if (renderNullOn && renderNullOn(props)) {
      console.warn(
        "rendered null for Element: " + (Component as Function).name
      );
      return null;
    }
    const { id, ...combinedSectionProps } = {
      ...(defaultSectionProps && defaultSectionProps(props)),
      ...sectionProps
    };
    return (
      <Section
        {...combinedSectionProps}
        noBackground={sectionProps.noBackground || sectionProps.inline}
        reducedSectionPadding={
          sectionProps.reducedSectionPadding || sectionProps.inline
        }
      >
        {id && <SectionAnchor id={id} name={id} />}
        <Content
          padded={!combinedSectionProps.fullWidth}
          leftIndented={
            combinedSectionProps.leftIndented || combinedSectionProps.inline
          }
          block={combinedSectionProps.contentBlock}
        >
          <Component {...componentProps} sectionProps={combinedSectionProps} />
        </Content>
      </Section>
    );
  };
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  return hoistNonReactStatic(ComponentWithSection, Component);
}
