/**
 * DO NOT MODIFY - This file has been generated using oazapfts.
 */
type Encoders = Array<(s: string) => string>;
// Encode param names and values as URIComponent
const encodeReserved = [encodeURIComponent, encodeURIComponent];
// Allow reserved chars in param values
const allowReserved = [encodeURIComponent, encodeURI];
type RequestOpts = {
    headers?: Record<string, string | undefined>;
    method?: string;
};
type FetchRequestOpts = RequestOpts & {
    body?: string | FormData;
};
type JsonRequestOpts = RequestOpts & {
    body: object;
};
type MultipartRequestOpts = RequestOpts & {
    body: Record<string, string | Blob | undefined | any>;
};
type ServerI = {
    url: string;
};
export type ApiOptions = {
    /**
     * @deprecated "baseUrl" has been renamed to "server"
     */
    baseUrl?: string;
    server?: ServerI | string;
    fetch?: typeof fetch;
} & RequestInit;
function getUrl(server: string | ServerI): string {
    if (typeof server === "string") {
        return server;
    }
    return server.url;
}
export class Api {
    private _baseUrl: string;
    private _fetchImpl?: typeof fetch;
    private _fetchOpts: RequestInit;
    constructor({ baseUrl = "/rest/drafts", server, fetch: fetchImpl, ...fetchOpts }: ApiOptions = {}) {
        this._fetchImpl = fetchImpl;
        this._baseUrl = server ? getUrl(server) : baseUrl;
        this._fetchOpts = fetchOpts;
    }
    private async _fetch(url: string, req: FetchRequestOpts = {}) {
        const headers = stripUndefined(req.headers);
        const res = await (this._fetchImpl || fetch)(this._baseUrl + url, {
            ...this._fetchOpts,
            ...req,
            headers
        });
        if (!res.ok) {
            throw new HttpError(res.status, res.statusText);
        }
        return res.text();
    }
    private async _fetchJson(url: string, req: FetchRequestOpts = {}) {
        const res = await this._fetch(url, {
            ...req,
            headers: {
                ...req.headers,
                Accept: "application/json"
            }
        });
        return mergeRefs(JSON.parse(res));
    }
    private _json({ body, headers, ...req }: JsonRequestOpts) {
        return {
            ...req,
            body: JSON.stringify(body),
            headers: {
                ...headers,
                "Content-Type": "application/json"
            }
        };
    }
    private _form({ body, headers, ...req }: JsonRequestOpts) {
        return {
            ...req,
            body: QS.form(body),
            headers: {
                ...headers,
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };
    }
    private _multipart({ body, ...req }: MultipartRequestOpts) {
        const data = new FormData();
        Object.entries(body).forEach(([name, value]) => {
            data.append(name, value);
        });
        return {
            ...req,
            body: data
        };
    }
    /**
     * Search contents
     */
    async listContentBySearch(term: string, { includeModels, excludeModels, linkableOnly, offset, limit }: {
        includeModels?: ("index" | "nachhaltigkeit" | "nachhaltigkeitArticle" | "segment" | "categoryCluster" | "category" | "program" | "externalProgram" | "document" | "aboutUs" | "aboutUsArticle" | "career" | "jobCategory" | "jobOffer" | "press" | "pressRelease" | "apManagement" | "apManagementArticle" | "ifbInfopage" | "euFunding" | "magazinPage" | "magazinNewsPage" | "magazinNews" | "magazinPortraitPage" | "magazinPortraits" | "magazinEventPage" | "externalEvent" | "partnerPage" | "partnerGroup" | "partnerCategory" | "contacts" | "contact" | "genericPage" | "programType" | "documentType" | "tag" | "redirect" | "systemFile" | "serviceIncome" | "repaymentCalculator" | "serviceQSForum" | "Downloads")[];
        excludeModels?: ("index" | "nachhaltigkeit" | "nachhaltigkeitArticle" | "segment" | "categoryCluster" | "category" | "program" | "externalProgram" | "document" | "aboutUs" | "aboutUsArticle" | "career" | "jobCategory" | "jobOffer" | "press" | "pressRelease" | "apManagement" | "apManagementArticle" | "ifbInfopage" | "euFunding" | "magazinPage" | "magazinNewsPage" | "magazinNews" | "magazinPortraitPage" | "magazinPortraits" | "magazinEventPage" | "externalEvent" | "partnerPage" | "partnerGroup" | "partnerCategory" | "contacts" | "contact" | "genericPage" | "programType" | "documentType" | "tag" | "redirect" | "systemFile" | "serviceIncome" | "repaymentCalculator" | "serviceQSForum" | "Downloads")[];
        linkableOnly?: boolean;
        offset?: number;
        limit?: number;
    } = {}) {
        return await this._fetchJson(`/search/content${QS.query(QS.form({
            term,
            linkableOnly,
            offset,
            limit
        }), QS.deep({
            includeModels,
            excludeModels
        }))}`) as {
            total: number;
            items: {
                id: string;
                title: string;
                description?: string;
                image?: {
                    _id: string;
                    _ref: "media";
                    _src: string;
                };
                url?: string;
            }[];
        };
    }
    /**
     * Suggest search terms
     */
    async listSearchSuggestions(term: string, { includeModels, excludeModels, linkableOnly }: {
        includeModels?: ("index" | "nachhaltigkeit" | "nachhaltigkeitArticle" | "segment" | "categoryCluster" | "category" | "program" | "externalProgram" | "document" | "aboutUs" | "aboutUsArticle" | "career" | "jobCategory" | "jobOffer" | "press" | "pressRelease" | "apManagement" | "apManagementArticle" | "ifbInfopage" | "euFunding" | "magazinPage" | "magazinNewsPage" | "magazinNews" | "magazinPortraitPage" | "magazinPortraits" | "magazinEventPage" | "externalEvent" | "partnerPage" | "partnerGroup" | "partnerCategory" | "contacts" | "contact" | "genericPage" | "programType" | "documentType" | "tag" | "redirect" | "systemFile" | "serviceIncome" | "repaymentCalculator" | "serviceQSForum" | "Downloads")[];
        excludeModels?: ("index" | "nachhaltigkeit" | "nachhaltigkeitArticle" | "segment" | "categoryCluster" | "category" | "program" | "externalProgram" | "document" | "aboutUs" | "aboutUsArticle" | "career" | "jobCategory" | "jobOffer" | "press" | "pressRelease" | "apManagement" | "apManagementArticle" | "ifbInfopage" | "euFunding" | "magazinPage" | "magazinNewsPage" | "magazinNews" | "magazinPortraitPage" | "magazinPortraits" | "magazinEventPage" | "externalEvent" | "partnerPage" | "partnerGroup" | "partnerCategory" | "contacts" | "contact" | "genericPage" | "programType" | "documentType" | "tag" | "redirect" | "systemFile" | "serviceIncome" | "repaymentCalculator" | "serviceQSForum" | "Downloads")[];
        linkableOnly?: boolean;
    } = {}) {
        return await this._fetchJson(`/search/suggest${QS.query(QS.form({
            term,
            linkableOnly
        }), QS.deep({
            includeModels,
            excludeModels
        }))}`) as string[];
    }
    /**
     * Load Index
     */
    async loadIndex({ fields, xRichtextFormat, search }: {
        fields?: ("HeroSlider" | "contents" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
        search?: {
            term?: string;
            scope?: "title" | "global";
        };
    } = {}) {
        return await this._fetchJson(`/index${QS.query(QS.deep({
            fields,
            join: { Contact: ["name", "email", "phone", "image", "type"], TextTeaser: ["overline", "headline", "subline", "text", "ctaLabel", "ctaRef", "layoutType"], Document: ["name", "file", "description", "type.name"], Program: ["name", "slug", "type.name", "abstract", "keyFacts"], ExternalProgram: ["type", "name", "abstract", "keyFacts", "ctaLabel", "ctaHref"], JobOffer: ["name", "slug", "intro", "employmentType", "employmentSchema", "posit", "category.slug", "category.name"] },
            search
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            HeroSlider?: {
                sliderItems: {
                    title: string;
                    description: string;
                    callToActionLink: ContentRef;
                    image: MediaRef & Media;
                }[];
            };
            contents: (({
                headline: string;
                subline: string;
                image?: MediaRef & Media;
            } & {
                _type: "Finder";
            }) | ({
                headline: string;
                copy?: string;
                contacts: {
                    role: string;
                    contact: ContentRef & Contact;
                }[];
                withImage?: boolean;
            } & {
                _type: "Contacts";
            }) | ({
                image: MediaRef & Media;
            } & {
                _type: "Image";
            }) | ({
                headline: string;
                intro?: string;
                cases?: {
                    label: string;
                    headline: string;
                    text: string;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                }[];
            } & {
                _type: "ShowCase";
            }) | ({
                overline?: string;
                headline: string;
                subline?: string;
                text: string;
                ctaLabel?: string;
                ctaRef?: ContentRef;
                layoutType?: boolean;
            } & {
                _type: "TextTeaser";
            }) | ({
                teaser: ContentRef & TextTeaser;
            } & {
                _type: "LinkTextTeaser";
            }) | ({
                items?: {
                    label?: string;
                    content?: (({
                        text?: string;
                    } & {
                        _type: "Text";
                    }) | ({
                        headline?: string;
                        documents?: (ContentRef & Document)[];
                    } & {
                        _type: "Downloads";
                    }) | ({
                        image: MediaRef & Media;
                    } & {
                        _type: "Image";
                    }) | ({
                        table: string;
                        seperator: string;
                        striped: string;
                        caption?: string;
                    } & {
                        _type: "Table";
                    }))[];
                }[];
            } & {
                _type: "Accordion";
            }) | ({
                overline: string;
                headline: string;
                intro: string;
                ctaLabel: string;
                ctaRef: ContentRef;
                dias: {
                    headline: string;
                    copy?: string;
                    icon?: MediaRef & Media;
                    ariaHidden?: boolean;
                }[];
            } & {
                _type: "DiaShow";
            }) | ({
                title: string;
                voices: {
                    quote: string;
                    subline: string;
                    program: ContentRef & (Program | ExternalProgram);
                }[];
            } & {
                _type: "CustomerVoices";
            }) | ({
                title: string;
                jobs: (ContentRef & JobOffer)[];
                small?: boolean;
            } & {
                _type: "Jobs";
            }) | ({
                events?: {
                    headline: string;
                    intro?: string;
                };
            } & {
                _type: "Magazin";
            }) | ({
                program: ContentRef & Program;
            } & {
                _type: "ProgramTeaser";
            }) | ({
                overline: string;
                headline: string;
                text: string;
                ctaLabel: string;
            } & {
                _type: "Newsletter";
            }))[];
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * Load Nachhaltigkeit
     */
    async loadNachhaltigkeit({ fields, xRichtextFormat, search }: {
        fields?: ("NachhaltigkeitSlider" | "contents" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
        search?: {
            term?: string;
            scope?: "title" | "global";
        };
    } = {}) {
        return await this._fetchJson(`/nachhaltigkeit${QS.query(QS.deep({
            fields,
            search
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            NachhaltigkeitSlider?: {
                sliderItems: {
                    title: string;
                    description: string;
                    callToActionLink?: ContentRef;
                    image: MediaRef & Media;
                }[];
            };
            contents: (({
                text: string;
            } & {
                _type: "BannerTeaser";
            }) | ({
                mainTitle: string;
                mainCard?: {
                    headline: string;
                }[];
                cardRowOne?: {
                    headline: string;
                    image?: {
                        image: MediaRef & Media;
                    };
                    buttonBeschriftung: string;
                    link: ContentRef;
                }[];
                cardRowTwo?: {
                    headline: string;
                    image?: {
                        image: MediaRef & Media;
                    };
                    buttonBeschriftung?: string;
                    link: ContentRef;
                }[];
                furtherDocuments?: string;
                cardRowThree?: {
                    headline: string;
                    image?: {
                        image: MediaRef & Media;
                    };
                    buttonBeschriftung?: string;
                    link: ContentRef;
                }[];
                lowerTextArea?: {
                    text?: string;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                }[];
            } & {
                _type: "ReportsSection";
            }) | ({
                overline?: string;
                headline: string;
                subline?: string;
                text: string;
                ctaLabel?: string;
                ctaRef?: ContentRef;
            } & {
                _type: "EssentialTeaser";
            }) | ({
                intro?: {
                    headline: string;
                    introText: string;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                }[];
                infoGraphicTitle: string;
                infoGraphicBanner?: {
                    headline: string;
                    introText: string;
                }[];
                sustainableDevelopmentBanner?: {
                    headline: string;
                    introText: string;
                }[];
                furtherInfo?: {
                    headline: string;
                    text: string;
                    image: MediaRef & Media;
                    links?: {
                        ctaLabel?: string;
                        ctaRef?: ContentRef;
                    }[];
                };
            } & {
                _type: "DevelopmentGoals";
            }))[];
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * List NachhaltigkeitArticles
     */
    async listNachhaltigkeitArticles({ orderBy, order, offset, limit, title, slug, fields, xRichtextFormat, search }: {
        orderBy?: "title" | "slug";
        order?: "asc" | "desc";
        offset?: number;
        limit?: number;
        title?: {
            eq?: string | string[];
            ne?: string | string[];
        };
        slug?: {
            eq?: string | string[];
            ne?: string | string[];
        };
        fields?: ("title" | "slug" | "description" | "sections" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
        search?: {
            term?: string;
            scope?: "title" | "global";
        };
    } = {}) {
        return await this._fetchJson(`/nachhaltigkeitArticle${QS.query(QS.form({
            orderBy,
            order,
            offset,
            limit
        }), QS.deep({
            "data.title": title,
            "data.slug": slug,
            fields,
            join: { Document: ["name", "file", "description", "type.name"] },
            search
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            total: number;
            items: ({
                title: string;
                slug: string;
                description?: string;
                sections?: {
                    items?: {
                        navLink?: string;
                        items?: (({
                            text?: string;
                        } & {
                            _type: "Text";
                        }) | ({
                            table: string;
                            seperator: string;
                            striped: string;
                            caption?: string;
                        } & {
                            _type: "Table";
                        }) | ({
                            headline?: string;
                            documents?: (ContentRef & Document)[];
                        } & {
                            _type: "Downloads";
                        }))[];
                    }[];
                };
                seo?: {
                    metaTitle?: string;
                    metaDescription?: string;
                    robots?: string;
                };
            } & {
                _id: string;
            })[];
        };
    }
    /**
     * Load NachhaltigkeitArticle
     */
    async loadNachhaltigkeitArticle(id: string, { fields, xRichtextFormat }: {
        fields?: ("title" | "slug" | "description" | "sections" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
    } = {}) {
        return await this._fetchJson(`/nachhaltigkeitArticle/${id}${QS.query(QS.deep({
            fields
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            title: string;
            slug: string;
            description?: string;
            sections?: {
                items?: {
                    navLink?: string;
                    items?: (({
                        text?: string;
                    } & {
                        _type: "Text";
                    }) | ({
                        table: string;
                        seperator: string;
                        striped: string;
                        caption?: string;
                    } & {
                        _type: "Table";
                    }) | ({
                        headline?: string;
                        documents?: ContentRef[];
                    } & {
                        _type: "Downloads";
                    }))[];
                }[];
            };
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * Load NachhaltigkeitArticle
     */
    async loadNachhaltigkeitArticleBySlug(uniqueValue: string, { fields, xRichtextFormat }: {
        fields?: ("title" | "slug" | "description" | "sections" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
    } = {}) {
        return await this._fetchJson(`/nachhaltigkeitArticle/slug/${uniqueValue}${QS.query(QS.deep({
            fields,
            join: { NachhaltigkeitArticle: ["slug"], Document: ["name", "file", "description", "type.name"] }
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            title: string;
            slug: string;
            description?: string;
            sections?: {
                items?: {
                    navLink?: string;
                    items?: (({
                        text?: string;
                    } & {
                        _type: "Text";
                    }) | ({
                        table: string;
                        seperator: string;
                        striped: string;
                        caption?: string;
                    } & {
                        _type: "Table";
                    }) | ({
                        headline?: string;
                        documents?: (ContentRef & Document)[];
                    } & {
                        _type: "Downloads";
                    }))[];
                }[];
            };
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * List Segments
     */
    async listSegments({ orderBy, order, offset, limit, name, slug, contact, categoryClusters, eventTags, fields, xRichtextFormat, search }: {
        orderBy?: "posit" | "name" | "slug";
        order?: "asc" | "desc";
        offset?: number;
        limit?: number;
        name?: {
            eq?: string | string[];
            ne?: string | string[];
        };
        slug?: {
            eq?: string | string[];
            ne?: string | string[];
        };
        contact?: {
            eq?: number | string | (number | string)[];
            ne?: number | string | (number | string)[];
            gt?: number | string;
            gte?: number | string;
            lt?: number | string;
            lte?: number | string;
            path?: string;
        };
        categoryClusters?: {
            eq?: number | string | (number | string)[];
            ne?: number | string | (number | string)[];
            gt?: number | string;
            gte?: number | string;
            lt?: number | string;
            lte?: number | string;
            path?: string;
        };
        eventTags?: {
            eq?: number | string | (number | string)[];
            ne?: number | string | (number | string)[];
            gt?: number | string;
            gte?: number | string;
            lt?: number | string;
            lte?: number | string;
            path?: string;
        };
        fields?: ("posit" | "name" | "menuDescription" | "slug" | "intro" | "contacts" | "contents" | "categoryClusters" | "eventTags" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
        search?: {
            term?: string;
            scope?: "title" | "global";
        };
    } = {}) {
        return await this._fetchJson(`/segment${QS.query(QS.form({
            orderBy,
            order,
            offset,
            limit
        }), QS.deep({
            "data.name": name,
            "data.slug": slug,
            "data.contacts.contact": contact,
            "data.categoryClusters": categoryClusters,
            "data.eventTags": eventTags,
            fields,
            join: { CategoryCluster: ["name", "header", "slug", "menuDescription", "description", "posit"] },
            search
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            total: number;
            items: ({
                posit?: string;
                name: string;
                menuDescription?: string;
                slug: string;
                intro?: string;
                contacts?: {
                    role: string;
                    contact: ContentRef;
                }[];
                contents?: (({
                    overline?: string;
                    headline: string;
                    subline?: string;
                    text: string;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                    layoutType?: boolean;
                } & {
                    _type: "TextTeaser";
                }) | ({
                    teaser: ContentRef;
                } & {
                    _type: "LinkTextTeaser";
                }) | ({
                    headline: string;
                    overline?: string;
                    text: string;
                    image: MediaRef & Media;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                    swapLayout?: boolean;
                } & {
                    _type: "ImageTextTeaser";
                }) | ({
                    headline: string;
                    intro?: string;
                    cases?: {
                        label: string;
                        headline: string;
                        text: string;
                        ctaLabel?: string;
                        ctaRef?: ContentRef;
                    }[];
                } & {
                    _type: "ShowCase";
                }) | ({
                    headline: string;
                    overline?: string;
                    subline?: string;
                    text: string;
                } & {
                    _type: "CollapsibleText";
                }))[];
                categoryClusters?: (ContentRef & CategoryCluster)[];
                eventTags?: ContentRef[];
                seo?: {
                    metaTitle?: string;
                    metaDescription?: string;
                    robots?: string;
                };
            } & {
                _id: string;
            })[];
        };
    }
    /**
     * Load Segment
     */
    async loadSegment(id: string, { fields, xRichtextFormat }: {
        fields?: ("posit" | "name" | "menuDescription" | "slug" | "intro" | "contacts" | "contents" | "categoryClusters" | "eventTags" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
    } = {}) {
        return await this._fetchJson(`/segment/${id}${QS.query(QS.deep({
            fields
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            posit?: string;
            name: string;
            menuDescription?: string;
            slug: string;
            intro?: string;
            contacts?: {
                role: string;
                contact: ContentRef;
            }[];
            contents?: (({
                overline?: string;
                headline: string;
                subline?: string;
                text: string;
                ctaLabel?: string;
                ctaRef?: ContentRef;
                layoutType?: boolean;
            } & {
                _type: "TextTeaser";
            }) | ({
                teaser: ContentRef;
            } & {
                _type: "LinkTextTeaser";
            }) | ({
                headline: string;
                overline?: string;
                text: string;
                image: MediaRef & Media;
                ctaLabel?: string;
                ctaRef?: ContentRef;
                swapLayout?: boolean;
            } & {
                _type: "ImageTextTeaser";
            }) | ({
                headline: string;
                intro?: string;
                cases?: {
                    label: string;
                    headline: string;
                    text: string;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                }[];
            } & {
                _type: "ShowCase";
            }) | ({
                headline: string;
                overline?: string;
                subline?: string;
                text: string;
            } & {
                _type: "CollapsibleText";
            }))[];
            categoryClusters?: ContentRef[];
            eventTags?: ContentRef[];
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * Load Segment
     */
    async loadSegmentByName(uniqueValue: string, { fields, xRichtextFormat }: {
        fields?: ("posit" | "name" | "menuDescription" | "slug" | "intro" | "contacts" | "contents" | "categoryClusters" | "eventTags" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
    } = {}) {
        return await this._fetchJson(`/segment/name/${uniqueValue}${QS.query(QS.deep({
            fields
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            posit?: string;
            name: string;
            menuDescription?: string;
            slug: string;
            intro?: string;
            contacts?: {
                role: string;
                contact: ContentRef;
            }[];
            contents?: (({
                overline?: string;
                headline: string;
                subline?: string;
                text: string;
                ctaLabel?: string;
                ctaRef?: ContentRef;
                layoutType?: boolean;
            } & {
                _type: "TextTeaser";
            }) | ({
                teaser: ContentRef;
            } & {
                _type: "LinkTextTeaser";
            }) | ({
                headline: string;
                overline?: string;
                text: string;
                image: MediaRef & Media;
                ctaLabel?: string;
                ctaRef?: ContentRef;
                swapLayout?: boolean;
            } & {
                _type: "ImageTextTeaser";
            }) | ({
                headline: string;
                intro?: string;
                cases?: {
                    label: string;
                    headline: string;
                    text: string;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                }[];
            } & {
                _type: "ShowCase";
            }) | ({
                headline: string;
                overline?: string;
                subline?: string;
                text: string;
            } & {
                _type: "CollapsibleText";
            }))[];
            categoryClusters?: ContentRef[];
            eventTags?: ContentRef[];
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * Load Segment
     */
    async loadSegmentBySlug(uniqueValue: string, { fields, xRichtextFormat }: {
        fields?: ("posit" | "name" | "menuDescription" | "slug" | "intro" | "contacts" | "contents" | "categoryClusters" | "eventTags" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
    } = {}) {
        return await this._fetchJson(`/segment/slug/${uniqueValue}${QS.query(QS.deep({
            fields,
            join: { Contact: ["name", "phone", "email", "type", "image"], CategoryCluster: ["name", "header", "slug", "menuDescription", "description", "posit"], MagazinPortraits: ["date", "title", "headerImage", "slug", "tags", "categories", "categories.name", "programs.slug", "programs.name", "programs.type.name"], MagazinNews: ["date", "title", "headerImage", "slug", "tags", "description", "categories"], TextTeaser: ["overline", "headline", "subline", "text", "ctaLabel", "ctaRef", "layoutType"] }
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            posit?: string;
            name: string;
            menuDescription?: string;
            slug: string;
            intro?: string;
            contacts?: {
                role: string;
                contact: ContentRef & Contact;
            }[];
            contents?: (({
                overline?: string;
                headline: string;
                subline?: string;
                text: string;
                ctaLabel?: string;
                ctaRef?: ContentRef;
                layoutType?: boolean;
            } & {
                _type: "TextTeaser";
            }) | ({
                teaser: ContentRef & TextTeaser;
            } & {
                _type: "LinkTextTeaser";
            }) | ({
                headline: string;
                overline?: string;
                text: string;
                image: MediaRef & Media;
                ctaLabel?: string;
                ctaRef?: ContentRef;
                swapLayout?: boolean;
            } & {
                _type: "ImageTextTeaser";
            }) | ({
                headline: string;
                intro?: string;
                cases?: {
                    label: string;
                    headline: string;
                    text: string;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                }[];
            } & {
                _type: "ShowCase";
            }) | ({
                headline: string;
                overline?: string;
                subline?: string;
                text: string;
            } & {
                _type: "CollapsibleText";
            }))[];
            categoryClusters?: (ContentRef & CategoryCluster)[];
            eventTags?: ContentRef[];
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * List CategoryClusters
     */
    async listCategoryClusters({ orderBy, order, offset, limit, name, slug, ctaRef, segment, contact, categories, fields, xRichtextFormat, search }: {
        orderBy?: "posit" | "name" | "slug";
        order?: "asc" | "desc";
        offset?: number;
        limit?: number;
        name?: {
            eq?: string | string[];
            ne?: string | string[];
        };
        slug?: {
            eq?: string | string[];
            ne?: string | string[];
        };
        ctaRef?: {
            eq?: number | string | (number | string)[];
            ne?: number | string | (number | string)[];
            gt?: number | string;
            gte?: number | string;
            lt?: number | string;
            lte?: number | string;
            path?: string;
        };
        segment?: {
            eq?: number | string | (number | string)[];
            ne?: number | string | (number | string)[];
            gt?: number | string;
            gte?: number | string;
            lt?: number | string;
            lte?: number | string;
            path?: string;
        };
        contact?: {
            eq?: number | string | (number | string)[];
            ne?: number | string | (number | string)[];
            gt?: number | string;
            gte?: number | string;
            lt?: number | string;
            lte?: number | string;
            path?: string;
        };
        categories?: {
            eq?: number | string | (number | string)[];
            ne?: number | string | (number | string)[];
            gt?: number | string;
            gte?: number | string;
            lt?: number | string;
            lte?: number | string;
            path?: string;
        };
        fields?: ("posit" | "name" | "slug" | "menuDescription" | "description" | "header" | "infoBox" | "segment" | "contacts" | "contents" | "categories" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
        search?: {
            term?: string;
            scope?: "title" | "global";
        };
    } = {}) {
        return await this._fetchJson(`/categoryCluster${QS.query(QS.form({
            orderBy,
            order,
            offset,
            limit
        }), QS.deep({
            "data.name": name,
            "data.slug": slug,
            "data.infoBox.ctaRef": ctaRef,
            "data.segment": segment,
            "data.contacts.contact": contact,
            "data.categories": categories,
            fields,
            join: { Contact: ["name", "phone", "email", "type", "image"], Segment: ["name", "posit"], Category: ["posit", "programs.name", "programs.documents", "programs.documents.name", "programs.documents.file", "programs.documents.description", "programs.documents.type.name"] },
            search
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            total: number;
            items: ({
                posit?: string;
                name: string;
                slug: string;
                menuDescription?: string;
                description: string;
                header?: {
                    image?: MediaRef & Media;
                    headline?: string;
                    intro?: string;
                    icon?: MediaRef & Media;
                };
                infoBox?: {
                    showBox?: boolean;
                    title?: string;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                };
                segment: ContentRef & Segment;
                contacts?: {
                    role: string;
                    contact: ContentRef & Contact;
                }[];
                contents?: (({
                    overline?: string;
                    headline: string;
                    subline?: string;
                    text: string;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                    layoutType?: boolean;
                } & {
                    _type: "TextTeaser";
                }) | ({
                    teaser: ContentRef;
                } & {
                    _type: "LinkTextTeaser";
                }) | ({
                    headline: string;
                    overline?: string;
                    text: string;
                    image: MediaRef & Media;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                    swapLayout?: boolean;
                } & {
                    _type: "ImageTextTeaser";
                }) | ({
                    headline: string;
                    intro?: string;
                    cases?: {
                        label: string;
                        headline: string;
                        text: string;
                        ctaLabel?: string;
                        ctaRef?: ContentRef;
                    }[];
                } & {
                    _type: "ShowCase";
                }) | ({
                    headline: string;
                    overline?: string;
                    subline?: string;
                    text: string;
                } & {
                    _type: "CollapsibleText";
                }) | ({
                    program: ContentRef;
                } & {
                    _type: "ProgramTeaser";
                }))[];
                categories?: (ContentRef & Category)[];
                seo?: {
                    metaTitle?: string;
                    metaDescription?: string;
                    robots?: string;
                };
            } & {
                _id: string;
            })[];
        };
    }
    /**
     * Load CategoryCluster
     */
    async loadCategoryCluster(id: string, { fields, xRichtextFormat }: {
        fields?: ("posit" | "name" | "slug" | "menuDescription" | "description" | "header" | "infoBox" | "segment" | "contacts" | "contents" | "categories" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
    } = {}) {
        return await this._fetchJson(`/categoryCluster/${id}${QS.query(QS.deep({
            fields
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            posit?: string;
            name: string;
            slug: string;
            menuDescription?: string;
            description: string;
            header?: {
                image?: MediaRef & Media;
                headline?: string;
                intro?: string;
                icon?: MediaRef & Media;
            };
            infoBox?: {
                showBox?: boolean;
                title?: string;
                ctaLabel?: string;
                ctaRef?: ContentRef;
            };
            segment: ContentRef;
            contacts?: {
                role: string;
                contact: ContentRef;
            }[];
            contents?: (({
                overline?: string;
                headline: string;
                subline?: string;
                text: string;
                ctaLabel?: string;
                ctaRef?: ContentRef;
                layoutType?: boolean;
            } & {
                _type: "TextTeaser";
            }) | ({
                teaser: ContentRef;
            } & {
                _type: "LinkTextTeaser";
            }) | ({
                headline: string;
                overline?: string;
                text: string;
                image: MediaRef & Media;
                ctaLabel?: string;
                ctaRef?: ContentRef;
                swapLayout?: boolean;
            } & {
                _type: "ImageTextTeaser";
            }) | ({
                headline: string;
                intro?: string;
                cases?: {
                    label: string;
                    headline: string;
                    text: string;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                }[];
            } & {
                _type: "ShowCase";
            }) | ({
                headline: string;
                overline?: string;
                subline?: string;
                text: string;
            } & {
                _type: "CollapsibleText";
            }) | ({
                program: ContentRef;
            } & {
                _type: "ProgramTeaser";
            }))[];
            categories?: ContentRef[];
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * Load CategoryCluster
     */
    async loadCategoryClusterBySlug(uniqueValue: string, { fields, xRichtextFormat }: {
        fields?: ("posit" | "name" | "slug" | "menuDescription" | "description" | "header" | "infoBox" | "segment" | "contacts" | "contents" | "categories" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
    } = {}) {
        return await this._fetchJson(`/categoryCluster/slug/${uniqueValue}${QS.query(QS.deep({
            fields,
            join: { Contact: ["name", "phone", "email", "type", "image"], Segment: ["name", "slug", "posit", "eventTags.name"], TextTeaser: ["overline", "headline", "subline", "text", "ctaLabel", "ctaRef", "layoutType"], Category: ["slug", "name", "subheader", "posit", "programs.type.name", "programs.slug", "programs.name", "programs.abstract", "programs.ctaHref"], MagazinPortraits: ["date", "title", "headerImage", "slug", "tags", "categories", "categories.name", "programs.slug", "programs.name", "programs.type.name"], MagazinNews: ["date", "title", "headerImage", "slug", "tags", "description", "categories"] }
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            posit?: string;
            name: string;
            slug: string;
            menuDescription?: string;
            description: string;
            header?: {
                image?: MediaRef & Media;
                headline?: string;
                intro?: string;
                icon?: MediaRef & Media;
            };
            infoBox?: {
                showBox?: boolean;
                title?: string;
                ctaLabel?: string;
                ctaRef?: ContentRef;
            };
            segment: ContentRef & Segment;
            contacts?: {
                role: string;
                contact: ContentRef & Contact;
            }[];
            contents?: (({
                overline?: string;
                headline: string;
                subline?: string;
                text: string;
                ctaLabel?: string;
                ctaRef?: ContentRef;
                layoutType?: boolean;
            } & {
                _type: "TextTeaser";
            }) | ({
                teaser: ContentRef & TextTeaser;
            } & {
                _type: "LinkTextTeaser";
            }) | ({
                headline: string;
                overline?: string;
                text: string;
                image: MediaRef & Media;
                ctaLabel?: string;
                ctaRef?: ContentRef;
                swapLayout?: boolean;
            } & {
                _type: "ImageTextTeaser";
            }) | ({
                headline: string;
                intro?: string;
                cases?: {
                    label: string;
                    headline: string;
                    text: string;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                }[];
            } & {
                _type: "ShowCase";
            }) | ({
                headline: string;
                overline?: string;
                subline?: string;
                text: string;
            } & {
                _type: "CollapsibleText";
            }) | ({
                program: ContentRef;
            } & {
                _type: "ProgramTeaser";
            }))[];
            categories?: (ContentRef & Category)[];
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * List Categories
     */
    async listCategories({ orderBy, order, offset, limit, name, slug, categoryCluster, programs, contact, fields, xRichtextFormat, search }: {
        orderBy?: "posit" | "name" | "slug";
        order?: "asc" | "desc";
        offset?: number;
        limit?: number;
        name?: {
            eq?: string | string[];
            ne?: string | string[];
        };
        slug?: {
            eq?: string | string[];
            ne?: string | string[];
        };
        categoryCluster?: {
            eq?: number | string | (number | string)[];
            ne?: number | string | (number | string)[];
            gt?: number | string;
            gte?: number | string;
            lt?: number | string;
            lte?: number | string;
            path?: string;
        };
        programs?: {
            eq?: number | string | (number | string)[];
            ne?: number | string | (number | string)[];
            gt?: number | string;
            gte?: number | string;
            lt?: number | string;
            lte?: number | string;
            path?: string;
        };
        contact?: {
            eq?: number | string | (number | string)[];
            ne?: number | string | (number | string)[];
            gt?: number | string;
            gte?: number | string;
            lt?: number | string;
            lte?: number | string;
            path?: string;
        };
        fields?: ("posit" | "name" | "slug" | "categoryCluster" | "subheader" | "programs" | "contacts" | "contents" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
        search?: {
            term?: string;
            scope?: "title" | "global";
        };
    } = {}) {
        return await this._fetchJson(`/category${QS.query(QS.form({
            orderBy,
            order,
            offset,
            limit
        }), QS.deep({
            "data.name": name,
            "data.slug": slug,
            "data.categoryCluster": categoryCluster,
            "data.programs": programs,
            "data.contacts.contact": contact,
            fields,
            join: { Program: ["name", "slug", "type.name", "abstract", "keyFacts"], ExternalProgram: ["name", "slug", "type", "abstract", "keyFacts", "ctaLabel", "ctaHref"] },
            search
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            total: number;
            items: ({
                posit?: string;
                name: string;
                slug: string;
                categoryCluster: ContentRef;
                subheader?: {
                    intro: string;
                    claim?: string;
                    icon?: MediaRef & Media;
                };
                programs?: (ContentRef & (Program | ExternalProgram))[];
                contacts?: {
                    role: string;
                    contact: ContentRef;
                }[];
                contents?: (({
                    overline?: string;
                    headline: string;
                    subline?: string;
                    text: string;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                    layoutType?: boolean;
                } & {
                    _type: "TextTeaser";
                }) | ({
                    teaser: ContentRef;
                } & {
                    _type: "LinkTextTeaser";
                }) | ({
                    headline: string;
                    overline?: string;
                    text: string;
                    image: MediaRef & Media;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                    swapLayout?: boolean;
                } & {
                    _type: "ImageTextTeaser";
                }) | ({
                    headline: string;
                    intro?: string;
                    cases?: {
                        label: string;
                        headline: string;
                        text: string;
                        ctaLabel?: string;
                        ctaRef?: ContentRef;
                    }[];
                } & {
                    _type: "ShowCase";
                }) | ({
                    headline: string;
                    overline?: string;
                    subline?: string;
                    text: string;
                } & {
                    _type: "CollapsibleText";
                }))[];
                seo?: {
                    metaTitle?: string;
                    metaDescription?: string;
                    robots?: string;
                };
            } & {
                _id: string;
            })[];
        };
    }
    /**
     * Load Category
     */
    async loadCategory(id: string, { fields, xRichtextFormat }: {
        fields?: ("posit" | "name" | "slug" | "categoryCluster" | "subheader" | "programs" | "contacts" | "contents" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
    } = {}) {
        return await this._fetchJson(`/category/${id}${QS.query(QS.deep({
            fields
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            posit?: string;
            name: string;
            slug: string;
            categoryCluster: ContentRef;
            subheader?: {
                intro: string;
                claim?: string;
                icon?: MediaRef & Media;
            };
            programs?: ContentRef[];
            contacts?: {
                role: string;
                contact: ContentRef;
            }[];
            contents?: (({
                overline?: string;
                headline: string;
                subline?: string;
                text: string;
                ctaLabel?: string;
                ctaRef?: ContentRef;
                layoutType?: boolean;
            } & {
                _type: "TextTeaser";
            }) | ({
                teaser: ContentRef;
            } & {
                _type: "LinkTextTeaser";
            }) | ({
                headline: string;
                overline?: string;
                text: string;
                image: MediaRef & Media;
                ctaLabel?: string;
                ctaRef?: ContentRef;
                swapLayout?: boolean;
            } & {
                _type: "ImageTextTeaser";
            }) | ({
                headline: string;
                intro?: string;
                cases?: {
                    label: string;
                    headline: string;
                    text: string;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                }[];
            } & {
                _type: "ShowCase";
            }) | ({
                headline: string;
                overline?: string;
                subline?: string;
                text: string;
            } & {
                _type: "CollapsibleText";
            }))[];
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * Load Category
     */
    async loadCategoryBySlug(uniqueValue: string, { fields, xRichtextFormat }: {
        fields?: ("posit" | "name" | "slug" | "categoryCluster" | "subheader" | "programs" | "contacts" | "contents" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
    } = {}) {
        return await this._fetchJson(`/category/slug/${uniqueValue}${QS.query(QS.deep({
            fields,
            join: { Contact: ["name", "phone", "email", "type", "image"], Program: ["name", "slug", "type.name", "abstract", "keyFacts"], TextTeaser: ["overline", "headline", "subline", "text", "ctaLabel", "ctaRef", "layoutType"], ExternalProgram: ["name", "slug", "type", "abstract", "keyFacts", "ctaLabel", "ctaHref"], CategoryCluster: ["name", "slug", "segment.slug", "segment.name", "segment.eventTags.name"] }
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            posit?: string;
            name: string;
            slug: string;
            categoryCluster: ContentRef & CategoryCluster;
            subheader?: {
                intro: string;
                claim?: string;
                icon?: MediaRef & Media;
            };
            programs?: (ContentRef & (Program | ExternalProgram))[];
            contacts?: {
                role: string;
                contact: ContentRef & Contact;
            }[];
            contents?: (({
                overline?: string;
                headline: string;
                subline?: string;
                text: string;
                ctaLabel?: string;
                ctaRef?: ContentRef;
                layoutType?: boolean;
            } & {
                _type: "TextTeaser";
            }) | ({
                teaser: ContentRef & TextTeaser;
            } & {
                _type: "LinkTextTeaser";
            }) | ({
                headline: string;
                overline?: string;
                text: string;
                image: MediaRef & Media;
                ctaLabel?: string;
                ctaRef?: ContentRef;
                swapLayout?: boolean;
            } & {
                _type: "ImageTextTeaser";
            }) | ({
                headline: string;
                intro?: string;
                cases?: {
                    label: string;
                    headline: string;
                    text: string;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                }[];
            } & {
                _type: "ShowCase";
            }) | ({
                headline: string;
                overline?: string;
                subline?: string;
                text: string;
            } & {
                _type: "CollapsibleText";
            }))[];
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * List Programs
     */
    async listPrograms({ orderBy, order, offset, limit, name, slug, type, promotionSteps, documents, contact, categories, fields, xRichtextFormat, search }: {
        orderBy?: "name" | "slug";
        order?: "asc" | "desc";
        offset?: number;
        limit?: number;
        name?: {
            eq?: string | string[];
            ne?: string | string[];
        };
        slug?: {
            eq?: string | string[];
            ne?: string | string[];
        };
        type?: {
            eq?: number | string | (number | string)[];
            ne?: number | string | (number | string)[];
            gt?: number | string;
            gte?: number | string;
            lt?: number | string;
            lte?: number | string;
            path?: string;
        };
        promotionSteps?: {
            eq?: number | string | (number | string)[];
            ne?: number | string | (number | string)[];
            gt?: number | string;
            gte?: number | string;
            lt?: number | string;
            lte?: number | string;
            path?: string;
        };
        documents?: {
            eq?: number | string | (number | string)[];
            ne?: number | string | (number | string)[];
            gt?: number | string;
            gte?: number | string;
            lt?: number | string;
            lte?: number | string;
            path?: string;
        };
        contact?: {
            eq?: number | string | (number | string)[];
            ne?: number | string | (number | string)[];
            gt?: number | string;
            gte?: number | string;
            lt?: number | string;
            lte?: number | string;
            path?: string;
        };
        categories?: {
            eq?: number | string | (number | string)[];
            ne?: number | string | (number | string)[];
            gt?: number | string;
            gte?: number | string;
            lt?: number | string;
            lte?: number | string;
            path?: string;
        };
        fields?: ("name" | "slug" | "type" | "abstract" | "keyFacts" | "promotionSteps" | "sections" | "teasers" | "documents" | "contacts" | "categories" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
        search?: {
            term?: string;
            scope?: "title" | "global";
        };
    } = {}) {
        return await this._fetchJson(`/program${QS.query(QS.form({
            orderBy,
            order,
            offset,
            limit
        }), QS.deep({
            "data.name": name,
            "data.slug": slug,
            "data.type": type,
            "data.promotionSteps": promotionSteps,
            "data.documents": documents,
            "data.contacts.contact": contact,
            "data.categories": categories,
            fields,
            join: { Document: ["name", "file", "description", "type.name"] },
            search
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            total: number;
            items: ({
                name: string;
                slug: string;
                type: ContentRef;
                abstract: string;
                keyFacts: string[];
                promotionSteps?: ContentRef;
                sections?: {
                    items?: {
                        navLink?: string;
                        items?: (({
                            text?: string;
                        } & {
                            _type: "Text";
                        }) | ({
                            table: string;
                            seperator: string;
                            striped: string;
                            caption?: string;
                        } & {
                            _type: "Table";
                        }) | ({
                            image: MediaRef & Media;
                        } & {
                            _type: "Image";
                        }) | ({
                            headline: string;
                            overline?: string;
                            text: string;
                            image: MediaRef & Media;
                            ctaLabel?: string;
                            ctaRef?: ContentRef;
                            swapLayout?: boolean;
                        } & {
                            _type: "ImageTextTeaser";
                        }))[];
                    }[];
                };
                teasers?: (({
                    title: string;
                    headline: string;
                    copy: string;
                    cta: string;
                    link: ContentRef;
                    image?: MediaRef & Media;
                } & {
                    _type: "Teaser";
                }) | ({
                    title: string;
                    headline: string;
                    copy: string;
                    cta: string;
                    link: ContentRef & Document;
                } & {
                    _type: "DownloadTeaser";
                }) | ({
                    image: MediaRef & Media;
                } & {
                    _type: "Brand";
                }))[];
                documents?: (ContentRef & Document)[];
                contacts?: {
                    role: string;
                    contact: ContentRef;
                }[];
                categories?: ContentRef[];
                seo?: {
                    metaTitle?: string;
                    metaDescription?: string;
                    robots?: string;
                };
            } & {
                _id: string;
            })[];
        };
    }
    /**
     * Load Program
     */
    async loadProgram(id: string, { fields, xRichtextFormat }: {
        fields?: ("name" | "slug" | "type" | "abstract" | "keyFacts" | "promotionSteps" | "sections" | "teasers" | "documents" | "contacts" | "categories" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
    } = {}) {
        return await this._fetchJson(`/program/${id}${QS.query(QS.deep({
            fields
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            name: string;
            slug: string;
            type: ContentRef;
            abstract: string;
            keyFacts: string[];
            promotionSteps?: ContentRef;
            sections?: {
                items?: {
                    navLink?: string;
                    items?: (({
                        text?: string;
                    } & {
                        _type: "Text";
                    }) | ({
                        table: string;
                        seperator: string;
                        striped: string;
                        caption?: string;
                    } & {
                        _type: "Table";
                    }) | ({
                        image: MediaRef & Media;
                    } & {
                        _type: "Image";
                    }) | ({
                        headline: string;
                        overline?: string;
                        text: string;
                        image: MediaRef & Media;
                        ctaLabel?: string;
                        ctaRef?: ContentRef;
                        swapLayout?: boolean;
                    } & {
                        _type: "ImageTextTeaser";
                    }))[];
                }[];
            };
            teasers?: (({
                title: string;
                headline: string;
                copy: string;
                cta: string;
                link: ContentRef;
                image?: MediaRef & Media;
            } & {
                _type: "Teaser";
            }) | ({
                title: string;
                headline: string;
                copy: string;
                cta: string;
                link: ContentRef;
            } & {
                _type: "DownloadTeaser";
            }) | ({
                image: MediaRef & Media;
            } & {
                _type: "Brand";
            }))[];
            documents?: ContentRef[];
            contacts?: {
                role: string;
                contact: ContentRef;
            }[];
            categories?: ContentRef[];
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * Load Program
     */
    async loadProgramByName(uniqueValue: string, { fields, xRichtextFormat }: {
        fields?: ("name" | "slug" | "type" | "abstract" | "keyFacts" | "promotionSteps" | "sections" | "teasers" | "documents" | "contacts" | "categories" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
    } = {}) {
        return await this._fetchJson(`/program/name/${uniqueValue}${QS.query(QS.deep({
            fields
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            name: string;
            slug: string;
            type: ContentRef;
            abstract: string;
            keyFacts: string[];
            promotionSteps?: ContentRef;
            sections?: {
                items?: {
                    navLink?: string;
                    items?: (({
                        text?: string;
                    } & {
                        _type: "Text";
                    }) | ({
                        table: string;
                        seperator: string;
                        striped: string;
                        caption?: string;
                    } & {
                        _type: "Table";
                    }) | ({
                        image: MediaRef & Media;
                    } & {
                        _type: "Image";
                    }) | ({
                        headline: string;
                        overline?: string;
                        text: string;
                        image: MediaRef & Media;
                        ctaLabel?: string;
                        ctaRef?: ContentRef;
                        swapLayout?: boolean;
                    } & {
                        _type: "ImageTextTeaser";
                    }))[];
                }[];
            };
            teasers?: (({
                title: string;
                headline: string;
                copy: string;
                cta: string;
                link: ContentRef;
                image?: MediaRef & Media;
            } & {
                _type: "Teaser";
            }) | ({
                title: string;
                headline: string;
                copy: string;
                cta: string;
                link: ContentRef;
            } & {
                _type: "DownloadTeaser";
            }) | ({
                image: MediaRef & Media;
            } & {
                _type: "Brand";
            }))[];
            documents?: ContentRef[];
            contacts?: {
                role: string;
                contact: ContentRef;
            }[];
            categories?: ContentRef[];
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * Load Program
     */
    async loadProgramBySlug(uniqueValue: string, { fields, xRichtextFormat }: {
        fields?: ("name" | "slug" | "type" | "abstract" | "keyFacts" | "promotionSteps" | "sections" | "teasers" | "documents" | "contacts" | "categories" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
    } = {}) {
        return await this._fetchJson(`/program/slug/${uniqueValue}${QS.query(QS.deep({
            fields,
            join: { ProgramType: ["name"], Document: ["name", "file", "description", "type.name"], Contact: ["name", "phone", "email", "type", "image"], PromotionSteps: ["headline", "steps"] }
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            name: string;
            slug: string;
            type: ContentRef & ProgramType;
            abstract: string;
            keyFacts: string[];
            promotionSteps?: ContentRef & PromotionSteps;
            sections?: {
                items?: {
                    navLink?: string;
                    items?: (({
                        text?: string;
                    } & {
                        _type: "Text";
                    }) | ({
                        table: string;
                        seperator: string;
                        striped: string;
                        caption?: string;
                    } & {
                        _type: "Table";
                    }) | ({
                        image: MediaRef & Media;
                    } & {
                        _type: "Image";
                    }) | ({
                        headline: string;
                        overline?: string;
                        text: string;
                        image: MediaRef & Media;
                        ctaLabel?: string;
                        ctaRef?: ContentRef;
                        swapLayout?: boolean;
                    } & {
                        _type: "ImageTextTeaser";
                    }))[];
                }[];
            };
            teasers?: (({
                title: string;
                headline: string;
                copy: string;
                cta: string;
                link: ContentRef;
                image?: MediaRef & Media;
            } & {
                _type: "Teaser";
            }) | ({
                title: string;
                headline: string;
                copy: string;
                cta: string;
                link: ContentRef & Document;
            } & {
                _type: "DownloadTeaser";
            }) | ({
                image: MediaRef & Media;
            } & {
                _type: "Brand";
            }))[];
            documents?: (ContentRef & Document)[];
            contacts?: {
                role: string;
                contact: ContentRef & Contact;
            }[];
            categories?: ContentRef[];
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * List ExternalPrograms
     */
    async listExternalPrograms({ orderBy, order, offset, limit, type, name, fields, search }: {
        orderBy?: "name";
        order?: "asc" | "desc";
        offset?: number;
        limit?: number;
        type?: {
            eq?: number | string | (number | string)[];
            ne?: number | string | (number | string)[];
            gt?: number | string;
            gte?: number | string;
            lt?: number | string;
            lte?: number | string;
            path?: string;
        };
        name?: {
            eq?: string | string[];
            ne?: string | string[];
        };
        fields?: ("type" | "name" | "abstract" | "keyFacts" | "ctaLabel" | "ctaHref")[];
        search?: {
            term?: string;
            scope?: "title" | "global";
        };
    } = {}) {
        return await this._fetchJson(`/externalProgram${QS.query(QS.form({
            orderBy,
            order,
            offset,
            limit
        }), QS.deep({
            "data.type": type,
            "data.name": name,
            fields,
            search
        }))}`) as {
            total: number;
            items: ({
                type: ContentRef;
                name: string;
                abstract: string;
                keyFacts?: string[];
                ctaLabel: string;
                ctaHref: string;
            } & {
                _id: string;
            })[];
        };
    }
    /**
     * Load ExternalProgram
     */
    async loadExternalProgram(id: string, { fields }: {
        fields?: ("type" | "name" | "abstract" | "keyFacts" | "ctaLabel" | "ctaHref")[];
    } = {}) {
        return await this._fetchJson(`/externalProgram/${id}${QS.query(QS.deep({
            fields
        }))}`) as {
            type: ContentRef;
            name: string;
            abstract: string;
            keyFacts?: string[];
            ctaLabel: string;
            ctaHref: string;
        } & {
            _id?: string;
        };
    }
    /**
     * Load ExternalProgram
     */
    async loadExternalProgramByName(uniqueValue: string, { fields }: {
        fields?: ("type" | "name" | "abstract" | "keyFacts" | "ctaLabel" | "ctaHref")[];
    } = {}) {
        return await this._fetchJson(`/externalProgram/name/${uniqueValue}${QS.query(QS.deep({
            fields
        }))}`) as {
            type: ContentRef;
            name: string;
            abstract: string;
            keyFacts?: string[];
            ctaLabel: string;
            ctaHref: string;
        } & {
            _id?: string;
        };
    }
    /**
     * List PromotionSteps
     */
    async listPromotionSteps({ orderBy, order, offset, limit, headline, fields, search }: {
        orderBy?: "headline";
        order?: "asc" | "desc";
        offset?: number;
        limit?: number;
        headline?: {
            eq?: string | string[];
            ne?: string | string[];
        };
        fields?: ("headline" | "steps")[];
        search?: {
            term?: string;
            scope?: "title" | "global";
        };
    } = {}) {
        return await this._fetchJson(`/promotionSteps${QS.query(QS.form({
            orderBy,
            order,
            offset,
            limit
        }), QS.deep({
            "data.headline": headline,
            fields,
            search
        }))}`) as {
            total: number;
            items: ({
                headline: string;
                steps: {
                    image: MediaRef & Media;
                    title: string;
                    text: string;
                }[];
            } & {
                _id: string;
            })[];
        };
    }
    /**
     * Load PromotionSteps
     */
    async loadPromotionSteps(id: string, { fields }: {
        fields?: ("headline" | "steps")[];
    } = {}) {
        return await this._fetchJson(`/promotionSteps/${id}${QS.query(QS.deep({
            fields
        }))}`) as {
            headline: string;
            steps: {
                image: MediaRef & Media;
                title: string;
                text: string;
            }[];
        } & {
            _id?: string;
        };
    }
    /**
     * List Documents
     */
    async listDocuments({ orderBy, order, offset, limit, name, type, fields, search }: {
        orderBy?: "name";
        order?: "asc" | "desc";
        offset?: number;
        limit?: number;
        name?: {
            eq?: string | string[];
            ne?: string | string[];
        };
        type?: {
            eq?: number | string | (number | string)[];
            ne?: number | string | (number | string)[];
            gt?: number | string;
            gte?: number | string;
            lt?: number | string;
            lte?: number | string;
            path?: string;
        };
        fields?: ("name" | "type" | "file" | "text" | "description")[];
        search?: {
            term?: string;
            scope?: "title" | "global";
        };
    } = {}) {
        return await this._fetchJson(`/document${QS.query(QS.form({
            orderBy,
            order,
            offset,
            limit
        }), QS.deep({
            "data.name": name,
            "data.type": type,
            fields,
            join: { DocumentType: ["name", "type"] },
            search
        }))}`) as {
            total: number;
            items: ({
                name: string;
                type: ContentRef & DocumentType;
                file: MediaRef & Media;
                text?: string;
                description?: string;
            } & {
                _id: string;
            })[];
        };
    }
    /**
     * Load Document
     */
    async loadDocument(id: string, { fields }: {
        fields?: ("name" | "type" | "file" | "text" | "description")[];
    } = {}) {
        return await this._fetchJson(`/document/${id}${QS.query(QS.deep({
            fields
        }))}`) as {
            name: string;
            type: ContentRef;
            file: MediaRef & Media;
            text?: string;
            description?: string;
        } & {
            _id?: string;
        };
    }
    /**
     * Load Document
     */
    async loadDocumentByName(uniqueValue: string, { fields }: {
        fields?: ("name" | "type" | "file" | "text" | "description")[];
    } = {}) {
        return await this._fetchJson(`/document/name/${uniqueValue}${QS.query(QS.deep({
            fields
        }))}`) as {
            name: string;
            type: ContentRef;
            file: MediaRef & Media;
            text?: string;
            description?: string;
        } & {
            _id?: string;
        };
    }
    /**
     * Load AboutUs
     */
    async loadAboutUs({ fields, search }: {
        fields?: ("name" | "header" | "seo")[];
        search?: {
            term?: string;
            scope?: "title" | "global";
        };
    } = {}) {
        return await this._fetchJson(`/aboutUs${QS.query(QS.deep({
            fields,
            search
        }))}`) as {
            name: string;
            header?: {
                image: MediaRef & Media;
                headline?: string;
                intro?: string;
            };
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * List AboutUsArticles
     */
    async listAboutUsArticles({ orderBy, order, offset, limit, name, slug, fields, xRichtextFormat, search }: {
        orderBy?: "posit" | "name" | "slug";
        order?: "asc" | "desc";
        offset?: number;
        limit?: number;
        name?: {
            eq?: string | string[];
            ne?: string | string[];
        };
        slug?: {
            eq?: string | string[];
            ne?: string | string[];
        };
        fields?: ("posit" | "name" | "slug" | "overline" | "description" | "content" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
        search?: {
            term?: string;
            scope?: "title" | "global";
        };
    } = {}) {
        return await this._fetchJson(`/aboutUsArticle${QS.query(QS.form({
            orderBy,
            order,
            offset,
            limit
        }), QS.deep({
            "data.name": name,
            "data.slug": slug,
            fields,
            search
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            total: number;
            items: ({
                posit?: string;
                name: string;
                slug: string;
                overline: string;
                description?: string;
                content: (({
                    items?: {
                        navLink?: string;
                        items?: (({
                            text?: string;
                        } & {
                            _type: "Text";
                        }) | ({
                            headline: string;
                            copy?: string;
                            contacts: {
                                role: string;
                                contact: ContentRef;
                            }[];
                            withImage?: boolean;
                        } & {
                            _type: "Contacts";
                        }) | ({
                            image: MediaRef & Media;
                        } & {
                            _type: "Image";
                        }) | ({
                            table: string;
                            seperator: string;
                            striped: string;
                            caption?: string;
                        } & {
                            _type: "Table";
                        }) | ({
                            headline?: string;
                            documents?: ContentRef[];
                        } & {
                            _type: "Downloads";
                        }) | ({
                            headline: string;
                            intro?: string;
                            cases?: {
                                label: string;
                                headline: string;
                                text: string;
                                ctaLabel?: string;
                                ctaRef?: ContentRef;
                            }[];
                        } & {
                            _type: "ShowCase";
                        }) | ({
                            overline?: string;
                            headline: string;
                            subline?: string;
                            text: string;
                            ctaLabel?: string;
                            ctaRef?: ContentRef;
                            layoutType?: boolean;
                        } & {
                            _type: "TextTeaser";
                        }) | ({
                            items?: ContentRef[];
                        } & {
                            _type: "FundingStories";
                        }) | ({
                            items?: {
                                label?: string;
                                content?: (({
                                    text?: string;
                                } & {
                                    _type: "Text";
                                }) | ({
                                    headline?: string;
                                    documents?: ContentRef[];
                                } & {
                                    _type: "Downloads";
                                }) | ({
                                    image: MediaRef & Media;
                                } & {
                                    _type: "Image";
                                }) | ({
                                    table: string;
                                    seperator: string;
                                    striped: string;
                                    caption?: string;
                                } & {
                                    _type: "Table";
                                }))[];
                            }[];
                        } & {
                            _type: "Accordion";
                        }) | ({
                            overline: string;
                            headline: string;
                            intro: string;
                            ctaLabel: string;
                            ctaRef: ContentRef;
                            dias: {
                                headline: string;
                                copy?: string;
                                icon?: MediaRef & Media;
                                ariaHidden?: boolean;
                            }[];
                        } & {
                            _type: "DiaShow";
                        }) | ({
                            title: string;
                            voices: {
                                quote: string;
                                subline: string;
                                program: ContentRef;
                            }[];
                        } & {
                            _type: "CustomerVoices";
                        }) | ({
                            title: string;
                            jobs: ContentRef[];
                            small?: boolean;
                        } & {
                            _type: "Jobs";
                        }) | ({
                            program: ContentRef;
                        } & {
                            _type: "ProgramTeaser";
                        }) | ({
                            headline: string;
                            overline?: string;
                            text: string;
                            image: MediaRef & Media;
                            ctaLabel?: string;
                            ctaRef?: ContentRef;
                            swapLayout?: boolean;
                        } & {
                            _type: "ImageTextTeaser";
                        }) | ({
                            teaser: ContentRef;
                        } & {
                            _type: "LinkTextTeaser";
                        }))[];
                    }[];
                } & {
                    _type: "sections";
                }) | ({
                    items?: (({
                        text?: string;
                    } & {
                        _type: "Text";
                    }) | ({
                        headline: string;
                        copy?: string;
                        contacts: {
                            role: string;
                            contact: ContentRef;
                        }[];
                        withImage?: boolean;
                    } & {
                        _type: "Contacts";
                    }) | ({
                        image: MediaRef & Media;
                    } & {
                        _type: "Image";
                    }) | ({
                        table: string;
                        seperator: string;
                        striped: string;
                        caption?: string;
                    } & {
                        _type: "Table";
                    }) | ({
                        headline?: string;
                        documents?: ContentRef[];
                    } & {
                        _type: "Downloads";
                    }) | ({
                        headline: string;
                        intro?: string;
                        cases?: {
                            label: string;
                            headline: string;
                            text: string;
                            ctaLabel?: string;
                            ctaRef?: ContentRef;
                        }[];
                    } & {
                        _type: "ShowCase";
                    }) | ({
                        overline?: string;
                        headline: string;
                        subline?: string;
                        text: string;
                        ctaLabel?: string;
                        ctaRef?: ContentRef;
                        layoutType?: boolean;
                    } & {
                        _type: "TextTeaser";
                    }) | ({
                        items?: ContentRef[];
                    } & {
                        _type: "FundingStories";
                    }) | ({
                        items?: {
                            label?: string;
                            content?: (({
                                text?: string;
                            } & {
                                _type: "Text";
                            }) | ({
                                headline?: string;
                                documents?: ContentRef[];
                            } & {
                                _type: "Downloads";
                            }) | ({
                                image: MediaRef & Media;
                            } & {
                                _type: "Image";
                            }) | ({
                                table: string;
                                seperator: string;
                                striped: string;
                                caption?: string;
                            } & {
                                _type: "Table";
                            }))[];
                        }[];
                    } & {
                        _type: "Accordion";
                    }) | ({
                        overline: string;
                        headline: string;
                        intro: string;
                        ctaLabel: string;
                        ctaRef: ContentRef;
                        dias: {
                            headline: string;
                            copy?: string;
                            icon?: MediaRef & Media;
                            ariaHidden?: boolean;
                        }[];
                    } & {
                        _type: "DiaShow";
                    }) | ({
                        title: string;
                        voices: {
                            quote: string;
                            subline: string;
                            program: ContentRef;
                        }[];
                    } & {
                        _type: "CustomerVoices";
                    }) | ({
                        title: string;
                        jobs: ContentRef[];
                        small?: boolean;
                    } & {
                        _type: "Jobs";
                    }) | ({
                        program: ContentRef;
                    } & {
                        _type: "ProgramTeaser";
                    }) | ({
                        headline: string;
                        overline?: string;
                        text: string;
                        image: MediaRef & Media;
                        ctaLabel?: string;
                        ctaRef?: ContentRef;
                        swapLayout?: boolean;
                    } & {
                        _type: "ImageTextTeaser";
                    }) | ({
                        teaser: ContentRef;
                    } & {
                        _type: "LinkTextTeaser";
                    }))[];
                } & {
                    _type: "contents";
                }))[];
                seo?: {
                    metaTitle?: string;
                    metaDescription?: string;
                    robots?: string;
                };
            } & {
                _id: string;
            })[];
        };
    }
    /**
     * Load AboutUsArticle
     */
    async loadAboutUsArticle(id: string, { fields, xRichtextFormat }: {
        fields?: ("posit" | "name" | "slug" | "overline" | "description" | "content" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
    } = {}) {
        return await this._fetchJson(`/aboutUsArticle/${id}${QS.query(QS.deep({
            fields
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            posit?: string;
            name: string;
            slug: string;
            overline: string;
            description?: string;
            content: (({
                items?: {
                    navLink?: string;
                    items?: (({
                        text?: string;
                    } & {
                        _type: "Text";
                    }) | ({
                        headline: string;
                        copy?: string;
                        contacts: {
                            role: string;
                            contact: ContentRef;
                        }[];
                        withImage?: boolean;
                    } & {
                        _type: "Contacts";
                    }) | ({
                        image: MediaRef & Media;
                    } & {
                        _type: "Image";
                    }) | ({
                        table: string;
                        seperator: string;
                        striped: string;
                        caption?: string;
                    } & {
                        _type: "Table";
                    }) | ({
                        headline?: string;
                        documents?: ContentRef[];
                    } & {
                        _type: "Downloads";
                    }) | ({
                        headline: string;
                        intro?: string;
                        cases?: {
                            label: string;
                            headline: string;
                            text: string;
                            ctaLabel?: string;
                            ctaRef?: ContentRef;
                        }[];
                    } & {
                        _type: "ShowCase";
                    }) | ({
                        overline?: string;
                        headline: string;
                        subline?: string;
                        text: string;
                        ctaLabel?: string;
                        ctaRef?: ContentRef;
                        layoutType?: boolean;
                    } & {
                        _type: "TextTeaser";
                    }) | ({
                        items?: ContentRef[];
                    } & {
                        _type: "FundingStories";
                    }) | ({
                        items?: {
                            label?: string;
                            content?: (({
                                text?: string;
                            } & {
                                _type: "Text";
                            }) | ({
                                headline?: string;
                                documents?: ContentRef[];
                            } & {
                                _type: "Downloads";
                            }) | ({
                                image: MediaRef & Media;
                            } & {
                                _type: "Image";
                            }) | ({
                                table: string;
                                seperator: string;
                                striped: string;
                                caption?: string;
                            } & {
                                _type: "Table";
                            }))[];
                        }[];
                    } & {
                        _type: "Accordion";
                    }) | ({
                        overline: string;
                        headline: string;
                        intro: string;
                        ctaLabel: string;
                        ctaRef: ContentRef;
                        dias: {
                            headline: string;
                            copy?: string;
                            icon?: MediaRef & Media;
                            ariaHidden?: boolean;
                        }[];
                    } & {
                        _type: "DiaShow";
                    }) | ({
                        title: string;
                        voices: {
                            quote: string;
                            subline: string;
                            program: ContentRef;
                        }[];
                    } & {
                        _type: "CustomerVoices";
                    }) | ({
                        title: string;
                        jobs: ContentRef[];
                        small?: boolean;
                    } & {
                        _type: "Jobs";
                    }) | ({
                        program: ContentRef;
                    } & {
                        _type: "ProgramTeaser";
                    }) | ({
                        headline: string;
                        overline?: string;
                        text: string;
                        image: MediaRef & Media;
                        ctaLabel?: string;
                        ctaRef?: ContentRef;
                        swapLayout?: boolean;
                    } & {
                        _type: "ImageTextTeaser";
                    }) | ({
                        teaser: ContentRef;
                    } & {
                        _type: "LinkTextTeaser";
                    }))[];
                }[];
            } & {
                _type: "sections";
            }) | ({
                items?: (({
                    text?: string;
                } & {
                    _type: "Text";
                }) | ({
                    headline: string;
                    copy?: string;
                    contacts: {
                        role: string;
                        contact: ContentRef;
                    }[];
                    withImage?: boolean;
                } & {
                    _type: "Contacts";
                }) | ({
                    image: MediaRef & Media;
                } & {
                    _type: "Image";
                }) | ({
                    table: string;
                    seperator: string;
                    striped: string;
                    caption?: string;
                } & {
                    _type: "Table";
                }) | ({
                    headline?: string;
                    documents?: ContentRef[];
                } & {
                    _type: "Downloads";
                }) | ({
                    headline: string;
                    intro?: string;
                    cases?: {
                        label: string;
                        headline: string;
                        text: string;
                        ctaLabel?: string;
                        ctaRef?: ContentRef;
                    }[];
                } & {
                    _type: "ShowCase";
                }) | ({
                    overline?: string;
                    headline: string;
                    subline?: string;
                    text: string;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                    layoutType?: boolean;
                } & {
                    _type: "TextTeaser";
                }) | ({
                    items?: ContentRef[];
                } & {
                    _type: "FundingStories";
                }) | ({
                    items?: {
                        label?: string;
                        content?: (({
                            text?: string;
                        } & {
                            _type: "Text";
                        }) | ({
                            headline?: string;
                            documents?: ContentRef[];
                        } & {
                            _type: "Downloads";
                        }) | ({
                            image: MediaRef & Media;
                        } & {
                            _type: "Image";
                        }) | ({
                            table: string;
                            seperator: string;
                            striped: string;
                            caption?: string;
                        } & {
                            _type: "Table";
                        }))[];
                    }[];
                } & {
                    _type: "Accordion";
                }) | ({
                    overline: string;
                    headline: string;
                    intro: string;
                    ctaLabel: string;
                    ctaRef: ContentRef;
                    dias: {
                        headline: string;
                        copy?: string;
                        icon?: MediaRef & Media;
                        ariaHidden?: boolean;
                    }[];
                } & {
                    _type: "DiaShow";
                }) | ({
                    title: string;
                    voices: {
                        quote: string;
                        subline: string;
                        program: ContentRef;
                    }[];
                } & {
                    _type: "CustomerVoices";
                }) | ({
                    title: string;
                    jobs: ContentRef[];
                    small?: boolean;
                } & {
                    _type: "Jobs";
                }) | ({
                    program: ContentRef;
                } & {
                    _type: "ProgramTeaser";
                }) | ({
                    headline: string;
                    overline?: string;
                    text: string;
                    image: MediaRef & Media;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                    swapLayout?: boolean;
                } & {
                    _type: "ImageTextTeaser";
                }) | ({
                    teaser: ContentRef;
                } & {
                    _type: "LinkTextTeaser";
                }))[];
            } & {
                _type: "contents";
            }))[];
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * Load AboutUsArticle
     */
    async loadAboutUsArticleBySlug(uniqueValue: string, { fields, xRichtextFormat }: {
        fields?: ("posit" | "name" | "slug" | "overline" | "description" | "content" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
    } = {}) {
        return await this._fetchJson(`/aboutUsArticle/slug/${uniqueValue}${QS.query(QS.deep({
            fields,
            join: { Contact: ["name", "email", "phone", "image", "type"], Document: ["name", "file", "description", "type.name"], MagazinPortraits: ["date", "title", "headerImage", "slug", "tags", "categories", "categories.name", "programs.slug", "programs.name", "programs.type.name"], Program: ["name", "slug", "type.name", "abstract", "keyFacts"], ExternalProgram: ["type", "name", "abstract", "keyFacts", "ctaLabel", "ctaHref"], JobOffer: ["name", "slug", "intro", "employmentType", "employmentSchema", "posit", "category.slug", "category.name"], TextTeaser: ["overline", "headline", "subline", "text", "ctaLabel", "ctaRef", "layoutType"] }
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            posit?: string;
            name: string;
            slug: string;
            overline: string;
            description?: string;
            content: (({
                items?: {
                    navLink?: string;
                    items?: (({
                        text?: string;
                    } & {
                        _type: "Text";
                    }) | ({
                        headline: string;
                        copy?: string;
                        contacts: {
                            role: string;
                            contact: ContentRef & Contact;
                        }[];
                        withImage?: boolean;
                    } & {
                        _type: "Contacts";
                    }) | ({
                        image: MediaRef & Media;
                    } & {
                        _type: "Image";
                    }) | ({
                        table: string;
                        seperator: string;
                        striped: string;
                        caption?: string;
                    } & {
                        _type: "Table";
                    }) | ({
                        headline?: string;
                        documents?: (ContentRef & Document)[];
                    } & {
                        _type: "Downloads";
                    }) | ({
                        headline: string;
                        intro?: string;
                        cases?: {
                            label: string;
                            headline: string;
                            text: string;
                            ctaLabel?: string;
                            ctaRef?: ContentRef;
                        }[];
                    } & {
                        _type: "ShowCase";
                    }) | ({
                        overline?: string;
                        headline: string;
                        subline?: string;
                        text: string;
                        ctaLabel?: string;
                        ctaRef?: ContentRef;
                        layoutType?: boolean;
                    } & {
                        _type: "TextTeaser";
                    }) | ({
                        items?: (ContentRef & MagazinPortraits)[];
                    } & {
                        _type: "FundingStories";
                    }) | ({
                        items?: {
                            label?: string;
                            content?: (({
                                text?: string;
                            } & {
                                _type: "Text";
                            }) | ({
                                headline?: string;
                                documents?: (ContentRef & Document)[];
                            } & {
                                _type: "Downloads";
                            }) | ({
                                image: MediaRef & Media;
                            } & {
                                _type: "Image";
                            }) | ({
                                table: string;
                                seperator: string;
                                striped: string;
                                caption?: string;
                            } & {
                                _type: "Table";
                            }))[];
                        }[];
                    } & {
                        _type: "Accordion";
                    }) | ({
                        overline: string;
                        headline: string;
                        intro: string;
                        ctaLabel: string;
                        ctaRef: ContentRef;
                        dias: {
                            headline: string;
                            copy?: string;
                            icon?: MediaRef & Media;
                            ariaHidden?: boolean;
                        }[];
                    } & {
                        _type: "DiaShow";
                    }) | ({
                        title: string;
                        voices: {
                            quote: string;
                            subline: string;
                            program: ContentRef & (Program | ExternalProgram);
                        }[];
                    } & {
                        _type: "CustomerVoices";
                    }) | ({
                        title: string;
                        jobs: (ContentRef & JobOffer)[];
                        small?: boolean;
                    } & {
                        _type: "Jobs";
                    }) | ({
                        program: ContentRef & Program;
                    } & {
                        _type: "ProgramTeaser";
                    }) | ({
                        headline: string;
                        overline?: string;
                        text: string;
                        image: MediaRef & Media;
                        ctaLabel?: string;
                        ctaRef?: ContentRef;
                        swapLayout?: boolean;
                    } & {
                        _type: "ImageTextTeaser";
                    }) | ({
                        teaser: ContentRef & TextTeaser;
                    } & {
                        _type: "LinkTextTeaser";
                    }))[];
                }[];
            } & {
                _type: "sections";
            }) | ({
                items?: (({
                    text?: string;
                } & {
                    _type: "Text";
                }) | ({
                    headline: string;
                    copy?: string;
                    contacts: {
                        role: string;
                        contact: ContentRef & Contact;
                    }[];
                    withImage?: boolean;
                } & {
                    _type: "Contacts";
                }) | ({
                    image: MediaRef & Media;
                } & {
                    _type: "Image";
                }) | ({
                    table: string;
                    seperator: string;
                    striped: string;
                    caption?: string;
                } & {
                    _type: "Table";
                }) | ({
                    headline?: string;
                    documents?: (ContentRef & Document)[];
                } & {
                    _type: "Downloads";
                }) | ({
                    headline: string;
                    intro?: string;
                    cases?: {
                        label: string;
                        headline: string;
                        text: string;
                        ctaLabel?: string;
                        ctaRef?: ContentRef;
                    }[];
                } & {
                    _type: "ShowCase";
                }) | ({
                    overline?: string;
                    headline: string;
                    subline?: string;
                    text: string;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                    layoutType?: boolean;
                } & {
                    _type: "TextTeaser";
                }) | ({
                    items?: (ContentRef & MagazinPortraits)[];
                } & {
                    _type: "FundingStories";
                }) | ({
                    items?: {
                        label?: string;
                        content?: (({
                            text?: string;
                        } & {
                            _type: "Text";
                        }) | ({
                            headline?: string;
                            documents?: (ContentRef & Document)[];
                        } & {
                            _type: "Downloads";
                        }) | ({
                            image: MediaRef & Media;
                        } & {
                            _type: "Image";
                        }) | ({
                            table: string;
                            seperator: string;
                            striped: string;
                            caption?: string;
                        } & {
                            _type: "Table";
                        }))[];
                    }[];
                } & {
                    _type: "Accordion";
                }) | ({
                    overline: string;
                    headline: string;
                    intro: string;
                    ctaLabel: string;
                    ctaRef: ContentRef;
                    dias: {
                        headline: string;
                        copy?: string;
                        icon?: MediaRef & Media;
                        ariaHidden?: boolean;
                    }[];
                } & {
                    _type: "DiaShow";
                }) | ({
                    title: string;
                    voices: {
                        quote: string;
                        subline: string;
                        program: ContentRef & (Program | ExternalProgram);
                    }[];
                } & {
                    _type: "CustomerVoices";
                }) | ({
                    title: string;
                    jobs: (ContentRef & JobOffer)[];
                    small?: boolean;
                } & {
                    _type: "Jobs";
                }) | ({
                    program: ContentRef & Program;
                } & {
                    _type: "ProgramTeaser";
                }) | ({
                    headline: string;
                    overline?: string;
                    text: string;
                    image: MediaRef & Media;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                    swapLayout?: boolean;
                } & {
                    _type: "ImageTextTeaser";
                }) | ({
                    teaser: ContentRef & TextTeaser;
                } & {
                    _type: "LinkTextTeaser";
                }))[];
            } & {
                _type: "contents";
            }))[];
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * Load Career
     */
    async loadCareer({ fields, xRichtextFormat, search }: {
        fields?: ("name" | "header" | "topjobs" | "joboffers" | "sections" | "contacts" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
        search?: {
            term?: string;
            scope?: "title" | "global";
        };
    } = {}) {
        return await this._fetchJson(`/career${QS.query(QS.deep({
            fields,
            join: { JobOffer: ["name", "slug", "intro", "employmentType", "employmentSchema", "posit", "category.slug", "category.name"], Contact: ["name", "phone", "email", "type", "image"], JobCategory: ["name", "slug", "headline", "intro", "teaser"], TextTeaser: ["overline", "headline", "subline", "text", "ctaLabel", "ctaRef", "layoutType"] },
            search
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            name: string;
            header?: {
                image?: MediaRef & Media;
                headline?: string;
                intro?: string;
                icon?: MediaRef & Media;
            };
            topjobs?: {
                title?: string;
                subTitle?: string;
                jobs?: (ContentRef & JobOffer)[];
            };
            joboffers?: {
                toptitle?: string;
                title?: string;
                intro?: string;
                jobCategories?: (ContentRef & JobCategory)[];
            };
            sections?: (({
                overline?: string;
                headline: string;
                subline?: string;
                text: string;
                ctaLabel?: string;
                ctaRef?: ContentRef;
                layoutType?: boolean;
            } & {
                _type: "TextTeaser";
            }) | ({
                teaser: ContentRef & TextTeaser;
            } & {
                _type: "LinkTextTeaser";
            }) | ({
                headline: string;
                overline?: string;
                text: string;
                image: MediaRef & Media;
                ctaLabel?: string;
                ctaRef?: ContentRef;
                swapLayout?: boolean;
            } & {
                _type: "ImageTextTeaser";
            }) | ({
                headline: string;
                intro?: string;
                cases?: {
                    label: string;
                    headline: string;
                    text: string;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                }[];
            } & {
                _type: "ShowCase";
            }) | ({
                headline: string;
                entries: {
                    headline: string;
                    text: string;
                    image: MediaRef & Media;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                }[];
            } & {
                _type: "Checkerboard";
            }) | ({
                headline: string;
                overline?: string;
                subline?: string;
                text: string;
            } & {
                _type: "CollapsibleText";
            }))[];
            contacts?: {
                role: string;
                contact: ContentRef & Contact;
            }[];
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * List JobCategories
     */
    async listJobCategories({ orderBy, order, offset, limit, name, slug, jobs, fields, search }: {
        orderBy?: "name" | "slug";
        order?: "asc" | "desc";
        offset?: number;
        limit?: number;
        name?: {
            eq?: string | string[];
            ne?: string | string[];
        };
        slug?: {
            eq?: string | string[];
            ne?: string | string[];
        };
        jobs?: {
            eq?: number | string | (number | string)[];
            ne?: number | string | (number | string)[];
            gt?: number | string;
            gte?: number | string;
            lt?: number | string;
            lte?: number | string;
            path?: string;
        };
        fields?: ("name" | "slug" | "teaser" | "headline" | "subheader" | "jobs" | "seo")[];
        search?: {
            term?: string;
            scope?: "title" | "global";
        };
    } = {}) {
        return await this._fetchJson(`/jobCategory${QS.query(QS.form({
            orderBy,
            order,
            offset,
            limit
        }), QS.deep({
            "data.name": name,
            "data.slug": slug,
            "data.jobs": jobs,
            fields,
            search
        }))}`) as {
            total: number;
            items: ({
                name: string;
                slug: string;
                teaser: string;
                headline: string;
                subheader?: {
                    intro: string;
                    claim?: string;
                    icon?: MediaRef & Media;
                };
                jobs?: ContentRef[];
                seo?: {
                    metaTitle?: string;
                    metaDescription?: string;
                    robots?: string;
                };
            } & {
                _id: string;
            })[];
        };
    }
    /**
     * Load JobCategory
     */
    async loadJobCategory(id: string, { fields }: {
        fields?: ("name" | "slug" | "teaser" | "headline" | "subheader" | "jobs" | "seo")[];
    } = {}) {
        return await this._fetchJson(`/jobCategory/${id}${QS.query(QS.deep({
            fields
        }))}`) as {
            name: string;
            slug: string;
            teaser: string;
            headline: string;
            subheader?: {
                intro: string;
                claim?: string;
                icon?: MediaRef & Media;
            };
            jobs?: ContentRef[];
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * Load JobCategory
     */
    async loadJobCategoryByName(uniqueValue: string, { fields }: {
        fields?: ("name" | "slug" | "teaser" | "headline" | "subheader" | "jobs" | "seo")[];
    } = {}) {
        return await this._fetchJson(`/jobCategory/name/${uniqueValue}${QS.query(QS.deep({
            fields
        }))}`) as {
            name: string;
            slug: string;
            teaser: string;
            headline: string;
            subheader?: {
                intro: string;
                claim?: string;
                icon?: MediaRef & Media;
            };
            jobs?: ContentRef[];
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * Load JobCategory
     */
    async loadJobCategoryBySlug(uniqueValue: string, { fields }: {
        fields?: ("name" | "slug" | "teaser" | "headline" | "subheader" | "jobs" | "seo")[];
    } = {}) {
        return await this._fetchJson(`/jobCategory/slug/${uniqueValue}${QS.query(QS.deep({
            fields,
            join: { JobOffer: ["name", "slug", "intro", "employmentType", "employmentSchema", "posit", "category.slug", "category.name"] }
        }))}`) as {
            name: string;
            slug: string;
            teaser: string;
            headline: string;
            subheader?: {
                intro: string;
                claim?: string;
                icon?: MediaRef & Media;
            };
            jobs?: (ContentRef & JobOffer)[];
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * List JobOffers
     */
    async listJobOffers({ orderBy, order, offset, limit, name, slug, category, fields, xRichtextFormat, search }: {
        orderBy?: "name" | "slug" | "posit";
        order?: "asc" | "desc";
        offset?: number;
        limit?: number;
        name?: {
            eq?: string | string[];
            ne?: string | string[];
        };
        slug?: {
            eq?: string | string[];
            ne?: string | string[];
        };
        category?: {
            eq?: number | string | (number | string)[];
            ne?: number | string | (number | string)[];
            gt?: number | string;
            gte?: number | string;
            lt?: number | string;
            lte?: number | string;
            path?: string;
        };
        fields?: ("name" | "slug" | "posit" | "intro" | "category" | "employmentType" | "employmentSchema" | "sections" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
        search?: {
            term?: string;
            scope?: "title" | "global";
        };
    } = {}) {
        return await this._fetchJson(`/jobOffer${QS.query(QS.form({
            orderBy,
            order,
            offset,
            limit
        }), QS.deep({
            "data.name": name,
            "data.slug": slug,
            "data.category": category,
            fields,
            search
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            total: number;
            items: ({
                name: string;
                slug: string;
                posit?: string;
                intro: string;
                category: ContentRef;
                employmentType?: string;
                employmentSchema?: string;
                sections?: {
                    items?: {
                        navLink?: string;
                        items?: (({
                            text?: string;
                        } & {
                            _type: "Text";
                        }) | ({
                            headline: string;
                            copy?: string;
                            contacts: {
                                role: string;
                                contact: ContentRef;
                            }[];
                            withImage?: boolean;
                        } & {
                            _type: "Contacts";
                        }) | ({
                            headline?: string;
                            documents?: ContentRef[];
                        } & {
                            _type: "Downloads";
                        }))[];
                    }[];
                };
                seo?: {
                    metaTitle?: string;
                    metaDescription?: string;
                    robots?: string;
                };
            } & {
                _id: string;
            })[];
        };
    }
    /**
     * Load JobOffer
     */
    async loadJobOffer(id: string, { fields, xRichtextFormat }: {
        fields?: ("name" | "slug" | "posit" | "intro" | "category" | "employmentType" | "employmentSchema" | "sections" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
    } = {}) {
        return await this._fetchJson(`/jobOffer/${id}${QS.query(QS.deep({
            fields
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            name: string;
            slug: string;
            posit?: string;
            intro: string;
            category: ContentRef;
            employmentType?: string;
            employmentSchema?: string;
            sections?: {
                items?: {
                    navLink?: string;
                    items?: (({
                        text?: string;
                    } & {
                        _type: "Text";
                    }) | ({
                        headline: string;
                        copy?: string;
                        contacts: {
                            role: string;
                            contact: ContentRef;
                        }[];
                        withImage?: boolean;
                    } & {
                        _type: "Contacts";
                    }) | ({
                        headline?: string;
                        documents?: ContentRef[];
                    } & {
                        _type: "Downloads";
                    }))[];
                }[];
            };
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * Load JobOffer
     */
    async loadJobOfferByName(uniqueValue: string, { fields, xRichtextFormat }: {
        fields?: ("name" | "slug" | "posit" | "intro" | "category" | "employmentType" | "employmentSchema" | "sections" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
    } = {}) {
        return await this._fetchJson(`/jobOffer/name/${uniqueValue}${QS.query(QS.deep({
            fields
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            name: string;
            slug: string;
            posit?: string;
            intro: string;
            category: ContentRef;
            employmentType?: string;
            employmentSchema?: string;
            sections?: {
                items?: {
                    navLink?: string;
                    items?: (({
                        text?: string;
                    } & {
                        _type: "Text";
                    }) | ({
                        headline: string;
                        copy?: string;
                        contacts: {
                            role: string;
                            contact: ContentRef;
                        }[];
                        withImage?: boolean;
                    } & {
                        _type: "Contacts";
                    }) | ({
                        headline?: string;
                        documents?: ContentRef[];
                    } & {
                        _type: "Downloads";
                    }))[];
                }[];
            };
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * Load JobOffer
     */
    async loadJobOfferBySlug(uniqueValue: string, { fields, xRichtextFormat }: {
        fields?: ("name" | "slug" | "posit" | "intro" | "category" | "employmentType" | "employmentSchema" | "sections" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
    } = {}) {
        return await this._fetchJson(`/jobOffer/slug/${uniqueValue}${QS.query(QS.deep({
            fields,
            join: { JobCategory: ["name", "slug", "headline", "intro", "teaser"], Contact: ["name", "phone", "email", "type", "image"], Document: ["name", "file", "description", "type.name"] }
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            name: string;
            slug: string;
            posit?: string;
            intro: string;
            category: ContentRef & JobCategory;
            employmentType?: string;
            employmentSchema?: string;
            sections?: {
                items?: {
                    navLink?: string;
                    items?: (({
                        text?: string;
                    } & {
                        _type: "Text";
                    }) | ({
                        headline: string;
                        copy?: string;
                        contacts: {
                            role: string;
                            contact: ContentRef & Contact;
                        }[];
                        withImage?: boolean;
                    } & {
                        _type: "Contacts";
                    }) | ({
                        headline?: string;
                        documents?: (ContentRef & Document)[];
                    } & {
                        _type: "Downloads";
                    }))[];
                }[];
            };
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * Load Press
     */
    async loadPress({ fields, search }: {
        fields?: ("name" | "header" | "pressImages" | "contact" | "seo")[];
        search?: {
            term?: string;
            scope?: "title" | "global";
        };
    } = {}) {
        return await this._fetchJson(`/press${QS.query(QS.deep({
            fields,
            join: { Document: ["name", "file", "description", "type.name"], Contact: ["name", "phone", "email", "type", "image"] },
            search
        }))}`) as {
            name: string;
            header?: {
                image?: MediaRef & Media;
                headline?: string;
                intro?: string;
                icon?: MediaRef & Media;
            };
            pressImages?: {
                headline?: string;
                intro?: string;
                images?: (ContentRef & Document)[];
            };
            contact?: {
                role: string;
                contact: ContentRef & Contact;
                adress: string;
            };
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * List PressReleases
     */
    async listPressReleases({ orderBy, order, offset, limit, date, title, slug, documents, fields, xRichtextFormat, search }: {
        orderBy?: "date" | "title" | "slug";
        order?: "asc" | "desc";
        offset?: number;
        limit?: number;
        date?: {
            eq?: number | string;
            ne?: number | string;
            gt?: number | string;
            gte?: number | string;
            lt?: number | string;
            lte?: number | string;
        };
        title?: {
            eq?: string | string[];
            ne?: string | string[];
        };
        slug?: {
            eq?: string | string[];
            ne?: string | string[];
        };
        documents?: {
            eq?: number | string | (number | string)[];
            ne?: number | string | (number | string)[];
            gt?: number | string;
            gte?: number | string;
            lt?: number | string;
            lte?: number | string;
            path?: string;
        };
        fields?: ("date" | "title" | "slug" | "description" | "sections" | "documents" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
        search?: {
            term?: string;
            scope?: "title" | "global";
        };
    } = {}) {
        return await this._fetchJson(`/pressRelease${QS.query(QS.form({
            orderBy,
            order,
            offset,
            limit
        }), QS.deep({
            "data.date": date,
            "data.title": title,
            "data.slug": slug,
            "data.documents": documents,
            fields,
            search
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            total: number;
            items: ({
                date: string;
                title: string;
                slug: string;
                description: string;
                sections?: {
                    items?: {
                        navLink?: string;
                        items?: (({
                            text?: string;
                        } & {
                            _type: "Text";
                        }) | ({
                            table: string;
                            seperator: string;
                            striped: string;
                            caption?: string;
                        } & {
                            _type: "Table";
                        }) | ({
                            image: MediaRef & Media;
                        } & {
                            _type: "Image";
                        }))[];
                    }[];
                };
                documents?: ContentRef[];
                seo?: {
                    metaTitle?: string;
                    metaDescription?: string;
                    robots?: string;
                };
            } & {
                _id: string;
            })[];
        };
    }
    /**
     * Load PressRelease
     */
    async loadPressRelease(id: string, { fields, xRichtextFormat }: {
        fields?: ("date" | "title" | "slug" | "description" | "sections" | "documents" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
    } = {}) {
        return await this._fetchJson(`/pressRelease/${id}${QS.query(QS.deep({
            fields
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            date: string;
            title: string;
            slug: string;
            description: string;
            sections?: {
                items?: {
                    navLink?: string;
                    items?: (({
                        text?: string;
                    } & {
                        _type: "Text";
                    }) | ({
                        table: string;
                        seperator: string;
                        striped: string;
                        caption?: string;
                    } & {
                        _type: "Table";
                    }) | ({
                        image: MediaRef & Media;
                    } & {
                        _type: "Image";
                    }))[];
                }[];
            };
            documents?: ContentRef[];
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * Load PressRelease
     */
    async loadPressReleaseBySlug(uniqueValue: string, { fields, xRichtextFormat }: {
        fields?: ("date" | "title" | "slug" | "description" | "sections" | "documents" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
    } = {}) {
        return await this._fetchJson(`/pressRelease/slug/${uniqueValue}${QS.query(QS.deep({
            fields,
            join: { Document: ["name", "file", "description", "type.name"] }
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            date: string;
            title: string;
            slug: string;
            description: string;
            sections?: {
                items?: {
                    navLink?: string;
                    items?: (({
                        text?: string;
                    } & {
                        _type: "Text";
                    }) | ({
                        table: string;
                        seperator: string;
                        striped: string;
                        caption?: string;
                    } & {
                        _type: "Table";
                    }) | ({
                        image: MediaRef & Media;
                    } & {
                        _type: "Image";
                    }))[];
                }[];
            };
            documents?: (ContentRef & Document)[];
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * Load ApManagement
     */
    async loadApManagement({ fields, xRichtextFormat, search }: {
        fields?: ("name" | "contents" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
        search?: {
            term?: string;
            scope?: "title" | "global";
        };
    } = {}) {
        return await this._fetchJson(`/apManagement${QS.query(QS.deep({
            fields,
            join: { Contact: ["name", "email", "phone", "image", "type"], Document: ["name", "file", "description", "type.name"], MagazinPortraits: ["date", "title", "headerImage", "slug", "tags", "categories", "categories.name", "programs.slug", "programs.name", "programs.type.name"], Program: ["name", "slug", "type.name", "abstract", "keyFacts"], ExternalProgram: ["type", "name", "abstract", "keyFacts", "ctaLabel", "ctaHref"], JobOffer: ["name", "slug", "intro", "employmentType", "employmentSchema", "posit", "category.slug", "category.name"], TextTeaser: ["overline", "headline", "subline", "text", "ctaLabel", "ctaRef", "layoutType"] },
            search
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            name: string;
            contents?: (({
                text?: string;
            } & {
                _type: "Text";
            }) | ({
                headline: string;
                copy?: string;
                contacts: {
                    role: string;
                    contact: ContentRef & Contact;
                }[];
                withImage?: boolean;
            } & {
                _type: "Contacts";
            }) | ({
                image: MediaRef & Media;
            } & {
                _type: "Image";
            }) | ({
                table: string;
                seperator: string;
                striped: string;
                caption?: string;
            } & {
                _type: "Table";
            }) | ({
                headline?: string;
                documents?: (ContentRef & Document)[];
            } & {
                _type: "Downloads";
            }) | ({
                headline: string;
                intro?: string;
                cases?: {
                    label: string;
                    headline: string;
                    text: string;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                }[];
            } & {
                _type: "ShowCase";
            }) | ({
                overline?: string;
                headline: string;
                subline?: string;
                text: string;
                ctaLabel?: string;
                ctaRef?: ContentRef;
                layoutType?: boolean;
            } & {
                _type: "TextTeaser";
            }) | ({
                items?: (ContentRef & MagazinPortraits)[];
            } & {
                _type: "FundingStories";
            }) | ({
                items?: {
                    label?: string;
                    content?: (({
                        text?: string;
                    } & {
                        _type: "Text";
                    }) | ({
                        headline?: string;
                        documents?: (ContentRef & Document)[];
                    } & {
                        _type: "Downloads";
                    }) | ({
                        image: MediaRef & Media;
                    } & {
                        _type: "Image";
                    }) | ({
                        table: string;
                        seperator: string;
                        striped: string;
                        caption?: string;
                    } & {
                        _type: "Table";
                    }))[];
                }[];
            } & {
                _type: "Accordion";
            }) | ({
                overline: string;
                headline: string;
                intro: string;
                ctaLabel: string;
                ctaRef: ContentRef;
                dias: {
                    headline: string;
                    copy?: string;
                    icon?: MediaRef & Media;
                    ariaHidden?: boolean;
                }[];
            } & {
                _type: "DiaShow";
            }) | ({
                title: string;
                voices: {
                    quote: string;
                    subline: string;
                    program: ContentRef & (Program | ExternalProgram);
                }[];
            } & {
                _type: "CustomerVoices";
            }) | ({
                title: string;
                jobs: (ContentRef & JobOffer)[];
                small?: boolean;
            } & {
                _type: "Jobs";
            }) | ({
                program: ContentRef & Program;
            } & {
                _type: "ProgramTeaser";
            }) | ({
                headline: string;
                overline?: string;
                text: string;
                image: MediaRef & Media;
                ctaLabel?: string;
                ctaRef?: ContentRef;
                swapLayout?: boolean;
            } & {
                _type: "ImageTextTeaser";
            }) | ({
                teaser: ContentRef & TextTeaser;
            } & {
                _type: "LinkTextTeaser";
            }))[];
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * List ApManagementArticles
     */
    async listApManagementArticles({ orderBy, order, offset, limit, name, slug, fields, xRichtextFormat, search }: {
        orderBy?: "name" | "slug";
        order?: "asc" | "desc";
        offset?: number;
        limit?: number;
        name?: {
            eq?: string | string[];
            ne?: string | string[];
        };
        slug?: {
            eq?: string | string[];
            ne?: string | string[];
        };
        fields?: ("name" | "slug" | "description" | "content" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
        search?: {
            term?: string;
            scope?: "title" | "global";
        };
    } = {}) {
        return await this._fetchJson(`/apManagementArticle${QS.query(QS.form({
            orderBy,
            order,
            offset,
            limit
        }), QS.deep({
            "data.name": name,
            "data.slug": slug,
            fields,
            search
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            total: number;
            items: ({
                name: string;
                slug: string;
                description?: string;
                content: (({
                    items?: {
                        navLink?: string;
                        items?: (({
                            text?: string;
                        } & {
                            _type: "Text";
                        }) | ({
                            headline: string;
                            copy?: string;
                            contacts: {
                                role: string;
                                contact: ContentRef;
                            }[];
                            withImage?: boolean;
                        } & {
                            _type: "Contacts";
                        }) | ({
                            image: MediaRef & Media;
                        } & {
                            _type: "Image";
                        }) | ({
                            table: string;
                            seperator: string;
                            striped: string;
                            caption?: string;
                        } & {
                            _type: "Table";
                        }) | ({
                            headline?: string;
                            documents?: ContentRef[];
                        } & {
                            _type: "Downloads";
                        }) | ({
                            headline: string;
                            intro?: string;
                            cases?: {
                                label: string;
                                headline: string;
                                text: string;
                                ctaLabel?: string;
                                ctaRef?: ContentRef;
                            }[];
                        } & {
                            _type: "ShowCase";
                        }) | ({
                            overline?: string;
                            headline: string;
                            subline?: string;
                            text: string;
                            ctaLabel?: string;
                            ctaRef?: ContentRef;
                            layoutType?: boolean;
                        } & {
                            _type: "TextTeaser";
                        }) | ({
                            items?: ContentRef[];
                        } & {
                            _type: "FundingStories";
                        }) | ({
                            items?: {
                                label?: string;
                                content?: (({
                                    text?: string;
                                } & {
                                    _type: "Text";
                                }) | ({
                                    headline?: string;
                                    documents?: ContentRef[];
                                } & {
                                    _type: "Downloads";
                                }) | ({
                                    image: MediaRef & Media;
                                } & {
                                    _type: "Image";
                                }) | ({
                                    table: string;
                                    seperator: string;
                                    striped: string;
                                    caption?: string;
                                } & {
                                    _type: "Table";
                                }))[];
                            }[];
                        } & {
                            _type: "Accordion";
                        }) | ({
                            overline: string;
                            headline: string;
                            intro: string;
                            ctaLabel: string;
                            ctaRef: ContentRef;
                            dias: {
                                headline: string;
                                copy?: string;
                                icon?: MediaRef & Media;
                                ariaHidden?: boolean;
                            }[];
                        } & {
                            _type: "DiaShow";
                        }) | ({
                            title: string;
                            voices: {
                                quote: string;
                                subline: string;
                                program: ContentRef;
                            }[];
                        } & {
                            _type: "CustomerVoices";
                        }) | ({
                            title: string;
                            jobs: ContentRef[];
                            small?: boolean;
                        } & {
                            _type: "Jobs";
                        }) | ({
                            program: ContentRef;
                        } & {
                            _type: "ProgramTeaser";
                        }) | ({
                            headline: string;
                            overline?: string;
                            text: string;
                            image: MediaRef & Media;
                            ctaLabel?: string;
                            ctaRef?: ContentRef;
                            swapLayout?: boolean;
                        } & {
                            _type: "ImageTextTeaser";
                        }) | ({
                            teaser: ContentRef;
                        } & {
                            _type: "LinkTextTeaser";
                        }))[];
                    }[];
                } & {
                    _type: "sections";
                }) | ({
                    items?: (({
                        text?: string;
                    } & {
                        _type: "Text";
                    }) | ({
                        headline: string;
                        copy?: string;
                        contacts: {
                            role: string;
                            contact: ContentRef;
                        }[];
                        withImage?: boolean;
                    } & {
                        _type: "Contacts";
                    }) | ({
                        image: MediaRef & Media;
                    } & {
                        _type: "Image";
                    }) | ({
                        table: string;
                        seperator: string;
                        striped: string;
                        caption?: string;
                    } & {
                        _type: "Table";
                    }) | ({
                        headline?: string;
                        documents?: ContentRef[];
                    } & {
                        _type: "Downloads";
                    }) | ({
                        headline: string;
                        intro?: string;
                        cases?: {
                            label: string;
                            headline: string;
                            text: string;
                            ctaLabel?: string;
                            ctaRef?: ContentRef;
                        }[];
                    } & {
                        _type: "ShowCase";
                    }) | ({
                        overline?: string;
                        headline: string;
                        subline?: string;
                        text: string;
                        ctaLabel?: string;
                        ctaRef?: ContentRef;
                        layoutType?: boolean;
                    } & {
                        _type: "TextTeaser";
                    }) | ({
                        items?: ContentRef[];
                    } & {
                        _type: "FundingStories";
                    }) | ({
                        items?: {
                            label?: string;
                            content?: (({
                                text?: string;
                            } & {
                                _type: "Text";
                            }) | ({
                                headline?: string;
                                documents?: ContentRef[];
                            } & {
                                _type: "Downloads";
                            }) | ({
                                image: MediaRef & Media;
                            } & {
                                _type: "Image";
                            }) | ({
                                table: string;
                                seperator: string;
                                striped: string;
                                caption?: string;
                            } & {
                                _type: "Table";
                            }))[];
                        }[];
                    } & {
                        _type: "Accordion";
                    }) | ({
                        overline: string;
                        headline: string;
                        intro: string;
                        ctaLabel: string;
                        ctaRef: ContentRef;
                        dias: {
                            headline: string;
                            copy?: string;
                            icon?: MediaRef & Media;
                            ariaHidden?: boolean;
                        }[];
                    } & {
                        _type: "DiaShow";
                    }) | ({
                        title: string;
                        voices: {
                            quote: string;
                            subline: string;
                            program: ContentRef;
                        }[];
                    } & {
                        _type: "CustomerVoices";
                    }) | ({
                        title: string;
                        jobs: ContentRef[];
                        small?: boolean;
                    } & {
                        _type: "Jobs";
                    }) | ({
                        program: ContentRef;
                    } & {
                        _type: "ProgramTeaser";
                    }) | ({
                        headline: string;
                        overline?: string;
                        text: string;
                        image: MediaRef & Media;
                        ctaLabel?: string;
                        ctaRef?: ContentRef;
                        swapLayout?: boolean;
                    } & {
                        _type: "ImageTextTeaser";
                    }) | ({
                        teaser: ContentRef;
                    } & {
                        _type: "LinkTextTeaser";
                    }))[];
                } & {
                    _type: "contents";
                }))[];
                seo?: {
                    metaTitle?: string;
                    metaDescription?: string;
                    robots?: string;
                };
            } & {
                _id: string;
            })[];
        };
    }
    /**
     * Load ApManagementArticle
     */
    async loadApManagementArticle(id: string, { fields, xRichtextFormat }: {
        fields?: ("name" | "slug" | "description" | "content" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
    } = {}) {
        return await this._fetchJson(`/apManagementArticle/${id}${QS.query(QS.deep({
            fields
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            name: string;
            slug: string;
            description?: string;
            content: (({
                items?: {
                    navLink?: string;
                    items?: (({
                        text?: string;
                    } & {
                        _type: "Text";
                    }) | ({
                        headline: string;
                        copy?: string;
                        contacts: {
                            role: string;
                            contact: ContentRef;
                        }[];
                        withImage?: boolean;
                    } & {
                        _type: "Contacts";
                    }) | ({
                        image: MediaRef & Media;
                    } & {
                        _type: "Image";
                    }) | ({
                        table: string;
                        seperator: string;
                        striped: string;
                        caption?: string;
                    } & {
                        _type: "Table";
                    }) | ({
                        headline?: string;
                        documents?: ContentRef[];
                    } & {
                        _type: "Downloads";
                    }) | ({
                        headline: string;
                        intro?: string;
                        cases?: {
                            label: string;
                            headline: string;
                            text: string;
                            ctaLabel?: string;
                            ctaRef?: ContentRef;
                        }[];
                    } & {
                        _type: "ShowCase";
                    }) | ({
                        overline?: string;
                        headline: string;
                        subline?: string;
                        text: string;
                        ctaLabel?: string;
                        ctaRef?: ContentRef;
                        layoutType?: boolean;
                    } & {
                        _type: "TextTeaser";
                    }) | ({
                        items?: ContentRef[];
                    } & {
                        _type: "FundingStories";
                    }) | ({
                        items?: {
                            label?: string;
                            content?: (({
                                text?: string;
                            } & {
                                _type: "Text";
                            }) | ({
                                headline?: string;
                                documents?: ContentRef[];
                            } & {
                                _type: "Downloads";
                            }) | ({
                                image: MediaRef & Media;
                            } & {
                                _type: "Image";
                            }) | ({
                                table: string;
                                seperator: string;
                                striped: string;
                                caption?: string;
                            } & {
                                _type: "Table";
                            }))[];
                        }[];
                    } & {
                        _type: "Accordion";
                    }) | ({
                        overline: string;
                        headline: string;
                        intro: string;
                        ctaLabel: string;
                        ctaRef: ContentRef;
                        dias: {
                            headline: string;
                            copy?: string;
                            icon?: MediaRef & Media;
                            ariaHidden?: boolean;
                        }[];
                    } & {
                        _type: "DiaShow";
                    }) | ({
                        title: string;
                        voices: {
                            quote: string;
                            subline: string;
                            program: ContentRef;
                        }[];
                    } & {
                        _type: "CustomerVoices";
                    }) | ({
                        title: string;
                        jobs: ContentRef[];
                        small?: boolean;
                    } & {
                        _type: "Jobs";
                    }) | ({
                        program: ContentRef;
                    } & {
                        _type: "ProgramTeaser";
                    }) | ({
                        headline: string;
                        overline?: string;
                        text: string;
                        image: MediaRef & Media;
                        ctaLabel?: string;
                        ctaRef?: ContentRef;
                        swapLayout?: boolean;
                    } & {
                        _type: "ImageTextTeaser";
                    }) | ({
                        teaser: ContentRef;
                    } & {
                        _type: "LinkTextTeaser";
                    }))[];
                }[];
            } & {
                _type: "sections";
            }) | ({
                items?: (({
                    text?: string;
                } & {
                    _type: "Text";
                }) | ({
                    headline: string;
                    copy?: string;
                    contacts: {
                        role: string;
                        contact: ContentRef;
                    }[];
                    withImage?: boolean;
                } & {
                    _type: "Contacts";
                }) | ({
                    image: MediaRef & Media;
                } & {
                    _type: "Image";
                }) | ({
                    table: string;
                    seperator: string;
                    striped: string;
                    caption?: string;
                } & {
                    _type: "Table";
                }) | ({
                    headline?: string;
                    documents?: ContentRef[];
                } & {
                    _type: "Downloads";
                }) | ({
                    headline: string;
                    intro?: string;
                    cases?: {
                        label: string;
                        headline: string;
                        text: string;
                        ctaLabel?: string;
                        ctaRef?: ContentRef;
                    }[];
                } & {
                    _type: "ShowCase";
                }) | ({
                    overline?: string;
                    headline: string;
                    subline?: string;
                    text: string;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                    layoutType?: boolean;
                } & {
                    _type: "TextTeaser";
                }) | ({
                    items?: ContentRef[];
                } & {
                    _type: "FundingStories";
                }) | ({
                    items?: {
                        label?: string;
                        content?: (({
                            text?: string;
                        } & {
                            _type: "Text";
                        }) | ({
                            headline?: string;
                            documents?: ContentRef[];
                        } & {
                            _type: "Downloads";
                        }) | ({
                            image: MediaRef & Media;
                        } & {
                            _type: "Image";
                        }) | ({
                            table: string;
                            seperator: string;
                            striped: string;
                            caption?: string;
                        } & {
                            _type: "Table";
                        }))[];
                    }[];
                } & {
                    _type: "Accordion";
                }) | ({
                    overline: string;
                    headline: string;
                    intro: string;
                    ctaLabel: string;
                    ctaRef: ContentRef;
                    dias: {
                        headline: string;
                        copy?: string;
                        icon?: MediaRef & Media;
                        ariaHidden?: boolean;
                    }[];
                } & {
                    _type: "DiaShow";
                }) | ({
                    title: string;
                    voices: {
                        quote: string;
                        subline: string;
                        program: ContentRef;
                    }[];
                } & {
                    _type: "CustomerVoices";
                }) | ({
                    title: string;
                    jobs: ContentRef[];
                    small?: boolean;
                } & {
                    _type: "Jobs";
                }) | ({
                    program: ContentRef;
                } & {
                    _type: "ProgramTeaser";
                }) | ({
                    headline: string;
                    overline?: string;
                    text: string;
                    image: MediaRef & Media;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                    swapLayout?: boolean;
                } & {
                    _type: "ImageTextTeaser";
                }) | ({
                    teaser: ContentRef;
                } & {
                    _type: "LinkTextTeaser";
                }))[];
            } & {
                _type: "contents";
            }))[];
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * Load ApManagementArticle
     */
    async loadApManagementArticleBySlug(uniqueValue: string, { fields, xRichtextFormat }: {
        fields?: ("name" | "slug" | "description" | "content" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
    } = {}) {
        return await this._fetchJson(`/apManagementArticle/slug/${uniqueValue}${QS.query(QS.deep({
            fields,
            join: { Contact: ["name", "email", "phone", "image", "type"], Document: ["name", "file", "description", "type.name"], MagazinPortraits: ["date", "title", "headerImage", "slug", "tags", "categories", "categories.name", "programs.slug", "programs.name", "programs.type.name"], Program: ["name", "slug", "type.name", "abstract", "keyFacts"], ExternalProgram: ["type", "name", "abstract", "keyFacts", "ctaLabel", "ctaHref"], JobOffer: ["name", "slug", "intro", "employmentType", "employmentSchema", "posit", "category.slug", "category.name"], TextTeaser: ["overline", "headline", "subline", "text", "ctaLabel", "ctaRef", "layoutType"] }
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            name: string;
            slug: string;
            description?: string;
            content: (({
                items?: {
                    navLink?: string;
                    items?: (({
                        text?: string;
                    } & {
                        _type: "Text";
                    }) | ({
                        headline: string;
                        copy?: string;
                        contacts: {
                            role: string;
                            contact: ContentRef & Contact;
                        }[];
                        withImage?: boolean;
                    } & {
                        _type: "Contacts";
                    }) | ({
                        image: MediaRef & Media;
                    } & {
                        _type: "Image";
                    }) | ({
                        table: string;
                        seperator: string;
                        striped: string;
                        caption?: string;
                    } & {
                        _type: "Table";
                    }) | ({
                        headline?: string;
                        documents?: (ContentRef & Document)[];
                    } & {
                        _type: "Downloads";
                    }) | ({
                        headline: string;
                        intro?: string;
                        cases?: {
                            label: string;
                            headline: string;
                            text: string;
                            ctaLabel?: string;
                            ctaRef?: ContentRef;
                        }[];
                    } & {
                        _type: "ShowCase";
                    }) | ({
                        overline?: string;
                        headline: string;
                        subline?: string;
                        text: string;
                        ctaLabel?: string;
                        ctaRef?: ContentRef;
                        layoutType?: boolean;
                    } & {
                        _type: "TextTeaser";
                    }) | ({
                        items?: (ContentRef & MagazinPortraits)[];
                    } & {
                        _type: "FundingStories";
                    }) | ({
                        items?: {
                            label?: string;
                            content?: (({
                                text?: string;
                            } & {
                                _type: "Text";
                            }) | ({
                                headline?: string;
                                documents?: (ContentRef & Document)[];
                            } & {
                                _type: "Downloads";
                            }) | ({
                                image: MediaRef & Media;
                            } & {
                                _type: "Image";
                            }) | ({
                                table: string;
                                seperator: string;
                                striped: string;
                                caption?: string;
                            } & {
                                _type: "Table";
                            }))[];
                        }[];
                    } & {
                        _type: "Accordion";
                    }) | ({
                        overline: string;
                        headline: string;
                        intro: string;
                        ctaLabel: string;
                        ctaRef: ContentRef;
                        dias: {
                            headline: string;
                            copy?: string;
                            icon?: MediaRef & Media;
                            ariaHidden?: boolean;
                        }[];
                    } & {
                        _type: "DiaShow";
                    }) | ({
                        title: string;
                        voices: {
                            quote: string;
                            subline: string;
                            program: ContentRef & (Program | ExternalProgram);
                        }[];
                    } & {
                        _type: "CustomerVoices";
                    }) | ({
                        title: string;
                        jobs: (ContentRef & JobOffer)[];
                        small?: boolean;
                    } & {
                        _type: "Jobs";
                    }) | ({
                        program: ContentRef & Program;
                    } & {
                        _type: "ProgramTeaser";
                    }) | ({
                        headline: string;
                        overline?: string;
                        text: string;
                        image: MediaRef & Media;
                        ctaLabel?: string;
                        ctaRef?: ContentRef;
                        swapLayout?: boolean;
                    } & {
                        _type: "ImageTextTeaser";
                    }) | ({
                        teaser: ContentRef & TextTeaser;
                    } & {
                        _type: "LinkTextTeaser";
                    }))[];
                }[];
            } & {
                _type: "sections";
            }) | ({
                items?: (({
                    text?: string;
                } & {
                    _type: "Text";
                }) | ({
                    headline: string;
                    copy?: string;
                    contacts: {
                        role: string;
                        contact: ContentRef & Contact;
                    }[];
                    withImage?: boolean;
                } & {
                    _type: "Contacts";
                }) | ({
                    image: MediaRef & Media;
                } & {
                    _type: "Image";
                }) | ({
                    table: string;
                    seperator: string;
                    striped: string;
                    caption?: string;
                } & {
                    _type: "Table";
                }) | ({
                    headline?: string;
                    documents?: (ContentRef & Document)[];
                } & {
                    _type: "Downloads";
                }) | ({
                    headline: string;
                    intro?: string;
                    cases?: {
                        label: string;
                        headline: string;
                        text: string;
                        ctaLabel?: string;
                        ctaRef?: ContentRef;
                    }[];
                } & {
                    _type: "ShowCase";
                }) | ({
                    overline?: string;
                    headline: string;
                    subline?: string;
                    text: string;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                    layoutType?: boolean;
                } & {
                    _type: "TextTeaser";
                }) | ({
                    items?: (ContentRef & MagazinPortraits)[];
                } & {
                    _type: "FundingStories";
                }) | ({
                    items?: {
                        label?: string;
                        content?: (({
                            text?: string;
                        } & {
                            _type: "Text";
                        }) | ({
                            headline?: string;
                            documents?: (ContentRef & Document)[];
                        } & {
                            _type: "Downloads";
                        }) | ({
                            image: MediaRef & Media;
                        } & {
                            _type: "Image";
                        }) | ({
                            table: string;
                            seperator: string;
                            striped: string;
                            caption?: string;
                        } & {
                            _type: "Table";
                        }))[];
                    }[];
                } & {
                    _type: "Accordion";
                }) | ({
                    overline: string;
                    headline: string;
                    intro: string;
                    ctaLabel: string;
                    ctaRef: ContentRef;
                    dias: {
                        headline: string;
                        copy?: string;
                        icon?: MediaRef & Media;
                        ariaHidden?: boolean;
                    }[];
                } & {
                    _type: "DiaShow";
                }) | ({
                    title: string;
                    voices: {
                        quote: string;
                        subline: string;
                        program: ContentRef & (Program | ExternalProgram);
                    }[];
                } & {
                    _type: "CustomerVoices";
                }) | ({
                    title: string;
                    jobs: (ContentRef & JobOffer)[];
                    small?: boolean;
                } & {
                    _type: "Jobs";
                }) | ({
                    program: ContentRef & Program;
                } & {
                    _type: "ProgramTeaser";
                }) | ({
                    headline: string;
                    overline?: string;
                    text: string;
                    image: MediaRef & Media;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                    swapLayout?: boolean;
                } & {
                    _type: "ImageTextTeaser";
                }) | ({
                    teaser: ContentRef & TextTeaser;
                } & {
                    _type: "LinkTextTeaser";
                }))[];
            } & {
                _type: "contents";
            }))[];
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * List IfbInfopages
     */
    async listIfbInfopages({ orderBy, order, offset, limit, name, slug, fields, xRichtextFormat, search }: {
        orderBy?: "name" | "slug" | "position";
        order?: "asc" | "desc";
        offset?: number;
        limit?: number;
        name?: {
            eq?: string | string[];
            ne?: string | string[];
        };
        slug?: {
            eq?: string | string[];
            ne?: string | string[];
        };
        fields?: ("name" | "slug" | "contents" | "position" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
        search?: {
            term?: string;
            scope?: "title" | "global";
        };
    } = {}) {
        return await this._fetchJson(`/ifbInfopage${QS.query(QS.form({
            orderBy,
            order,
            offset,
            limit
        }), QS.deep({
            "data.name": name,
            "data.slug": slug,
            fields,
            search
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            total: number;
            items: ({
                name: string;
                slug: string;
                contents?: (({
                    text?: string;
                } & {
                    _type: "Text";
                }) | ({
                    headline: string;
                    copy?: string;
                    contacts: {
                        role: string;
                        contact: ContentRef;
                    }[];
                    withImage?: boolean;
                } & {
                    _type: "Contacts";
                }) | ({
                    image: MediaRef & Media;
                } & {
                    _type: "Image";
                }) | ({
                    table: string;
                    seperator: string;
                    striped: string;
                    caption?: string;
                } & {
                    _type: "Table";
                }) | ({
                    headline?: string;
                    documents?: ContentRef[];
                } & {
                    _type: "Downloads";
                }) | ({
                    headline: string;
                    intro?: string;
                    cases?: {
                        label: string;
                        headline: string;
                        text: string;
                        ctaLabel?: string;
                        ctaRef?: ContentRef;
                    }[];
                } & {
                    _type: "ShowCase";
                }) | ({
                    overline?: string;
                    headline: string;
                    subline?: string;
                    text: string;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                    layoutType?: boolean;
                } & {
                    _type: "TextTeaser";
                }) | ({
                    items?: ContentRef[];
                } & {
                    _type: "FundingStories";
                }) | ({
                    items?: {
                        label?: string;
                        content?: (({
                            text?: string;
                        } & {
                            _type: "Text";
                        }) | ({
                            headline?: string;
                            documents?: ContentRef[];
                        } & {
                            _type: "Downloads";
                        }) | ({
                            image: MediaRef & Media;
                        } & {
                            _type: "Image";
                        }) | ({
                            table: string;
                            seperator: string;
                            striped: string;
                            caption?: string;
                        } & {
                            _type: "Table";
                        }))[];
                    }[];
                } & {
                    _type: "Accordion";
                }) | ({
                    overline: string;
                    headline: string;
                    intro: string;
                    ctaLabel: string;
                    ctaRef: ContentRef;
                    dias: {
                        headline: string;
                        copy?: string;
                        icon?: MediaRef & Media;
                        ariaHidden?: boolean;
                    }[];
                } & {
                    _type: "DiaShow";
                }) | ({
                    title: string;
                    voices: {
                        quote: string;
                        subline: string;
                        program: ContentRef;
                    }[];
                } & {
                    _type: "CustomerVoices";
                }) | ({
                    title: string;
                    jobs: ContentRef[];
                    small?: boolean;
                } & {
                    _type: "Jobs";
                }) | ({
                    program: ContentRef;
                } & {
                    _type: "ProgramTeaser";
                }) | ({
                    headline: string;
                    overline?: string;
                    text: string;
                    image: MediaRef & Media;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                    swapLayout?: boolean;
                } & {
                    _type: "ImageTextTeaser";
                }) | ({
                    teaser: ContentRef;
                } & {
                    _type: "LinkTextTeaser";
                }))[];
                position?: string;
                seo?: {
                    metaTitle?: string;
                    metaDescription?: string;
                    robots?: string;
                };
            } & {
                _id: string;
            })[];
        };
    }
    /**
     * Load IfbInfopage
     */
    async loadIfbInfopage(id: string, { fields, xRichtextFormat }: {
        fields?: ("name" | "slug" | "contents" | "position" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
    } = {}) {
        return await this._fetchJson(`/ifbInfopage/${id}${QS.query(QS.deep({
            fields
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            name: string;
            slug: string;
            contents?: (({
                text?: string;
            } & {
                _type: "Text";
            }) | ({
                headline: string;
                copy?: string;
                contacts: {
                    role: string;
                    contact: ContentRef;
                }[];
                withImage?: boolean;
            } & {
                _type: "Contacts";
            }) | ({
                image: MediaRef & Media;
            } & {
                _type: "Image";
            }) | ({
                table: string;
                seperator: string;
                striped: string;
                caption?: string;
            } & {
                _type: "Table";
            }) | ({
                headline?: string;
                documents?: ContentRef[];
            } & {
                _type: "Downloads";
            }) | ({
                headline: string;
                intro?: string;
                cases?: {
                    label: string;
                    headline: string;
                    text: string;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                }[];
            } & {
                _type: "ShowCase";
            }) | ({
                overline?: string;
                headline: string;
                subline?: string;
                text: string;
                ctaLabel?: string;
                ctaRef?: ContentRef;
                layoutType?: boolean;
            } & {
                _type: "TextTeaser";
            }) | ({
                items?: ContentRef[];
            } & {
                _type: "FundingStories";
            }) | ({
                items?: {
                    label?: string;
                    content?: (({
                        text?: string;
                    } & {
                        _type: "Text";
                    }) | ({
                        headline?: string;
                        documents?: ContentRef[];
                    } & {
                        _type: "Downloads";
                    }) | ({
                        image: MediaRef & Media;
                    } & {
                        _type: "Image";
                    }) | ({
                        table: string;
                        seperator: string;
                        striped: string;
                        caption?: string;
                    } & {
                        _type: "Table";
                    }))[];
                }[];
            } & {
                _type: "Accordion";
            }) | ({
                overline: string;
                headline: string;
                intro: string;
                ctaLabel: string;
                ctaRef: ContentRef;
                dias: {
                    headline: string;
                    copy?: string;
                    icon?: MediaRef & Media;
                    ariaHidden?: boolean;
                }[];
            } & {
                _type: "DiaShow";
            }) | ({
                title: string;
                voices: {
                    quote: string;
                    subline: string;
                    program: ContentRef;
                }[];
            } & {
                _type: "CustomerVoices";
            }) | ({
                title: string;
                jobs: ContentRef[];
                small?: boolean;
            } & {
                _type: "Jobs";
            }) | ({
                program: ContentRef;
            } & {
                _type: "ProgramTeaser";
            }) | ({
                headline: string;
                overline?: string;
                text: string;
                image: MediaRef & Media;
                ctaLabel?: string;
                ctaRef?: ContentRef;
                swapLayout?: boolean;
            } & {
                _type: "ImageTextTeaser";
            }) | ({
                teaser: ContentRef;
            } & {
                _type: "LinkTextTeaser";
            }))[];
            position?: string;
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * Load IfbInfopage
     */
    async loadIfbInfopageBySlug(uniqueValue: string, { fields, xRichtextFormat }: {
        fields?: ("name" | "slug" | "contents" | "position" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
    } = {}) {
        return await this._fetchJson(`/ifbInfopage/slug/${uniqueValue}${QS.query(QS.deep({
            fields,
            join: { Contact: ["name", "email", "phone", "image", "type"], Document: ["name", "file", "description", "type.name"], MagazinPortraits: ["date", "title", "headerImage", "slug", "tags", "categories", "categories.name", "programs.slug", "programs.name", "programs.type.name"], Program: ["name", "slug", "type.name", "abstract", "keyFacts"], ExternalProgram: ["type", "name", "abstract", "keyFacts", "ctaLabel", "ctaHref"], JobOffer: ["name", "slug", "intro", "employmentType", "employmentSchema", "posit", "category.slug", "category.name"], TextTeaser: ["overline", "headline", "subline", "text", "ctaLabel", "ctaRef", "layoutType"] }
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            name: string;
            slug: string;
            contents?: (({
                text?: string;
            } & {
                _type: "Text";
            }) | ({
                headline: string;
                copy?: string;
                contacts: {
                    role: string;
                    contact: ContentRef & Contact;
                }[];
                withImage?: boolean;
            } & {
                _type: "Contacts";
            }) | ({
                image: MediaRef & Media;
            } & {
                _type: "Image";
            }) | ({
                table: string;
                seperator: string;
                striped: string;
                caption?: string;
            } & {
                _type: "Table";
            }) | ({
                headline?: string;
                documents?: (ContentRef & Document)[];
            } & {
                _type: "Downloads";
            }) | ({
                headline: string;
                intro?: string;
                cases?: {
                    label: string;
                    headline: string;
                    text: string;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                }[];
            } & {
                _type: "ShowCase";
            }) | ({
                overline?: string;
                headline: string;
                subline?: string;
                text: string;
                ctaLabel?: string;
                ctaRef?: ContentRef;
                layoutType?: boolean;
            } & {
                _type: "TextTeaser";
            }) | ({
                items?: (ContentRef & MagazinPortraits)[];
            } & {
                _type: "FundingStories";
            }) | ({
                items?: {
                    label?: string;
                    content?: (({
                        text?: string;
                    } & {
                        _type: "Text";
                    }) | ({
                        headline?: string;
                        documents?: (ContentRef & Document)[];
                    } & {
                        _type: "Downloads";
                    }) | ({
                        image: MediaRef & Media;
                    } & {
                        _type: "Image";
                    }) | ({
                        table: string;
                        seperator: string;
                        striped: string;
                        caption?: string;
                    } & {
                        _type: "Table";
                    }))[];
                }[];
            } & {
                _type: "Accordion";
            }) | ({
                overline: string;
                headline: string;
                intro: string;
                ctaLabel: string;
                ctaRef: ContentRef;
                dias: {
                    headline: string;
                    copy?: string;
                    icon?: MediaRef & Media;
                    ariaHidden?: boolean;
                }[];
            } & {
                _type: "DiaShow";
            }) | ({
                title: string;
                voices: {
                    quote: string;
                    subline: string;
                    program: ContentRef & (Program | ExternalProgram);
                }[];
            } & {
                _type: "CustomerVoices";
            }) | ({
                title: string;
                jobs: (ContentRef & JobOffer)[];
                small?: boolean;
            } & {
                _type: "Jobs";
            }) | ({
                program: ContentRef & Program;
            } & {
                _type: "ProgramTeaser";
            }) | ({
                headline: string;
                overline?: string;
                text: string;
                image: MediaRef & Media;
                ctaLabel?: string;
                ctaRef?: ContentRef;
                swapLayout?: boolean;
            } & {
                _type: "ImageTextTeaser";
            }) | ({
                teaser: ContentRef & TextTeaser;
            } & {
                _type: "LinkTextTeaser";
            }))[];
            position?: string;
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * Load EuFunding
     */
    async loadEuFunding({ fields, xRichtextFormat, search }: {
        fields?: ("name" | "content" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
        search?: {
            term?: string;
            scope?: "title" | "global";
        };
    } = {}) {
        return await this._fetchJson(`/euFunding${QS.query(QS.deep({
            fields,
            join: { Contact: ["name", "email", "phone", "image", "type"], Document: ["name", "file", "description", "type.name"], MagazinPortraits: ["date", "title", "headerImage", "slug", "tags", "categories", "categories.name", "programs.slug", "programs.name", "programs.type.name"], Program: ["name", "slug", "type.name", "abstract", "keyFacts"], ExternalProgram: ["type", "name", "abstract", "keyFacts", "ctaLabel", "ctaHref"], JobOffer: ["name", "slug", "intro", "employmentType", "employmentSchema", "posit", "category.slug", "category.name"], TextTeaser: ["overline", "headline", "subline", "text", "ctaLabel", "ctaRef", "layoutType"] },
            search
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            name: string;
            content: (({
                items?: {
                    navLink?: string;
                    items?: (({
                        text?: string;
                    } & {
                        _type: "Text";
                    }) | ({
                        headline: string;
                        copy?: string;
                        contacts: {
                            role: string;
                            contact: ContentRef & Contact;
                        }[];
                        withImage?: boolean;
                    } & {
                        _type: "Contacts";
                    }) | ({
                        image: MediaRef & Media;
                    } & {
                        _type: "Image";
                    }) | ({
                        table: string;
                        seperator: string;
                        striped: string;
                        caption?: string;
                    } & {
                        _type: "Table";
                    }) | ({
                        headline?: string;
                        documents?: (ContentRef & Document)[];
                    } & {
                        _type: "Downloads";
                    }) | ({
                        headline: string;
                        intro?: string;
                        cases?: {
                            label: string;
                            headline: string;
                            text: string;
                            ctaLabel?: string;
                            ctaRef?: ContentRef;
                        }[];
                    } & {
                        _type: "ShowCase";
                    }) | ({
                        overline?: string;
                        headline: string;
                        subline?: string;
                        text: string;
                        ctaLabel?: string;
                        ctaRef?: ContentRef;
                        layoutType?: boolean;
                    } & {
                        _type: "TextTeaser";
                    }) | ({
                        items?: (ContentRef & MagazinPortraits)[];
                    } & {
                        _type: "FundingStories";
                    }) | ({
                        items?: {
                            label?: string;
                            content?: (({
                                text?: string;
                            } & {
                                _type: "Text";
                            }) | ({
                                headline?: string;
                                documents?: (ContentRef & Document)[];
                            } & {
                                _type: "Downloads";
                            }) | ({
                                image: MediaRef & Media;
                            } & {
                                _type: "Image";
                            }) | ({
                                table: string;
                                seperator: string;
                                striped: string;
                                caption?: string;
                            } & {
                                _type: "Table";
                            }))[];
                        }[];
                    } & {
                        _type: "Accordion";
                    }) | ({
                        overline: string;
                        headline: string;
                        intro: string;
                        ctaLabel: string;
                        ctaRef: ContentRef;
                        dias: {
                            headline: string;
                            copy?: string;
                            icon?: MediaRef & Media;
                            ariaHidden?: boolean;
                        }[];
                    } & {
                        _type: "DiaShow";
                    }) | ({
                        title: string;
                        voices: {
                            quote: string;
                            subline: string;
                            program: ContentRef & (Program | ExternalProgram);
                        }[];
                    } & {
                        _type: "CustomerVoices";
                    }) | ({
                        title: string;
                        jobs: (ContentRef & JobOffer)[];
                        small?: boolean;
                    } & {
                        _type: "Jobs";
                    }) | ({
                        program: ContentRef & Program;
                    } & {
                        _type: "ProgramTeaser";
                    }) | ({
                        headline: string;
                        overline?: string;
                        text: string;
                        image: MediaRef & Media;
                        ctaLabel?: string;
                        ctaRef?: ContentRef;
                        swapLayout?: boolean;
                    } & {
                        _type: "ImageTextTeaser";
                    }) | ({
                        teaser: ContentRef & TextTeaser;
                    } & {
                        _type: "LinkTextTeaser";
                    }))[];
                }[];
            } & {
                _type: "sections";
            }) | ({
                items?: (({
                    text?: string;
                } & {
                    _type: "Text";
                }) | ({
                    headline: string;
                    copy?: string;
                    contacts: {
                        role: string;
                        contact: ContentRef & Contact;
                    }[];
                    withImage?: boolean;
                } & {
                    _type: "Contacts";
                }) | ({
                    image: MediaRef & Media;
                } & {
                    _type: "Image";
                }) | ({
                    table: string;
                    seperator: string;
                    striped: string;
                    caption?: string;
                } & {
                    _type: "Table";
                }) | ({
                    headline?: string;
                    documents?: (ContentRef & Document)[];
                } & {
                    _type: "Downloads";
                }) | ({
                    headline: string;
                    intro?: string;
                    cases?: {
                        label: string;
                        headline: string;
                        text: string;
                        ctaLabel?: string;
                        ctaRef?: ContentRef;
                    }[];
                } & {
                    _type: "ShowCase";
                }) | ({
                    overline?: string;
                    headline: string;
                    subline?: string;
                    text: string;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                    layoutType?: boolean;
                } & {
                    _type: "TextTeaser";
                }) | ({
                    items?: (ContentRef & MagazinPortraits)[];
                } & {
                    _type: "FundingStories";
                }) | ({
                    items?: {
                        label?: string;
                        content?: (({
                            text?: string;
                        } & {
                            _type: "Text";
                        }) | ({
                            headline?: string;
                            documents?: (ContentRef & Document)[];
                        } & {
                            _type: "Downloads";
                        }) | ({
                            image: MediaRef & Media;
                        } & {
                            _type: "Image";
                        }) | ({
                            table: string;
                            seperator: string;
                            striped: string;
                            caption?: string;
                        } & {
                            _type: "Table";
                        }))[];
                    }[];
                } & {
                    _type: "Accordion";
                }) | ({
                    overline: string;
                    headline: string;
                    intro: string;
                    ctaLabel: string;
                    ctaRef: ContentRef;
                    dias: {
                        headline: string;
                        copy?: string;
                        icon?: MediaRef & Media;
                        ariaHidden?: boolean;
                    }[];
                } & {
                    _type: "DiaShow";
                }) | ({
                    title: string;
                    voices: {
                        quote: string;
                        subline: string;
                        program: ContentRef & (Program | ExternalProgram);
                    }[];
                } & {
                    _type: "CustomerVoices";
                }) | ({
                    title: string;
                    jobs: (ContentRef & JobOffer)[];
                    small?: boolean;
                } & {
                    _type: "Jobs";
                }) | ({
                    program: ContentRef & Program;
                } & {
                    _type: "ProgramTeaser";
                }) | ({
                    headline: string;
                    overline?: string;
                    text: string;
                    image: MediaRef & Media;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                    swapLayout?: boolean;
                } & {
                    _type: "ImageTextTeaser";
                }) | ({
                    teaser: ContentRef & TextTeaser;
                } & {
                    _type: "LinkTextTeaser";
                }))[];
            } & {
                _type: "contents";
            }))[];
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * Load MagazinPage
     */
    async loadMagazinPage({ fields, search }: {
        fields?: ("seo")[];
        search?: {
            term?: string;
            scope?: "title" | "global";
        };
    } = {}) {
        return await this._fetchJson(`/magazinPage${QS.query(QS.deep({
            fields,
            search
        }))}`) as {
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * Load MagazinNewsPage
     */
    async loadMagazinNewsPage({ fields, search }: {
        fields?: ("name" | "intro" | "seo")[];
        search?: {
            term?: string;
            scope?: "title" | "global";
        };
    } = {}) {
        return await this._fetchJson(`/magazinNewsPage${QS.query(QS.deep({
            fields,
            search
        }))}`) as {
            name: string;
            intro: string;
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * List MagazinNews
     */
    async listMagazinNews({ orderBy, order, offset, limit, title, slug, date, categories, tags, fields, xRichtextFormat, search }: {
        orderBy?: "title" | "slug" | "date";
        order?: "asc" | "desc";
        offset?: number;
        limit?: number;
        title?: {
            eq?: string | string[];
            ne?: string | string[];
        };
        slug?: {
            eq?: string | string[];
            ne?: string | string[];
        };
        date?: {
            eq?: number | string;
            ne?: number | string;
            gt?: number | string;
            gte?: number | string;
            lt?: number | string;
            lte?: number | string;
        };
        categories?: {
            eq?: number | string | (number | string)[];
            ne?: number | string | (number | string)[];
            gt?: number | string;
            gte?: number | string;
            lt?: number | string;
            lte?: number | string;
            path?: string;
        };
        tags?: {
            eq?: number | string | (number | string)[];
            ne?: number | string | (number | string)[];
            gt?: number | string;
            gte?: number | string;
            lt?: number | string;
            lte?: number | string;
            path?: string;
        };
        fields?: ("title" | "slug" | "date" | "headerImage" | "description" | "content" | "categories" | "tags" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
        search?: {
            term?: string;
            scope?: "title" | "global";
        };
    } = {}) {
        return await this._fetchJson(`/magazinNews${QS.query(QS.form({
            orderBy,
            order,
            offset,
            limit
        }), QS.deep({
            "data.title": title,
            "data.slug": slug,
            "data.date": date,
            "data.categories": categories,
            "data.tags": tags,
            fields,
            join: { Category: ["slug", "name"] },
            search
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            total: number;
            items: ({
                title: string;
                slug: string;
                date: string;
                headerImage?: MediaRef & Media;
                description: string;
                content?: (({
                    text?: string;
                } & {
                    _type: "text";
                }) | ({
                    image?: MediaRef & Media;
                    imageSubTitle?: string;
                    text?: string;
                } & {
                    _type: "image";
                }))[];
                categories?: (ContentRef & Category)[];
                tags?: ContentRef[];
                seo?: {
                    metaTitle?: string;
                    metaDescription?: string;
                    robots?: string;
                };
            } & {
                _id: string;
            })[];
        };
    }
    /**
     * Load MagazinNews
     */
    async loadMagazinNews(id: string, { fields, xRichtextFormat }: {
        fields?: ("title" | "slug" | "date" | "headerImage" | "description" | "content" | "categories" | "tags" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
    } = {}) {
        return await this._fetchJson(`/magazinNews/${id}${QS.query(QS.deep({
            fields
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            title: string;
            slug: string;
            date: string;
            headerImage?: MediaRef & Media;
            description: string;
            content?: (({
                text?: string;
            } & {
                _type: "text";
            }) | ({
                image?: MediaRef & Media;
                imageSubTitle?: string;
                text?: string;
            } & {
                _type: "image";
            }))[];
            categories?: ContentRef[];
            tags?: ContentRef[];
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * Load MagazinNews
     */
    async loadMagazinNewsBySlug(uniqueValue: string, { fields, xRichtextFormat }: {
        fields?: ("title" | "slug" | "date" | "headerImage" | "description" | "content" | "categories" | "tags" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
    } = {}) {
        return await this._fetchJson(`/magazinNews/slug/${uniqueValue}${QS.query(QS.deep({
            fields,
            join: { Category: ["slug", "name"] }
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            title: string;
            slug: string;
            date: string;
            headerImage?: MediaRef & Media;
            description: string;
            content?: (({
                text?: string;
            } & {
                _type: "text";
            }) | ({
                image?: MediaRef & Media;
                imageSubTitle?: string;
                text?: string;
            } & {
                _type: "image";
            }))[];
            categories?: (ContentRef & Category)[];
            tags?: ContentRef[];
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * Load MagazinPortraitPage
     */
    async loadMagazinPortraitPage({ fields, search }: {
        fields?: ("name" | "intro" | "seo")[];
        search?: {
            term?: string;
            scope?: "title" | "global";
        };
    } = {}) {
        return await this._fetchJson(`/magazinPortraitPage${QS.query(QS.deep({
            fields,
            search
        }))}`) as {
            name: string;
            intro: string;
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * List MagazinPortraits
     */
    async listMagazinPortraits({ orderBy, order, offset, limit, title, slug, date, categories, tags, programs, fields, xRichtextFormat, search }: {
        orderBy?: "title" | "slug" | "date";
        order?: "asc" | "desc";
        offset?: number;
        limit?: number;
        title?: {
            eq?: string | string[];
            ne?: string | string[];
        };
        slug?: {
            eq?: string | string[];
            ne?: string | string[];
        };
        date?: {
            eq?: number | string;
            ne?: number | string;
            gt?: number | string;
            gte?: number | string;
            lt?: number | string;
            lte?: number | string;
        };
        categories?: {
            eq?: number | string | (number | string)[];
            ne?: number | string | (number | string)[];
            gt?: number | string;
            gte?: number | string;
            lt?: number | string;
            lte?: number | string;
            path?: string;
        };
        tags?: {
            eq?: number | string | (number | string)[];
            ne?: number | string | (number | string)[];
            gt?: number | string;
            gte?: number | string;
            lt?: number | string;
            lte?: number | string;
            path?: string;
        };
        programs?: {
            eq?: number | string | (number | string)[];
            ne?: number | string | (number | string)[];
            gt?: number | string;
            gte?: number | string;
            lt?: number | string;
            lte?: number | string;
            path?: string;
        };
        fields?: ("title" | "slug" | "date" | "headerImage" | "content" | "categories" | "tags" | "programs" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
        search?: {
            term?: string;
            scope?: "title" | "global";
        };
    } = {}) {
        return await this._fetchJson(`/magazinPortraits${QS.query(QS.form({
            orderBy,
            order,
            offset,
            limit
        }), QS.deep({
            "data.title": title,
            "data.slug": slug,
            "data.date": date,
            "data.categories": categories,
            "data.tags": tags,
            "data.programs": programs,
            fields,
            join: { Program: ["name", "slug", "type.name", "abstract", "keyFacts"], ExternalProgram: ["name", "slug", "type", "abstract", "keyFacts", "ctaLabel", "ctaHref"], Category: ["slug", "name"] },
            search
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            total: number;
            items: ({
                title: string;
                slug: string;
                date: string;
                headerImage?: MediaRef & Media;
                content?: (({
                    text?: string;
                } & {
                    _type: "text";
                }) | ({
                    image?: MediaRef & Media;
                    imageSubTitle?: string;
                    text?: string;
                } & {
                    _type: "image";
                }))[];
                categories?: (ContentRef & Category)[];
                tags?: ContentRef[];
                programs?: (ContentRef & Program)[];
                seo?: {
                    metaTitle?: string;
                    metaDescription?: string;
                    robots?: string;
                };
            } & {
                _id: string;
            })[];
        };
    }
    /**
     * Load MagazinPortraits
     */
    async loadMagazinPortraits(id: string, { fields, xRichtextFormat }: {
        fields?: ("title" | "slug" | "date" | "headerImage" | "content" | "categories" | "tags" | "programs" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
    } = {}) {
        return await this._fetchJson(`/magazinPortraits/${id}${QS.query(QS.deep({
            fields
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            title: string;
            slug: string;
            date: string;
            headerImage?: MediaRef & Media;
            content?: (({
                text?: string;
            } & {
                _type: "text";
            }) | ({
                image?: MediaRef & Media;
                imageSubTitle?: string;
                text?: string;
            } & {
                _type: "image";
            }))[];
            categories?: ContentRef[];
            tags?: ContentRef[];
            programs?: ContentRef[];
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * Load MagazinPortraits
     */
    async loadMagazinPortraitsBySlug(uniqueValue: string, { fields, xRichtextFormat }: {
        fields?: ("title" | "slug" | "date" | "headerImage" | "content" | "categories" | "tags" | "programs" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
    } = {}) {
        return await this._fetchJson(`/magazinPortraits/slug/${uniqueValue}${QS.query(QS.deep({
            fields,
            join: { Program: ["name", "slug", "type.name", "abstract", "keyFacts"], ExternalProgram: ["name", "slug", "type", "abstract", "keyFacts", "ctaLabel", "ctaHref"], Category: ["slug", "name"] }
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            title: string;
            slug: string;
            date: string;
            headerImage?: MediaRef & Media;
            content?: (({
                text?: string;
            } & {
                _type: "text";
            }) | ({
                image?: MediaRef & Media;
                imageSubTitle?: string;
                text?: string;
            } & {
                _type: "image";
            }))[];
            categories?: (ContentRef & Category)[];
            tags?: ContentRef[];
            programs?: (ContentRef & Program)[];
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * Load MagazinEventPage
     */
    async loadMagazinEventPage({ fields, search }: {
        fields?: ("name" | "intro" | "highlightEvent" | "highlightImage" | "seo")[];
        search?: {
            term?: string;
            scope?: "title" | "global";
        };
    } = {}) {
        return await this._fetchJson(`/magazinEventPage${QS.query(QS.deep({
            fields,
            join: { ExternalEvent: ["dates", "name", "description", "organizer", "ctaHref"] },
            search
        }))}`) as {
            name: string;
            intro: string;
            highlightEvent?: ContentRef & ExternalEvent;
            highlightImage?: MediaRef & Media;
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * List ExternalEvents
     */
    async listExternalEvents({ orderBy, order, offset, limit, name, date, tags, fields, search }: {
        orderBy?: "name" | "date";
        order?: "asc" | "desc";
        offset?: number;
        limit?: number;
        name?: {
            eq?: string | string[];
            ne?: string | string[];
        };
        date?: {
            eq?: number | string;
            ne?: number | string;
            gt?: number | string;
            gte?: number | string;
            lt?: number | string;
            lte?: number | string;
        };
        tags?: {
            eq?: number | string | (number | string)[];
            ne?: number | string | (number | string)[];
            gt?: number | string;
            gte?: number | string;
            lt?: number | string;
            lte?: number | string;
            path?: string;
        };
        fields?: ("name" | "dates" | "description" | "location" | "organizer" | "ctaHref" | "tags" | "searchContent")[];
        search?: {
            term?: string;
            scope?: "title" | "global";
        };
    } = {}) {
        return await this._fetchJson(`/externalEvent${QS.query(QS.form({
            orderBy,
            order,
            offset,
            limit
        }), QS.deep({
            "data.name": name,
            "data.dates.date": date,
            "data.tags": tags,
            fields,
            search
        }))}`) as {
            total: number;
            items: ({
                name: string;
                dates: {
                    date: string;
                    time: string;
                }[];
                description: string;
                location?: string;
                organizer?: string;
                ctaHref: string;
                tags?: ContentRef[];
                searchContent: string;
            } & {
                _id: string;
            })[];
        };
    }
    /**
     * Load ExternalEvent
     */
    async loadExternalEvent(id: string, { fields }: {
        fields?: ("name" | "dates" | "description" | "location" | "organizer" | "ctaHref" | "tags" | "searchContent")[];
    } = {}) {
        return await this._fetchJson(`/externalEvent/${id}${QS.query(QS.deep({
            fields
        }))}`) as {
            name: string;
            dates: {
                date: string;
                time: string;
            }[];
            description: string;
            location?: string;
            organizer?: string;
            ctaHref: string;
            tags?: ContentRef[];
            searchContent: string;
        } & {
            _id?: string;
        };
    }
    /**
     * Load PartnerPage
     */
    async loadPartnerPage({ fields, search }: {
        fields?: ("name" | "intro" | "seo")[];
        search?: {
            term?: string;
            scope?: "title" | "global";
        };
    } = {}) {
        return await this._fetchJson(`/partnerPage${QS.query(QS.deep({
            fields,
            search
        }))}`) as {
            name: string;
            intro: string;
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * List PartnerGroups
     */
    async listPartnerGroups({ orderBy, order, offset, limit, name, slug, contact, partnerCategories, fields, xRichtextFormat, search }: {
        orderBy?: "name" | "slug";
        order?: "asc" | "desc";
        offset?: number;
        limit?: number;
        name?: {
            eq?: string | string[];
            ne?: string | string[];
        };
        slug?: {
            eq?: string | string[];
            ne?: string | string[];
        };
        contact?: {
            eq?: number | string | (number | string)[];
            ne?: number | string | (number | string)[];
            gt?: number | string;
            gte?: number | string;
            lt?: number | string;
            lte?: number | string;
            path?: string;
        };
        partnerCategories?: {
            eq?: number | string | (number | string)[];
            ne?: number | string | (number | string)[];
            gt?: number | string;
            gte?: number | string;
            lt?: number | string;
            lte?: number | string;
            path?: string;
        };
        fields?: ("name" | "slug" | "header" | "contents" | "contacts" | "partnerCategories" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
        search?: {
            term?: string;
            scope?: "title" | "global";
        };
    } = {}) {
        return await this._fetchJson(`/partnerGroup${QS.query(QS.form({
            orderBy,
            order,
            offset,
            limit
        }), QS.deep({
            "data.name": name,
            "data.slug": slug,
            "data.contacts.contact": contact,
            "data.partnerCategories": partnerCategories,
            fields,
            search
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            total: number;
            items: ({
                name: string;
                slug: string;
                header?: {
                    image?: MediaRef & Media;
                    headline?: string;
                    intro?: string;
                };
                contents?: (({
                    overline?: string;
                    headline: string;
                    subline?: string;
                    text: string;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                    layoutType?: boolean;
                } & {
                    _type: "TextTeaser";
                }) | ({
                    teaser: ContentRef;
                } & {
                    _type: "LinkTextTeaser";
                }) | ({
                    headline: string;
                    overline?: string;
                    text: string;
                    image: MediaRef & Media;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                    swapLayout?: boolean;
                } & {
                    _type: "ImageTextTeaser";
                }) | ({
                    headline: string;
                    intro?: string;
                    cases?: {
                        label: string;
                        headline: string;
                        text: string;
                        ctaLabel?: string;
                        ctaRef?: ContentRef;
                    }[];
                } & {
                    _type: "ShowCase";
                }) | ({
                    headline: string;
                    overline?: string;
                    subline?: string;
                    text: string;
                } & {
                    _type: "CollapsibleText";
                }))[];
                contacts?: {
                    role: string;
                    contact: ContentRef;
                }[];
                partnerCategories?: ContentRef[];
                seo?: {
                    metaTitle?: string;
                    metaDescription?: string;
                    robots?: string;
                };
            } & {
                _id: string;
            })[];
        };
    }
    /**
     * Load PartnerGroup
     */
    async loadPartnerGroup(id: string, { fields, xRichtextFormat }: {
        fields?: ("name" | "slug" | "header" | "contents" | "contacts" | "partnerCategories" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
    } = {}) {
        return await this._fetchJson(`/partnerGroup/${id}${QS.query(QS.deep({
            fields
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            name: string;
            slug: string;
            header?: {
                image?: MediaRef & Media;
                headline?: string;
                intro?: string;
            };
            contents?: (({
                overline?: string;
                headline: string;
                subline?: string;
                text: string;
                ctaLabel?: string;
                ctaRef?: ContentRef;
                layoutType?: boolean;
            } & {
                _type: "TextTeaser";
            }) | ({
                teaser: ContentRef;
            } & {
                _type: "LinkTextTeaser";
            }) | ({
                headline: string;
                overline?: string;
                text: string;
                image: MediaRef & Media;
                ctaLabel?: string;
                ctaRef?: ContentRef;
                swapLayout?: boolean;
            } & {
                _type: "ImageTextTeaser";
            }) | ({
                headline: string;
                intro?: string;
                cases?: {
                    label: string;
                    headline: string;
                    text: string;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                }[];
            } & {
                _type: "ShowCase";
            }) | ({
                headline: string;
                overline?: string;
                subline?: string;
                text: string;
            } & {
                _type: "CollapsibleText";
            }))[];
            contacts?: {
                role: string;
                contact: ContentRef;
            }[];
            partnerCategories?: ContentRef[];
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * Load PartnerGroup
     */
    async loadPartnerGroupByName(uniqueValue: string, { fields, xRichtextFormat }: {
        fields?: ("name" | "slug" | "header" | "contents" | "contacts" | "partnerCategories" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
    } = {}) {
        return await this._fetchJson(`/partnerGroup/name/${uniqueValue}${QS.query(QS.deep({
            fields
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            name: string;
            slug: string;
            header?: {
                image?: MediaRef & Media;
                headline?: string;
                intro?: string;
            };
            contents?: (({
                overline?: string;
                headline: string;
                subline?: string;
                text: string;
                ctaLabel?: string;
                ctaRef?: ContentRef;
                layoutType?: boolean;
            } & {
                _type: "TextTeaser";
            }) | ({
                teaser: ContentRef;
            } & {
                _type: "LinkTextTeaser";
            }) | ({
                headline: string;
                overline?: string;
                text: string;
                image: MediaRef & Media;
                ctaLabel?: string;
                ctaRef?: ContentRef;
                swapLayout?: boolean;
            } & {
                _type: "ImageTextTeaser";
            }) | ({
                headline: string;
                intro?: string;
                cases?: {
                    label: string;
                    headline: string;
                    text: string;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                }[];
            } & {
                _type: "ShowCase";
            }) | ({
                headline: string;
                overline?: string;
                subline?: string;
                text: string;
            } & {
                _type: "CollapsibleText";
            }))[];
            contacts?: {
                role: string;
                contact: ContentRef;
            }[];
            partnerCategories?: ContentRef[];
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * Load PartnerGroup
     */
    async loadPartnerGroupBySlug(uniqueValue: string, { fields, xRichtextFormat }: {
        fields?: ("name" | "slug" | "header" | "contents" | "contacts" | "partnerCategories" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
    } = {}) {
        return await this._fetchJson(`/partnerGroup/slug/${uniqueValue}${QS.query(QS.deep({
            fields,
            join: { Contact: ["name", "phone", "email", "type", "image"], PartnerCategory: ["name", "slug", "intro"], TextTeaser: ["overline", "headline", "subline", "text", "ctaLabel", "ctaRef", "layoutType"] }
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            name: string;
            slug: string;
            header?: {
                image?: MediaRef & Media;
                headline?: string;
                intro?: string;
            };
            contents?: (({
                overline?: string;
                headline: string;
                subline?: string;
                text: string;
                ctaLabel?: string;
                ctaRef?: ContentRef;
                layoutType?: boolean;
            } & {
                _type: "TextTeaser";
            }) | ({
                teaser: ContentRef & TextTeaser;
            } & {
                _type: "LinkTextTeaser";
            }) | ({
                headline: string;
                overline?: string;
                text: string;
                image: MediaRef & Media;
                ctaLabel?: string;
                ctaRef?: ContentRef;
                swapLayout?: boolean;
            } & {
                _type: "ImageTextTeaser";
            }) | ({
                headline: string;
                intro?: string;
                cases?: {
                    label: string;
                    headline: string;
                    text: string;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                }[];
            } & {
                _type: "ShowCase";
            }) | ({
                headline: string;
                overline?: string;
                subline?: string;
                text: string;
            } & {
                _type: "CollapsibleText";
            }))[];
            contacts?: {
                role: string;
                contact: ContentRef & Contact;
            }[];
            partnerCategories?: (ContentRef & PartnerCategory)[];
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * List PartnerCategories
     */
    async listPartnerCategories({ orderBy, order, offset, limit, name, slug, group, programs, contact, documents, fields, xRichtextFormat, search }: {
        orderBy?: "name" | "slug";
        order?: "asc" | "desc";
        offset?: number;
        limit?: number;
        name?: {
            eq?: string | string[];
            ne?: string | string[];
        };
        slug?: {
            eq?: string | string[];
            ne?: string | string[];
        };
        group?: {
            eq?: number | string | (number | string)[];
            ne?: number | string | (number | string)[];
            gt?: number | string;
            gte?: number | string;
            lt?: number | string;
            lte?: number | string;
            path?: string;
        };
        programs?: {
            eq?: number | string | (number | string)[];
            ne?: number | string | (number | string)[];
            gt?: number | string;
            gte?: number | string;
            lt?: number | string;
            lte?: number | string;
            path?: string;
        };
        contact?: {
            eq?: number | string | (number | string)[];
            ne?: number | string | (number | string)[];
            gt?: number | string;
            gte?: number | string;
            lt?: number | string;
            lte?: number | string;
            path?: string;
        };
        documents?: {
            eq?: number | string | (number | string)[];
            ne?: number | string | (number | string)[];
            gt?: number | string;
            gte?: number | string;
            lt?: number | string;
            lte?: number | string;
            path?: string;
        };
        fields?: ("name" | "slug" | "intro" | "group" | "sections" | "programs" | "contacts" | "documents" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
        search?: {
            term?: string;
            scope?: "title" | "global";
        };
    } = {}) {
        return await this._fetchJson(`/partnerCategory${QS.query(QS.form({
            orderBy,
            order,
            offset,
            limit
        }), QS.deep({
            "data.name": name,
            "data.slug": slug,
            "data.group": group,
            "data.programs": programs,
            "data.contacts.contact": contact,
            "data.documents": documents,
            fields,
            search
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            total: number;
            items: ({
                name: string;
                slug: string;
                intro: string;
                group: ContentRef;
                sections?: {
                    navLink?: string;
                    items?: (({
                        overline?: string;
                        headline: string;
                        subline?: string;
                        text: string;
                        ctaLabel?: string;
                        ctaRef?: ContentRef;
                        layoutType?: boolean;
                    } & {
                        _type: "TextTeaser";
                    }) | ({
                        teaser: ContentRef;
                    } & {
                        _type: "LinkTextTeaser";
                    }) | ({
                        headline: string;
                        overline?: string;
                        text: string;
                        image: MediaRef & Media;
                        ctaLabel?: string;
                        ctaRef?: ContentRef;
                        swapLayout?: boolean;
                    } & {
                        _type: "ImageTextTeaser";
                    }) | ({
                        headline: string;
                        intro?: string;
                        cases?: {
                            label: string;
                            headline: string;
                            text: string;
                            ctaLabel?: string;
                            ctaRef?: ContentRef;
                        }[];
                    } & {
                        _type: "ShowCase";
                    }) | ({
                        headline: string;
                        overline?: string;
                        subline?: string;
                        text: string;
                    } & {
                        _type: "CollapsibleText";
                    }))[];
                }[];
                programs?: ContentRef[];
                contacts?: {
                    role: string;
                    contact: ContentRef;
                }[];
                documents?: ContentRef[];
                seo?: {
                    metaTitle?: string;
                    metaDescription?: string;
                    robots?: string;
                };
            } & {
                _id: string;
            })[];
        };
    }
    /**
     * Load PartnerCategory
     */
    async loadPartnerCategory(id: string, { fields, xRichtextFormat }: {
        fields?: ("name" | "slug" | "intro" | "group" | "sections" | "programs" | "contacts" | "documents" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
    } = {}) {
        return await this._fetchJson(`/partnerCategory/${id}${QS.query(QS.deep({
            fields
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            name: string;
            slug: string;
            intro: string;
            group: ContentRef;
            sections?: {
                navLink?: string;
                items?: (({
                    overline?: string;
                    headline: string;
                    subline?: string;
                    text: string;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                    layoutType?: boolean;
                } & {
                    _type: "TextTeaser";
                }) | ({
                    teaser: ContentRef;
                } & {
                    _type: "LinkTextTeaser";
                }) | ({
                    headline: string;
                    overline?: string;
                    text: string;
                    image: MediaRef & Media;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                    swapLayout?: boolean;
                } & {
                    _type: "ImageTextTeaser";
                }) | ({
                    headline: string;
                    intro?: string;
                    cases?: {
                        label: string;
                        headline: string;
                        text: string;
                        ctaLabel?: string;
                        ctaRef?: ContentRef;
                    }[];
                } & {
                    _type: "ShowCase";
                }) | ({
                    headline: string;
                    overline?: string;
                    subline?: string;
                    text: string;
                } & {
                    _type: "CollapsibleText";
                }))[];
            }[];
            programs?: ContentRef[];
            contacts?: {
                role: string;
                contact: ContentRef;
            }[];
            documents?: ContentRef[];
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * Load PartnerCategory
     */
    async loadPartnerCategoryByName(uniqueValue: string, { fields, xRichtextFormat }: {
        fields?: ("name" | "slug" | "intro" | "group" | "sections" | "programs" | "contacts" | "documents" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
    } = {}) {
        return await this._fetchJson(`/partnerCategory/name/${uniqueValue}${QS.query(QS.deep({
            fields
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            name: string;
            slug: string;
            intro: string;
            group: ContentRef;
            sections?: {
                navLink?: string;
                items?: (({
                    overline?: string;
                    headline: string;
                    subline?: string;
                    text: string;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                    layoutType?: boolean;
                } & {
                    _type: "TextTeaser";
                }) | ({
                    teaser: ContentRef;
                } & {
                    _type: "LinkTextTeaser";
                }) | ({
                    headline: string;
                    overline?: string;
                    text: string;
                    image: MediaRef & Media;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                    swapLayout?: boolean;
                } & {
                    _type: "ImageTextTeaser";
                }) | ({
                    headline: string;
                    intro?: string;
                    cases?: {
                        label: string;
                        headline: string;
                        text: string;
                        ctaLabel?: string;
                        ctaRef?: ContentRef;
                    }[];
                } & {
                    _type: "ShowCase";
                }) | ({
                    headline: string;
                    overline?: string;
                    subline?: string;
                    text: string;
                } & {
                    _type: "CollapsibleText";
                }))[];
            }[];
            programs?: ContentRef[];
            contacts?: {
                role: string;
                contact: ContentRef;
            }[];
            documents?: ContentRef[];
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * Load PartnerCategory
     */
    async loadPartnerCategoryBySlug(uniqueValue: string, { fields, xRichtextFormat }: {
        fields?: ("name" | "slug" | "intro" | "group" | "sections" | "programs" | "contacts" | "documents" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
    } = {}) {
        return await this._fetchJson(`/partnerCategory/slug/${uniqueValue}${QS.query(QS.deep({
            fields,
            join: { Contact: ["name", "phone", "email", "type", "image"], Program: ["name", "slug", "type.name", "abstract", "keyFacts"], ExternalProgram: ["name", "slug", "type", "abstract", "keyFacts", "ctaLabel", "ctaHref"], PartnerGroup: ["name", "slug"], Document: ["name", "file", "description", "type.name"], TextTeaser: ["overline", "headline", "subline", "text", "ctaLabel", "ctaRef", "layoutType"] }
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            name: string;
            slug: string;
            intro: string;
            group: ContentRef & PartnerGroup;
            sections?: {
                navLink?: string;
                items?: (({
                    overline?: string;
                    headline: string;
                    subline?: string;
                    text: string;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                    layoutType?: boolean;
                } & {
                    _type: "TextTeaser";
                }) | ({
                    teaser: ContentRef & TextTeaser;
                } & {
                    _type: "LinkTextTeaser";
                }) | ({
                    headline: string;
                    overline?: string;
                    text: string;
                    image: MediaRef & Media;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                    swapLayout?: boolean;
                } & {
                    _type: "ImageTextTeaser";
                }) | ({
                    headline: string;
                    intro?: string;
                    cases?: {
                        label: string;
                        headline: string;
                        text: string;
                        ctaLabel?: string;
                        ctaRef?: ContentRef;
                    }[];
                } & {
                    _type: "ShowCase";
                }) | ({
                    headline: string;
                    overline?: string;
                    subline?: string;
                    text: string;
                } & {
                    _type: "CollapsibleText";
                }))[];
            }[];
            programs?: (ContentRef & (Program | ExternalProgram))[];
            contacts?: {
                role: string;
                contact: ContentRef & Contact;
            }[];
            documents?: (ContentRef & Document)[];
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * Load Contacts
     */
    async loadContacts({ fields, search }: {
        fields?: ("headline" | "intro" | "contactSections" | "seo")[];
        search?: {
            term?: string;
            scope?: "title" | "global";
        };
    } = {}) {
        return await this._fetchJson(`/contacts${QS.query(QS.deep({
            fields,
            join: { Contact: ["name", "phone", "email", "type", "image"] },
            search
        }))}`) as {
            headline?: string;
            intro?: string;
            contactSections?: {
                name?: string;
                contacts?: {
                    role: string;
                    contact: ContentRef & Contact;
                }[];
            }[];
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * List Contacts
     */
    async listContacts({ orderBy, order, offset, limit, name, fields, search }: {
        orderBy?: "name";
        order?: "asc" | "desc";
        offset?: number;
        limit?: number;
        name?: {
            eq?: string | string[];
            ne?: string | string[];
        };
        fields?: ("name" | "email" | "phone" | "image" | "type")[];
        search?: {
            term?: string;
            scope?: "title" | "global";
        };
    } = {}) {
        return await this._fetchJson(`/contact${QS.query(QS.form({
            orderBy,
            order,
            offset,
            limit
        }), QS.deep({
            "data.name": name,
            fields,
            search
        }))}`) as {
            total: number;
            items: ({
                name: string;
                email: string;
                phone: string;
                image?: MediaRef & Media;
                type: string;
            } & {
                _id: string;
            })[];
        };
    }
    /**
     * Load Contact
     */
    async loadContact(id: string, { fields }: {
        fields?: ("name" | "email" | "phone" | "image" | "type")[];
    } = {}) {
        return await this._fetchJson(`/contact/${id}${QS.query(QS.deep({
            fields
        }))}`) as {
            name: string;
            email: string;
            phone: string;
            image?: MediaRef & Media;
            type: string;
        } & {
            _id?: string;
        };
    }
    /**
     * Load Contact
     */
    async loadContactByName(uniqueValue: string, { fields }: {
        fields?: ("name" | "email" | "phone" | "image" | "type")[];
    } = {}) {
        return await this._fetchJson(`/contact/name/${uniqueValue}${QS.query(QS.deep({
            fields
        }))}`) as {
            name: string;
            email: string;
            phone: string;
            image?: MediaRef & Media;
            type: string;
        } & {
            _id?: string;
        };
    }
    /**
     * Load Impressum
     */
    async loadImpressum({ fields, xRichtextFormat, search }: {
        fields?: ("title" | "contents" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
        search?: {
            term?: string;
            scope?: "title" | "global";
        };
    } = {}) {
        return await this._fetchJson(`/impressum${QS.query(QS.deep({
            fields,
            search
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            title: string;
            contents?: {
                items: (({
                    text?: string;
                } & {
                    _type: "Text";
                }))[];
            };
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * Load DataPrivacy
     */
    async loadDataPrivacy({ fields, xRichtextFormat, search }: {
        fields?: ("title" | "contents" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
        search?: {
            term?: string;
            scope?: "title" | "global";
        };
    } = {}) {
        return await this._fetchJson(`/dataPrivacy${QS.query(QS.deep({
            fields,
            join: { Document: ["name", "file", "description", "type.name"] },
            search
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            title: string;
            contents?: {
                items: (({
                    text?: string;
                } & {
                    _type: "Text";
                }) | ({
                    items?: {
                        label?: string;
                        content?: (({
                            text?: string;
                        } & {
                            _type: "Text";
                        }) | ({
                            headline?: string;
                            documents?: (ContentRef & Document)[];
                        } & {
                            _type: "Downloads";
                        }) | ({
                            image: MediaRef & Media;
                        } & {
                            _type: "Image";
                        }) | ({
                            table: string;
                            seperator: string;
                            striped: string;
                            caption?: string;
                        } & {
                            _type: "Table";
                        }))[];
                    }[];
                } & {
                    _type: "Accordion";
                }) | ({
                    enabled?: {
                        description: string;
                        checkboxLabel: string;
                    };
                    disabled?: {
                        description: string;
                        checkboxLabel: string;
                    };
                } & {
                    _type: "DataPrivacy";
                }))[];
            };
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * Load Accessibility
     */
    async loadAccessibility({ fields, xRichtextFormat, search }: {
        fields?: ("title" | "contents" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
        search?: {
            term?: string;
            scope?: "title" | "global";
        };
    } = {}) {
        return await this._fetchJson(`/accessibility${QS.query(QS.deep({
            fields,
            join: { Contact: ["name", "email", "phone", "image", "type"], Document: ["name", "file", "description", "type.name"], MagazinPortraits: ["date", "title", "headerImage", "slug", "tags", "categories", "categories.name", "programs.slug", "programs.name", "programs.type.name"], Program: ["name", "slug", "type.name", "abstract", "keyFacts"], ExternalProgram: ["type", "name", "abstract", "keyFacts", "ctaLabel", "ctaHref"], JobOffer: ["name", "slug", "intro", "employmentType", "employmentSchema", "posit", "category.slug", "category.name"], TextTeaser: ["overline", "headline", "subline", "text", "ctaLabel", "ctaRef", "layoutType"] },
            search
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            title: string;
            contents?: {
                items: (({
                    text?: string;
                } & {
                    _type: "Text";
                }) | ({
                    headline: string;
                    copy?: string;
                    contacts: {
                        role: string;
                        contact: ContentRef & Contact;
                    }[];
                    withImage?: boolean;
                } & {
                    _type: "Contacts";
                }) | ({
                    image: MediaRef & Media;
                } & {
                    _type: "Image";
                }) | ({
                    table: string;
                    seperator: string;
                    striped: string;
                    caption?: string;
                } & {
                    _type: "Table";
                }) | ({
                    headline?: string;
                    documents?: (ContentRef & Document)[];
                } & {
                    _type: "Downloads";
                }) | ({
                    headline: string;
                    intro?: string;
                    cases?: {
                        label: string;
                        headline: string;
                        text: string;
                        ctaLabel?: string;
                        ctaRef?: ContentRef;
                    }[];
                } & {
                    _type: "ShowCase";
                }) | ({
                    overline?: string;
                    headline: string;
                    subline?: string;
                    text: string;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                    layoutType?: boolean;
                } & {
                    _type: "TextTeaser";
                }) | ({
                    items?: (ContentRef & MagazinPortraits)[];
                } & {
                    _type: "FundingStories";
                }) | ({
                    items?: {
                        label?: string;
                        content?: (({
                            text?: string;
                        } & {
                            _type: "Text";
                        }) | ({
                            headline?: string;
                            documents?: (ContentRef & Document)[];
                        } & {
                            _type: "Downloads";
                        }) | ({
                            image: MediaRef & Media;
                        } & {
                            _type: "Image";
                        }) | ({
                            table: string;
                            seperator: string;
                            striped: string;
                            caption?: string;
                        } & {
                            _type: "Table";
                        }))[];
                    }[];
                } & {
                    _type: "Accordion";
                }) | ({
                    overline: string;
                    headline: string;
                    intro: string;
                    ctaLabel: string;
                    ctaRef: ContentRef;
                    dias: {
                        headline: string;
                        copy?: string;
                        icon?: MediaRef & Media;
                        ariaHidden?: boolean;
                    }[];
                } & {
                    _type: "DiaShow";
                }) | ({
                    title: string;
                    voices: {
                        quote: string;
                        subline: string;
                        program: ContentRef & (Program | ExternalProgram);
                    }[];
                } & {
                    _type: "CustomerVoices";
                }) | ({
                    title: string;
                    jobs: (ContentRef & JobOffer)[];
                    small?: boolean;
                } & {
                    _type: "Jobs";
                }) | ({
                    program: ContentRef & Program;
                } & {
                    _type: "ProgramTeaser";
                }) | ({
                    headline: string;
                    overline?: string;
                    text: string;
                    image: MediaRef & Media;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                    swapLayout?: boolean;
                } & {
                    _type: "ImageTextTeaser";
                }) | ({
                    teaser: ContentRef & TextTeaser;
                } & {
                    _type: "LinkTextTeaser";
                }))[];
            };
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * List GenericPages
     */
    async listGenericPages({ orderBy, order, offset, limit, name, slug, fields, xRichtextFormat, search }: {
        orderBy?: "name" | "slug";
        order?: "asc" | "desc";
        offset?: number;
        limit?: number;
        name?: {
            eq?: string | string[];
            ne?: string | string[];
        };
        slug?: {
            eq?: string | string[];
            ne?: string | string[];
        };
        fields?: ("name" | "slug" | "description" | "content" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
        search?: {
            term?: string;
            scope?: "title" | "global";
        };
    } = {}) {
        return await this._fetchJson(`/genericPage${QS.query(QS.form({
            orderBy,
            order,
            offset,
            limit
        }), QS.deep({
            "data.name": name,
            "data.slug": slug,
            fields,
            search
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            total: number;
            items: ({
                name: string;
                slug: string;
                description?: string;
                content: (({
                    items?: {
                        navLink?: string;
                        items?: (({
                            text?: string;
                        } & {
                            _type: "Text";
                        }) | ({
                            headline: string;
                            copy?: string;
                            contacts: {
                                role: string;
                                contact: ContentRef;
                            }[];
                            withImage?: boolean;
                        } & {
                            _type: "Contacts";
                        }) | ({
                            image: MediaRef & Media;
                        } & {
                            _type: "Image";
                        }) | ({
                            table: string;
                            seperator: string;
                            striped: string;
                            caption?: string;
                        } & {
                            _type: "Table";
                        }) | ({
                            headline?: string;
                            documents?: ContentRef[];
                        } & {
                            _type: "Downloads";
                        }) | ({
                            headline: string;
                            intro?: string;
                            cases?: {
                                label: string;
                                headline: string;
                                text: string;
                                ctaLabel?: string;
                                ctaRef?: ContentRef;
                            }[];
                        } & {
                            _type: "ShowCase";
                        }) | ({
                            overline?: string;
                            headline: string;
                            subline?: string;
                            text: string;
                            ctaLabel?: string;
                            ctaRef?: ContentRef;
                            layoutType?: boolean;
                        } & {
                            _type: "TextTeaser";
                        }) | ({
                            items?: ContentRef[];
                        } & {
                            _type: "FundingStories";
                        }) | ({
                            items?: {
                                label?: string;
                                content?: (({
                                    text?: string;
                                } & {
                                    _type: "Text";
                                }) | ({
                                    headline?: string;
                                    documents?: ContentRef[];
                                } & {
                                    _type: "Downloads";
                                }) | ({
                                    image: MediaRef & Media;
                                } & {
                                    _type: "Image";
                                }) | ({
                                    table: string;
                                    seperator: string;
                                    striped: string;
                                    caption?: string;
                                } & {
                                    _type: "Table";
                                }))[];
                            }[];
                        } & {
                            _type: "Accordion";
                        }) | ({
                            overline: string;
                            headline: string;
                            intro: string;
                            ctaLabel: string;
                            ctaRef: ContentRef;
                            dias: {
                                headline: string;
                                copy?: string;
                                icon?: MediaRef & Media;
                                ariaHidden?: boolean;
                            }[];
                        } & {
                            _type: "DiaShow";
                        }) | ({
                            title: string;
                            voices: {
                                quote: string;
                                subline: string;
                                program: ContentRef;
                            }[];
                        } & {
                            _type: "CustomerVoices";
                        }) | ({
                            title: string;
                            jobs: ContentRef[];
                            small?: boolean;
                        } & {
                            _type: "Jobs";
                        }) | ({
                            program: ContentRef;
                        } & {
                            _type: "ProgramTeaser";
                        }) | ({
                            headline: string;
                            overline?: string;
                            text: string;
                            image: MediaRef & Media;
                            ctaLabel?: string;
                            ctaRef?: ContentRef;
                            swapLayout?: boolean;
                        } & {
                            _type: "ImageTextTeaser";
                        }) | ({
                            teaser: ContentRef;
                        } & {
                            _type: "LinkTextTeaser";
                        }))[];
                    }[];
                } & {
                    _type: "sections";
                }) | ({
                    items?: (({
                        text?: string;
                    } & {
                        _type: "Text";
                    }) | ({
                        headline: string;
                        copy?: string;
                        contacts: {
                            role: string;
                            contact: ContentRef;
                        }[];
                        withImage?: boolean;
                    } & {
                        _type: "Contacts";
                    }) | ({
                        image: MediaRef & Media;
                    } & {
                        _type: "Image";
                    }) | ({
                        table: string;
                        seperator: string;
                        striped: string;
                        caption?: string;
                    } & {
                        _type: "Table";
                    }) | ({
                        headline?: string;
                        documents?: ContentRef[];
                    } & {
                        _type: "Downloads";
                    }) | ({
                        headline: string;
                        intro?: string;
                        cases?: {
                            label: string;
                            headline: string;
                            text: string;
                            ctaLabel?: string;
                            ctaRef?: ContentRef;
                        }[];
                    } & {
                        _type: "ShowCase";
                    }) | ({
                        overline?: string;
                        headline: string;
                        subline?: string;
                        text: string;
                        ctaLabel?: string;
                        ctaRef?: ContentRef;
                        layoutType?: boolean;
                    } & {
                        _type: "TextTeaser";
                    }) | ({
                        items?: ContentRef[];
                    } & {
                        _type: "FundingStories";
                    }) | ({
                        items?: {
                            label?: string;
                            content?: (({
                                text?: string;
                            } & {
                                _type: "Text";
                            }) | ({
                                headline?: string;
                                documents?: ContentRef[];
                            } & {
                                _type: "Downloads";
                            }) | ({
                                image: MediaRef & Media;
                            } & {
                                _type: "Image";
                            }) | ({
                                table: string;
                                seperator: string;
                                striped: string;
                                caption?: string;
                            } & {
                                _type: "Table";
                            }))[];
                        }[];
                    } & {
                        _type: "Accordion";
                    }) | ({
                        overline: string;
                        headline: string;
                        intro: string;
                        ctaLabel: string;
                        ctaRef: ContentRef;
                        dias: {
                            headline: string;
                            copy?: string;
                            icon?: MediaRef & Media;
                            ariaHidden?: boolean;
                        }[];
                    } & {
                        _type: "DiaShow";
                    }) | ({
                        title: string;
                        voices: {
                            quote: string;
                            subline: string;
                            program: ContentRef;
                        }[];
                    } & {
                        _type: "CustomerVoices";
                    }) | ({
                        title: string;
                        jobs: ContentRef[];
                        small?: boolean;
                    } & {
                        _type: "Jobs";
                    }) | ({
                        program: ContentRef;
                    } & {
                        _type: "ProgramTeaser";
                    }) | ({
                        headline: string;
                        overline?: string;
                        text: string;
                        image: MediaRef & Media;
                        ctaLabel?: string;
                        ctaRef?: ContentRef;
                        swapLayout?: boolean;
                    } & {
                        _type: "ImageTextTeaser";
                    }) | ({
                        teaser: ContentRef;
                    } & {
                        _type: "LinkTextTeaser";
                    }))[];
                } & {
                    _type: "contents";
                }))[];
                seo?: {
                    metaTitle?: string;
                    metaDescription?: string;
                    robots?: string;
                };
            } & {
                _id: string;
            })[];
        };
    }
    /**
     * Load GenericPage
     */
    async loadGenericPage(id: string, { fields, xRichtextFormat }: {
        fields?: ("name" | "slug" | "description" | "content" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
    } = {}) {
        return await this._fetchJson(`/genericPage/${id}${QS.query(QS.deep({
            fields
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            name: string;
            slug: string;
            description?: string;
            content: (({
                items?: {
                    navLink?: string;
                    items?: (({
                        text?: string;
                    } & {
                        _type: "Text";
                    }) | ({
                        headline: string;
                        copy?: string;
                        contacts: {
                            role: string;
                            contact: ContentRef;
                        }[];
                        withImage?: boolean;
                    } & {
                        _type: "Contacts";
                    }) | ({
                        image: MediaRef & Media;
                    } & {
                        _type: "Image";
                    }) | ({
                        table: string;
                        seperator: string;
                        striped: string;
                        caption?: string;
                    } & {
                        _type: "Table";
                    }) | ({
                        headline?: string;
                        documents?: ContentRef[];
                    } & {
                        _type: "Downloads";
                    }) | ({
                        headline: string;
                        intro?: string;
                        cases?: {
                            label: string;
                            headline: string;
                            text: string;
                            ctaLabel?: string;
                            ctaRef?: ContentRef;
                        }[];
                    } & {
                        _type: "ShowCase";
                    }) | ({
                        overline?: string;
                        headline: string;
                        subline?: string;
                        text: string;
                        ctaLabel?: string;
                        ctaRef?: ContentRef;
                        layoutType?: boolean;
                    } & {
                        _type: "TextTeaser";
                    }) | ({
                        items?: ContentRef[];
                    } & {
                        _type: "FundingStories";
                    }) | ({
                        items?: {
                            label?: string;
                            content?: (({
                                text?: string;
                            } & {
                                _type: "Text";
                            }) | ({
                                headline?: string;
                                documents?: ContentRef[];
                            } & {
                                _type: "Downloads";
                            }) | ({
                                image: MediaRef & Media;
                            } & {
                                _type: "Image";
                            }) | ({
                                table: string;
                                seperator: string;
                                striped: string;
                                caption?: string;
                            } & {
                                _type: "Table";
                            }))[];
                        }[];
                    } & {
                        _type: "Accordion";
                    }) | ({
                        overline: string;
                        headline: string;
                        intro: string;
                        ctaLabel: string;
                        ctaRef: ContentRef;
                        dias: {
                            headline: string;
                            copy?: string;
                            icon?: MediaRef & Media;
                            ariaHidden?: boolean;
                        }[];
                    } & {
                        _type: "DiaShow";
                    }) | ({
                        title: string;
                        voices: {
                            quote: string;
                            subline: string;
                            program: ContentRef;
                        }[];
                    } & {
                        _type: "CustomerVoices";
                    }) | ({
                        title: string;
                        jobs: ContentRef[];
                        small?: boolean;
                    } & {
                        _type: "Jobs";
                    }) | ({
                        program: ContentRef;
                    } & {
                        _type: "ProgramTeaser";
                    }) | ({
                        headline: string;
                        overline?: string;
                        text: string;
                        image: MediaRef & Media;
                        ctaLabel?: string;
                        ctaRef?: ContentRef;
                        swapLayout?: boolean;
                    } & {
                        _type: "ImageTextTeaser";
                    }) | ({
                        teaser: ContentRef;
                    } & {
                        _type: "LinkTextTeaser";
                    }))[];
                }[];
            } & {
                _type: "sections";
            }) | ({
                items?: (({
                    text?: string;
                } & {
                    _type: "Text";
                }) | ({
                    headline: string;
                    copy?: string;
                    contacts: {
                        role: string;
                        contact: ContentRef;
                    }[];
                    withImage?: boolean;
                } & {
                    _type: "Contacts";
                }) | ({
                    image: MediaRef & Media;
                } & {
                    _type: "Image";
                }) | ({
                    table: string;
                    seperator: string;
                    striped: string;
                    caption?: string;
                } & {
                    _type: "Table";
                }) | ({
                    headline?: string;
                    documents?: ContentRef[];
                } & {
                    _type: "Downloads";
                }) | ({
                    headline: string;
                    intro?: string;
                    cases?: {
                        label: string;
                        headline: string;
                        text: string;
                        ctaLabel?: string;
                        ctaRef?: ContentRef;
                    }[];
                } & {
                    _type: "ShowCase";
                }) | ({
                    overline?: string;
                    headline: string;
                    subline?: string;
                    text: string;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                    layoutType?: boolean;
                } & {
                    _type: "TextTeaser";
                }) | ({
                    items?: ContentRef[];
                } & {
                    _type: "FundingStories";
                }) | ({
                    items?: {
                        label?: string;
                        content?: (({
                            text?: string;
                        } & {
                            _type: "Text";
                        }) | ({
                            headline?: string;
                            documents?: ContentRef[];
                        } & {
                            _type: "Downloads";
                        }) | ({
                            image: MediaRef & Media;
                        } & {
                            _type: "Image";
                        }) | ({
                            table: string;
                            seperator: string;
                            striped: string;
                            caption?: string;
                        } & {
                            _type: "Table";
                        }))[];
                    }[];
                } & {
                    _type: "Accordion";
                }) | ({
                    overline: string;
                    headline: string;
                    intro: string;
                    ctaLabel: string;
                    ctaRef: ContentRef;
                    dias: {
                        headline: string;
                        copy?: string;
                        icon?: MediaRef & Media;
                        ariaHidden?: boolean;
                    }[];
                } & {
                    _type: "DiaShow";
                }) | ({
                    title: string;
                    voices: {
                        quote: string;
                        subline: string;
                        program: ContentRef;
                    }[];
                } & {
                    _type: "CustomerVoices";
                }) | ({
                    title: string;
                    jobs: ContentRef[];
                    small?: boolean;
                } & {
                    _type: "Jobs";
                }) | ({
                    program: ContentRef;
                } & {
                    _type: "ProgramTeaser";
                }) | ({
                    headline: string;
                    overline?: string;
                    text: string;
                    image: MediaRef & Media;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                    swapLayout?: boolean;
                } & {
                    _type: "ImageTextTeaser";
                }) | ({
                    teaser: ContentRef;
                } & {
                    _type: "LinkTextTeaser";
                }))[];
            } & {
                _type: "contents";
            }))[];
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * Load GenericPage
     */
    async loadGenericPageBySlug(uniqueValue: string, { fields, xRichtextFormat }: {
        fields?: ("name" | "slug" | "description" | "content" | "seo")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
    } = {}) {
        return await this._fetchJson(`/genericPage/slug/${uniqueValue}${QS.query(QS.deep({
            fields,
            join: { Contact: ["name", "email", "phone", "image", "type"], Document: ["name", "file", "description", "type.name"], MagazinPortraits: ["date", "title", "headerImage", "slug", "tags", "categories", "categories.name", "programs.slug", "programs.name", "programs.type.name"], Program: ["name", "slug", "type.name", "abstract", "keyFacts"], ExternalProgram: ["type", "name", "abstract", "keyFacts", "ctaLabel", "ctaHref"], JobOffer: ["name", "slug", "intro", "employmentType", "employmentSchema", "posit", "category.slug", "category.name"], TextTeaser: ["overline", "headline", "subline", "text", "ctaLabel", "ctaRef", "layoutType"] }
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            name: string;
            slug: string;
            description?: string;
            content: (({
                items?: {
                    navLink?: string;
                    items?: (({
                        text?: string;
                    } & {
                        _type: "Text";
                    }) | ({
                        headline: string;
                        copy?: string;
                        contacts: {
                            role: string;
                            contact: ContentRef & Contact;
                        }[];
                        withImage?: boolean;
                    } & {
                        _type: "Contacts";
                    }) | ({
                        image: MediaRef & Media;
                    } & {
                        _type: "Image";
                    }) | ({
                        table: string;
                        seperator: string;
                        striped: string;
                        caption?: string;
                    } & {
                        _type: "Table";
                    }) | ({
                        headline?: string;
                        documents?: (ContentRef & Document)[];
                    } & {
                        _type: "Downloads";
                    }) | ({
                        headline: string;
                        intro?: string;
                        cases?: {
                            label: string;
                            headline: string;
                            text: string;
                            ctaLabel?: string;
                            ctaRef?: ContentRef;
                        }[];
                    } & {
                        _type: "ShowCase";
                    }) | ({
                        overline?: string;
                        headline: string;
                        subline?: string;
                        text: string;
                        ctaLabel?: string;
                        ctaRef?: ContentRef;
                        layoutType?: boolean;
                    } & {
                        _type: "TextTeaser";
                    }) | ({
                        items?: (ContentRef & MagazinPortraits)[];
                    } & {
                        _type: "FundingStories";
                    }) | ({
                        items?: {
                            label?: string;
                            content?: (({
                                text?: string;
                            } & {
                                _type: "Text";
                            }) | ({
                                headline?: string;
                                documents?: (ContentRef & Document)[];
                            } & {
                                _type: "Downloads";
                            }) | ({
                                image: MediaRef & Media;
                            } & {
                                _type: "Image";
                            }) | ({
                                table: string;
                                seperator: string;
                                striped: string;
                                caption?: string;
                            } & {
                                _type: "Table";
                            }))[];
                        }[];
                    } & {
                        _type: "Accordion";
                    }) | ({
                        overline: string;
                        headline: string;
                        intro: string;
                        ctaLabel: string;
                        ctaRef: ContentRef;
                        dias: {
                            headline: string;
                            copy?: string;
                            icon?: MediaRef & Media;
                            ariaHidden?: boolean;
                        }[];
                    } & {
                        _type: "DiaShow";
                    }) | ({
                        title: string;
                        voices: {
                            quote: string;
                            subline: string;
                            program: ContentRef & (Program | ExternalProgram);
                        }[];
                    } & {
                        _type: "CustomerVoices";
                    }) | ({
                        title: string;
                        jobs: (ContentRef & JobOffer)[];
                        small?: boolean;
                    } & {
                        _type: "Jobs";
                    }) | ({
                        program: ContentRef & Program;
                    } & {
                        _type: "ProgramTeaser";
                    }) | ({
                        headline: string;
                        overline?: string;
                        text: string;
                        image: MediaRef & Media;
                        ctaLabel?: string;
                        ctaRef?: ContentRef;
                        swapLayout?: boolean;
                    } & {
                        _type: "ImageTextTeaser";
                    }) | ({
                        teaser: ContentRef & TextTeaser;
                    } & {
                        _type: "LinkTextTeaser";
                    }))[];
                }[];
            } & {
                _type: "sections";
            }) | ({
                items?: (({
                    text?: string;
                } & {
                    _type: "Text";
                }) | ({
                    headline: string;
                    copy?: string;
                    contacts: {
                        role: string;
                        contact: ContentRef & Contact;
                    }[];
                    withImage?: boolean;
                } & {
                    _type: "Contacts";
                }) | ({
                    image: MediaRef & Media;
                } & {
                    _type: "Image";
                }) | ({
                    table: string;
                    seperator: string;
                    striped: string;
                    caption?: string;
                } & {
                    _type: "Table";
                }) | ({
                    headline?: string;
                    documents?: (ContentRef & Document)[];
                } & {
                    _type: "Downloads";
                }) | ({
                    headline: string;
                    intro?: string;
                    cases?: {
                        label: string;
                        headline: string;
                        text: string;
                        ctaLabel?: string;
                        ctaRef?: ContentRef;
                    }[];
                } & {
                    _type: "ShowCase";
                }) | ({
                    overline?: string;
                    headline: string;
                    subline?: string;
                    text: string;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                    layoutType?: boolean;
                } & {
                    _type: "TextTeaser";
                }) | ({
                    items?: (ContentRef & MagazinPortraits)[];
                } & {
                    _type: "FundingStories";
                }) | ({
                    items?: {
                        label?: string;
                        content?: (({
                            text?: string;
                        } & {
                            _type: "Text";
                        }) | ({
                            headline?: string;
                            documents?: (ContentRef & Document)[];
                        } & {
                            _type: "Downloads";
                        }) | ({
                            image: MediaRef & Media;
                        } & {
                            _type: "Image";
                        }) | ({
                            table: string;
                            seperator: string;
                            striped: string;
                            caption?: string;
                        } & {
                            _type: "Table";
                        }))[];
                    }[];
                } & {
                    _type: "Accordion";
                }) | ({
                    overline: string;
                    headline: string;
                    intro: string;
                    ctaLabel: string;
                    ctaRef: ContentRef;
                    dias: {
                        headline: string;
                        copy?: string;
                        icon?: MediaRef & Media;
                        ariaHidden?: boolean;
                    }[];
                } & {
                    _type: "DiaShow";
                }) | ({
                    title: string;
                    voices: {
                        quote: string;
                        subline: string;
                        program: ContentRef & (Program | ExternalProgram);
                    }[];
                } & {
                    _type: "CustomerVoices";
                }) | ({
                    title: string;
                    jobs: (ContentRef & JobOffer)[];
                    small?: boolean;
                } & {
                    _type: "Jobs";
                }) | ({
                    program: ContentRef & Program;
                } & {
                    _type: "ProgramTeaser";
                }) | ({
                    headline: string;
                    overline?: string;
                    text: string;
                    image: MediaRef & Media;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                    swapLayout?: boolean;
                } & {
                    _type: "ImageTextTeaser";
                }) | ({
                    teaser: ContentRef & TextTeaser;
                } & {
                    _type: "LinkTextTeaser";
                }))[];
            } & {
                _type: "contents";
            }))[];
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * Load Global
     */
    async loadGlobal({ fields, search }: {
        fields?: ("name" | "footer" | "breakingNews")[];
        search?: {
            term?: string;
            scope?: "title" | "global";
        };
    } = {}) {
        return await this._fetchJson(`/global${QS.query(QS.deep({
            fields,
            search
        }))}`) as {
            name: string;
            footer?: {
                contactTeaser?: {
                    overline: string;
                    headline: string;
                    ctaLabel: string;
                };
                route?: {
                    headline: string;
                    address: string;
                    text: string;
                    ctaLabel: string;
                };
                eu?: {
                    headline: string;
                    text: string;
                };
            };
            breakingNews?: {
                show?: boolean;
                title?: string;
                subTitle?: string;
                links?: {
                    title?: string;
                    ref?: ContentRef;
                }[];
            };
        } & {
            _id?: string;
        };
    }
    /**
     * List ProgramTypes
     */
    async listProgramTypes({ orderBy, order, offset, limit, name, fields, search }: {
        orderBy?: "name";
        order?: "asc" | "desc";
        offset?: number;
        limit?: number;
        name?: {
            eq?: string | string[];
            ne?: string | string[];
        };
        fields?: ("name")[];
        search?: {
            term?: string;
            scope?: "title" | "global";
        };
    } = {}) {
        return await this._fetchJson(`/programType${QS.query(QS.form({
            orderBy,
            order,
            offset,
            limit
        }), QS.deep({
            "data.name": name,
            fields,
            search
        }))}`) as {
            total: number;
            items: ({
                name: string;
            } & {
                _id: string;
            })[];
        };
    }
    /**
     * Load ProgramType
     */
    async loadProgramType(id: string, { fields }: {
        fields?: ("name")[];
    } = {}) {
        return await this._fetchJson(`/programType/${id}${QS.query(QS.deep({
            fields
        }))}`) as {
            name: string;
        } & {
            _id?: string;
        };
    }
    /**
     * Load ProgramType
     */
    async loadProgramTypeByName(uniqueValue: string, { fields }: {
        fields?: ("name")[];
    } = {}) {
        return await this._fetchJson(`/programType/name/${uniqueValue}${QS.query(QS.deep({
            fields
        }))}`) as {
            name: string;
        } & {
            _id?: string;
        };
    }
    /**
     * List TextTeasers
     */
    async listTextTeasers({ orderBy, order, offset, limit, headline, ctaRef, fields, xRichtextFormat, search }: {
        orderBy?: "headline";
        order?: "asc" | "desc";
        offset?: number;
        limit?: number;
        headline?: {
            eq?: string | string[];
            ne?: string | string[];
        };
        ctaRef?: {
            eq?: number | string | (number | string)[];
            ne?: number | string | (number | string)[];
            gt?: number | string;
            gte?: number | string;
            lt?: number | string;
            lte?: number | string;
            path?: string;
        };
        fields?: ("overline" | "headline" | "subline" | "text" | "ctaLabel" | "ctaRef" | "layoutType")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
        search?: {
            term?: string;
            scope?: "title" | "global";
        };
    } = {}) {
        return await this._fetchJson(`/textTeaser${QS.query(QS.form({
            orderBy,
            order,
            offset,
            limit
        }), QS.deep({
            "data.headline": headline,
            "data.ctaRef": ctaRef,
            fields,
            search
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            total: number;
            items: ({
                overline?: string;
                headline: string;
                subline?: string;
                text: string;
                ctaLabel?: string;
                ctaRef?: ContentRef;
                layoutType?: boolean;
            } & {
                _id: string;
            })[];
        };
    }
    /**
     * Load TextTeaser
     */
    async loadTextTeaser(id: string, { fields, xRichtextFormat }: {
        fields?: ("overline" | "headline" | "subline" | "text" | "ctaLabel" | "ctaRef" | "layoutType")[];
        xRichtextFormat?: "json" | "html" | "markdown" | "plaintext";
    } = {}) {
        return await this._fetchJson(`/textTeaser/${id}${QS.query(QS.deep({
            fields
        }))}`, {
            headers: {
                "X-Richtext-Format": xRichtextFormat
            }
        }) as {
            overline?: string;
            headline: string;
            subline?: string;
            text: string;
            ctaLabel?: string;
            ctaRef?: ContentRef;
            layoutType?: boolean;
        } & {
            _id?: string;
        };
    }
    /**
     * List DocumentTypes
     */
    async listDocumentTypes({ orderBy, order, offset, limit, name, type, fields, search }: {
        orderBy?: "name" | "type";
        order?: "asc" | "desc";
        offset?: number;
        limit?: number;
        name?: {
            eq?: string | string[];
            ne?: string | string[];
        };
        type?: {
            eq?: string | string[];
            ne?: string | string[];
        };
        fields?: ("name" | "type")[];
        search?: {
            term?: string;
            scope?: "title" | "global";
        };
    } = {}) {
        return await this._fetchJson(`/documentType${QS.query(QS.form({
            orderBy,
            order,
            offset,
            limit
        }), QS.deep({
            "data.name": name,
            "data.type": type,
            fields,
            search
        }))}`) as {
            total: number;
            items: ({
                name: string;
                type: string;
            } & {
                _id: string;
            })[];
        };
    }
    /**
     * Load DocumentType
     */
    async loadDocumentType(id: string, { fields }: {
        fields?: ("name" | "type")[];
    } = {}) {
        return await this._fetchJson(`/documentType/${id}${QS.query(QS.deep({
            fields
        }))}`) as {
            name: string;
            type: string;
        } & {
            _id?: string;
        };
    }
    /**
     * Load DocumentType
     */
    async loadDocumentTypeByName(uniqueValue: string, { fields }: {
        fields?: ("name" | "type")[];
    } = {}) {
        return await this._fetchJson(`/documentType/name/${uniqueValue}${QS.query(QS.deep({
            fields
        }))}`) as {
            name: string;
            type: string;
        } & {
            _id?: string;
        };
    }
    /**
     * List Tags
     */
    async listTags({ orderBy, order, offset, limit, name, fields, search }: {
        orderBy?: "name";
        order?: "asc" | "desc";
        offset?: number;
        limit?: number;
        name?: {
            eq?: string | string[];
            ne?: string | string[];
        };
        fields?: ("name")[];
        search?: {
            term?: string;
            scope?: "title" | "global";
        };
    } = {}) {
        return await this._fetchJson(`/tag${QS.query(QS.form({
            orderBy,
            order,
            offset,
            limit
        }), QS.deep({
            "data.name": name,
            fields,
            search
        }))}`) as {
            total: number;
            items: ({
                name: string;
            } & {
                _id: string;
            })[];
        };
    }
    /**
     * Load Tag
     */
    async loadTag(id: string, { fields }: {
        fields?: ("name")[];
    } = {}) {
        return await this._fetchJson(`/tag/${id}${QS.query(QS.deep({
            fields
        }))}`) as {
            name: string;
        } & {
            _id?: string;
        };
    }
    /**
     * Load Tag
     */
    async loadTagByName(uniqueValue: string, { fields }: {
        fields?: ("name")[];
    } = {}) {
        return await this._fetchJson(`/tag/name/${uniqueValue}${QS.query(QS.deep({
            fields
        }))}`) as {
            name: string;
        } & {
            _id?: string;
        };
    }
    /**
     * List Redirects
     */
    async listRedirects({ orderBy, order, offset, limit, from, to, fields, search }: {
        orderBy?: "from";
        order?: "asc" | "desc";
        offset?: number;
        limit?: number;
        from?: {
            eq?: string | string[];
            ne?: string | string[];
        };
        to?: {
            eq?: number | string | (number | string)[];
            ne?: number | string | (number | string)[];
            gt?: number | string;
            gte?: number | string;
            lt?: number | string;
            lte?: number | string;
            path?: string;
        };
        fields?: ("from" | "to" | "temporary")[];
        search?: {
            term?: string;
            scope?: "title" | "global";
        };
    } = {}) {
        return await this._fetchJson(`/redirect${QS.query(QS.form({
            orderBy,
            order,
            offset,
            limit
        }), QS.deep({
            "data.from": from,
            "data.to": to,
            fields,
            search
        }))}`) as {
            total: number;
            items: ({
                from: string;
                to: ContentRef;
                temporary?: boolean;
            } & {
                _id: string;
            })[];
        };
    }
    /**
     * Load Redirect
     */
    async loadRedirect(id: string, { fields }: {
        fields?: ("from" | "to" | "temporary")[];
    } = {}) {
        return await this._fetchJson(`/redirect/${id}${QS.query(QS.deep({
            fields
        }))}`) as {
            from: string;
            to: ContentRef;
            temporary?: boolean;
        } & {
            _id?: string;
        };
    }
    /**
     * Load Redirect
     */
    async loadRedirectByFrom(uniqueValue: string, { fields }: {
        fields?: ("from" | "to" | "temporary")[];
    } = {}) {
        return await this._fetchJson(`/redirect/from/${uniqueValue}${QS.query(QS.deep({
            fields
        }))}`) as {
            from: string;
            to: ContentRef;
            temporary?: boolean;
        } & {
            _id?: string;
        };
    }
    /**
     * List SystemFiles
     */
    async listSystemFiles({ orderBy, order, offset, limit, path, fields, search }: {
        orderBy?: "path";
        order?: "asc" | "desc";
        offset?: number;
        limit?: number;
        path?: {
            eq?: string | string[];
            ne?: string | string[];
        };
        fields?: ("path" | "content")[];
        search?: {
            term?: string;
            scope?: "title" | "global";
        };
    } = {}) {
        return await this._fetchJson(`/systemFile${QS.query(QS.form({
            orderBy,
            order,
            offset,
            limit
        }), QS.deep({
            "data.path": path,
            fields,
            search
        }))}`) as {
            total: number;
            items: ({
                path: string;
                content?: string;
            } & {
                _id: string;
            })[];
        };
    }
    /**
     * Load SystemFile
     */
    async loadSystemFile(id: string, { fields }: {
        fields?: ("path" | "content")[];
    } = {}) {
        return await this._fetchJson(`/systemFile/${id}${QS.query(QS.deep({
            fields
        }))}`) as {
            path: string;
            content?: string;
        } & {
            _id?: string;
        };
    }
    /**
     * Load SystemFile
     */
    async loadSystemFileByPath(uniqueValue: string, { fields }: {
        fields?: ("path" | "content")[];
    } = {}) {
        return await this._fetchJson(`/systemFile/path/${uniqueValue}${QS.query(QS.deep({
            fields
        }))}`) as {
            path: string;
            content?: string;
        } & {
            _id?: string;
        };
    }
    /**
     * Load ServiceIncome
     */
    async loadServiceIncome({ fields, search }: {
        fields?: ("title" | "description" | "seo")[];
        search?: {
            term?: string;
            scope?: "title" | "global";
        };
    } = {}) {
        return await this._fetchJson(`/serviceIncome${QS.query(QS.deep({
            fields,
            search
        }))}`) as {
            title?: string;
            description?: string;
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * Load RepaymentCalculator
     */
    async loadRepaymentCalculator({ fields, search }: {
        fields?: ("title" | "description" | "programs" | "seo")[];
        search?: {
            term?: string;
            scope?: "title" | "global";
        };
    } = {}) {
        return await this._fetchJson(`/repaymentCalculator${QS.query(QS.deep({
            fields,
            search
        }))}`) as {
            title: string;
            description?: string;
            programs?: {
                name: string;
                rates: {
                    years: number;
                    rate: number;
                    isDefault?: boolean;
                }[];
                loan?: {
                    from: number;
                    to: number;
                    defaultValue?: number;
                };
                time?: {
                    from: number;
                    to: number;
                    defaultValue?: number;
                };
                graceTime?: {
                    from: number;
                    to: number;
                    defaultValue?: number;
                };
            }[];
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * Load ServiceQsForum
     */
    async loadServiceQsForum({ fields, search }: {
        fields?: ("title" | "description" | "seo")[];
        search?: {
            term?: string;
            scope?: "title" | "global";
        };
    } = {}) {
        return await this._fetchJson(`/serviceQSForum${QS.query(QS.deep({
            fields,
            search
        }))}`) as {
            title?: string;
            description?: string;
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
    /**
     * Load Downloads
     */
    async loadDownloads({ fields, search }: {
        fields?: ("title" | "description" | "seo")[];
        search?: {
            term?: string;
            scope?: "title" | "global";
        };
    } = {}) {
        return await this._fetchJson(`/Downloads${QS.query(QS.deep({
            fields,
            search
        }))}`) as {
            title?: string;
            description?: string;
            seo?: {
                metaTitle?: string;
                metaDescription?: string;
                robots?: string;
            };
        } & {
            _id?: string;
        };
    }
}
/**
 * Deeply remove all properties with undefined values.
 */
function stripUndefined<T>(obj: T) {
    return obj && JSON.parse(JSON.stringify(obj));
}
/**
 * Creates a tag-function to encode template strings with the given encoders.
 */
function encode(encoders: Encoders, delimiter = ",") {
    const q = (v: any, i: number) => {
        const encoder = encoders[i % encoders.length];
        if (typeof v === "object") {
            if (Array.isArray(v)) {
                return v.map(encoder).join(delimiter);
            }
            const flat = Object.entries(v).reduce((flat, entry) => [...flat, ...entry], [] as any);
            return flat.map(encoder).join(delimiter);
        }
        return encoder(String(v));
    };
    return (strings: TemplateStringsArray, ...values: any[]) => {
        return strings.reduce((prev, s, i) => {
            return `${prev}${s}${q(values[i] || "", i)}`;
        }, "");
    };
}
/**
 * Separate array values by the given delimiter.
 */
function delimited(delimiter = ",") {
    return (params: Record<string, any>, encoders = encodeReserved) => Object.entries(params)
        .filter(([, value]) => value !== undefined)
        .map(([name, value]) => encode(encoders, delimiter) `${name}=${value}`)
        .join("&");
}
/**
 * Functions to serialize query parameters in different styles.
 */
export const QS = {
    encode,
    encodeReserved,
    allowReserved,
    /**
     * Join params using an ampersand and prepends a questionmark if not empty.
     */
    query(...params: string[]) {
        const s = params.join("&");
        return s && `?${s}`;
    },
    /**
     * Serializes nested objects according to the `deepObject` style specified in
     * https://github.com/OAI/OpenAPI-Specification/blob/master/versions/3.0.0.md#style-values
     */
    deep(params: Record<string, any>, [k, v] = encodeReserved): string {
        const qk = encode([s => s, k]);
        const qv = encode([s => s, v]);
        // don't add index to arrays
        // https://github.com/expressjs/body-parser/issues/289
        const visit = (obj: any, prefix = ""): string => Object.entries(obj)
            .filter(([, v]) => v !== undefined)
            .map(([prop, v]) => {
            const index = Array.isArray(obj) ? "" : prop;
            const key = prefix
                ? qk `${prefix}[${index}]` : prop;
            if (typeof v === "object") {
                return visit(v, key);
            }
            return qv `${key}=${v}`;
        })
            .join("&");
        return visit(params);
    },
    /**
     * Property values of type array or object generate separate parameters
     * for each value of the array, or key-value-pair of the map.
     * For other types of properties this property has no effect.
     * See https://github.com/OAI/OpenAPI-Specification/blob/master/versions/3.0.0.md#encoding-object
     */
    explode(params: Record<string, any>, encoders = encodeReserved): string {
        const q = encode(encoders);
        return Object.entries(params)
            .filter(([, value]) => value !== undefined)
            .map(([name, value]) => {
            if (Array.isArray(value)) {
                return value.map(v => q `${name}=${v}`).join("&");
            }
            if (typeof value === "object") {
                return QS.explode(value, encoders);
            }
            return q `${name}=${value}`;
        })
            .join("&");
    },
    form: delimited(),
    pipe: delimited("|"),
    space: delimited("%20")
};
export class HttpError extends Error {
    status: number;
    constructor(status: number, message: string) {
        super(message);
        this.status = status;
    }
}
type PromisedApiResult<M> = M extends (...args: any) => Promise<infer T> ? T : never;
export type ApiResult<N extends keyof Api> = PromisedApiResult<Api[N]>;
export type Media = {
    size?: number;
    originalname?: string;
    mimetype?: string;
    imagetype?: string;
    width?: number;
    height?: number;
    created_at?: string;
    title?: string;
    alt?: string;
    credit?: string;
    focusX?: number;
    focusY?: number;
};
export type Index = {
    HeroSlider?: {
        sliderItems: {
            title: string;
            description: string;
            callToActionLink: ContentRef;
            image: MediaRef & Media;
        }[];
    };
    contents: (({
        headline: string;
        subline: string;
        image?: MediaRef & Media;
    } & {
        _type: "Finder";
    }) | ({
        headline: string;
        copy?: string;
        contacts: {
            role: string;
            contact: ContentRef;
        }[];
        withImage?: boolean;
    } & {
        _type: "Contacts";
    }) | ({
        image: MediaRef & Media;
    } & {
        _type: "Image";
    }) | ({
        headline: string;
        intro?: string;
        cases?: {
            label: string;
            headline: string;
            text: string;
            ctaLabel?: string;
            ctaRef?: ContentRef;
        }[];
    } & {
        _type: "ShowCase";
    }) | ({
        overline?: string;
        headline: string;
        subline?: string;
        text: string;
        ctaLabel?: string;
        ctaRef?: ContentRef;
        layoutType?: boolean;
    } & {
        _type: "TextTeaser";
    }) | ({
        teaser: ContentRef;
    } & {
        _type: "LinkTextTeaser";
    }) | ({
        items?: {
            label?: string;
            content?: (({
                text?: string;
            } & {
                _type: "Text";
            }) | ({
                headline?: string;
                documents?: ContentRef[];
            } & {
                _type: "Downloads";
            }) | ({
                image: MediaRef & Media;
            } & {
                _type: "Image";
            }) | ({
                table: string;
                seperator: string;
                striped: string;
                caption?: string;
            } & {
                _type: "Table";
            }))[];
        }[];
    } & {
        _type: "Accordion";
    }) | ({
        overline: string;
        headline: string;
        intro: string;
        ctaLabel: string;
        ctaRef: ContentRef;
        dias: {
            headline: string;
            copy?: string;
            icon?: MediaRef & Media;
            ariaHidden?: boolean;
        }[];
    } & {
        _type: "DiaShow";
    }) | ({
        title: string;
        voices: {
            quote: string;
            subline: string;
            program: ContentRef;
        }[];
    } & {
        _type: "CustomerVoices";
    }) | ({
        title: string;
        jobs: ContentRef[];
        small?: boolean;
    } & {
        _type: "Jobs";
    }) | ({
        events?: {
            headline: string;
            intro?: string;
        };
    } & {
        _type: "Magazin";
    }) | ({
        program: ContentRef;
    } & {
        _type: "ProgramTeaser";
    }) | ({
        overline: string;
        headline: string;
        text: string;
        ctaLabel: string;
    } & {
        _type: "Newsletter";
    }))[];
    seo?: {
        metaTitle?: string;
        metaDescription?: string;
        robots?: string;
    };
};
export type Contact = {
    name: string;
    email: string;
    phone: string;
    image?: MediaRef & Media;
    type: string;
};
export type TextTeaser = {
    overline?: string;
    headline: string;
    subline?: string;
    text: string;
    ctaLabel?: string;
    ctaRef?: ContentRef;
    layoutType?: boolean;
};
export type Document = {
    name: string;
    type: ContentRef;
    file: MediaRef & Media;
    text?: string;
    description?: string;
};
export type Program = {
    name: string;
    slug: string;
    type: ContentRef;
    abstract: string;
    keyFacts: string[];
    promotionSteps?: ContentRef;
    sections?: {
        items?: {
            navLink?: string;
            items?: (({
                text?: string;
            } & {
                _type: "Text";
            }) | ({
                table: string;
                seperator: string;
                striped: string;
                caption?: string;
            } & {
                _type: "Table";
            }) | ({
                image: MediaRef & Media;
            } & {
                _type: "Image";
            }) | ({
                headline: string;
                overline?: string;
                text: string;
                image: MediaRef & Media;
                ctaLabel?: string;
                ctaRef?: ContentRef;
                swapLayout?: boolean;
            } & {
                _type: "ImageTextTeaser";
            }))[];
        }[];
    };
    teasers?: (({
        title: string;
        headline: string;
        copy: string;
        cta: string;
        link: ContentRef;
        image?: MediaRef & Media;
    } & {
        _type: "Teaser";
    }) | ({
        title: string;
        headline: string;
        copy: string;
        cta: string;
        link: ContentRef;
    } & {
        _type: "DownloadTeaser";
    }) | ({
        image: MediaRef & Media;
    } & {
        _type: "Brand";
    }))[];
    documents?: ContentRef[];
    contacts?: {
        role: string;
        contact: ContentRef;
    }[];
    categories?: ContentRef[];
    seo?: {
        metaTitle?: string;
        metaDescription?: string;
        robots?: string;
    };
};
export type ExternalProgram = {
    type: ContentRef;
    name: string;
    abstract: string;
    keyFacts?: string[];
    ctaLabel: string;
    ctaHref: string;
};
export type JobOffer = {
    name: string;
    slug: string;
    posit?: string;
    intro: string;
    category: ContentRef;
    employmentType?: string;
    employmentSchema?: string;
    sections?: {
        items?: {
            navLink?: string;
            items?: (({
                text?: string;
            } & {
                _type: "Text";
            }) | ({
                headline: string;
                copy?: string;
                contacts: {
                    role: string;
                    contact: ContentRef;
                }[];
                withImage?: boolean;
            } & {
                _type: "Contacts";
            }) | ({
                headline?: string;
                documents?: ContentRef[];
            } & {
                _type: "Downloads";
            }))[];
        }[];
    };
    seo?: {
        metaTitle?: string;
        metaDescription?: string;
        robots?: string;
    };
};
export type Nachhaltigkeit = {
    NachhaltigkeitSlider?: {
        sliderItems: {
            title: string;
            description: string;
            callToActionLink?: ContentRef;
            image: MediaRef & Media;
        }[];
    };
    contents: (({
        text: string;
    } & {
        _type: "BannerTeaser";
    }) | ({
        mainTitle: string;
        mainCard?: {
            headline: string;
        }[];
        cardRowOne?: {
            headline: string;
            image?: {
                image: MediaRef & Media;
            };
            buttonBeschriftung: string;
            link: ContentRef;
        }[];
        cardRowTwo?: {
            headline: string;
            image?: {
                image: MediaRef & Media;
            };
            buttonBeschriftung?: string;
            link: ContentRef;
        }[];
        furtherDocuments?: string;
        cardRowThree?: {
            headline: string;
            image?: {
                image: MediaRef & Media;
            };
            buttonBeschriftung?: string;
            link: ContentRef;
        }[];
        lowerTextArea?: {
            text?: string;
            ctaLabel?: string;
            ctaRef?: ContentRef;
        }[];
    } & {
        _type: "ReportsSection";
    }) | ({
        overline?: string;
        headline: string;
        subline?: string;
        text: string;
        ctaLabel?: string;
        ctaRef?: ContentRef;
    } & {
        _type: "EssentialTeaser";
    }) | ({
        intro?: {
            headline: string;
            introText: string;
            ctaLabel?: string;
            ctaRef?: ContentRef;
        }[];
        infoGraphicTitle: string;
        infoGraphicBanner?: {
            headline: string;
            introText: string;
        }[];
        sustainableDevelopmentBanner?: {
            headline: string;
            introText: string;
        }[];
        furtherInfo?: {
            headline: string;
            text: string;
            image: MediaRef & Media;
            links?: {
                ctaLabel?: string;
                ctaRef?: ContentRef;
            }[];
        };
    } & {
        _type: "DevelopmentGoals";
    }))[];
    seo?: {
        metaTitle?: string;
        metaDescription?: string;
        robots?: string;
    };
};
export type NachhaltigkeitArticle = {
    title: string;
    slug: string;
    description?: string;
    sections?: {
        items?: {
            navLink?: string;
            items?: (({
                text?: string;
            } & {
                _type: "Text";
            }) | ({
                table: string;
                seperator: string;
                striped: string;
                caption?: string;
            } & {
                _type: "Table";
            }) | ({
                headline?: string;
                documents?: ContentRef[];
            } & {
                _type: "Downloads";
            }))[];
        }[];
    };
    seo?: {
        metaTitle?: string;
        metaDescription?: string;
        robots?: string;
    };
};
export type notFound = any;
export type Segment = {
    posit?: string;
    name: string;
    menuDescription?: string;
    slug: string;
    intro?: string;
    contacts?: {
        role: string;
        contact: ContentRef;
    }[];
    contents?: (({
        overline?: string;
        headline: string;
        subline?: string;
        text: string;
        ctaLabel?: string;
        ctaRef?: ContentRef;
        layoutType?: boolean;
    } & {
        _type: "TextTeaser";
    }) | ({
        teaser: ContentRef;
    } & {
        _type: "LinkTextTeaser";
    }) | ({
        headline: string;
        overline?: string;
        text: string;
        image: MediaRef & Media;
        ctaLabel?: string;
        ctaRef?: ContentRef;
        swapLayout?: boolean;
    } & {
        _type: "ImageTextTeaser";
    }) | ({
        headline: string;
        intro?: string;
        cases?: {
            label: string;
            headline: string;
            text: string;
            ctaLabel?: string;
            ctaRef?: ContentRef;
        }[];
    } & {
        _type: "ShowCase";
    }) | ({
        headline: string;
        overline?: string;
        subline?: string;
        text: string;
    } & {
        _type: "CollapsibleText";
    }))[];
    categoryClusters?: ContentRef[];
    eventTags?: ContentRef[];
    seo?: {
        metaTitle?: string;
        metaDescription?: string;
        robots?: string;
    };
};
export type CategoryCluster = {
    posit?: string;
    name: string;
    slug: string;
    menuDescription?: string;
    description: string;
    header?: {
        image?: MediaRef & Media;
        headline?: string;
        intro?: string;
        icon?: MediaRef & Media;
    };
    infoBox?: {
        showBox?: boolean;
        title?: string;
        ctaLabel?: string;
        ctaRef?: ContentRef;
    };
    segment: ContentRef;
    contacts?: {
        role: string;
        contact: ContentRef;
    }[];
    contents?: (({
        overline?: string;
        headline: string;
        subline?: string;
        text: string;
        ctaLabel?: string;
        ctaRef?: ContentRef;
        layoutType?: boolean;
    } & {
        _type: "TextTeaser";
    }) | ({
        teaser: ContentRef;
    } & {
        _type: "LinkTextTeaser";
    }) | ({
        headline: string;
        overline?: string;
        text: string;
        image: MediaRef & Media;
        ctaLabel?: string;
        ctaRef?: ContentRef;
        swapLayout?: boolean;
    } & {
        _type: "ImageTextTeaser";
    }) | ({
        headline: string;
        intro?: string;
        cases?: {
            label: string;
            headline: string;
            text: string;
            ctaLabel?: string;
            ctaRef?: ContentRef;
        }[];
    } & {
        _type: "ShowCase";
    }) | ({
        headline: string;
        overline?: string;
        subline?: string;
        text: string;
    } & {
        _type: "CollapsibleText";
    }) | ({
        program: ContentRef;
    } & {
        _type: "ProgramTeaser";
    }))[];
    categories?: ContentRef[];
    seo?: {
        metaTitle?: string;
        metaDescription?: string;
        robots?: string;
    };
};
export type Tag = {
    name: string;
};
export type Category = {
    posit?: string;
    name: string;
    slug: string;
    categoryCluster: ContentRef;
    subheader?: {
        intro: string;
        claim?: string;
        icon?: MediaRef & Media;
    };
    programs?: ContentRef[];
    contacts?: {
        role: string;
        contact: ContentRef;
    }[];
    contents?: (({
        overline?: string;
        headline: string;
        subline?: string;
        text: string;
        ctaLabel?: string;
        ctaRef?: ContentRef;
        layoutType?: boolean;
    } & {
        _type: "TextTeaser";
    }) | ({
        teaser: ContentRef;
    } & {
        _type: "LinkTextTeaser";
    }) | ({
        headline: string;
        overline?: string;
        text: string;
        image: MediaRef & Media;
        ctaLabel?: string;
        ctaRef?: ContentRef;
        swapLayout?: boolean;
    } & {
        _type: "ImageTextTeaser";
    }) | ({
        headline: string;
        intro?: string;
        cases?: {
            label: string;
            headline: string;
            text: string;
            ctaLabel?: string;
            ctaRef?: ContentRef;
        }[];
    } & {
        _type: "ShowCase";
    }) | ({
        headline: string;
        overline?: string;
        subline?: string;
        text: string;
    } & {
        _type: "CollapsibleText";
    }))[];
    seo?: {
        metaTitle?: string;
        metaDescription?: string;
        robots?: string;
    };
};
export type ProgramType = {
    name: string;
};
export type PromotionSteps = {
    headline: string;
    steps: {
        image: MediaRef & Media;
        title: string;
        text: string;
    }[];
};
export type DocumentType = {
    name: string;
    type: string;
};
export type AboutUs = {
    name: string;
    header?: {
        image: MediaRef & Media;
        headline?: string;
        intro?: string;
    };
    seo?: {
        metaTitle?: string;
        metaDescription?: string;
        robots?: string;
    };
};
export type AboutUsArticle = {
    posit?: string;
    name: string;
    slug: string;
    overline: string;
    description?: string;
    content: (({
        items?: {
            navLink?: string;
            items?: (({
                text?: string;
            } & {
                _type: "Text";
            }) | ({
                headline: string;
                copy?: string;
                contacts: {
                    role: string;
                    contact: ContentRef;
                }[];
                withImage?: boolean;
            } & {
                _type: "Contacts";
            }) | ({
                image: MediaRef & Media;
            } & {
                _type: "Image";
            }) | ({
                table: string;
                seperator: string;
                striped: string;
                caption?: string;
            } & {
                _type: "Table";
            }) | ({
                headline?: string;
                documents?: ContentRef[];
            } & {
                _type: "Downloads";
            }) | ({
                headline: string;
                intro?: string;
                cases?: {
                    label: string;
                    headline: string;
                    text: string;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                }[];
            } & {
                _type: "ShowCase";
            }) | ({
                overline?: string;
                headline: string;
                subline?: string;
                text: string;
                ctaLabel?: string;
                ctaRef?: ContentRef;
                layoutType?: boolean;
            } & {
                _type: "TextTeaser";
            }) | ({
                items?: ContentRef[];
            } & {
                _type: "FundingStories";
            }) | ({
                items?: {
                    label?: string;
                    content?: (({
                        text?: string;
                    } & {
                        _type: "Text";
                    }) | ({
                        headline?: string;
                        documents?: ContentRef[];
                    } & {
                        _type: "Downloads";
                    }) | ({
                        image: MediaRef & Media;
                    } & {
                        _type: "Image";
                    }) | ({
                        table: string;
                        seperator: string;
                        striped: string;
                        caption?: string;
                    } & {
                        _type: "Table";
                    }))[];
                }[];
            } & {
                _type: "Accordion";
            }) | ({
                overline: string;
                headline: string;
                intro: string;
                ctaLabel: string;
                ctaRef: ContentRef;
                dias: {
                    headline: string;
                    copy?: string;
                    icon?: MediaRef & Media;
                    ariaHidden?: boolean;
                }[];
            } & {
                _type: "DiaShow";
            }) | ({
                title: string;
                voices: {
                    quote: string;
                    subline: string;
                    program: ContentRef;
                }[];
            } & {
                _type: "CustomerVoices";
            }) | ({
                title: string;
                jobs: ContentRef[];
                small?: boolean;
            } & {
                _type: "Jobs";
            }) | ({
                program: ContentRef;
            } & {
                _type: "ProgramTeaser";
            }) | ({
                headline: string;
                overline?: string;
                text: string;
                image: MediaRef & Media;
                ctaLabel?: string;
                ctaRef?: ContentRef;
                swapLayout?: boolean;
            } & {
                _type: "ImageTextTeaser";
            }) | ({
                teaser: ContentRef;
            } & {
                _type: "LinkTextTeaser";
            }))[];
        }[];
    } & {
        _type: "sections";
    }) | ({
        items?: (({
            text?: string;
        } & {
            _type: "Text";
        }) | ({
            headline: string;
            copy?: string;
            contacts: {
                role: string;
                contact: ContentRef;
            }[];
            withImage?: boolean;
        } & {
            _type: "Contacts";
        }) | ({
            image: MediaRef & Media;
        } & {
            _type: "Image";
        }) | ({
            table: string;
            seperator: string;
            striped: string;
            caption?: string;
        } & {
            _type: "Table";
        }) | ({
            headline?: string;
            documents?: ContentRef[];
        } & {
            _type: "Downloads";
        }) | ({
            headline: string;
            intro?: string;
            cases?: {
                label: string;
                headline: string;
                text: string;
                ctaLabel?: string;
                ctaRef?: ContentRef;
            }[];
        } & {
            _type: "ShowCase";
        }) | ({
            overline?: string;
            headline: string;
            subline?: string;
            text: string;
            ctaLabel?: string;
            ctaRef?: ContentRef;
            layoutType?: boolean;
        } & {
            _type: "TextTeaser";
        }) | ({
            items?: ContentRef[];
        } & {
            _type: "FundingStories";
        }) | ({
            items?: {
                label?: string;
                content?: (({
                    text?: string;
                } & {
                    _type: "Text";
                }) | ({
                    headline?: string;
                    documents?: ContentRef[];
                } & {
                    _type: "Downloads";
                }) | ({
                    image: MediaRef & Media;
                } & {
                    _type: "Image";
                }) | ({
                    table: string;
                    seperator: string;
                    striped: string;
                    caption?: string;
                } & {
                    _type: "Table";
                }))[];
            }[];
        } & {
            _type: "Accordion";
        }) | ({
            overline: string;
            headline: string;
            intro: string;
            ctaLabel: string;
            ctaRef: ContentRef;
            dias: {
                headline: string;
                copy?: string;
                icon?: MediaRef & Media;
                ariaHidden?: boolean;
            }[];
        } & {
            _type: "DiaShow";
        }) | ({
            title: string;
            voices: {
                quote: string;
                subline: string;
                program: ContentRef;
            }[];
        } & {
            _type: "CustomerVoices";
        }) | ({
            title: string;
            jobs: ContentRef[];
            small?: boolean;
        } & {
            _type: "Jobs";
        }) | ({
            program: ContentRef;
        } & {
            _type: "ProgramTeaser";
        }) | ({
            headline: string;
            overline?: string;
            text: string;
            image: MediaRef & Media;
            ctaLabel?: string;
            ctaRef?: ContentRef;
            swapLayout?: boolean;
        } & {
            _type: "ImageTextTeaser";
        }) | ({
            teaser: ContentRef;
        } & {
            _type: "LinkTextTeaser";
        }))[];
    } & {
        _type: "contents";
    }))[];
    seo?: {
        metaTitle?: string;
        metaDescription?: string;
        robots?: string;
    };
};
export type MagazinPortraits = {
    title: string;
    slug: string;
    date: string;
    headerImage?: MediaRef & Media;
    content?: (({
        text?: string;
    } & {
        _type: "text";
    }) | ({
        image?: MediaRef & Media;
        imageSubTitle?: string;
        text?: string;
    } & {
        _type: "image";
    }))[];
    categories?: ContentRef[];
    tags?: ContentRef[];
    programs?: ContentRef[];
    seo?: {
        metaTitle?: string;
        metaDescription?: string;
        robots?: string;
    };
};
export type Career = {
    name: string;
    header?: {
        image?: MediaRef & Media;
        headline?: string;
        intro?: string;
        icon?: MediaRef & Media;
    };
    topjobs?: {
        title?: string;
        subTitle?: string;
        jobs?: ContentRef[];
    };
    joboffers?: {
        toptitle?: string;
        title?: string;
        intro?: string;
        jobCategories?: ContentRef[];
    };
    sections?: (({
        overline?: string;
        headline: string;
        subline?: string;
        text: string;
        ctaLabel?: string;
        ctaRef?: ContentRef;
        layoutType?: boolean;
    } & {
        _type: "TextTeaser";
    }) | ({
        teaser: ContentRef;
    } & {
        _type: "LinkTextTeaser";
    }) | ({
        headline: string;
        overline?: string;
        text: string;
        image: MediaRef & Media;
        ctaLabel?: string;
        ctaRef?: ContentRef;
        swapLayout?: boolean;
    } & {
        _type: "ImageTextTeaser";
    }) | ({
        headline: string;
        intro?: string;
        cases?: {
            label: string;
            headline: string;
            text: string;
            ctaLabel?: string;
            ctaRef?: ContentRef;
        }[];
    } & {
        _type: "ShowCase";
    }) | ({
        headline: string;
        entries: {
            headline: string;
            text: string;
            image: MediaRef & Media;
            ctaLabel?: string;
            ctaRef?: ContentRef;
        }[];
    } & {
        _type: "Checkerboard";
    }) | ({
        headline: string;
        overline?: string;
        subline?: string;
        text: string;
    } & {
        _type: "CollapsibleText";
    }))[];
    contacts?: {
        role: string;
        contact: ContentRef;
    }[];
    seo?: {
        metaTitle?: string;
        metaDescription?: string;
        robots?: string;
    };
};
export type JobCategory = {
    name: string;
    slug: string;
    teaser: string;
    headline: string;
    subheader?: {
        intro: string;
        claim?: string;
        icon?: MediaRef & Media;
    };
    jobs?: ContentRef[];
    seo?: {
        metaTitle?: string;
        metaDescription?: string;
        robots?: string;
    };
};
export type Press = {
    name: string;
    header?: {
        image?: MediaRef & Media;
        headline?: string;
        intro?: string;
        icon?: MediaRef & Media;
    };
    pressImages?: {
        headline?: string;
        intro?: string;
        images?: ContentRef[];
    };
    contact?: {
        role: string;
        contact: ContentRef;
        adress: string;
    };
    seo?: {
        metaTitle?: string;
        metaDescription?: string;
        robots?: string;
    };
};
export type PressRelease = {
    date: string;
    title: string;
    slug: string;
    description: string;
    sections?: {
        items?: {
            navLink?: string;
            items?: (({
                text?: string;
            } & {
                _type: "Text";
            }) | ({
                table: string;
                seperator: string;
                striped: string;
                caption?: string;
            } & {
                _type: "Table";
            }) | ({
                image: MediaRef & Media;
            } & {
                _type: "Image";
            }))[];
        }[];
    };
    documents?: ContentRef[];
    seo?: {
        metaTitle?: string;
        metaDescription?: string;
        robots?: string;
    };
};
export type ApManagement = {
    name: string;
    contents?: (({
        text?: string;
    } & {
        _type: "Text";
    }) | ({
        headline: string;
        copy?: string;
        contacts: {
            role: string;
            contact: ContentRef;
        }[];
        withImage?: boolean;
    } & {
        _type: "Contacts";
    }) | ({
        image: MediaRef & Media;
    } & {
        _type: "Image";
    }) | ({
        table: string;
        seperator: string;
        striped: string;
        caption?: string;
    } & {
        _type: "Table";
    }) | ({
        headline?: string;
        documents?: ContentRef[];
    } & {
        _type: "Downloads";
    }) | ({
        headline: string;
        intro?: string;
        cases?: {
            label: string;
            headline: string;
            text: string;
            ctaLabel?: string;
            ctaRef?: ContentRef;
        }[];
    } & {
        _type: "ShowCase";
    }) | ({
        overline?: string;
        headline: string;
        subline?: string;
        text: string;
        ctaLabel?: string;
        ctaRef?: ContentRef;
        layoutType?: boolean;
    } & {
        _type: "TextTeaser";
    }) | ({
        items?: ContentRef[];
    } & {
        _type: "FundingStories";
    }) | ({
        items?: {
            label?: string;
            content?: (({
                text?: string;
            } & {
                _type: "Text";
            }) | ({
                headline?: string;
                documents?: ContentRef[];
            } & {
                _type: "Downloads";
            }) | ({
                image: MediaRef & Media;
            } & {
                _type: "Image";
            }) | ({
                table: string;
                seperator: string;
                striped: string;
                caption?: string;
            } & {
                _type: "Table";
            }))[];
        }[];
    } & {
        _type: "Accordion";
    }) | ({
        overline: string;
        headline: string;
        intro: string;
        ctaLabel: string;
        ctaRef: ContentRef;
        dias: {
            headline: string;
            copy?: string;
            icon?: MediaRef & Media;
            ariaHidden?: boolean;
        }[];
    } & {
        _type: "DiaShow";
    }) | ({
        title: string;
        voices: {
            quote: string;
            subline: string;
            program: ContentRef;
        }[];
    } & {
        _type: "CustomerVoices";
    }) | ({
        title: string;
        jobs: ContentRef[];
        small?: boolean;
    } & {
        _type: "Jobs";
    }) | ({
        program: ContentRef;
    } & {
        _type: "ProgramTeaser";
    }) | ({
        headline: string;
        overline?: string;
        text: string;
        image: MediaRef & Media;
        ctaLabel?: string;
        ctaRef?: ContentRef;
        swapLayout?: boolean;
    } & {
        _type: "ImageTextTeaser";
    }) | ({
        teaser: ContentRef;
    } & {
        _type: "LinkTextTeaser";
    }))[];
    seo?: {
        metaTitle?: string;
        metaDescription?: string;
        robots?: string;
    };
};
export type ApManagementArticle = {
    name: string;
    slug: string;
    description?: string;
    content: (({
        items?: {
            navLink?: string;
            items?: (({
                text?: string;
            } & {
                _type: "Text";
            }) | ({
                headline: string;
                copy?: string;
                contacts: {
                    role: string;
                    contact: ContentRef;
                }[];
                withImage?: boolean;
            } & {
                _type: "Contacts";
            }) | ({
                image: MediaRef & Media;
            } & {
                _type: "Image";
            }) | ({
                table: string;
                seperator: string;
                striped: string;
                caption?: string;
            } & {
                _type: "Table";
            }) | ({
                headline?: string;
                documents?: ContentRef[];
            } & {
                _type: "Downloads";
            }) | ({
                headline: string;
                intro?: string;
                cases?: {
                    label: string;
                    headline: string;
                    text: string;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                }[];
            } & {
                _type: "ShowCase";
            }) | ({
                overline?: string;
                headline: string;
                subline?: string;
                text: string;
                ctaLabel?: string;
                ctaRef?: ContentRef;
                layoutType?: boolean;
            } & {
                _type: "TextTeaser";
            }) | ({
                items?: ContentRef[];
            } & {
                _type: "FundingStories";
            }) | ({
                items?: {
                    label?: string;
                    content?: (({
                        text?: string;
                    } & {
                        _type: "Text";
                    }) | ({
                        headline?: string;
                        documents?: ContentRef[];
                    } & {
                        _type: "Downloads";
                    }) | ({
                        image: MediaRef & Media;
                    } & {
                        _type: "Image";
                    }) | ({
                        table: string;
                        seperator: string;
                        striped: string;
                        caption?: string;
                    } & {
                        _type: "Table";
                    }))[];
                }[];
            } & {
                _type: "Accordion";
            }) | ({
                overline: string;
                headline: string;
                intro: string;
                ctaLabel: string;
                ctaRef: ContentRef;
                dias: {
                    headline: string;
                    copy?: string;
                    icon?: MediaRef & Media;
                    ariaHidden?: boolean;
                }[];
            } & {
                _type: "DiaShow";
            }) | ({
                title: string;
                voices: {
                    quote: string;
                    subline: string;
                    program: ContentRef;
                }[];
            } & {
                _type: "CustomerVoices";
            }) | ({
                title: string;
                jobs: ContentRef[];
                small?: boolean;
            } & {
                _type: "Jobs";
            }) | ({
                program: ContentRef;
            } & {
                _type: "ProgramTeaser";
            }) | ({
                headline: string;
                overline?: string;
                text: string;
                image: MediaRef & Media;
                ctaLabel?: string;
                ctaRef?: ContentRef;
                swapLayout?: boolean;
            } & {
                _type: "ImageTextTeaser";
            }) | ({
                teaser: ContentRef;
            } & {
                _type: "LinkTextTeaser";
            }))[];
        }[];
    } & {
        _type: "sections";
    }) | ({
        items?: (({
            text?: string;
        } & {
            _type: "Text";
        }) | ({
            headline: string;
            copy?: string;
            contacts: {
                role: string;
                contact: ContentRef;
            }[];
            withImage?: boolean;
        } & {
            _type: "Contacts";
        }) | ({
            image: MediaRef & Media;
        } & {
            _type: "Image";
        }) | ({
            table: string;
            seperator: string;
            striped: string;
            caption?: string;
        } & {
            _type: "Table";
        }) | ({
            headline?: string;
            documents?: ContentRef[];
        } & {
            _type: "Downloads";
        }) | ({
            headline: string;
            intro?: string;
            cases?: {
                label: string;
                headline: string;
                text: string;
                ctaLabel?: string;
                ctaRef?: ContentRef;
            }[];
        } & {
            _type: "ShowCase";
        }) | ({
            overline?: string;
            headline: string;
            subline?: string;
            text: string;
            ctaLabel?: string;
            ctaRef?: ContentRef;
            layoutType?: boolean;
        } & {
            _type: "TextTeaser";
        }) | ({
            items?: ContentRef[];
        } & {
            _type: "FundingStories";
        }) | ({
            items?: {
                label?: string;
                content?: (({
                    text?: string;
                } & {
                    _type: "Text";
                }) | ({
                    headline?: string;
                    documents?: ContentRef[];
                } & {
                    _type: "Downloads";
                }) | ({
                    image: MediaRef & Media;
                } & {
                    _type: "Image";
                }) | ({
                    table: string;
                    seperator: string;
                    striped: string;
                    caption?: string;
                } & {
                    _type: "Table";
                }))[];
            }[];
        } & {
            _type: "Accordion";
        }) | ({
            overline: string;
            headline: string;
            intro: string;
            ctaLabel: string;
            ctaRef: ContentRef;
            dias: {
                headline: string;
                copy?: string;
                icon?: MediaRef & Media;
                ariaHidden?: boolean;
            }[];
        } & {
            _type: "DiaShow";
        }) | ({
            title: string;
            voices: {
                quote: string;
                subline: string;
                program: ContentRef;
            }[];
        } & {
            _type: "CustomerVoices";
        }) | ({
            title: string;
            jobs: ContentRef[];
            small?: boolean;
        } & {
            _type: "Jobs";
        }) | ({
            program: ContentRef;
        } & {
            _type: "ProgramTeaser";
        }) | ({
            headline: string;
            overline?: string;
            text: string;
            image: MediaRef & Media;
            ctaLabel?: string;
            ctaRef?: ContentRef;
            swapLayout?: boolean;
        } & {
            _type: "ImageTextTeaser";
        }) | ({
            teaser: ContentRef;
        } & {
            _type: "LinkTextTeaser";
        }))[];
    } & {
        _type: "contents";
    }))[];
    seo?: {
        metaTitle?: string;
        metaDescription?: string;
        robots?: string;
    };
};
export type IfbInfopage = {
    name: string;
    slug: string;
    contents?: (({
        text?: string;
    } & {
        _type: "Text";
    }) | ({
        headline: string;
        copy?: string;
        contacts: {
            role: string;
            contact: ContentRef;
        }[];
        withImage?: boolean;
    } & {
        _type: "Contacts";
    }) | ({
        image: MediaRef & Media;
    } & {
        _type: "Image";
    }) | ({
        table: string;
        seperator: string;
        striped: string;
        caption?: string;
    } & {
        _type: "Table";
    }) | ({
        headline?: string;
        documents?: ContentRef[];
    } & {
        _type: "Downloads";
    }) | ({
        headline: string;
        intro?: string;
        cases?: {
            label: string;
            headline: string;
            text: string;
            ctaLabel?: string;
            ctaRef?: ContentRef;
        }[];
    } & {
        _type: "ShowCase";
    }) | ({
        overline?: string;
        headline: string;
        subline?: string;
        text: string;
        ctaLabel?: string;
        ctaRef?: ContentRef;
        layoutType?: boolean;
    } & {
        _type: "TextTeaser";
    }) | ({
        items?: ContentRef[];
    } & {
        _type: "FundingStories";
    }) | ({
        items?: {
            label?: string;
            content?: (({
                text?: string;
            } & {
                _type: "Text";
            }) | ({
                headline?: string;
                documents?: ContentRef[];
            } & {
                _type: "Downloads";
            }) | ({
                image: MediaRef & Media;
            } & {
                _type: "Image";
            }) | ({
                table: string;
                seperator: string;
                striped: string;
                caption?: string;
            } & {
                _type: "Table";
            }))[];
        }[];
    } & {
        _type: "Accordion";
    }) | ({
        overline: string;
        headline: string;
        intro: string;
        ctaLabel: string;
        ctaRef: ContentRef;
        dias: {
            headline: string;
            copy?: string;
            icon?: MediaRef & Media;
            ariaHidden?: boolean;
        }[];
    } & {
        _type: "DiaShow";
    }) | ({
        title: string;
        voices: {
            quote: string;
            subline: string;
            program: ContentRef;
        }[];
    } & {
        _type: "CustomerVoices";
    }) | ({
        title: string;
        jobs: ContentRef[];
        small?: boolean;
    } & {
        _type: "Jobs";
    }) | ({
        program: ContentRef;
    } & {
        _type: "ProgramTeaser";
    }) | ({
        headline: string;
        overline?: string;
        text: string;
        image: MediaRef & Media;
        ctaLabel?: string;
        ctaRef?: ContentRef;
        swapLayout?: boolean;
    } & {
        _type: "ImageTextTeaser";
    }) | ({
        teaser: ContentRef;
    } & {
        _type: "LinkTextTeaser";
    }))[];
    position?: string;
    seo?: {
        metaTitle?: string;
        metaDescription?: string;
        robots?: string;
    };
};
export type EuFunding = {
    name: string;
    content: (({
        items?: {
            navLink?: string;
            items?: (({
                text?: string;
            } & {
                _type: "Text";
            }) | ({
                headline: string;
                copy?: string;
                contacts: {
                    role: string;
                    contact: ContentRef;
                }[];
                withImage?: boolean;
            } & {
                _type: "Contacts";
            }) | ({
                image: MediaRef & Media;
            } & {
                _type: "Image";
            }) | ({
                table: string;
                seperator: string;
                striped: string;
                caption?: string;
            } & {
                _type: "Table";
            }) | ({
                headline?: string;
                documents?: ContentRef[];
            } & {
                _type: "Downloads";
            }) | ({
                headline: string;
                intro?: string;
                cases?: {
                    label: string;
                    headline: string;
                    text: string;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                }[];
            } & {
                _type: "ShowCase";
            }) | ({
                overline?: string;
                headline: string;
                subline?: string;
                text: string;
                ctaLabel?: string;
                ctaRef?: ContentRef;
                layoutType?: boolean;
            } & {
                _type: "TextTeaser";
            }) | ({
                items?: ContentRef[];
            } & {
                _type: "FundingStories";
            }) | ({
                items?: {
                    label?: string;
                    content?: (({
                        text?: string;
                    } & {
                        _type: "Text";
                    }) | ({
                        headline?: string;
                        documents?: ContentRef[];
                    } & {
                        _type: "Downloads";
                    }) | ({
                        image: MediaRef & Media;
                    } & {
                        _type: "Image";
                    }) | ({
                        table: string;
                        seperator: string;
                        striped: string;
                        caption?: string;
                    } & {
                        _type: "Table";
                    }))[];
                }[];
            } & {
                _type: "Accordion";
            }) | ({
                overline: string;
                headline: string;
                intro: string;
                ctaLabel: string;
                ctaRef: ContentRef;
                dias: {
                    headline: string;
                    copy?: string;
                    icon?: MediaRef & Media;
                    ariaHidden?: boolean;
                }[];
            } & {
                _type: "DiaShow";
            }) | ({
                title: string;
                voices: {
                    quote: string;
                    subline: string;
                    program: ContentRef;
                }[];
            } & {
                _type: "CustomerVoices";
            }) | ({
                title: string;
                jobs: ContentRef[];
                small?: boolean;
            } & {
                _type: "Jobs";
            }) | ({
                program: ContentRef;
            } & {
                _type: "ProgramTeaser";
            }) | ({
                headline: string;
                overline?: string;
                text: string;
                image: MediaRef & Media;
                ctaLabel?: string;
                ctaRef?: ContentRef;
                swapLayout?: boolean;
            } & {
                _type: "ImageTextTeaser";
            }) | ({
                teaser: ContentRef;
            } & {
                _type: "LinkTextTeaser";
            }))[];
        }[];
    } & {
        _type: "sections";
    }) | ({
        items?: (({
            text?: string;
        } & {
            _type: "Text";
        }) | ({
            headline: string;
            copy?: string;
            contacts: {
                role: string;
                contact: ContentRef;
            }[];
            withImage?: boolean;
        } & {
            _type: "Contacts";
        }) | ({
            image: MediaRef & Media;
        } & {
            _type: "Image";
        }) | ({
            table: string;
            seperator: string;
            striped: string;
            caption?: string;
        } & {
            _type: "Table";
        }) | ({
            headline?: string;
            documents?: ContentRef[];
        } & {
            _type: "Downloads";
        }) | ({
            headline: string;
            intro?: string;
            cases?: {
                label: string;
                headline: string;
                text: string;
                ctaLabel?: string;
                ctaRef?: ContentRef;
            }[];
        } & {
            _type: "ShowCase";
        }) | ({
            overline?: string;
            headline: string;
            subline?: string;
            text: string;
            ctaLabel?: string;
            ctaRef?: ContentRef;
            layoutType?: boolean;
        } & {
            _type: "TextTeaser";
        }) | ({
            items?: ContentRef[];
        } & {
            _type: "FundingStories";
        }) | ({
            items?: {
                label?: string;
                content?: (({
                    text?: string;
                } & {
                    _type: "Text";
                }) | ({
                    headline?: string;
                    documents?: ContentRef[];
                } & {
                    _type: "Downloads";
                }) | ({
                    image: MediaRef & Media;
                } & {
                    _type: "Image";
                }) | ({
                    table: string;
                    seperator: string;
                    striped: string;
                    caption?: string;
                } & {
                    _type: "Table";
                }))[];
            }[];
        } & {
            _type: "Accordion";
        }) | ({
            overline: string;
            headline: string;
            intro: string;
            ctaLabel: string;
            ctaRef: ContentRef;
            dias: {
                headline: string;
                copy?: string;
                icon?: MediaRef & Media;
                ariaHidden?: boolean;
            }[];
        } & {
            _type: "DiaShow";
        }) | ({
            title: string;
            voices: {
                quote: string;
                subline: string;
                program: ContentRef;
            }[];
        } & {
            _type: "CustomerVoices";
        }) | ({
            title: string;
            jobs: ContentRef[];
            small?: boolean;
        } & {
            _type: "Jobs";
        }) | ({
            program: ContentRef;
        } & {
            _type: "ProgramTeaser";
        }) | ({
            headline: string;
            overline?: string;
            text: string;
            image: MediaRef & Media;
            ctaLabel?: string;
            ctaRef?: ContentRef;
            swapLayout?: boolean;
        } & {
            _type: "ImageTextTeaser";
        }) | ({
            teaser: ContentRef;
        } & {
            _type: "LinkTextTeaser";
        }))[];
    } & {
        _type: "contents";
    }))[];
    seo?: {
        metaTitle?: string;
        metaDescription?: string;
        robots?: string;
    };
};
export type MagazinPage = {
    seo?: {
        metaTitle?: string;
        metaDescription?: string;
        robots?: string;
    };
};
export type MagazinNewsPage = {
    name: string;
    intro: string;
    seo?: {
        metaTitle?: string;
        metaDescription?: string;
        robots?: string;
    };
};
export type MagazinNews = {
    title: string;
    slug: string;
    date: string;
    headerImage?: MediaRef & Media;
    description: string;
    content?: (({
        text?: string;
    } & {
        _type: "text";
    }) | ({
        image?: MediaRef & Media;
        imageSubTitle?: string;
        text?: string;
    } & {
        _type: "image";
    }))[];
    categories?: ContentRef[];
    tags?: ContentRef[];
    seo?: {
        metaTitle?: string;
        metaDescription?: string;
        robots?: string;
    };
};
export type MagazinPortraitPage = {
    name: string;
    intro: string;
    seo?: {
        metaTitle?: string;
        metaDescription?: string;
        robots?: string;
    };
};
export type MagazinEventPage = {
    name: string;
    intro: string;
    highlightEvent?: ContentRef;
    highlightImage?: MediaRef & Media;
    seo?: {
        metaTitle?: string;
        metaDescription?: string;
        robots?: string;
    };
};
export type ExternalEvent = {
    name: string;
    dates: {
        date: string;
        time: string;
    }[];
    description: string;
    location?: string;
    organizer?: string;
    ctaHref: string;
    tags?: ContentRef[];
    searchContent: string;
};
export type PartnerPage = {
    name: string;
    intro: string;
    seo?: {
        metaTitle?: string;
        metaDescription?: string;
        robots?: string;
    };
};
export type PartnerGroup = {
    name: string;
    slug: string;
    header?: {
        image?: MediaRef & Media;
        headline?: string;
        intro?: string;
    };
    contents?: (({
        overline?: string;
        headline: string;
        subline?: string;
        text: string;
        ctaLabel?: string;
        ctaRef?: ContentRef;
        layoutType?: boolean;
    } & {
        _type: "TextTeaser";
    }) | ({
        teaser: ContentRef;
    } & {
        _type: "LinkTextTeaser";
    }) | ({
        headline: string;
        overline?: string;
        text: string;
        image: MediaRef & Media;
        ctaLabel?: string;
        ctaRef?: ContentRef;
        swapLayout?: boolean;
    } & {
        _type: "ImageTextTeaser";
    }) | ({
        headline: string;
        intro?: string;
        cases?: {
            label: string;
            headline: string;
            text: string;
            ctaLabel?: string;
            ctaRef?: ContentRef;
        }[];
    } & {
        _type: "ShowCase";
    }) | ({
        headline: string;
        overline?: string;
        subline?: string;
        text: string;
    } & {
        _type: "CollapsibleText";
    }))[];
    contacts?: {
        role: string;
        contact: ContentRef;
    }[];
    partnerCategories?: ContentRef[];
    seo?: {
        metaTitle?: string;
        metaDescription?: string;
        robots?: string;
    };
};
export type PartnerCategory = {
    name: string;
    slug: string;
    intro: string;
    group: ContentRef;
    sections?: {
        navLink?: string;
        items?: (({
            overline?: string;
            headline: string;
            subline?: string;
            text: string;
            ctaLabel?: string;
            ctaRef?: ContentRef;
            layoutType?: boolean;
        } & {
            _type: "TextTeaser";
        }) | ({
            teaser: ContentRef;
        } & {
            _type: "LinkTextTeaser";
        }) | ({
            headline: string;
            overline?: string;
            text: string;
            image: MediaRef & Media;
            ctaLabel?: string;
            ctaRef?: ContentRef;
            swapLayout?: boolean;
        } & {
            _type: "ImageTextTeaser";
        }) | ({
            headline: string;
            intro?: string;
            cases?: {
                label: string;
                headline: string;
                text: string;
                ctaLabel?: string;
                ctaRef?: ContentRef;
            }[];
        } & {
            _type: "ShowCase";
        }) | ({
            headline: string;
            overline?: string;
            subline?: string;
            text: string;
        } & {
            _type: "CollapsibleText";
        }))[];
    }[];
    programs?: ContentRef[];
    contacts?: {
        role: string;
        contact: ContentRef;
    }[];
    documents?: ContentRef[];
    seo?: {
        metaTitle?: string;
        metaDescription?: string;
        robots?: string;
    };
};
export type Contacts = {
    headline?: string;
    intro?: string;
    contactSections?: {
        name?: string;
        contacts?: {
            role: string;
            contact: ContentRef;
        }[];
    }[];
    seo?: {
        metaTitle?: string;
        metaDescription?: string;
        robots?: string;
    };
};
export type Impressum = {
    title: string;
    contents?: {
        items: (({
            text?: string;
        } & {
            _type: "Text";
        }))[];
    };
    seo?: {
        metaTitle?: string;
        metaDescription?: string;
        robots?: string;
    };
};
export type DataPrivacy = {
    title: string;
    contents?: {
        items: (({
            text?: string;
        } & {
            _type: "Text";
        }) | ({
            items?: {
                label?: string;
                content?: (({
                    text?: string;
                } & {
                    _type: "Text";
                }) | ({
                    headline?: string;
                    documents?: ContentRef[];
                } & {
                    _type: "Downloads";
                }) | ({
                    image: MediaRef & Media;
                } & {
                    _type: "Image";
                }) | ({
                    table: string;
                    seperator: string;
                    striped: string;
                    caption?: string;
                } & {
                    _type: "Table";
                }))[];
            }[];
        } & {
            _type: "Accordion";
        }) | ({
            enabled?: {
                description: string;
                checkboxLabel: string;
            };
            disabled?: {
                description: string;
                checkboxLabel: string;
            };
        } & {
            _type: "DataPrivacy";
        }))[];
    };
    seo?: {
        metaTitle?: string;
        metaDescription?: string;
        robots?: string;
    };
};
export type Accessibility = {
    title: string;
    contents?: {
        items: (({
            text?: string;
        } & {
            _type: "Text";
        }) | ({
            headline: string;
            copy?: string;
            contacts: {
                role: string;
                contact: ContentRef;
            }[];
            withImage?: boolean;
        } & {
            _type: "Contacts";
        }) | ({
            image: MediaRef & Media;
        } & {
            _type: "Image";
        }) | ({
            table: string;
            seperator: string;
            striped: string;
            caption?: string;
        } & {
            _type: "Table";
        }) | ({
            headline?: string;
            documents?: ContentRef[];
        } & {
            _type: "Downloads";
        }) | ({
            headline: string;
            intro?: string;
            cases?: {
                label: string;
                headline: string;
                text: string;
                ctaLabel?: string;
                ctaRef?: ContentRef;
            }[];
        } & {
            _type: "ShowCase";
        }) | ({
            overline?: string;
            headline: string;
            subline?: string;
            text: string;
            ctaLabel?: string;
            ctaRef?: ContentRef;
            layoutType?: boolean;
        } & {
            _type: "TextTeaser";
        }) | ({
            items?: ContentRef[];
        } & {
            _type: "FundingStories";
        }) | ({
            items?: {
                label?: string;
                content?: (({
                    text?: string;
                } & {
                    _type: "Text";
                }) | ({
                    headline?: string;
                    documents?: ContentRef[];
                } & {
                    _type: "Downloads";
                }) | ({
                    image: MediaRef & Media;
                } & {
                    _type: "Image";
                }) | ({
                    table: string;
                    seperator: string;
                    striped: string;
                    caption?: string;
                } & {
                    _type: "Table";
                }))[];
            }[];
        } & {
            _type: "Accordion";
        }) | ({
            overline: string;
            headline: string;
            intro: string;
            ctaLabel: string;
            ctaRef: ContentRef;
            dias: {
                headline: string;
                copy?: string;
                icon?: MediaRef & Media;
                ariaHidden?: boolean;
            }[];
        } & {
            _type: "DiaShow";
        }) | ({
            title: string;
            voices: {
                quote: string;
                subline: string;
                program: ContentRef;
            }[];
        } & {
            _type: "CustomerVoices";
        }) | ({
            title: string;
            jobs: ContentRef[];
            small?: boolean;
        } & {
            _type: "Jobs";
        }) | ({
            program: ContentRef;
        } & {
            _type: "ProgramTeaser";
        }) | ({
            headline: string;
            overline?: string;
            text: string;
            image: MediaRef & Media;
            ctaLabel?: string;
            ctaRef?: ContentRef;
            swapLayout?: boolean;
        } & {
            _type: "ImageTextTeaser";
        }) | ({
            teaser: ContentRef;
        } & {
            _type: "LinkTextTeaser";
        }))[];
    };
    seo?: {
        metaTitle?: string;
        metaDescription?: string;
        robots?: string;
    };
};
export type GenericPage = {
    name: string;
    slug: string;
    description?: string;
    content: (({
        items?: {
            navLink?: string;
            items?: (({
                text?: string;
            } & {
                _type: "Text";
            }) | ({
                headline: string;
                copy?: string;
                contacts: {
                    role: string;
                    contact: ContentRef;
                }[];
                withImage?: boolean;
            } & {
                _type: "Contacts";
            }) | ({
                image: MediaRef & Media;
            } & {
                _type: "Image";
            }) | ({
                table: string;
                seperator: string;
                striped: string;
                caption?: string;
            } & {
                _type: "Table";
            }) | ({
                headline?: string;
                documents?: ContentRef[];
            } & {
                _type: "Downloads";
            }) | ({
                headline: string;
                intro?: string;
                cases?: {
                    label: string;
                    headline: string;
                    text: string;
                    ctaLabel?: string;
                    ctaRef?: ContentRef;
                }[];
            } & {
                _type: "ShowCase";
            }) | ({
                overline?: string;
                headline: string;
                subline?: string;
                text: string;
                ctaLabel?: string;
                ctaRef?: ContentRef;
                layoutType?: boolean;
            } & {
                _type: "TextTeaser";
            }) | ({
                items?: ContentRef[];
            } & {
                _type: "FundingStories";
            }) | ({
                items?: {
                    label?: string;
                    content?: (({
                        text?: string;
                    } & {
                        _type: "Text";
                    }) | ({
                        headline?: string;
                        documents?: ContentRef[];
                    } & {
                        _type: "Downloads";
                    }) | ({
                        image: MediaRef & Media;
                    } & {
                        _type: "Image";
                    }) | ({
                        table: string;
                        seperator: string;
                        striped: string;
                        caption?: string;
                    } & {
                        _type: "Table";
                    }))[];
                }[];
            } & {
                _type: "Accordion";
            }) | ({
                overline: string;
                headline: string;
                intro: string;
                ctaLabel: string;
                ctaRef: ContentRef;
                dias: {
                    headline: string;
                    copy?: string;
                    icon?: MediaRef & Media;
                    ariaHidden?: boolean;
                }[];
            } & {
                _type: "DiaShow";
            }) | ({
                title: string;
                voices: {
                    quote: string;
                    subline: string;
                    program: ContentRef;
                }[];
            } & {
                _type: "CustomerVoices";
            }) | ({
                title: string;
                jobs: ContentRef[];
                small?: boolean;
            } & {
                _type: "Jobs";
            }) | ({
                program: ContentRef;
            } & {
                _type: "ProgramTeaser";
            }) | ({
                headline: string;
                overline?: string;
                text: string;
                image: MediaRef & Media;
                ctaLabel?: string;
                ctaRef?: ContentRef;
                swapLayout?: boolean;
            } & {
                _type: "ImageTextTeaser";
            }) | ({
                teaser: ContentRef;
            } & {
                _type: "LinkTextTeaser";
            }))[];
        }[];
    } & {
        _type: "sections";
    }) | ({
        items?: (({
            text?: string;
        } & {
            _type: "Text";
        }) | ({
            headline: string;
            copy?: string;
            contacts: {
                role: string;
                contact: ContentRef;
            }[];
            withImage?: boolean;
        } & {
            _type: "Contacts";
        }) | ({
            image: MediaRef & Media;
        } & {
            _type: "Image";
        }) | ({
            table: string;
            seperator: string;
            striped: string;
            caption?: string;
        } & {
            _type: "Table";
        }) | ({
            headline?: string;
            documents?: ContentRef[];
        } & {
            _type: "Downloads";
        }) | ({
            headline: string;
            intro?: string;
            cases?: {
                label: string;
                headline: string;
                text: string;
                ctaLabel?: string;
                ctaRef?: ContentRef;
            }[];
        } & {
            _type: "ShowCase";
        }) | ({
            overline?: string;
            headline: string;
            subline?: string;
            text: string;
            ctaLabel?: string;
            ctaRef?: ContentRef;
            layoutType?: boolean;
        } & {
            _type: "TextTeaser";
        }) | ({
            items?: ContentRef[];
        } & {
            _type: "FundingStories";
        }) | ({
            items?: {
                label?: string;
                content?: (({
                    text?: string;
                } & {
                    _type: "Text";
                }) | ({
                    headline?: string;
                    documents?: ContentRef[];
                } & {
                    _type: "Downloads";
                }) | ({
                    image: MediaRef & Media;
                } & {
                    _type: "Image";
                }) | ({
                    table: string;
                    seperator: string;
                    striped: string;
                    caption?: string;
                } & {
                    _type: "Table";
                }))[];
            }[];
        } & {
            _type: "Accordion";
        }) | ({
            overline: string;
            headline: string;
            intro: string;
            ctaLabel: string;
            ctaRef: ContentRef;
            dias: {
                headline: string;
                copy?: string;
                icon?: MediaRef & Media;
                ariaHidden?: boolean;
            }[];
        } & {
            _type: "DiaShow";
        }) | ({
            title: string;
            voices: {
                quote: string;
                subline: string;
                program: ContentRef;
            }[];
        } & {
            _type: "CustomerVoices";
        }) | ({
            title: string;
            jobs: ContentRef[];
            small?: boolean;
        } & {
            _type: "Jobs";
        }) | ({
            program: ContentRef;
        } & {
            _type: "ProgramTeaser";
        }) | ({
            headline: string;
            overline?: string;
            text: string;
            image: MediaRef & Media;
            ctaLabel?: string;
            ctaRef?: ContentRef;
            swapLayout?: boolean;
        } & {
            _type: "ImageTextTeaser";
        }) | ({
            teaser: ContentRef;
        } & {
            _type: "LinkTextTeaser";
        }))[];
    } & {
        _type: "contents";
    }))[];
    seo?: {
        metaTitle?: string;
        metaDescription?: string;
        robots?: string;
    };
};
export type Global = {
    name: string;
    footer?: {
        contactTeaser?: {
            overline: string;
            headline: string;
            ctaLabel: string;
        };
        route?: {
            headline: string;
            address: string;
            text: string;
            ctaLabel: string;
        };
        eu?: {
            headline: string;
            text: string;
        };
    };
    breakingNews?: {
        show?: boolean;
        title?: string;
        subTitle?: string;
        links?: {
            title?: string;
            ref?: ContentRef;
        }[];
    };
};
export type Redirect = {
    from: string;
    to: ContentRef;
    temporary?: boolean;
};
export type SystemFile = {
    path: string;
    content?: string;
};
export type ServiceIncome = {
    title?: string;
    description?: string;
    seo?: {
        metaTitle?: string;
        metaDescription?: string;
        robots?: string;
    };
};
export type RepaymentCalculator = {
    title: string;
    description?: string;
    programs?: {
        name: string;
        rates: {
            years: number;
            rate: number;
            isDefault?: boolean;
        }[];
        loan?: {
            from: number;
            to: number;
            defaultValue?: number;
        };
        time?: {
            from: number;
            to: number;
            defaultValue?: number;
        };
        graceTime?: {
            from: number;
            to: number;
            defaultValue?: number;
        };
    }[];
    seo?: {
        metaTitle?: string;
        metaDescription?: string;
        robots?: string;
    };
};
export type ServiceQsForum = {
    title?: string;
    description?: string;
    seo?: {
        metaTitle?: string;
        metaDescription?: string;
        robots?: string;
    };
};
export type Downloads = {
    title?: string;
    description?: string;
    seo?: {
        metaTitle?: string;
        metaDescription?: string;
        robots?: string;
    };
};
export class DraftedContentsServer {
    url: string;
    constructor() {
        this.url = "/rest/drafts";
    }
}
export class PublishedContentsServer {
    url: string;
    constructor() {
        this.url = "/rest/published";
    }
}

export type ContentRef = {
    _ref: "content";
    _id: string;
    _url?: string;
};
export type MediaRef = {
    _ref: "media";
    _id: string;
    _src: string;
};
function mergeRefs(root: any) {
    if (typeof root !== "object" || !root._refs)
        return root;
    const { _refs, ...data } = root;
    const walk = (obj: any) => {
        if (!obj)
            return;
        if (typeof obj !== "object")
            return;
        if (Array.isArray(obj)) {
            obj.forEach(walk);
            return;
        }
        Object.keys(obj).forEach(key => {
            const value = obj[key];
            if (key === "_ref") {
                const id = obj._id;
                const refs = _refs[value];
                if (!refs || !id)
                    return;
                const type: string | undefined = obj[`_${value}`];
                const lookup = type ? refs[type] : refs;
                if (!lookup)
                    return;
                const ref = lookup[id];
                if (ref) {
                    walk(ref);
                    Object.assign(obj, ref);
                }
            }
            walk(value);
        });
    };
    walk(data);
    return data;
}