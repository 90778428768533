import React, { HTMLAttributes, MouseEvent, ReactNode } from "react";
import Link from "../utils/Link";
import styled, { css } from "styled-components";
import { border, colors, fontSizing, media, THEME_SCALE } from "../theme";
import { RouteName, RouteParams } from "../../lib/routes";

export const defaultButtonStyle = css`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font: inherit;
  color: white;
  text-decoration: none;
  background: ${colors.blue};
  border: 0;
  border-radius: ${border.radius};
  ${fontSizing("label", 24)}
  padding: 12px calc((4px * 9) * ${THEME_SCALE});
  box-shadow: 0 8px 16px 0 ${colors.blue20};
  transition: background-color 120ms ease-out;

  &:hover{
    background: ${colors.calypso};
  }
  &:active, &:focus{
    outline: none;
    /* background: ${colors.bluegreyDark}; */
  }
`;

export const StyledButton = styled.button<ButtonProps>`
  ${defaultButtonStyle}
  @media screen and ${media.tablet} {
    ${p =>
      !p.text &&
      css<ButtonProps>`
        padding: 12px calc(4px * 6);
      `}

  }
  ${p =>
    p.light &&
    css<ButtonProps>`
      border: 2px solid ${colors.bluegreyDark};
      color: ${colors.blue};
      background: none;
      box-shadow: none;
      &:hover {
        border-color: ${colors.pigeon};
        color: ${colors.pigeon};
        background: none;
      }
    `}
  ${p =>
    p.ghost &&
    css<ButtonProps>`
      transition: border-color 120ms ease-out, color 120ms ease-out;

      color: ${colors.blueDark};
      background: none;
      border: 2px solid ${colors.blueDark};
      box-shadow: none;

      &:hover {
        color: ${colors.calypso};
        border-color: ${colors.calypso};
        background: none;
      }
      &:focus {
        background: none;
        color: ${colors.calypso};
        border-color: ${colors.calypso};
      }

      ${p =>
        p.inverted &&
        css`
          color: white;
          border-color: ${colors.bluegrey};
        `}
    `}

  ${p =>
    p.small &&
    css`
      padding: 12px calc(4px * 5);
      ${fontSizing("label", 20)}
    `}
  ${p =>
    p.tiny &&
    css`
      && {
        padding: 12px calc(4px * 3);
        ${fontSizing("label", 20)}
      }
    `}
  ${p =>
    p.wide &&
    css`
      && {
        min-width: 148px;
      }
    `}
  ${p =>
    p.alignCenter &&
    css`
      align-self: center;
    `}
  ${p =>
    p.alignLeft &&
    css`
      align-self: flex-start;
    `}
  ${p =>
    p.bold &&
    css`
      font-weight: 500;
    `}
    /* TODO: load svg dynamically, refactor */
  ${p =>
    p.collapseStyle &&
    css<ButtonProps>`
      position: relative;
      padding: 12px calc(32px + 16px + 16px) !important;
      ::before {
        content: "";
        position: absolute;
        left: 32px;
        top: calc(50% - 10px);
        background-image: url("/static/chevron.svg");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        width: 16px;
        height: 20px;
        transition: all 0.5s;
        ${!p.collapsed &&
          css`
            transform: rotate(180deg);
          `}
      }
      @media screen and ${media.tablet} {
        padding: 12px calc(24px + 16px + 16px) !important;
        ::before {
          left: 24px;
        }
      }
    `}
  ${p =>
    p.text &&
    css`
      color: ${colors.blueDark};
      box-shadow: none;
      background: transparent;
      border: none;
      padding: 0;
      height: auto;
      border-radius: 0;
      margin: 0 16px;
      border-bottom: 2px solid ${colors.blueDark};

      &:active,
      &:focus,
      &:hover {
        color: ${colors.calypso};
        border-color: ${colors.calypso};
        background: none;
      }
    `}
  ${p =>
    p.whiteBorder &&
    css`
      border: 2px solid #fff;
    `}
   
  @media print {
    display: none;
  }
`;

export type ButtonProps = {
  className?: string;
  text?: boolean;
  inverted?: boolean;
  href?: string;
  route?: RouteName;
  params?: RouteParams;
  small?: boolean;
  tiny?: boolean;
  wide?: boolean;
  ghost?: boolean;
  light?: boolean;
  whiteBorder?: boolean;
  alignCenter?: boolean;
  alignLeft?: boolean;
  children: ReactNode | ReactNode[];
  onclick?: (e: MouseEvent) => void;
  type?: "button" | "reset" | "submit";
  bold?: boolean;
  collapseStyle?: boolean;
  collapsed?: boolean;
  "aria-label"?: string;
  tabIndex?: number;
  prefetch?: boolean;
} & HTMLAttributes<HTMLButtonElement & HTMLAnchorElement>;

const Button = ({
  className,
  onclick,
  inverted,
  text,
  small,
  wide,
  tiny,
  ghost,
  light,
  href,
  children,
  route,
  params,
  alignCenter,
  alignLeft,
  type = "button",
  bold,
  "aria-label": ariaLabel,
  collapseStyle,
  collapsed,
  tabIndex = 0,
  prefetch,
  whiteBorder,
  ...props
}: ButtonProps) => {
  if (href || route) {
    return (
      <Link href={href} route={route} params={params} prefetch={prefetch}>
        <StyledButton
          className={className}
          as="a"
          text={text}
          inverted={inverted}
          small={small}
          tiny={tiny}
          wide={wide}
          alignCenter={alignCenter}
          alignLeft={alignLeft}
          ghost={ghost}
          light={light}
          whiteBorder={whiteBorder}
          bold={bold}
          aria-label={ariaLabel}
          collapseStyle={collapseStyle}
          collapsed={collapsed}
          tabIndex={tabIndex}
          {...props}
        >
          {children}
        </StyledButton>
      </Link>
    );
  } else {
    return (
      <StyledButton
        as="button"
        onClick={onclick}
        small={small}
        tiny={tiny}
        wide={wide}
        ghost={ghost}
        inverted={inverted}
        text={text}
        href={href}
        alignCenter={alignCenter}
        type={type}
        aria-label={ariaLabel}
        bold={bold}
        className={className}
        collapseStyle={collapseStyle}
        collapsed={collapsed}
        tabIndex={tabIndex}
        {...props}
      >
        {children}
      </StyledButton>
    );
  }
};

export default Button;
