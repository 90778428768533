import React, { ReactNode } from "react";
import styled from "styled-components";
import { colors, font, THEME_SCALE, spacing } from "../theme";
import { Caret } from "./icons";
import Link from "../utils/Link";
import { RouteName, RouteParams } from "../../lib/routes";

const Container = styled.nav<{ white?: boolean; blueGrey?: boolean }>`
  font-weight: ${font.weightMedium};
  height: calc((4px * 20) * ${THEME_SCALE});
  ${spacing("margin-bottom", 64)}
  display: flex;
  align-items: center;
  color: ${p =>
    p.blueGrey ? colors.bluegrey : p.white ? colors.white : colors.darkgrey};
  font-size: ${18 * THEME_SCALE}px;
  ol {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
`;

interface Props {
  children: ReactNode | ReactNode[];
  white?: boolean;
  blueGrey?: boolean;
  className?: string;
}

const StyledCaret = styled(Caret)`
  transform: rotate(-90deg);
  margin: 3px calc(4px * 6) 0;
`;

const StyledBreadCrumbItem = styled.li`
  padding: calc(4px * 3) 0;
  display: flex;
  align-items: center;
  &:last-child svg {
    display: none;
  }
`;

const Breadcrumb = (p: Props) => (
  <Container
    className={p.className}
    aria-label="Seitenpfad"
    blueGrey={p.blueGrey}
    white={p.white}
  >
    <ol>{p.children}</ol>
  </Container>
);

interface ItemProps {
  children: ReactNode | ReactNode[];
  link?: { href: string } | { route: RouteName; params?: RouteParams };
  ariaLabel?: string;
  ariaCurrent?: "location";
}

export const BreadcrumbItem = ({
  children,
  link = { href: "/" },
  ariaLabel,
  ariaCurrent
}: ItemProps) => (
  <StyledBreadCrumbItem>
    <Link
      route={"route" in link ? link.route : undefined}
      params={"params" in link ? link.params : {}}
      href={"href" in link ? link.href : undefined}
    >
      <a aria-label={ariaLabel} aria-current={ariaCurrent}>
        {children}
      </a>
    </Link>
    <StyledCaret />
  </StyledBreadCrumbItem>
);

export default Breadcrumb;
