import { Image } from "./image";

export default function(media: Image) {
  if (!media || typeof media === "string") return "center";

  const { focusX, focusY, width, height } = media;
  if (!width || !height) return;

  const positionX = typeof focusX === "number" ? (focusX / width) * 100 : 50;
  const positionY = typeof focusY === "number" ? (focusY / height) * 100 : 50;
  return `${positionX}% ${positionY}%`;
}
