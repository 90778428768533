import React, { ReactNode } from "react";
import styled, { css } from "styled-components";
import { colors } from "../theme";

interface Props {
  children: ReactNode | ReactNode[];
  white?: boolean;
  className?: string;
  smallShadow?: boolean;
  embeddedStyle?: boolean;
}

const Card = styled.div<Props>`
  background: ${colors.pigeonSuperLight};
  box-shadow: 0 60px 80px -16px rgba(0, 69, 106, 0.08);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  ${(p: Props) =>
    p.white &&
    css`
      background: white;
      box-shadow: 0 0 24px 0 ${colors.bluegrey},
        0 60px 80px -16px ${colors.blue}12;
    `}
  ${p =>
    p.smallShadow &&
    css`
      box-shadow: 0 16px 16px -10px ${colors.bluegrey};
      border: 2px solid ${colors.lightGrey};
    `}
  ${p =>
    p.embeddedStyle &&
    css`
      box-shadow: none;
      border: 0;
    `}
`;

export const SliderCard = styled(Card)`
  box-shadow: 0 4px 4px 0 ${colors.cloudyBlueTwo};
`;

export default Card;
