import { colors, media, spacing, gradientBackground } from "../../theme";
import styled, { css } from "styled-components";
import Content from "../Content";
import React, { useContext } from "react";
import { ReactNode } from "react";
import SubNav, { SubNavLinksType } from "./SubNav";
import Breadcrumbs, { BreadcrumbPathType } from "./Breadcrumbs";
import HeadlineBox, { HeadlineBoxProps } from "./HeadlineBox";
import GlobalContext from "../../context/GlobalContext";
import ReactResizeDetector from "react-resize-detector";
import { generateAutoPlacementForIE } from "../Grid";
const LinearBackground = styled.div`
  width: 100%;
  ${gradientBackground};
  color: #fff;
`;
const Background = styled.div`
  width: 100%;
  background-color: ${colors.blue};
  color: #fff;
`;
const BreadcrumbsStyled = styled(Breadcrumbs)`
  grid-area: breadcrumbs;
  height: 100%;
  padding: 12px 0;
  @media screen and ${media.mobile} {
    display: none;
  }
  @media screen and ${media.desktop} {
  }
  @media print {
    display: none;
  }
`;
const InfoBoxWrapper = styled.div<{
  fullInfoBox?: boolean;
  infoBoxBlock?: boolean;
  infoBoxTop?: boolean;
}>`
  grid-area: infobox;
  display: ${p => (p.infoBoxBlock ? "block" : "flex")};
  justify-self: flex-end;
  ${p => p.fullInfoBox && "height: 100%;"}
  width: 100%;
  align-self: flex-start;
  justify-content: flex-start;
  ${p => !p.infoBoxTop && spacing("margin-top", 64)}
  @media screen and ${media.tablet} {
    position: static;
    justify-self: center;
    width: 100%;
    ${spacing("margin-top", 8)};
  }
  ${p =>
    !p.infoBoxBlock &&
    css`
      > * {
        max-width: 100%;
      }
    `}
`;
const StyledContent = styled(Content.withComponent("header"))<{
  fullInfoBox?: boolean;
  infoboxLayout?: boolean;
  infoBoxTop?: boolean;
  noMargin?: boolean;
  infoBoxTopOnTablet?: boolean;
}>`
  display: grid;
  display: -ms-grid;
  column-gap: calc(4px * 12);
  grid-template-rows: max-content;
  ${p =>
    p.infoboxLayout
      ? p.fullInfoBox
        ? css`
            -ms-grid-columns: 1fr calc(4px * 12) 3fr;
            -ms-grid-rows: auto auto auto;
            grid-template-columns: 1fr 3fr;
            grid-template-areas:
              "breadcrumbs breadcrumbs"
              "headline infobox"
              "subnav subnav";
            > :nth-child(1) {
              -ms-grid-column: 1;
              -ms-grid-row: 1;
              -ms-grid-column-span: 3;
            }
            > :nth-child(2) {
              -ms-grid-column: 1;
              -ms-grid-row: 2;
            }
            > :nth-child(3) {
              -ms-grid-column: 1;
              -ms-grid-row: 3;
              -ms-grid-column-span: 3;
            }
            > :nth-child(4) {
              -ms-grid-column: 3;
              -ms-grid-row: 2;
            }
          `
        : p.infoBoxTop
        ? css`
            -ms-grid-columns: 1fr calc(4px * 12) 336px;
            grid-template-columns: 1fr 336px;
            grid-template-areas:
              "breadcrumbs infobox"
              "headline infobox"
              "subnav subnav";

            > :nth-child(1) {
              -ms-grid-column: 1;
              -ms-grid-row: 1;
            }
            > :nth-child(2) {
              -ms-grid-column: 1;
              -ms-grid-row: 2;
            }
            > :nth-child(3) {
              -ms-grid-column: 1;
              -ms-grid-row: 3;
              -ms-grid-column-span: 3;
            }
            > :nth-child(4) {
              -ms-grid-column: 3;
              -ms-grid-row: 1;
              -ms-grid-row-span: 2;
            }
          `
        : css`
            -ms-grid-columns: 1fr calc(4px * 12) 336px;
            grid-template-columns: 1fr 336px;
            grid-template-areas:
              "breadcrumbs breadcrumbs"
              "headline infobox"
              "subnav subnav";

            > :nth-child(1) {
              -ms-grid-column: 1;
              -ms-grid-row: 1;
              -ms-grid-column-span: 3;
            }
            > :nth-child(2) {
              -ms-grid-column: 1;
              -ms-grid-row: 2;
            }
            > :nth-child(3) {
              -ms-grid-column: 1;
              -ms-grid-row: 3;
              -ms-grid-column-span: 3;
            }
            > :nth-child(4) {
              -ms-grid-column: 3;
              -ms-grid-row: 2;
            }
          `
      : css`
          ${generateAutoPlacementForIE(p.children, "1fr", {
            row: 0,
            column: 4 * 12
          })}
          grid-template-areas:
            "breadcrumbs"
            "headline"
            "subnav";
        `}
  ${p =>
    !p.noMargin &&
    css`
      ${spacing("margin-bottom", 24)}
    `}

  @media screen and ${p => (p.fullInfoBox ? media.mobile : media.tablet)} {
    grid-template-columns: 1fr;
    ${p =>
      generateAutoPlacementForIE(p.children, "1fr", {
        row: 0,
        column: 4 * 12
      })}
    ${p =>
      p.infoBoxTopOnTablet
        ? css`
            grid-template-areas: "breadcrumbs" "infobox" "headline" "subnav";
          `
        : css`
            grid-template-areas: "breadcrumbs" "headline" "subnav" "infobox";
          `}
    
  }
  @media print and (-ms-high-contrast:none){
    display:block;
  }

`;

const SubNavStyled = styled(SubNav)`
  grid-area: subnav;
`;
const HeadlineBoxStyled = styled(HeadlineBox)`
  grid-area: headline;
  ${spacing("margin-top", 64)};
`;
export type Props = HeadlineBoxProps & {
  subLinks?: SubNavLinksType;
  breadCrumpPath: BreadcrumbPathType;
  infoBox?: ReactNode;
  linearBackground?: boolean;
  background?: boolean;
  children?: ReactNode;
  className?: string;
  overlap?: number;
  tiltChevron?: boolean;
  fullInfoBox?: boolean;
  infoBoxBlock?: boolean;
  infoBoxTop?: boolean;
  infoBoxTopOnTablet?: boolean;
  noMargin?: boolean;
  onResize?: () => void;
};
const TopBox: React.FC<Props> = (props: Props) => {
  const {
    overline,
    title,
    intro,
    infoBox,
    overlap,
    tiltChevron,
    fullInfoBox,
    infoBoxTop,
    infoBoxTopOnTablet,
    infoBoxBlock,
    onResize,
    noMargin
  } = props;
  const hBoxProps = { overline, title, intro, overlap };
  const Wrapper = props.linearBackground
    ? LinearBackground
    : props.background
    ? Background
    : React.Fragment;
  const wrapperStyles =
    props.linearBackground || props.background
      ? { className: props.className }
      : {};
  const contentStyles = !(props.linearBackground || props.background)
    ? { className: props.className }
    : {};

  const whiteFont = props.linearBackground || props.background;

  return (
    <Wrapper {...wrapperStyles}>
      <ReactResizeDetector handleHeight onResize={onResize} />
      <StyledContent
        relative
        padded
        infoboxLayout={!!props.infoBox}
        {...contentStyles}
        fullInfoBox={fullInfoBox}
        infoBoxTop={infoBoxTop}
        infoBoxTopOnTablet={infoBoxTopOnTablet}
        noMargin={noMargin}
      >
        <BreadcrumbsStyled
          path={props.breadCrumpPath}
          blueGrey={!!props.linearBackground}
          white={whiteFont}
        />
        <HeadlineBoxStyled
          {...hBoxProps}
          white={whiteFont}
          labelBlueGrey={!!props.linearBackground}
        />
        {props.subLinks && props.subLinks.length > 0 ? (
          <SubNavStyled items={props.subLinks} tiltChevron={tiltChevron} />
        ) : (
          <div />
        )}
        {props.children && props.children}
        {props.infoBox && (
          <InfoBoxWrapper
            fullInfoBox={fullInfoBox}
            infoBoxBlock={infoBoxBlock}
            infoBoxTop={infoBoxTop}
          >
            {infoBox}
          </InfoBoxWrapper>
        )}
      </StyledContent>
    </Wrapper>
  );
};

export default TopBox;
