import React, { ReactNode } from "react";
import styled, { css } from "styled-components";
import { media, fontSizing } from "../../theme";
import Headline from "../Headline";
import Label from "../Label";
import Paragraph from "../Paragraph";

const Box = styled.div`
  display: grid;
  grid-auto-rows: auto;
  grid-column-gap: calc(4px * 5);
  grid-row-gap: calc(4px * 12);
  grid-template-columns: 1fr;
`;
const Header = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledParagraph = styled(Paragraph)<{ overlap?: number }>`
  @media screen and ${media.desktop} {
    ${p =>
      p.overlap &&
      css`
        padding-bottom: ${p.overlap - 50}px;
      `}
  }
`;
const StyledHeadline = styled(Headline)`
  @media print {
    ${fontSizing("headline", 32)}
  }
`;
const StyledLabel = styled(Label)`
  font-weight: 400;
`;

export interface HeadlineBoxProps {
  overline?: string | ReactNode;
  title: string;
  intro?: (() => ReactNode) | string;
  className?: string;
  overlap?: number;
  white?: boolean;
  labelBlueGrey?: boolean;
}

const HeadlineBox: React.FC<HeadlineBoxProps> = props => {
  return (
    <Box className={props.className}>
      <Header>
        {props.overline && (
          <StyledLabel white={props.white} blueGrey={props.labelBlueGrey}>
            {props.overline}
          </StyledLabel>
        )}
        <StyledHeadline size={1} level={1} white={props.white}>
          {props.title}
        </StyledHeadline>
        {props.intro && (
          <StyledParagraph serif overlap={props.overlap}>
            {typeof props.intro === "string" ? props.intro : props.intro()}
          </StyledParagraph>
        )}
      </Header>
    </Box>
  );
};
export default HeadlineBox;
