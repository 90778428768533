import styled, { keyframes } from "styled-components";

const bounce = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
`;

const Indicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
`;

const Svg = styled.svg`
  margin: 2px;
  width: 16px;
  height: 16px;
  animation: ${bounce} 1.4s ease-in-out 0s infinite both;
  fill: currentColor;
  :nth-child(1) {
    animation-delay: -0.32s;
  }
  :nth-child(2) {
    animation-delay: -0.16s;
  }
`;

const Dot = () => (
  <Svg xmlns="http://www.w3.org/2000/svg">
    <path d="M16 0c-.6 5.3-.6 10.6 0 16H0C.6 11 .7 5.7 0 0h16z" />
  </Svg>
);

export default function LoadingIndicator(props) {
  return (
    <Indicator {...props}>
      <Dot />
      <Dot />
      <Dot />
    </Indicator>
  );
}
