import {
  Link as RouterLink,
  RouteName,
  RouteParams,
  Router
} from "../../lib/routes";
import { Children, Component, ReactNode } from "react";
import * as React from "react";

export interface LinkProps {
  href?: string;
  route?: RouteName;
  params?: RouteParams;
  prefetch?: boolean;
  shallow?: boolean;
  scroll?: boolean;
  replace?: boolean;
  onError?(error: Error): void;
  className?: string;
  children: ReactNode | ReactNode[];
}

export default class Link extends Component<LinkProps> {
  state = {
    active: false
  };
  componentDidMount(): void {
    this.changeRoute();
    Router.events.on("routeChangeComplete", this.changeRoute);
  }

  componentWillUnmount(): void {
    Router.events.off("routeChangeComplete", this.changeRoute);
  }

  setChildrenActive = (child, isExternal, isMail) => {
    const oldClass = child.props.className || "";
    const props = {
      ...child.props,
      className: this.state.active ? oldClass + " active" : oldClass,
      target: isMail ? "_self" : isExternal ? "_blank" : undefined,
      rel: isExternal ? "noopener" : undefined
    };

    return React.cloneElement(child, props);
  };

  changeRoute = () => {
    if (
      this.props.route &&
      window &&
      window.history &&
      window.history.state &&
      window.history.state.url
    ) {
      this.setState(() => ({
        active: window.history.state.url === "/" + this.props.route + "?"
      }));
    }
  };

  render() {
    const { route, href, children, prefetch, ...linkProps } = this.props;
    if (
      linkProps.params &&
      Object.values(linkProps.params).some(e => e === undefined)
    ) {
      return null;
    }
    const hrefString = (href || "").toString();
    const isAbsolute = hrefString.includes("://");
    const isExternal =
      isAbsolute &&
      (hrefString.includes("veranstaltungen-ifbhh.de") ||
        (!hrefString.includes("ifbhh.de") && !hrefString.includes("ifb-web.")));
    const isMail = isExternal && hrefString.includes("mailto");

    const page = route || href;

    if (!page) return children;
    const [FirstElement] = Children.toArray(children);
    return (
      <RouterLink
        route={isAbsolute ? undefined : page}
        href={href}
        passHref
        prefetch={isExternal ? false : prefetch}
        {...linkProps}
      >
        {this.setChildrenActive(FirstElement, isExternal, isMail)}
      </RouterLink>
    );
  }
}
