import { Media, MediaRef } from "./CmsApi";

export default function staticMedia(media: string | Media & MediaRef): string {
  if (!media) return null;
  if (typeof media === "string") return media;
  return media._src;
}

export function sizedMedia(
  media: (Media & MediaRef) | string,
  width: number,
  height?: number
) {
  if (!media) return null;

  const getSize = (
    media: Media | string,
    size: number,
    type: "width" | "height"
  ) => {
    if (!size) return null;
    const s =
      typeof media === "string"
        ? size
        : media[type] && size > media[type]
        ? media[type]
        : size;

    return parseInt(String(s));
  };
  const mediaURL = typeof media === "string" ? media : media._src;

  if (!mediaURL) return null;

  if (!mediaURL.startsWith("http")) {
    return mediaURL; //TODO: Scale Images from Static
  }
  return `/api/its/?src=${mediaURL}${
    width ? `&width=${getSize(media, width, "width")}` : ""
  }${height ? `&height=${getSize(media, height, "height")}` : ""}`;
}
