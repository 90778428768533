import React, { Fragment } from "react";
import Headline from "../common/Headline";
import { Download as DownloadIcon } from "./icons";
import styled from "styled-components";
import { colors, font, media, spacing, fontSizing } from "../theme";
import BookmarkButton from "../bookmarks/BookmarkButton";
import { mimeFormat, sizeFormat } from "../../lib/formatters";
import { Media, MediaRef, ContentRef } from "../../lib/CmsApi";
import A from "./A";
import { StripedList } from "../bookmarks/BookmarkPage";
import Content from "./Content";

const DownloadContainer = styled.div`
  display: flex;
  align-items: center;
  &:not(:last-child) {
    ${spacing("margin-bottom", 16)}
  }
  @media ${media.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Link = styled(A)`
  width: 100%;
  display: flex;
  align-items: flex-start;
  font-weight: ${font.weightLight};
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: ${colors.pigeonLight};
  text-underline-position: under;
  & > svg {
    margin-right: 25px;
    flex-shrink: 0;
    color: ${colors.pigeonLight};
  }
  & > span {
    ${fontSizing("headline", 24)}
    font-weight: normal;
    white-space: normal;
    word-wrap: normal;
    &:not(:last-child) {
      padding-right: 1ch;
    }
  }
`;

const StyledDownloadIcon = styled(DownloadIcon)`
  margin-top: 6px;
  height: 1em;
  width: 1em;
`;
const StyledSubHeadline = styled(Headline)`
  ${spacing("margin-top", 48)}
`;
const StyledBookmarkButton = styled(BookmarkButton)`
  min-width: 110px;
  margin-left: 50px;
  @media ${media.mobile} {
    margin-top: calc(4px * 4);
  }

  @media screen and ${media.superTinyZoom125} {
    margin: calc(4px * 4) auto 0;
  }
`;
const UnstripedRow = styled(Content.withComponent("li"))`
  :not(:last-child) {
    ${spacing("margin-bottom", 12)}
    @media ${media.mobile} {
      ${spacing("margin-bottom", 32)}
    }
  }
`;

const NoStyleList = styled.ul`
  margin: 0;
  padding: 0;
`;

const NoStyleListElement = styled.li`
  list-style: none;
`;

interface SimpleDownloadProps {
  title: string;
  id?: string;
  size?: number;
  mimetype?: string;
  isDeleted?: boolean;
  href?: string;
}
export type DownloadProps = {
  id: string;
  wideButton?: boolean;
} & SimpleDownloadProps;
export const SimpleDownload = (props: SimpleDownloadProps) => {
  const type = props.mimetype ? mimeFormat(props.mimetype) : null;

  const id =
    props.href && props.href.includes("/media/")
      ? props.href.split("/media/")[1]
      : props.id;
  if (!id) {
    return null;
  }

  const parsedHref = props.href
    ? `/api/services/attachments/${encodeURIComponent(
        props.title.replace(/\s+/g, "_").trim()
      )}.${id.split(".")[1]}?id=${id}`
    : `/api/services/document/${id}`;

  return (
    <Link href={parsedHref} target="_blank" className="download" tabIndex={0}>
      <StyledDownloadIcon />
      <span id={id}>
        {props.title}
        {(type || props.size) && (
          <>
            {" "}
            ({type && type}
            {type && props.size && ", "}
            {props.size && sizeFormat(props.size)})
          </>
        )}
      </span>
    </Link>
  );
};
export const Download = ({ id, wideButton, ...props }: DownloadProps) => {
  return (
    <DownloadContainer>
      <SimpleDownload {...props} id={id} />
      <StyledBookmarkButton
        id={`${id}_bookmark_cta`}
        // see suggestion of https://report.bitvtest.de/51c6ded0-9ca7-4305-b76d-7400cccfa873.html#checkpoint-3e9f161271-v2-n2
        // see IFB-1100
        aria-labelledby={`${id} ${id}_bookmark_cta`}
        tiny
        wide={wideButton}
        activeText={"Gemerkt"}
        bookmark={{
          id,
          title: props.title
        }}
        isDownload
      >
        Merken
      </StyledBookmarkButton>
    </DownloadContainer>
  );
};
export interface Download {
  _id: string;
  name: string;
  file: Media & MediaRef;
  type: {
    name: string;
  };
}
export interface DownloadListProps {
  downloads: (ContentRef & Download)[];
  headline?: string;
  className?: string;
  padded?: boolean;
  striped?: boolean;
}

export default function Downloads({
  headline,
  downloads,
  className,
  padded,
  striped
}: DownloadListProps) {
  if (!downloads || downloads.length === 0) return null;

  const sortedDownloads = downloads
    .filter(d => d.type && d.type.name)
    .reduce<{ [s: string]: Download[] }>((acc, d) => {
      if (acc[d.type.name]) {
        acc[d.type.name].push(d);
      } else {
        acc[d.type.name] = [d];
      }
      return acc;
    }, {});
  return (
    <div className={className}>
      {headline && (
        <Headline size={4} grey bold level={2}>
          {headline}
        </Headline>
      )}
      <NoStyleList>
        {Object.entries(sortedDownloads).map(([type, downloads]) =>
          downloads.length !== 0 ? (
            <NoStyleListElement key={type}>
              <Content padded={padded}>
                <StyledSubHeadline size={5} grey bold level={3}>
                  {type}
                </StyledSubHeadline>
              </Content>
              <NoStyleList>
                {downloads.map((d, idx) =>
                  striped ? (
                    <StripedList key={`${d._id}-${idx}`}>
                      <Content padded={padded}>
                        <Download
                          wideButton
                          title={d.name}
                          size={d.file.size}
                          mimetype={d.file.mimetype}
                          id={d._id}
                        />
                      </Content>
                    </StripedList>
                  ) : (
                    <UnstripedRow padded={padded} key={`${d._id}-${idx}`}>
                      <Download
                        title={d.name}
                        key={`${d._id}-${idx}`}
                        size={d.file.size}
                        mimetype={d.file.mimetype}
                        id={d._id}
                      />
                    </UnstripedRow>
                  )
                )}
              </NoStyleList>
            </NoStyleListElement>
          ) : null
        )}
      </NoStyleList>
    </div>
  );
}
