import React from "react";
import Breadcrumb, { BreadcrumbItem } from "../Breadcrumb";
import { RouteName, RouteParams } from "../../../lib/routes";

export type BreadcrumbPathType = {
  route: RouteName;
  params?: RouteParams;
  title: string;
}[];

interface Props {
  path: BreadcrumbPathType;
  className?: string;
  white?: boolean;
  blueGrey?: boolean;
}

const Breadcrumbs: React.FC<Props> = ({ path, className, white, blueGrey }) => {
  return (
    <Breadcrumb className={className} white={white} blueGrey={blueGrey}>
      <BreadcrumbItem
        link={{ route: "index" }}
        ariaLabel={"Start - zur Homepage"}
      >
        Start
      </BreadcrumbItem>
      {path.map((p, i) => (
        <BreadcrumbItem
          key={i}
          link={{
            route: p.route,
            params: p.params
          }}
          ariaCurrent={i === path.length - 1 ? "location" : undefined}
        >
          {p.title}
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};
export default Breadcrumbs;
