import styled from "styled-components";
import { visuallyHidden, FOCUS_CLASS_NAME } from "../GlobalStyle";
import { colors, fontSizing } from "../theme";

const Checkbox = styled("input")<{ lightTheme?: boolean }>`
  ${visuallyHidden}
  & + label::after {
    content: none;
  }
  & :checked + label::after {
    content: "";
  }
  & :checked + label::before {
    background: ${p => (p.lightTheme ? colors.white : colors.blueDark)};
    border-color: ${p => (p.lightTheme ? colors.white : colors.blueDark)};
  }
  .${FOCUS_CLASS_NAME} &:focus + label::before {
    box-shadow: 0 0 0 2px ${colors.focus};
  }
`;

const CheckboxLabel = styled("label")<{ lightTheme?: boolean }>`
  cursor: pointer;
  position: relative;
  display: inline-block;
  padding-left: calc(4px * 9);
  color: ${p => (p.lightTheme ? colors.white : colors.blueDark)};
  ${fontSizing("text", 24)}
  &::before,
&::after {
    position: absolute;
    content: "";
    display: inline-block;
  }

  &::before {
    height: calc(4px * 6);
    width: calc(4px * 6);
    border: 2px solid
      ${p => (p.lightTheme ? colors.pigeonGreyLight : colors.darkgrey)};
    left: 0px;
    border-radius: 100%;
    top: 50%;
    transform: translateY(-50%);
  }
  &::after {
    color: ${p => (p.lightTheme ? colors.bluegreyDark : colors.white)};
    height: 8px;
    width: 14px;
    border-left: 2px solid;
    border-bottom: 2px solid;
    transform: rotate(-45deg) translateY(-90%);
    left: 10px;
    top: 50%;
  }
`;

export { Checkbox, CheckboxLabel };
