import Head from "next-server/head";
import Seo from "../../lib/models/seo";
export interface SeoProps {
  seo?: Seo;
}
export type Props = { title?: string; desc?: string } & SeoProps;

const CustomPageHead: React.FC<Props> = ({ title, desc, seo = {} }) => {
  const fixedDescPart =
    "Jetzt aktuell informieren zur Förderung für Privatpersonen, Unternehmen und Immobilienwirtschaft.";
  const metaDesc = seo.metaDescription || desc;
  const fullMetaTitle = seo.metaTitle ? seo.metaTitle : (title
    ? `${title} | IFB Hamburg`
    : "IFB Hamburg");
  return (
    <Head key={"IfbHeader"}>
      <title>{fullMetaTitle}</title>
      <meta
        name="description"
        content={
          metaDesc
            ? `${fullMetaTitle}: ${metaDesc}`
            : `${fullMetaTitle}: ${fixedDescPart}`
        }
      />
      {seo && seo.robots && <meta name="robots" content={seo.robots} />}
    </Head>
  );
};

export default CustomPageHead;
