import styled from "styled-components";
import Dots from "./Dots";
import { colors, media, spacing } from "../theme";

const Row = styled.div<{
  innerButtons?: boolean;
  alwaysMobileControls?: boolean;
}>`
  position: absolute;
  ${p => (p.innerButtons ? spacing("bottom", 48) : "bottom: 0")};
  left: 0;
  right: 0;
  @media ${media.mobile} {
    display: ${p => !p.innerButtons && "none"};
  }
  ${p => p.alwaysMobileControls && "display: none"}
`;

const Col = styled.div`
  flex: 1 1 50%;
`;

const RightCol = styled(Col)`
  text-align: center;
  @media ${media.desktop} {
    text-align: right;
  }
`;

const DotContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.pigeonLight};
`;

const DotBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  height: 30px;
  border-radius: 4px;
  width: auto;
`;

interface Props {
  prev: () => void;
  next: () => void;
  goto: (i: number) => void;
  pages: number;
  index: number;
  innerButtons?: boolean;
  ariaLabel: string;
  alwaysMobileControls?: boolean;
  blueControls?: boolean;
}

export default function Controls(props: Props) {
  const {
    goto,
    pages,
    index,
    innerButtons,
    ariaLabel,
    alwaysMobileControls,
    blueControls
  } = props;

  return (
    <Row
      innerButtons={innerButtons}
      aria-label={`${ariaLabel} Slider Navigation`}
      alwaysMobileControls={alwaysMobileControls}
    >
      <Col />
      {pages > 1 && (
        <DotContainer>
          <DotBackground>
            <Dots
              blueControls={blueControls}
              pages={pages}
              index={index}
              goto={goto}
            />
          </DotBackground>
        </DotContainer>
      )}
      <RightCol />
    </Row>
  );
}
