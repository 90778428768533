// see https://github.com/styled-components/styled-components/issues/3427

// eslint-disable-next-line no-restricted-imports
import _styled from "styled-components";

const styledWithForwardProp = tag => {
  // For some reasons styled(Component) breaks when chained by .withConfig()
  // So we only apply shouldForwardProp to HTML tags
  if (typeof tag === "string") {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    return _styled(tag).withConfig({
      shouldForwardProp: (prop, defaultValidatorFn) =>
        ![
          "size", // h1, h2, h3, span, p
          "cols", // p
          "color", // section
          // "hidden", // svg
          "disabled", // li
          "type" // div
          // "aria-pressed", // button -> IFB-1104
          // "name", // meta
          // "value" // td
        ].includes(prop) && defaultValidatorFn(prop)
    });
  }
  return _styled(tag);
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
const styled: typeof _styled = styledWithForwardProp;

// Proxying the `styled-components` tag shortcuts, e.g. `styled.div`, `styled.a`, etc.
Object.keys(_styled).forEach(tag => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  styled[tag] = styledWithForwardProp(tag);
});

// eslint-disable-next-line no-restricted-imports
export * from "styled-components";
export default styled;
