import React, { Fragment, useContext, useEffect } from "react";
import BookmarksContext, { Bookmark } from "./BookmarksProvider";
import { SimpleDownload } from "../common/Downloads";
import styled from "styled-components";
import { colors, media, spacing, fontSizing } from "../theme";
import DefaultContent from "../common/Content";
import Link from "../utils/Link";
import DefaultBookmarkButton from "./BookmarkButton";
import { Link as LinkIcon } from "../common/icons";
import CommonButton, { defaultButtonStyle } from "../common/Button";
import LoadingIndicator from "../common/LoadingIndicator";
import A from "../common/A";
import Flex from "../common/Flex";
import CustomPageHead from "../common/CustomPageHead";
import Header from "./Header";

export const StripedList = styled.div`
  &:nth-child(2n) {
    background-color: ${colors.paleGrey};
  }
  padding: calc(4px * 5) 0;
  @media ${media.mobile} {
    padding: calc(4px * 8) 0;
  }
`;

const BoxHeader = styled(DefaultContent)`
 ${spacing("margin-bottom", 24)}
  @media ${media.mobile} {
    flex-direction: column;
    align-items: flex-start;
    & button {
      ${spacing("margin-top", 24)}
    }
  }
`;

const EntryContent = styled(DefaultContent)`
  > div {
    flex-shrink: 1;
    width: 100%;
  }
  @media ${media.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Container = styled.div`
  ${spacing("margin-bottom", 160)}
`;

const BookmarkButton = styled(DefaultBookmarkButton)`
  margin-left: 50px;
  @media ${media.mobile} {
    margin-top: calc(4px * 4);
  }

  @media screen and ${media.superTinyZoom125} {
    margin: calc(4px * 4) auto 0;
    display: block;
  }
`;

const DownloadAllButton = styled.a`
  ${defaultButtonStyle}
  display: inline-block;
  margin: auto;
  height: auto;
  ${spacing("padding-top", 8)};
  ${spacing("padding-bottom", 8)};
  ${spacing("padding-left", 64)};
  ${spacing("padding-right", 64)};
  ${spacing("margin-top", 64)}
  ${fontSizing("label", 20)}
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
`;

export const ATag = styled(A)`
  flex: 1;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  & > svg {
    margin-right: 25px;
    color: ${colors.pigeonLight};
    flex-shrink: 0;
  }
  & > span {
    text-decoration: underline;
    text-decoration-color: ${colors.pigeonLight};
    font-weight: normal;
    &:not(:last-child) {
      padding-right: 1ch;
    }
  }
`;
const Bold = styled.h2`
  ${fontSizing("headline", 32)}
  margin:0;
`;

const Box = styled.div`
  ${spacing("margin-bottom", 64)}
  ${spacing("margin-top", 64)}
`;
const StyledLinkIcon = styled(LinkIcon)`
  margin-top: 6px;
  height: 1em;
  width: 1em;
`;
export const SimpleLink = (props: Bookmark) => (
  <Link href={props.href}>
    <ATag tabIndex={0}>
      <StyledLinkIcon />
      <span>{props.title}</span>
    </ATag>
  </Link>
);

export type EntryProps = Bookmark & {
  isDownload?: boolean;
  className?: string;
  inverse?: boolean;
  activeText?: string;
  inactiveText?: boolean;
};
export const Entry = ({
  isDownload,
  className,
  inverse,
  activeText,
  inactiveText,
  ...bookmark
}: EntryProps) => {
  return (
    <StripedList className={className}>
      <EntryContent padded row>
        <div>
          {isDownload ? (
            <SimpleDownload {...bookmark} />
          ) : (
            <SimpleLink {...bookmark} />
          )}
        </div>
        <BookmarkButton
          tiny
          wide
          activeText={activeText || "Entfernen"}
          bookmark={bookmark}
          isDownload={isDownload}
          inverse={inverse}
        >
          {inactiveText || "Merken"}
        </BookmarkButton>
      </EntryContent>
    </StripedList>
  );
};

export default function BookmarkPage() {
  const bookmarks = useContext(BookmarksContext);
  const { isLoading, bookmarkedArticles, bookmarkedDownloads } = bookmarks;

  // re-scan page for links when bookmarks are loaded
  useEffect(() => {
    if ("_paq" in window && !isLoading) {
      setTimeout(() => window._paq.push(["enableLinkTracking"]), 0);
    }
  }, [isLoading]);

  return (
    <Fragment>
      <CustomPageHead title="Merkliste" />
      <DefaultContent padded>
        <Header
          headline="Merkliste"
          intro="Auf der Merkliste können Sie interessante Themen und Downloads zu unseren Förderangeboten sammeln, die Sie mit der „Merken“-Funktion markiert haben. Ihre gemerkten Dokumente können Sie auch gesammelt als Zip-Archiv herunterladen und speichern."
        />
      </DefaultContent>
      <main>
        {isLoading ? (
          <DefaultContent padded center>
            <LoadingIndicator />
          </DefaultContent>
        ) : bookmarkedArticles.length === 0 &&
          bookmarkedDownloads.length === 0 ? (
          <DefaultContent padded bottomMargin>
            <Bold>Keine Einträge verfügbar</Bold>
          </DefaultContent>
        ) : (
          <Container>
            {bookmarkedArticles.length > 0 && (
              <Box>
                <BoxHeader row padded>
                  <Bold>Gemerkte Artikel</Bold>
                  <CommonButton
                    tiny
                    wide
                    onclick={bookmarks.removeAllArticles}
                    ghost
                    aria-label={"Alle gemerkten Artikel entfernen"}
                  >
                    Alle entfernen
                  </CommonButton>
                </BoxHeader>
                {bookmarkedArticles.map(bookmark => (
                  <Entry {...bookmark} key={bookmark.id} inverse />
                ))}
              </Box>
            )}
            {bookmarkedDownloads.length > 0 && (
              <Fragment>
                <Box>
                  <BoxHeader row padded>
                    <Bold>Gemerkte Downloads</Bold>
                    <CommonButton
                      tiny
                      wide
                      onclick={bookmarks.removeAllDownloads}
                      ghost
                      aria-label={"Alle gemerkten Downloads entfernen"}
                    >
                      Alle entfernen
                    </CommonButton>
                  </BoxHeader>
                  {bookmarkedDownloads.map(bookmark => (
                    <Entry {...bookmark} isDownload key={bookmark.id} inverse />
                  ))}
                </Box>
                <Flex>
                  <DownloadAllButton
                    download
                    href={`/api/services/downloads/?${bookmarkedDownloads.reduce(
                      (acc, el) => {
                        return el.isDeleted ? acc : acc + `ids=${el.id}&`;
                      },
                      ""
                    )}`}
                  >
                    <span>Gemerkte Downloads</span>
                    <br />
                    <span>herunterladen</span>
                  </DownloadAllButton>
                </Flex>
              </Fragment>
            )}
          </Container>
        )}
      </main>
    </Fragment>
  );
}
