import styled from "styled-components";
import { colors } from "../theme";

interface P {
  active: boolean;
  blueControls?: boolean;
}

const Dot = styled.button<P>`
  padding: 0;
  font: inherit;
  color: inherit;
  display: inline-block;
  margin: 0 8px;
  width: 14px;
  height: 14px;
  min-width: 14px;
  border: none;
  border-radius: 50%;
  cursor: ${(p: P) => (p.active ? "auto" : "pointer")};
  border: 1px solid
    ${(p: P) =>
      !p.active
        ? colors.blueDark
        : p.active
        ? colors.blueDark
        : p.active
        ? p.blueControls
          ? colors.blueDark
          : "currentColor"
        : p.blueControls
        ? colors.blueDark
        : colors.lightBlue};
  background: ${(p: P) =>
    p.active
      ? p.blueControls
        ? colors.blueDark
        : "currentColor"
      : p.blueControls
      ? colors.white
      : colors.lightBlue};

  transition: all 0.25s;
  :hover {
    border-color: currentColor;
    background-color: currentColor;
  }
  :focus {
    outline: none;
    /* box-shadow: 0 0 0 2px ${colors.focus}; */
  }
`;

export default Dot;
