import React, { Component } from "react";
import hoistNonReactStatic from "hoist-non-react-statics";
import api from "../../lib/clientApi";
import { mapping as routes } from "../../lib/routes";

const LOCAL_STORAGE_KEY = "bookmarks";

interface BookmarksContext {
  bookmarkedDownloads: Bookmark[];
  bookmarkedArticles: Bookmark[];
  articleIsBookmarked: (id: string) => boolean;
  downloadIsBookmarked: (id: string) => boolean;
  addArticle: (b: Bookmark) => void;
  removeArticle: (id: string) => void;
  removeAllArticles: () => void;
  addDownload: (b: Bookmark) => void;
  addDownloads: (b: Bookmark[]) => void;
  removeDownload: (id: string) => void;
  removeDownloads: (ids: string[]) => void;
  removeAllDownloads: () => void;
  removeAllBookmarks: () => void;
  allDownloadsAdded: (ids: string[]) => boolean;

  isLoading: boolean;
}

type BookmarkType = "downloads" | "articles";
export interface Bookmark {
  id: string;
  href?: string;
  title: string;
  isDeleted?: boolean;
}

interface State {
  loading: boolean;
  downloads: { [id: string]: Bookmark };
  articles: { [id: string]: Bookmark };
}

export class BookmarksProvider extends Component<{}, State> {
  state: State = {
    loading: true,
    downloads: {},
    articles: {}
  };

  async componentDidMount() {
    if (!localStorage || !localStorage.getItem(LOCAL_STORAGE_KEY)) {
      return;
    }
    try {
      const b = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) as {
        downloads: State["downloads"];
        articles: State["articles"];
      };
      if (b && b.downloads && b.articles) {
        await Promise.all([
          ...Object.keys(b.downloads).map(dId =>
            api.loadDocument(dId, { fields: [] }).catch(e => {
              if (e.status === 404) {
                delete b.downloads[dId];
              }
            })
          )
        ]);

        this.setState({
          loading: false,
          downloads: b.downloads,
          articles: b.articles
        });
      } else {
        this.setState({ loading: false });
      }
    } catch {}
  }

  get bookmarkedDownloads() {
    return Object.values(this.state.downloads);
  }
  get bookmarkedArticles() {
    return Object.values(this.state.articles);
  }

  articleIsBookmarked = (id: string) => !!this.state.articles[id];
  downloadIsBookmarked = (id: string) => !!this.state.downloads[id];

  private persist() {
    const { downloads, articles } = this.state;
    localStorage.setItem(
      LOCAL_STORAGE_KEY,
      JSON.stringify({ articles, downloads })
    );
  }
  private addBookmark = (bookmark: Bookmark, type: BookmarkType) => {
    this.setState(
      prevState => ({
        ...prevState,
        [type]: { ...prevState[type], [bookmark.id]: bookmark }
      }),
      this.persist
    );
  };

  private removeBookmark = (id: string, type: BookmarkType) => {
    this.setState(
      ({ [type]: { [id]: a, ...rest }, ...prevState }) => ({
        ...prevState,
        [type]: rest
      }),
      this.persist
    );
  };
  private removeTypeBookmarks = (type: BookmarkType) => {
    this.setState(
      ({ [type]: { ...rest }, ...prevState }) => ({
        ...prevState,
        [type]: {}
      }),
      this.persist
    );
  };

  addArticle = (bookmark: Bookmark) => {
    return this.addBookmark(bookmark, "articles");
  };
  removeArticle = (id: string) => {
    return this.removeBookmark(id, "articles");
  };
  removeAllArticles = () => {
    return this.removeTypeBookmarks("articles");
  };

  addDownload = (bookmark: Bookmark) => {
    return this.addBookmark(bookmark, "downloads");
  };
  addDownloads = (bookmarks: Bookmark[]) => {
    return bookmarks.forEach(b => this.addBookmark(b, "downloads"));
  };
  removeDownload = (id: string) => {
    return this.removeBookmark(id, "downloads");
  };
  removeDownloads = (ids: string[]) => {
    return ids.forEach(id => this.removeBookmark(id, "downloads"));
  };
  removeAllDownloads = () => {
    return this.removeTypeBookmarks("downloads");
  };

  allDownloadsAdded = (ids: string[]) => {
    let allAdded = true;

    ids.forEach(id => {
      if (!this.state.downloads[id]) allAdded = false;
    });
    return allAdded;
  };

  removeAllBookmarks = () => {
    this.removeTypeBookmarks("articles");
    this.removeTypeBookmarks("downloads");
  };

  render() {
    const { children } = this.props;

    return (
      <BookmarksContext.Provider
        value={{
          bookmarkedDownloads: this.bookmarkedDownloads,
          bookmarkedArticles: this.bookmarkedArticles,
          articleIsBookmarked: this.articleIsBookmarked,
          downloadIsBookmarked: this.downloadIsBookmarked,
          addArticle: this.addArticle,
          removeArticle: this.removeArticle,
          removeAllArticles: this.removeAllArticles,
          addDownload: this.addDownload,
          addDownloads: this.addDownloads,
          removeDownload: this.removeDownload,
          removeDownloads: this.removeDownloads,
          removeAllDownloads: this.removeAllDownloads,
          removeAllBookmarks: this.removeAllBookmarks,
          allDownloadsAdded: this.allDownloadsAdded,
          isLoading: this.state.loading
        }}
      >
        {children}
      </BookmarksContext.Provider>
    );
  }
}

const BookmarksContext = React.createContext<BookmarksContext | null>(null);
export default BookmarksContext;

export interface withBookmarks {
  bookmarks: BookmarksContext | null;
}

export function withBookmarksContext<P extends withBookmarks>(
  Component: React.ComponentType<P>
) {
  function ComponentWithBookmarks(
    props: Pick<P, Exclude<keyof P, keyof withBookmarks>>
  ) {
    return (
      <BookmarksContext.Consumer>
        {b => <Component {...(props as any)} bookmarks={b} />}
      </BookmarksContext.Consumer>
    );
  }
  return hoistNonReactStatic(ComponentWithBookmarks, Component);
}
