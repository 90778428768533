import HeaderWithIntro from "../common/HeaderWithIntro";
import Breadcrumbs from "../common/TopBox/Breadcrumbs";

export default function Header(props: {
  headline: string;
  intro: string;
  className?: string;
}) {
  return (
    <header>
      <Breadcrumbs
        path={[{
          title: "Merkliste",
          route: "bookmarks"
        }]}
      />
      <HeaderWithIntro
        headline={props.headline}
        blue
        noWordBreak
        small
        size={1}
        level={1}
        intro={props.intro}
      />
    </header>
  );
}
