import styled  from "styled-components";
import { MediaRef, Media } from "../../lib/CmsApi";
import { colors } from "../theme";
import { responsiveBackgroundImage } from "../../lib/responsiveImage";
import { Image } from "../../lib/image";
import getImageFocus from "../../lib/getImageFocus";

const StyledLandscapeImage = styled.div<{ bgImg?: Image }>`
  width: 100%;
  padding-bottom: 56.25%;
  background-size: cover;
  background-color: ${colors.anotherblue};
  ${p =>
    p.bgImg &&
    responsiveBackgroundImage({
      image: p.bgImg,
      sizes: {
        0: 300
      }
    })}
  ${p =>
    p.bgImg &&
    `background-position: ${getImageFocus(
      p.bgImg
    )};`}
`;
interface Props {
  image: MediaRef & Media;
  altFallback: string;
  className?: string;
}
const LandscapeImage = ({ image, className, altFallback }: Props) => (
  <StyledLandscapeImage
    className={className}
    bgImg={image}
    role="img"
    aria-label={(image && image.alt) || altFallback || ""}
  />
);

LandscapeImage.getImages = ({ image }: Props) => {
  return image ? [image] : [];
};
export default LandscapeImage;
