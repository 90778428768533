import React, { ReactNode } from "react";
import styled, { css } from "../../lib/styled";
import { colors, font, fontSizing, spacing } from "../theme";

const StyledParagraph = styled.p<ParagraphProps>`
  color: ${colors.darkgrey};
  display: inline-block;
  margin:0;
  white-space: pre-wrap;
  font-weight: ${font.weightRegular};
  line-height: 1.8;
  max-width: 100%;
  ${fontSizing("text", 28)}
  ${p => !p.noMargin && spacing("margin-bottom", 48)}
  ${p =>
    p.small &&
    css<ParagraphProps>`
      line-height: 1.5;
      ${fontSizing("text", 24)}
      ${p => !p.noMargin && spacing("margin-bottom", 32)}
    `}
  ${(p: ParagraphProps) =>
    p.tiny &&
    css<ParagraphProps>`
      ${fontSizing("text", 16)}
      ${p => !p.noMargin && spacing("margin-bottom", 16)}
    `}

  ${p =>
    p.serif &&
    css`
      font-weight: ${font.weightRegular};
      font-family: ${font.secondary};
    `}
  ${p =>
    p.blue &&
    css`
      color: ${colors.blue} !important;
    `}
  ${p =>
    p.noMargin &&
    css`
      margin: 0;
    `}


  column-count: ${(p: ParagraphProps) => p.cols};
`;

export interface ParagraphProps {
  className?: string;
  small?: boolean;
  serif?: boolean;
  tiny?: boolean;
  cols?: number;
  blue?: boolean;
  noMargin?: boolean;
}

const Paragraph = (
  p: ParagraphProps & { children: ReactNode | ReactNode[] }
) => {
  const { children, serif, cols = 1, blue, noMargin } = p;
  return (
    <StyledParagraph
      className={p.className}
      small={p.small}
      tiny={p.tiny}
      cols={cols}
      serif={serif}
      blue={blue}
      noMargin={noMargin}
    >
      {children}
    </StyledParagraph>
  );
};
export default Paragraph;
