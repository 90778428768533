import styled, { css } from "styled-components";
import React, { ReactNode } from "react";
import Headline, { HeadingLevel } from "./Headline";
import Paragraph, { ParagraphProps } from "./Paragraph";
import Label from "./Label";
import { media, spacing } from "../theme";
import { generateAutoPlacementForIE } from "./Grid";

const Header = styled.div<{ twoColumn?: boolean }>`
  display: -ms-grid;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: calc(4px * 6);
  ${p => generateAutoPlacementForIE(p.children, "1fr", 4 * 6)}
  ${p =>
    p.twoColumn &&
    css<{ children: React.ReactNode }>`
      grid-template-columns: 1.5fr 2fr;
      ${p => generateAutoPlacementForIE(p.children, "1.5fr 2fr", 4 * 6)}
      @media screen and ${media.tablet} {
        grid-template-columns: 1.5fr 1fr;
        ${p => generateAutoPlacementForIE(p.children, "1.5fr 1fr", 4 * 6)}
      }
    `}
  @media screen and ${media.tablet}{
    grid-template-columns:1fr;
    ${p => generateAutoPlacementForIE(p.children, "1fr", 4 * 6)}
  }
  ${spacing("margin-bottom", 64)}
`;
export const StyledParagraph = styled(Paragraph)`
  color: inherit;
`;
type Props = ParagraphProps & {
  headline: string;
  overline?: string;
  size?: HeadingLevel;
  level?: HeadingLevel;
  boldHeadline?: boolean;
  noWordBreak?: boolean;
} & (
    | { children: ReactNode | ReactNode[]; introText?: string }
    | { intro: string });

const HeaderWithIntro = (p: Props) => {
  const {
    overline,
    noWordBreak = false,
    headline,
    size = 2,
    level = 2,
    serif,
    blue,
    small,
    boldHeadline
  } = p;
  const pProps = { serif, blue, small };
  return (
    <div>
      {overline && <Label big={size === 1}>{overline}</Label>}
      <Header
        twoColumn={
          !!("intro" in p && p.intro) || !!("children" in p && p.children)
        }
      >
        <div>
          <Headline
            size={size}
            level={level}
            noMargin
            noWordBreak={noWordBreak}
            bold={boldHeadline}
          >
            {headline}
          </Headline>
          {"introText" in p && p.introText && (
            <StyledParagraph noMargin {...pProps}>
              {p.introText}
            </StyledParagraph>
          )}
        </div>
        {"intro" in p && p.intro && (
          <StyledParagraph {...pProps} noMargin>
            {p.intro}
          </StyledParagraph>
        )}
        {"children" in p && p.children}
      </Header>
    </div>
  );
};
export default HeaderWithIntro;
