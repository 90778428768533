import React, { Fragment, ReactNode } from "react";
import styled, { css } from "../../../lib/styled";
import { colors, font, fontSizing, media, spacing } from "../../theme";
import Label from "../Label";
import { Phone, Mail } from "../icons";
import { Contact } from "../../../lib/CmsApi";
import Headline from "../Headline";
import Spacer from "../Spacer";
import { responsiveBackgroundImage } from "../../../lib/responsiveImage";
import { Image } from "../../../lib/image";
import getImageFocus from "../../../lib/getImageFocus";

interface StyledContact {
  stretch?: boolean;
  type: ContactType;
  invertWhite?: boolean;
}
const StyledContact = styled.div<StyledContact>`
  background: ${colors.pigeonSuperLight};
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  ${p =>
    p.stretch &&
    css`
      width: 100% !important;
    `};
  color: ${colors.blue};
  ${fontSizing("text", 28)}
  ${spacing("padding", 48)}

  & a {
    text-decoration-color: ${p =>
      p.type === "Bestandskunden" ? colors.pigeonGrey : colors.lightBlue};
  }
  ${({ type }) =>
    css<StyledContact>`
      background: ${p =>
        type === "Bestandskunden"
          ? colors.lightBlue
          : type === "Beratungszentrum"
          ? colors.pigeonSuperLight
          : p.invertWhite
          ? colors.anotherblue
          : colors.white};
    `}
    @media screen and ${media.mobile}{
      width: 400px;
    }
    @media screen and ${media.tiny}{
      width: 100%;
    }
`;

const StyledContactLink = styled.a<{ blue?: boolean; tabIndex: number }>`
  display: inline;
  color: ${p => (p.blue ? colors.blue : "inherit")};
  text-decoration: underline;
  text-underline-position: under;
  word-break: break-all;
  &:hover {
    color: ${colors.pigeonLight};
  }
`;

const ContactName = styled.span`
  display: block;
  font-weight: ${font.weightLight};
  min-height: calc(4px * 16);

  ${fontSizing("text", 24)}
  ${spacing("margin-bottom", 24)}
`;

const ContactLink = styled(StyledContactLink)`
  ${fontSizing("text", 24)}
`;

const StyledIcon = css`
  margin-right: 1ch;
  color: ${colors.pigeonLight};
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: 6px;
`;

const StyledPhone = styled(Phone)`
  ${StyledIcon}
`;

const StyledMail = styled(Mail)`
  ${StyledIcon}
`;

export const ContactNumber = ({
  phone = "",
  blue
}: {
  phone: string;
  blue?: boolean;
}) => {
  const number = `tel:${phone.replace(/\D/g, "")}`;
  return (
    <StyledContactWrap>
      <StyledPhone ariaHidden="true" />
      <StyledContactLink
        href={number}
        blue={blue}
        aria-label={"Telefonnummer: " + phone.replace(/([^0-9])/gm, "")}
        tabIndex={0}
      >
        {phone}
      </StyledContactLink>
    </StyledContactWrap>
  );
};

const StyledContactWrap = styled.div`
  display: flex;
  align-items: flex-start;
  &:not(:last-child) {
    ${spacing("margin-bottom", 24)}
  }
`;
const StyledContactImage = styled.div<{ image?: Image }>`
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  margin: 0 auto;
  ${spacing("margin-bottom", 24)}
  ${spacing("width", 120)}
  ${spacing("padding-top", 120)}
  ${p =>
    p.image &&
    responsiveBackgroundImage({
      image: p.image,
      sizes: {
        0: 120
      }
    })};
    background-position: ${({ image }) => getImageFocus(image)};
`;

export const ContactMail = ({
  blue,
  email
}: {
  email: string;
  blue?: boolean;
}) => {
  const href = `mailto:${email.trim()}`;
  return (
    <StyledContactWrap>
      <StyledMail ariaHidden="true" />
      <StyledContactLink href={href} blue={blue} tabIndex={0}>
        {email}
      </StyledContactLink>
    </StyledContactWrap>
  );
};

export type ContactType = "Standard" | "Beratungszentrum" | "Bestandskunden";

type Props = Contact & {
  type?: string;
  role: string;
  ctaHeader?: string;
  ctaLabel?: string;
  linkHref?: string;
  withImage?: boolean;
  invertWhite?: boolean;
  stretch?: boolean;
};

const ContactTile = (p: Props) => {
  return (
    <StyledContact
      type={p.type as ContactType}
      invertWhite={p.invertWhite}
      stretch={p.stretch}
    >
      {p.withImage &&
        (p.image ? (
          <StyledContactImage
            role="img"
            aria-label={p.image.alt || p.name}
            image={p.image}
          />
        ) : (
          <StyledContactImage />
        ))}
      <Headline size={4} bold level={3}>
        {p.withImage ? p.name : p.role}
      </Headline>
      <ContactName>{p.withImage ? p.role : p.name}</ContactName>
      <Spacer />
      <address>
        <ContactNumber phone={p.phone} />
        <ContactMail email={p.email} />
      </address>
      {p.linkHref && p.ctaLabel && (
        <Fragment>
          {p.ctaHeader && <Label small>{p.ctaHeader}</Label>}
          <ContactLink href={p.linkHref} tabIndex={0}>
            {p.ctaLabel}
          </ContactLink>
        </Fragment>
      )}
    </StyledContact>
  );
};

export default ContactTile;
