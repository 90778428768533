import React, { ReactNode } from "react";
import styled, { css } from "styled-components";
interface Props {
  flexDirection?: "row" | "column" | "row-reverse" | "column-reverse";
  alignItems?: "stretch" | "center" | "flex-start" | "flex-end";
  justifyContent?:
    | "center"
    | "flex-start"
    | "flex-end"
    | "space-between"
    | "space-evenly"
    | "space-around";
  flexWrap?: boolean;
  children: ReactNode | ReactNode[];
}

const Flex = styled.div<Props>`
  display: flex;
  ${({
    flexDirection = "row",
    alignItems = "stretch",
    justifyContent = "flex-start",
    flexWrap
  }: Props) => css`
    flex-direction: ${flexDirection};
    align-items: ${alignItems};
    justify-content: ${justifyContent};
    ${flexWrap && "flex-wrap:wrap;"}
  `}
`;
export default Flex;

export const FlexColumn = (
  p: Omit<Props, "flexDirection"> & React.HTMLAttributes<HTMLDivElement>
) => <Flex {...p} flexDirection="column" />;
export const FlexRow = (
  p: Omit<Props, "flexDirection"> & React.HTMLAttributes<HTMLDivElement>
) => <Flex {...p} flexDirection="row" />;
