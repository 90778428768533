import React, { ReactNode } from "react";
import styled, { css } from "styled-components";
import { breakpoints, media, spacing } from "../theme";
import Card from "./Card";
import Label from "./Label";
import Headline, { HeadingLevel } from "./Headline";
import Paragraph from "./Paragraph";
import Button from "./Button";
import Grid from "./Grid";
import { Media, MediaRef } from "../../lib/CmsApi";
import { RouteName, RouteParams } from "../../lib/routes";
import LandscapeImage from "./LandscapeImage";
import Spacer from "./Spacer";
import { responsiveBackgroundImage } from "../../lib/responsiveImage";
import Link from "../utils/Link";

const StyledParagraph = styled(Paragraph)<{
  introOnlyOnWide?: boolean;
}>`
  ${spacing("margin-bottom", 32)}
  ${p =>
    p.introOnlyOnWide &&
    css`
      display: none;
      @media screen and ${media.wide} {
        display: inline-block;
      }
    `}
`;

const Content = styled.div<{ embeddedStyle?: boolean; withImage?: boolean }>`
  ${p =>
    p.embeddedStyle
      ? p.withImage
        ? spacing("padding-top", 48)
        : ""
      : spacing("padding", 48)}
  display:flex;
  height: 100%;
  flex-direction: column;
`;
const StyledButton = styled(Button)``;
const StyledLandscapeImage = styled(LandscapeImage)`
  width: calc(100% + 4px);
  margin: 0 -2px;
  -webkit-print-color-adjust: exact !important;
  color-adjust: exact !important;
  ${({ image }) =>
    responsiveBackgroundImage({
      image,
      sizes: {
        0: breakpoints.tiny,
        [breakpoints.tiny]: 650,
        [breakpoints.tablet]: 500
      }
    })}
`;
export interface Props {
  image?: MediaRef & Media;
  withFallbackImage?: boolean;
  type?: string | ReactNode;
  headline: string;
  headlineLevel?: HeadingLevel;
  text?: string;
  cta: string;
  link: { href: string } | { route: RouteName; params: RouteParams };
  children?: ReactNode | ReactNode[];
  className?: string;
  introOnlyOnWide?: boolean;
  bigShadow?: boolean;
  embeddedStyle?: boolean;
  ghost?: boolean;
  grey?: boolean;
}

export function TopicItem(props: Props) {
  const linkAttrs = {
    href: "href" in props.link ? props.link.href : undefined,
    route: "route" in props.link ? props.link.route : undefined,
    params: "params" in props.link ? props.link.params : undefined
  };
  return (
    <Card
      white
      smallShadow={!props.bigShadow}
      embeddedStyle={props.embeddedStyle}
      className={props.className}
    >
      {(props.image || props.withFallbackImage) && (
        <StyledLandscapeImage
          image={props.image}
          altFallback={props.headline}
        />
      )}
      <Content embeddedStyle={props.embeddedStyle} withImage={!!props.image}>
        {props.type && <Label small>{props.type}</Label>}
        <Headline size={4} bold level={props.headlineLevel || 3}>
          <Link {...linkAttrs}>
            <a tabIndex={-1}>{props.headline}</a>
          </Link>
        </Headline>
        {props.text && (
          <StyledParagraph
            small
            blue={!props.grey}
            introOnlyOnWide={props.introOnlyOnWide}
          >
            {props.text}
          </StyledParagraph>
        )}
        {props.children && props.children}
        <Spacer />
        <StyledButton
          {...linkAttrs}
          aria-label={`${props.cta}: ${props.headline}`}
          small
          alignLeft
          ghost={props.ghost}
          href={"href" in props.link ? props.link.href : undefined}
        >
          {props.cta}
        </StyledButton>
      </Content>
    </Card>
  );
}

const TopicGrid = Grid;
export default TopicGrid;
