import { css } from "styled-components";
import { sizedMedia } from "./static";
import {
  breakpointsSrcsetWide,
  maxWidthImage,
  breakpoints
} from "../components/theme";
import { Media, MediaRef } from "./CmsApi";

// Todo: should high density actually be 2x ?!
export const getHighDensityWidth = (
  image: (Media & MediaRef) | string,
  size: number
): number => {
  if (!image) return undefined;
  if (typeof image === "string" || !image.width) return size * 1.5;
  return size * 1.5 > image.width ? image.width : size * 1.5;
};
export const getHighDensityHeight = (
  image: (Media & MediaRef) | string,
  size: number
): number => {
  if (!image) return undefined;
  if (typeof image === "string" || !image.height) return size * 1.5;
  return size * 1.5 > image.height ? image.height : size * 1.5;
};

export const cssBackgroundImageSet = (
  image: (Media & MediaRef) | string,
  size: number
) => {
  // HINT: https://developer.mozilla.org/en-US/docs/Web/CSS/image/image-set is currently only implemented with
  // -webkit vendor-prefix, using it without that results in a w3c validator error
  // see also https://lists.w3.org/Archives/Public/www-validator/2020Apr/0003.html
  return css`
    background-image: ${`url(${sizedMedia(image, size)})`};
    background-image: ${`-webkit-image-set(${`url('${sizedMedia(
      image,
      size
    )}')`} 1x,
    ${`url('${sizedMedia(image, getHighDensityWidth(image, size))}')`} 2x)`};
  `;
};

export function responsiveBackgroundImage({
  image,
  sizes = breakpointsSrcsetWide,
  maxWidth = maxWidthImage,
  prefixes = [],
  tabletNoPrefix = false
}) {
  if (!image) {
    return "";
  }
  const prefix = `${prefixes.join()}${prefixes.length ? "," : ""}`;

  const bpValues = Object.values(sizes);
  const bpKeys = Object.keys(sizes);

  const mediaQuery = (mw: string | number, w: number) => {
    const thisPrefix = tabletNoPrefix
      ? w <= breakpoints.tablet
        ? ""
        : prefix
      : prefix;
    // HINT: https://developer.mozilla.org/en-US/docs/Web/CSS/image/image-set is currently only implemented with
    // -webkit vendor-prefix, using it without that results in a w3c validator error
    // see also https://lists.w3.org/Archives/Public/www-validator/2020Apr/0003.html
    return css`
      @media (min-width: ${mw}px) {
        background-image: ${`${thisPrefix} url(${sizedMedia(image, w)})`};
        background-image: ${`${thisPrefix} -webkit-image-set(${`url('${sizedMedia(
          image,
          w
        )}')`} 1x,
    ${`url('${sizedMedia(image, getHighDensityWidth(image, w))}')`} 2x)`};
      }
    `;
  };

  return [
    css`
      background-image: ${`${prefix} url(${sizedMedia(
        image,
        breakpoints.desktop
      )})`};
    `,
    ...bpKeys.map((w, idx) => mediaQuery(w, bpValues[idx] || maxWidth))
  ];
}

export const responsiveImageSrcset = ({
  image,
  srcSet = [400, 600, 800, 1000, 1200, 1400, 1600],
  sizes = [
    `(max-width: ${breakpoints.tiny}px) 95vw`,
    `(max-width: ${breakpoints.tablet}px) 95vw`,
    `(max-width: ${breakpoints.desktop}px) 95vw`,
    `(max-width: ${breakpoints.wide}px) 95vw`,
    "95vw"
  ]
}: {
  image: (Media & MediaRef) | string;
  srcSet?: number[];
  sizes?: string[];
}) => {
  if (!image) return undefined;

  if (typeof image == "object") {
    srcSet = srcSet.filter(width => width < image.width);
    srcSet.push(image.width);
  }
  srcSet = srcSet.filter(Boolean);
  const srcset = srcSet.map(b => `${sizedMedia(image, b)} ${b}w`);
  return {
    src: sizedMedia(image, breakpoints.desktop),
    srcSet: srcset.join(),
    sizes: sizes.join()
  };
};
