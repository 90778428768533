import React, { Component, ReactNode, TouchEvent } from "react";

interface RenderProps {
  pages: number;
  index: number;
  elementsPerPage: number;
  rootProps: {
    onTouchStart: (e: TouchEvent<HTMLElement>) => void;
    onTouchMove: (e: TouchEvent<HTMLElement>) => void;
    onTouchEnd: (_e: TouchEvent<HTMLElement>) => void;
  };
  strip: {
    transform: string;
    ref: React.RefObject<HTMLDivElement>;
  };
  slidesContainerRef: React.RefObject<HTMLUListElement>;
  slideProps: {
    "data-slide": true;
  };
  goto: (number) => void;
  prev: () => void;
  next: () => void;
}

interface Props {
  render: (renderProps: RenderProps) => ReactNode | ReactNode[];
  slidesContainerRef?: React.RefObject<HTMLUListElement>;
  singleSlide?: boolean;
  numSlides: number;
}

interface State {
  pages: number;
  pageSize: number;
  index: number;
  offset: number;
  elementsPerPage: number;
}

export default class PlainSlider extends Component<Props, State> {
  state = {
    pages: 0,
    pageSize: 0,
    index: 0,
    offset: 0,
    elementsPerPage: 0
  };

  constructor(props: Props) {
    super(props);
    this.slidesContainerRef =
      props.slidesContainerRef || React.createRef<HTMLUListElement>();
  }

  windowInnerWidth: null | number = null;
  startX = 0;
  pageY = 0;

  wrapper = React.createRef<HTMLDivElement>();
  slidesContainerRef = React.createRef<HTMLUListElement>();

  componentDidMount() {
    this.measure();
    if (window) window.addEventListener("resize", this.measure);
  }

  componentWillUnmount() {
    if (window) window.removeEventListener("resize", this.measure);
  }
  componentDidUpdate(prevProps: Props) {
    if (prevProps.numSlides !== this.props.numSlides) this.measure();
  }
  measure = () => {
    // Only measure when window width changes,
    // otherwise on mobile the slider will jump back to first slide
    // every time the browsers navbar appears on scrolling up
    if (this.windowInnerWidth === window.innerWidth) return;
    this.windowInnerWidth = window.innerWidth;

    const el = this.wrapper.current;
    if (!el) return;
    const slides: NodeListOf<HTMLElement> = el.querySelectorAll("[data-slide]");
    const max = el.offsetLeft + el.offsetWidth;
    const cutOff = Array.from(slides).find(s => {
      return Math.ceil(s.offsetLeft) >= max;
    });
    if (cutOff) {
      const l = slides[0].offsetLeft;
      const r =
        slides[slides.length - 1].offsetLeft +
        slides[slides.length - 1].offsetWidth;
      const size = r - l;
      const pageSize: number = this.props.singleSlide
        ? slides[0].offsetWidth
        : cutOff.offsetLeft - l;
      const elementsPerPage = Number(
        (pageSize / slides[0].offsetWidth).toFixed(0)
      );
      // prevent ghost pages
      // e.g. 4.000098 should not turn into 5 pages.
      // this happens sometimes when resizing the window
      const tempPages = Number((size / pageSize).toFixed(2));

      const pages = Math.ceil(tempPages);
      this.setState({ pages, pageSize, index: 0, elementsPerPage });
    } else {
      this.setState({ pages: 1, index: 0, elementsPerPage: slides.length });
    }
  };

  get lastIndex() {
    return this.state.pages - 1;
  }

  goto = (index: number) => {
    this.setState({ index });
    if (this.slidesContainerRef.current) {
      // emulate focus without scrolling, focus needed for a11y
      let x = window.pageXOffset,
        y = window.pageYOffset;
      this.slidesContainerRef.current.focus({ preventScroll: true });
      setTimeout(() => window.scrollTo(x, y), 0);
    }
  };

  navigation = {
    goto: this.goto,

    next: () => {
      let newIndex = this.state.index + 1;
      if (newIndex > this.lastIndex) newIndex = 0;
      this.goto(newIndex);
    },

    prev: () => {
      let newIndex = this.state.index - 1;
      if (newIndex < 0) newIndex = this.lastIndex;
      this.goto(newIndex);
    }
  };

  touchEvents = {
    onTouchStart: (e: TouchEvent<HTMLElement>) => {
      this.startX = e.touches[0].pageX;
      this.pageY = window.pageYOffset;
    },
    onTouchMove: (e: TouchEvent<HTMLElement>) => {
      const { index } = this.state;
      const offset = e.touches[0].pageX - this.startX;
      if (
        window.pageYOffset === this.pageY &&
        (index > 0 || offset <= 0) &&
        (index < this.lastIndex || offset >= 0)
      ) {
        this.setState({ offset });
      }
    },
    onTouchEnd: () => {
      const { offset } = this.state;
      if (offset > 50) this.navigation.prev();
      if (offset < -50) this.navigation.next();
      this.setState({ offset: 0 });
    }
  };

  render() {
    const { render } = this.props;
    const { index, offset, pages, pageSize, elementsPerPage } = this.state;
    const x = -pageSize * index + offset;
    const transform = `translateZ(0) translateX(${x}px)`;
    return render({
      pages,
      elementsPerPage,
      index,
      rootProps: this.touchEvents,
      strip: {
        transform,
        ref: this.wrapper
      },
      slidesContainerRef: this.slidesContainerRef,
      slideProps: {
        "data-slide": true
      },
      ...this.navigation
    });
  }
}
