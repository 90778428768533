import React from "react";
import styled, { css } from "styled-components";
import { media } from "../../theme";
import { RouteName, RouteParams } from "../../../lib/routes";
import A from "../A";
import { Caret } from "../icons";
import Link from "../../utils/Link";

const Evenly = styled.div`
  display: flex;
  justify-items: flex-start;
  align-items: center;
  flex-wrap: wrap;
  @media screen and ${media.mobile} {
    display: block;
  }
`;

const SubLink = styled(A)`
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: flex-start;
  text-decoration: none;
  padding-right: calc(4px * 5);
  flex-basis: 33%;
  flex-grow: 0;
  margin-bottom: 16px;
  > svg {
    margin-left: calc(4px * 3);
    margin-top: 4px;
    margin-right: 24px;
    flex-shrink: 0;
  }
  @media screen and ${media.mobile} {
    padding-right: 0;
    > svg {
      margin-right: 0;
    }
    > span {
      white-space: inherit;
    }
  }
`;
const StyledCaret = styled(Caret)<{ isRotate?: boolean }>`
  ${p =>
    p.isRotate &&
    css`
      transform: rotate(90deg);
    `}
`;

export type SubNavLinksType = (
  | { route: RouteName; params?: RouteParams; title: string }
  | { href: string; title: string })[];
interface SubNavProps {
  items: SubNavLinksType;
  className?: string;
  tiltChevron?: boolean;
}
const SubNav: React.FC<SubNavProps> = props => {
  const { className, tiltChevron } = props;
  return (
    <Evenly className={className}>
      {props.items.map((link, i) => (
        <Link
          route={"route" in link ? link.route : undefined}
          params={"params" in link ? link.params : undefined}
          href={"href" in link ? link.href : undefined}
          key={i}
        >
          <SubLink tabIndex={0}>
            <span>{link.title}</span>
            <StyledCaret isRotate={tiltChevron} caretSize="small" />
          </SubLink>
        </Link>
      ))}
    </Evenly>
  );
};
export default SubNav;
