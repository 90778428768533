import React, { ReactChildren, ReactNode } from "react";
import styled, { css } from "styled-components";
import { media } from "../theme";

export const generateAutoPlacementForIE = (
  children: ReactChildren | ReactNode,
  columnsFraktions: string = "1fr 1fr 1fr",
  gaps: { row: string | number; column: string | number } | number = {
    row: 48,
    column: 20
  },
  swap?: boolean
) => {
  if (typeof gaps === "number") {
    gaps = { row: gaps, column: gaps };
  }
  const columnsFraktion = columnsFraktions.split(" ");
  const columns = columnsFraktion.length;
  const childrenCount = React.Children.count(children);
  const rows = Math.ceil(childrenCount / columns);
  //ATTENTION: the solution for ms-grid-rows ia a compromise to mitigate CSS parsing issues on validator.w3.org
  //HINT: it is the proper long term value to support IE10-11 grid rows, the short term e.g. (0px auto)[6] led to errors
  //      in the w3c validation
  let cssString = css`
    -ms-grid-columns: ${columnsFraktion.join(
      ` ${typeof gaps.column === "number" ? gaps.column + "px" : gaps.column} `
    )};
    -ms-grid-rows: auto
      ${rows > 1 &&
        ` ${
          typeof gaps.row === "number" ? gaps.row + "px" : gaps.row
        } auto`.repeat(rows - 1)};
  `;
  for (let i = 0; i < childrenCount; i++) {
    const fullColumns = columns * 2;
    cssString = css`
         ${cssString}
        > *:nth-child(${swap ? childrenCount - i : i + 1}) {
          -ms-grid-column: ${((i * 2) % fullColumns) + 1};
          -ms-grid-row: ${Math.ceil((i + 1) / columns) * 2 - 1};
        }
      `;
  }
  return cssString;
};

const trimElementsCSS = columnsPerRow => {
  return css`
    > :nth-child(n + ${columnsPerRow + 1}) {
      display: none;
    }
  `;
};

interface P {
  columns?: number;
  tabletColumns?: number;
  mobileColumns?: number;
  tinyColumns?: number;
  rowGap?: string | number;
  columnGap?: string | number;
  trimElements?: boolean;
  disableAutoPlacementForIE?: boolean;
}
const Grid = styled.div<P>`
display: -ms-grid;
display: grid;
grid-template-columns: repeat(${p => p.columns}, 1fr);
grid-auto-rows: auto;
grid-column-gap: ${p =>
  typeof p.columnGap === "number" ? p.columnGap + "px" : p.columnGap};
grid-row-gap: ${p =>
  typeof p.rowGap === "number" ? p.rowGap + "px" : p.rowGap};
${p => p.trimElements && trimElementsCSS(p.columns)}
${p =>
  !p.disableAutoPlacementForIE &&
  generateAutoPlacementForIE(
    p.children,
    Array(p.columns)
      .fill("1fr")
      .join(" "),
    {
      row: "rowGap" in p ? p.rowGap : 4 * 12,
      column: "columnGap" in p ? p.columnGap : 4 * 5
    }
  )}
  @media screen and ${media.tablet} {
  ${p =>
    p.tabletColumns &&
    css<P>`
      ${p => p.trimElements && trimElementsCSS(p.tabletColumns)}
      grid-template-columns: repeat(${p.tabletColumns}, 1fr);
      ${!p.disableAutoPlacementForIE &&
        generateAutoPlacementForIE(
          p.children,
          Array(p.tabletColumns)
            .fill("1fr")
            .join(" "),
          {
            row: "rowGap" in p ? p.rowGap : 4 * 12,
            column: "columnGap" in p ? p.columnGap : 4 * 5
          }
        )}
    `}
}


${p =>
  p.mobileColumns &&
  css<P>`
    @media screen and ${media.mobile} {
      ${p => p.trimElements && trimElementsCSS(p.mobileColumns)}
      grid-template-columns: repeat(${p.mobileColumns}, 1fr);
      ${!p.disableAutoPlacementForIE &&
        generateAutoPlacementForIE(
          p.children,
          Array(p.mobileColumns)
            .fill("1fr")
            .join(" "),
          {
            row: "rowGap" in p ? p.rowGap : 4 * 12,
            column: "columnGap" in p ? p.columnGap : 4 * 5
          }
        )}
    }
  `}
${p =>
  p.tinyColumns &&
  css<P>`
    @media screen and ${media.tiny} {
      ${p => p.trimElements && trimElementsCSS(p.tinyColumns)}
      grid-template-columns: repeat(${p.tinyColumns}, 1fr);
      ${!p.disableAutoPlacementForIE &&
        generateAutoPlacementForIE(
          p.children,
          Array(p.tinyColumns)
            .fill("1fr")
            .join(" "),
          {
            row: "rowGap" in p ? p.rowGap : 4 * 12,
            column: "columnGap" in p ? p.columnGap : 4 * 5
          }
        )}
    }
  `}
`;

Grid.defaultProps = {
  columns: 3,
  columnGap: 4 * 5,
  rowGap: 4 * 12
};
export default Grid;
