import Routes, {
  HTTPHandler,
  LinkProps,
  RouteParams,
  Router as RouterType
} from "next-routes";
import { LinkProps as NextLinkProps } from "next/link";
import { ComponentType } from "react";
import Server from "next-server/dist/server/next-server";

interface RoutesType {
  getRequestHandler(app: Server, custom?: HTTPHandler): HTTPHandler;
  add(name: RouteName, pattern?: string, page?: string): this;
  add(pattern: string, page: string): this;
  add(options: { name: RouteName; pattern?: string; page?: string }): this;
  Link: ComponentType<
    LinkProps & NextLinkProps & { href?: string; passHref?: boolean }
  >;
  Router: RouterType;
  findByName(
    name: RouteName
  ): {
    getAs(params: RouteParams): string;
  };
}
export const routes = (new Routes() as unknown) as RoutesType;

export const { Link, Router } = routes;

export const mapping = {
  blank: "#",
  index: "/",
  segment: "/programme/:segment",
  categoryCluster: "/programme/:segment/:categoryCluster",
  category: "/programme/:segment/:categoryCluster/:category",

  program: "/programme/:segment/:categoryCluster/:category/:slug",
  partnerCategoryProgram: "/partner/:group/:category/:slug",
  shortProgram: "/foerderprogramm/:slug",

  career: "/karriere",
  careerLevel: "/karriere/:slug",
  joboffer: "/karriere/:level/:slug",

  press: "/presse",
  pressRelease: "/presse/meldung/:slug",
  pressArchive: "/presse/archiv",

  magazin: "/magazin",

  news: "/magazin/news",
  newsArticle: "/magazin/news/:slug",

  portraits: "/magazin/portrait",
  portraitArticle: "/magazin/portrait/:slug",

  events: "/magazin/veranstaltungen",

  partners: "/partner",
  partnerGroup: "/partner/:slug",
  partnerCategory: "/partner/:group/:slug",

  // content: "/ifb",
  // contentCategory: "/ifb/:category",
  // contentArticle: "/ifb/:category/:slug",

  aboutUsArticle: "/ueber-uns/:slug",
  aboutUs: "/ueber-uns",

  apManagementArticle: "/aktiv-passiv-management/:slug",
  apManagement: "/aktiv-passiv-management",

  euFunding: "/eu-foerderung",

  infoArticle: "/info/:slug",

  contact: "/kontakt",
  downloads: "/downloads",
  search: "/suche",
  bookmarks: "/merkliste",

  genericPage: "/g/:slug",

  meta: "/meta/:slug",

  dataPrivacy: "/datenschutzerklaerung",
  impressum: "/impressum",
  accessibility: "/barrierefreiheit",

  newsletterSubscribe: "/newsletter",
  newsletterSubscribe2: "/newsletter/danke",
  newsletterSubscribe3: "/newsletter/eingetragen",
  newsletterSubscribeError: "/newsletter/error",
  newsletterSubscribeExisting: "/newsletter/existing",
  newsletterUnSubscribe: "/newsletter/abmeldung",
  newsletterUnSubscribe2: "/newsletter/abmeldung/wiedersehen",
  newsletterUnSubscribe3: "/newsletter/abmeldung/ausgetragen",

  documentNotFound: "/dokumente/404",

  tilgungsrechner: "/services/tilgungsrechner",
  serviceIncome: "/services/finanzass/einkommensrechner",
  serviceQSForum: "/services/qsforum",

  nachhaltigkeit: "/nachhaltigkeit",
  nachhaltigkeitArticle: "/nachhaltigkeit/:slug"
};

// Add all routes
Object.entries(mapping).forEach(([route, pattern]) => {
  routes.add(route, pattern);
});

export function getHref(routeName: RouteName, query?: RouteParams): string {
  const route = routes.findByName(routeName);
  return route.getAs(query);
}

export type RouteParams = RouteParams;
export type RouteName = keyof typeof mapping;

export async function pushRouteWithQuery(routeName: RouteName, query?: any) {
  await Router.pushRoute(routeName, query);
  if (typeof window !== "undefined") {
    window.scrollTo(0, 0);
  }
}
